import axios from 'axios';
import Cookies from 'js-cookie';
import { getSessionCookie } from '../utils/cookie';

// Base axios instance
const client = axios.create({
  baseUrl: process.env.REACT_APP_API_URL,
});

// Allows us to se the Auth header before sending any request to server
client.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const newConfig = config;
    newConfig.headers.Authorization = getSessionCookie('token');
    return newConfig;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

const UNAUTHORIZED = 401;
client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === UNAUTHORIZED) {
      Cookies.remove('googleToken');
      Cookies.remove('token');
      Cookies.remove('1v1meadminuser');

      window.location = '/';
    }
    return Promise.reject(error);
  }
);

export default client;
