import React from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import classNames from 'classnames';
import { convertToDollarAmount } from '../../utils';

import GlobeIcon from '../../assets/Icons/globe.svg';
import PartnerIcon from '../../assets/Icons/partner-badge.svg';
import Button from '../../components/Buttons/Button/Button';

const defaultImage =
  'https://mobile-assets.1v1me.com/users/v1/default_profile.png';

const handleImageError = (e) => {
  e.target.src = defaultImage;
};

const UserProfile = ({ globalUser, openModal, openUsername, isPartner }) => (
  <>
    <div className="md:flex items-center w-full md:overflow-hidden">
      <img
        src={globalUser.user.profile_image_url}
        alt="profile"
        className="rounded-full w-12"
        onError={handleImageError}
      />
      <div className="md:ml-3 mt-3 lg:mt-0 flex-1 overflow-hidden">
        <h2 className="text-white text-2xl font-bold flex items-center gap-2 justify-between overflow-hidden">
          <div className="flex items-center gap-2 justify-between overflow-hidden">
            <span className="truncate">{globalUser.user.username}</span>{' '}
            {isPartner && (
              <div className="shrink-0">
                <img src={PartnerIcon} alt="partner" />
              </div>
            )}
          </div>

          <Button
            text="Update Profile"
            variant="tertiary"
            onClick={openUsername}
          />
        </h2>
        <p className="text-grey-2 text-sm">
          <b>Signed Up:</b>{' '}
          {format(new Date(globalUser.user.created_at), 'MMMM dd, yyyy')}
        </p>
        <p className="text-grey-2 text-sm">
          <b>Status:</b>{' '}
          <span
            className={classNames('uppercase', {
              'text-warning': globalUser.user.status === 'disabled',
              'text-success': globalUser.user.status === 'enabled',
            })}
          >
            {globalUser.user.status}
          </span>
        </p>
        <p className="text-grey-2 text-sm">
          <b>Phone:</b> <span>{globalUser.phone_number || 'Not Set'}</span>
        </p>
        <div className="flex items-center">
          <img src={GlobeIcon} alt="globe" />
          {globalUser.last_check_in ? (
            <div role="button" onClick={openModal} className="text-grey-2 ml-2">
              {globalUser.last_check_in.city}, {globalUser.last_check_in.state}
            </div>
          ) : (
            <p className="text-grey-2 ml-2">--</p>
          )}
        </div>
      </div>
    </div>

    <div className="grid grid-cols-4 gap-4 text-white mt-3">
      <div className=" border-grey-3 border rounded-md p-2 ">
        <p className="text-center">
          {convertToDollarAmount(globalUser.user.user_stats.total_earnings)}
        </p>
        <p className="text-xs text-zinc-500 text-center">EARNED</p>
      </div>
      <div className=" border-grey-3 border rounded-md p-2 ">
        <p className="text-center">
          {globalUser.user.user_stats.follower_count}
        </p>
        <p className="text-xs text-zinc-500 text-center">FOLLOWERS</p>
      </div>
      <div className=" border-grey-3 border rounded-md p-2 ">
        <p className="text-center">
          {globalUser.user.user_stats.completed_matches}
        </p>
        <p className="text-xs text-zinc-500 text-center">MATCHES</p>
      </div>
      <div className=" border-grey-3 border rounded-md p-2 ">
        <p className="text-center">{globalUser.user.user_stats.wins}</p>
        <p className="text-xs text-zinc-500 text-center">WINS</p>
      </div>
    </div>
  </>
);

UserProfile.propTypes = {
  openUsername: PropTypes.func,
  globalUser: PropTypes.shape({
    user: PropTypes.shape({
      profile_image_url: PropTypes.string,
      created_at: PropTypes.string,
      full_name: PropTypes.string,
      username: PropTypes.string,
      status: PropTypes.string,
      user_stats: PropTypes.shape({
        total_earnings: PropTypes.number,
        follower_count: PropTypes.number,
        wins: PropTypes.number,
        completed_matches: PropTypes.number,
      }),
    }),
  }),
};

export default UserProfile;
