/* eslint-disable no-restricted-globals */
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { setSessionCookie, getSessionCookie } from '../../utils/cookie';
import useAuthWithGoogle from '../../hooks/useAuthWithGoogle';
import authenticate from '../../api/auth/auth';
import client from '../../services/client';

const authContext = createContext({
  error: false,
  auth: false,
  god: {},
});

const useAuth = () => {
  const [error, setError] = useState(false);
  const [authed, setAuthed] = useState(false);
  const [god, setGod] = useState({});

  const login = async () => {
    const data = await useAuthWithGoogle();

    if (
      data.status === 500 ||
      data.status === 403 ||
      data.response?.status === 500 ||
      data.response?.status === 403
    ) {
      setError(true);
    } else {
      const {
        data: { admin_user, token },
      } = data;

      setSessionCookie('token', token);
      setSessionCookie('1v1meadminuser', JSON.stringify(admin_user.user.id));
      setError(false);
      setAuthed(true);
      setGod({ id: admin_user.user.id });
    }
  };

  const logout = async () => {
    setAuthed(false);
    setGod({});

    await client.delete(`${process.env.REACT_APP_API_URL}/api/v1/sessions`, {
      data: { 'User-Agent': navigator.userAgent },
    });

    Cookies.remove('googleToken');
    Cookies.remove('token');
    Cookies.remove('1v1meadminuser');

    window.location = '/';
  };

  const checkAuth = async () => {
    const authToken = getSessionCookie('token');
    const user = getSessionCookie('1v1meadminuser');

    if (typeof user === 'string' && authToken?.length) {
      setAuthed(true);
      setGod({ id: Number(user) });
      return;
    }
    const token = getSessionCookie('googleToken');

    if (!token.length) {
      window.location =
        window.location.pathname.length + window.location.search.length > 3
          ? `/?redirectTo=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`
          : '/';
    }

    const data = await authenticate(token);

    if (
      data.status === 500 ||
      data.status === 403 ||
      data.response?.status === 500 ||
      data.response?.status === 403
    ) {
      Cookies.remove('googleToken');
      Cookies.remove('token');
      Cookies.remove('1v1meadminuser');
    } else {
      const {
        data: { admin_user },
      } = data;
      setAuthed(true);
      setGod({ id: admin_user.id });
      setSessionCookie('1v1meadminuser', JSON.stringify(admin_user.user.id));
    }
  };

  return {
    error,
    authed,
    login,
    logout,
    checkAuth,
    setAuthed,
    setGod,
    god,
  };
};

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
