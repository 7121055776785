import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Loader from '../../../components/Loader/Loader';
import client from '../../../services/client';
import { Console, Game } from '../../../types';
import Selected from '../../../assets/Icons/selected.svg';

const ConsoleSelect: FC<{
  games: Game[];
  onConfirm: () => void;
  onSelect: (id: number) => void;
  gameId: number;
  selectedConsole?: number;
}> = ({ games, onConfirm, onSelect, gameId, selectedConsole }) => {
  const [consoles, setConsoles] = useState<Console[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getConsoles = async () => {
      try {
        setLoading(true);
        const { data } = await client.get(
          `${process.env.REACT_APP_API_URL}/api/v1/consoles`
        );
        setConsoles(data);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getConsoles();
  }, []);

  const selectedGame = useMemo(() => {
    if (!games) return undefined;
    return games.find((g) => g.id === gameId);
  }, [games, gameId]);

  const enabledConsoles = useMemo(() => {
    if (!consoles.length || !selectedGame) return [];
    return consoles.filter((c) => selectedGame.console_ids.includes(c.id));
  }, [consoles, selectedGame]);

  return (
    <div className="overflow-y-auto no-scrollbar transition-all duration-300 ease-in-out translate-x-0 fade-in-fast">
      <h3 className="text-3xl font-bold text-white mb-8">Select Console</h3>
      {error && (
        <p className="text-warning text-center">
          There was an issue fecthing the list of consoles
        </p>
      )}
      {loading && (
        <div className="py-12">
          <Loader />
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2  gap-8 sm:mb-24">
        {enabledConsoles.map((console) => (
          <button
            key={`console_${console.id}`}
            type="button"
            onClick={() => {
              onSelect(console.id);
              onConfirm();
            }}
            className={classNames(
              'relative rounded-2xl !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue bg-grey-4 flex flex-col items-center justify-center p-12 transition-opacity ease-in-out hover:opacity-75',
              {
                'opacity-50': selectedConsole && selectedConsole !== console.id,
              }
            )}
          >
            {selectedConsole === console.id && (
              <img
                src={Selected}
                alt="selected"
                className="absolute top-2 right-2 h-12 w-12"
              />
            )}
            <div className="mb-8">
              <img
                src={console.icon_url}
                alt={console.name}
                className="rounded-2xl  mb-8 object-contain"
                height={48}
                width={48}
              />
            </div>
            <p className="font-bold text-white text-xl">{console.name}</p>
            <p className="text-sm text-grey-2">{console.description}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ConsoleSelect;
