/* eslint-disable import/prefer-default-export */
export const HEADER = {
  counters: {
    u_column: 37,
    u_row: 18,
    u_content_image: 28,
    u_content_text: 7,
    u_content_heading: 10,
    u_content_button: 2,
    u_content_menu: 1,
  },
  body: {
    id: '34nYduowRQ',
    rows: [
      {
        id: 'IWAqxLIsrY',
        cells: [1],
        columns: [
          {
            id: 'ifAWbXVslT',
            contents: [
              {
                id: 'eZcFMSuCDb',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/da53a24d-6a71-49a8-85c0-4cf85f2f38b1',
                    width: 2398,
                    height: 332,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: 'https://1v1me.com', target: '_blank' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'SEGsNN4aYL',
        cells: [1],
        columns: [
          {
            id: 'cgJtp86tTG',
            contents: [],
            values: {
              backgroundColor: '#000000',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_37', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_18', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'I-wX3TNkhp',
        cells: [1, 1],
        columns: [
          {
            id: 'IMpFDkPZS1',
            contents: [
              {
                id: 'SqSfH0my7v',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/dcc6bb49-3751-4612-b3d3-0320d3f5c96c',
                    width: 709,
                    height: 384,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_10',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_8', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'AYi8vDVDXY',
            contents: [
              {
                id: 'Ftk3G-q6ub',
                type: 'heading',
                values: {
                  containerPadding: '48px 0px 12px',
                  anchor: '',
                  headingType: 'h3',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_7',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'DOWNLOAD THE APP',
                },
              },
              {
                id: 'zu-GlKqmLL',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/76d73ad1-521f-4446-82b4-c8cd50b7c6bd',
                    width: 400,
                    height: 131,
                    autoWidth: false,
                    maxWidth: '48%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/webbanner',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_11',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_8', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'z7NauV6I9m',
        cells: [1],
        columns: [
          {
            id: 'FbDEsm_j83',
            contents: [
              {
                id: 'BJbBsIqn1-',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6b939366-6cd7-4c64-b091-fadc021680b8',
                    width: 400,
                    height: 124,
                    autoWidth: false,
                    maxWidth: '19%',
                  },
                  textAlign: 'center',
                  altText: '1v1Me',
                  action: {
                    name: 'web',
                    values: { href: 'https://1v1me.com', target: '_blank' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_23',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: { mobile: { hideMobile: false } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_35', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_17', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { desktop: { hideDesktop: false } },
        },
      },
      {
        id: 'oudVuuSmXB',
        cells: [25.76, 10, 10, 10, 10, 10, 24.24],
        columns: [
          {
            id: 'OhUm-JNmeN',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_29', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'QeAT1lN5yn',
            contents: [
              {
                id: 'MsGoiy4bew',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/0f2a6a1d-0d5f-4f3a-b3d8-1c2fd4105688',
                    width: 96,
                    height: 96,
                    autoWidth: true,
                    maxWidth: '100%',
                  },
                  textAlign: 'center',
                  altText: 'tiktok',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://www.tiktok.com/@1v1me',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_24',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_30', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'lBsm5bHyM-',
            contents: [
              {
                id: 'ViNkcSi7sv',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/0222bbd0-f4ba-4d75-88d0-0a2579ac06d6',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitter',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://twitter.com/1v1me',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_25',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_31', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '8TzlLLrBwQ',
            contents: [
              {
                id: 'YQ0_d2ulfA',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/5a59bf00-2670-470d-aed8-3c5ff50cb9a3',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Facebook',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://www.facebook.com/1v1meapp/',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_26',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_32', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'E7-VwyL17N',
            contents: [
              {
                id: 'sOIA_u7IBD',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/820cf7a6-c94a-45c0-aece-cfb15d08d488',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'instagram',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://www.instagram.com/1v1meapp/',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_27',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_33', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'BKudhtcc4r',
            contents: [
              {
                id: 'nCNxFmMcqj',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/8a6de02e-4125-4e25-9ea7-baf666366782',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitch',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://www.twitch.tv/1v1meapp',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_28',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_34', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '4hD1dm-2wc',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_36', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_16', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: {
            mobile: {
              noStackMobile: true,
              backgroundColor: '#000000',
              hideMobile: false,
            },
            desktop: { hideDesktop: false },
          },
        },
      },
      {
        id: 'tYRgQpRhOY',
        cells: [1],
        columns: [
          {
            id: 'yPGkfQS7Pu',
            contents: [
              {
                id: 'HxwSHKZ1Nh',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_7',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Don’t want these emails? <a rel="noopener" href="<%= unsubscribe %>" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6IjwlPSB1bnN1YnNjcmliZSAlPiIsInRhcmdldCI6Il9ibGFuayJ9fQ=="><span style="line-height: 19.6px;">Unsubscribe</span></a></p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_20', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_11', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: 'center',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      textColor: '#ffffff',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: '#000000',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      preheaderText: 'WELCOME TO THE COMMUNITY!',
      linkStyle: {
        body: true,
        linkColor: '#ffc20e',
        linkHoverColor: '#0000ee',
        linkUnderline: false,
        linkHoverUnderline: true,
        inherit: false,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 13,
};

export const WELCOME = {
  counters: {
    u_column: 36,
    u_row: 17,
    u_content_image: 28,
    u_content_text: 7,
    u_content_heading: 10,
    u_content_button: 2,
    u_content_menu: 1,
  },
  body: {
    id: '34nYduowRQ',
    rows: [
      {
        id: 'IWAqxLIsrY',
        cells: [1],
        columns: [
          {
            id: 'ifAWbXVslT',
            contents: [
              {
                id: 'eZcFMSuCDb',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/da53a24d-6a71-49a8-85c0-4cf85f2f38b1',
                    width: 2398,
                    height: 332,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'mvSYYpnL5E',
        cells: [1],
        columns: [
          {
            id: 'CvW3CFUdGi',
            contents: [
              {
                id: 'RonDxKn7vr',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '15px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '100%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_1',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 100%;">THANKS FOR SIGNING UP</p>',
                  _override: {
                    mobile: { fontSize: '12px', containerPadding: '0px' },
                  },
                },
              },
              {
                id: 'ALAiVQEQpi',
                type: 'heading',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '24px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_1',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'WELCOME TO THE COMMUNITY!',
                },
              },
              {
                id: '0qqe2QYdfM',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '16px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">1v1Me is the only safe &amp; legal way to win cash backing your favorite teams in video game wagers</p>',
                },
              },
              {
                id: '-Mf1wGmfeR',
                type: 'image',
                values: {
                  containerPadding: '10px 38px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/3a382388-4a7b-4933-8c70-8e7321686f33',
                    width: 1013,
                    height: 192,
                    autoWidth: false,
                    maxWidth: '60%',
                  },
                  textAlign: 'center',
                  altText: 'Stake & Win Now',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_12',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_2', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_2', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'pPvyoR1CAA',
        cells: [1],
        columns: [
          {
            id: 'es3JHrxpI5',
            contents: [
              {
                id: '5e6bGv1F3I',
                type: 'heading',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '22px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_2',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'GETTING STARTED IS EASY',
                  _override: { mobile: { fontSize: '18px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_3', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_3', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'PDBJrtHtwG',
        cells: [1, 2],
        columns: [
          {
            id: 'Vctn2z5lHQ',
            contents: [
              {
                id: 'MPLVIuIYRi',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/7832e795-2d6a-438a-a33a-870d47ee9f09',
                    width: 97,
                    height: 96,
                    autoWidth: false,
                    maxWidth: '20%',
                  },
                  textAlign: 'right',
                  altText: '1',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_2',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_21', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'Wp-qIqlcKS',
            contents: [
              {
                id: 'fr6j8GmYy5',
                type: 'heading',
                values: {
                  containerPadding: '4px 4px 4px 13px',
                  anchor: '',
                  headingType: 'h2',
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_3',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'FIND A MATCH',
                  _override: {
                    mobile: {
                      fontSize: '18px',
                      containerPadding: '0px 4px 0px 8px',
                    },
                  },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_22', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_12', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'NT3SByMQJS',
        cells: [1],
        columns: [
          {
            id: 'Q9SV6hEHim',
            contents: [
              {
                id: 'qlKXyUm_U7',
                type: 'text',
                values: {
                  containerPadding: '5px',
                  anchor: '',
                  fontSize: '16px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_3',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Check out the STAKE Tab to find an upcoming match</p>',
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
              {
                id: 'PwRPgmcYCK',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/146d2001-0e7b-4da2-927c-a12eae61b2ad',
                    width: 1030,
                    height: 1065,
                    autoWidth: false,
                    maxWidth: '73%',
                  },
                  textAlign: 'center',
                  altText: 'Find a match',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_3',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '0px 24px 24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_4', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_4', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'OaxvkO7UTS',
        cells: [1, 2],
        columns: [
          {
            id: 'ApxBk3BxN8',
            contents: [
              {
                id: '_NOtmBLYcA',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/e21ed7ec-dbd1-45e0-8cb6-de22a7900f7d',
                    width: 97,
                    height: 96,
                    autoWidth: false,
                    maxWidth: '20%',
                  },
                  textAlign: 'right',
                  altText: '2',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_20',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_23', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'UPO6kIlirh',
            contents: [
              {
                id: 'si9XsEpmJ3',
                type: 'heading',
                values: {
                  containerPadding: '4px 4px 4px 13px',
                  anchor: '',
                  headingType: 'h2',
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_8',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'PICK A TEAM',
                  _override: {
                    mobile: {
                      fontSize: '18px',
                      containerPadding: '0px 4px 0px 8px',
                    },
                  },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_24', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_13', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'TgzWbRnsce',
        cells: [1],
        columns: [
          {
            id: '5oG53JzfgY',
            contents: [
              {
                id: '00aOSdmAB8',
                type: 'text',
                values: {
                  containerPadding: '5px',
                  anchor: '',
                  fontSize: '16px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_4',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Tap the STAKE button of the team you want to back</p>',
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
              {
                id: 'EQ7b6ts8Xo',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/f08202c1-019a-4af9-98c8-ff960fc66de7',
                    width: 1054,
                    height: 1101,
                    autoWidth: false,
                    maxWidth: '73%',
                  },
                  textAlign: 'center',
                  altText: 'Pick a team',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_5',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px 24px 24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_5', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_5', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'nLoh8YjqT0',
        cells: [1, 2],
        columns: [
          {
            id: 'J0rtPKKcBx',
            contents: [
              {
                id: 'howOaynvEf',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/f63617b4-740c-4e30-8f89-fb11520ed911',
                    width: 97,
                    height: 96,
                    autoWidth: false,
                    maxWidth: '20%',
                  },
                  textAlign: 'right',
                  altText: '3',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_22',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_27', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '6WoQltBwB0',
            contents: [
              {
                id: '4W8Odi60Jt',
                type: 'heading',
                values: {
                  containerPadding: '4px 4px 4px 13px',
                  anchor: '',
                  headingType: 'h2',
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_10',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'ENTER AMOUNT',
                  _override: {
                    mobile: {
                      fontSize: '18px',
                      containerPadding: '0px 0px 0px 8px',
                    },
                  },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_28', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_15', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'Terph0BSq5',
        cells: [1],
        columns: [
          {
            id: '13i-Xw5z9A',
            contents: [
              {
                id: 'OwIOSqfFU4',
                type: 'text',
                values: {
                  containerPadding: '5px',
                  anchor: '',
                  fontSize: '16px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_6',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Enter the amount that you want to back the team for</p>',
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
              {
                id: 'iGK2aukaQe',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/e2e1cfb2-86d8-49d0-a4da-f4554758b250',
                    width: 1054,
                    height: 927,
                    autoWidth: false,
                    maxWidth: '75%',
                  },
                  textAlign: 'center',
                  altText: 'Enter amount',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_7',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '0px 24px 24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_6', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_6', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'w-J7hx6oX_',
        cells: [27.71, 72.29],
        columns: [
          {
            id: 'eILSNvkBYt',
            contents: [
              {
                id: 'zcUt3KHFxh',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6676a254-3482-43cb-a7cb-fbcfc96ace0f',
                    width: 97,
                    height: 97,
                    autoWidth: false,
                    maxWidth: '20%',
                  },
                  textAlign: 'right',
                  altText: '4',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_21',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: { mobile: { containerPadding: '4px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_25', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'HmalMFOXoY',
            contents: [
              {
                id: 'xGDfmRueGK',
                type: 'heading',
                values: {
                  containerPadding: '4px 4px 4px 13px',
                  anchor: '',
                  headingType: 'h2',
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_9',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'GET PAID INSTANTLY',
                  _override: {
                    mobile: {
                      fontSize: '18px',
                      containerPadding: '0px 4px 0px 8px',
                    },
                  },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_26', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_14', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'UeV-eJHe6F',
        cells: [1],
        columns: [
          {
            id: 'jS60BfFqWA',
            contents: [
              {
                id: 'RlwQc2G4N7',
                type: 'text',
                values: {
                  containerPadding: '5px',
                  anchor: '',
                  fontSize: '16px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_5',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">If your team wins, you’ll get paid out instantly (once the results are verified)</p>',
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
              {
                id: 'E8hn4NW15z',
                type: 'image',
                values: {
                  containerPadding: '16px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/b5702c1b-afc5-4142-8de5-fce3397df185',
                    width: 499,
                    height: 366,
                    autoWidth: false,
                    maxWidth: '48%',
                  },
                  textAlign: 'center',
                  altText: 'Get paid instantly',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_9',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: 'e6qTHjNc5N',
                type: 'image',
                values: {
                  containerPadding: '10px 38px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/429a75cd-7418-4e88-b22e-dc53f9483359',
                    width: 1013,
                    height: 192,
                    autoWidth: false,
                    maxWidth: '58.8%',
                  },
                  textAlign: 'center',
                  altText: 'Stake & Win Now',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_13',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px 24px 24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_7', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_7', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'I-wX3TNkhp',
        cells: [1, 1],
        columns: [
          {
            id: 'IMpFDkPZS1',
            contents: [
              {
                id: 'SqSfH0my7v',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/dcc6bb49-3751-4612-b3d3-0320d3f5c96c',
                    width: 709,
                    height: 384,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_10',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_8', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'AYi8vDVDXY',
            contents: [
              {
                id: 'Ftk3G-q6ub',
                type: 'heading',
                values: {
                  containerPadding: '48px 0px 12px',
                  anchor: '',
                  headingType: 'h3',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_7',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'DOWNLOAD THE APP',
                },
              },
              {
                id: 'zu-GlKqmLL',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6f2a8e3f-9d12-43e2-a64d-46118a7d5f0e',
                    width: 400,
                    height: 131,
                    autoWidth: false,
                    maxWidth: '48%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/AxN12BiEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_11',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_8', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'z7NauV6I9m',
        cells: [1],
        columns: [
          {
            id: 'FbDEsm_j83',
            contents: [
              {
                id: 'BJbBsIqn1-',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6b939366-6cd7-4c64-b091-fadc021680b8',
                    width: 400,
                    height: 124,
                    autoWidth: false,
                    maxWidth: '19%',
                  },
                  textAlign: 'center',
                  altText: '1v1Me',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_23',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: { mobile: { hideMobile: false } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_35', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_17', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { desktop: { hideDesktop: false } },
        },
      },
      {
        id: 'oudVuuSmXB',
        cells: [25.76, 10, 10, 10, 10, 10, 24.24],
        columns: [
          {
            id: 'OhUm-JNmeN',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_29', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'QeAT1lN5yn',
            contents: [
              {
                id: 'MsGoiy4bew',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/d2c56c65-0fbf-475f-9572-90134f7bc268',
                    width: 96,
                    height: 96,
                    autoWidth: true,
                    maxWidth: '100%',
                  },
                  textAlign: 'center',
                  altText: 'tiktok',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/93FpFYkEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_24',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_30', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'lBsm5bHyM-',
            contents: [
              {
                id: 'ViNkcSi7sv',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1681b2d9-916f-4e3a-be71-2ced09bf968a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitter',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/V1PW9RqEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_25',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_31', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '8TzlLLrBwQ',
            contents: [
              {
                id: 'YQ0_d2ulfA',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/856f006d-6370-4054-9a55-e15192f4da7c',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Facebook',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/st8hFXsEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_26',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_32', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'E7-VwyL17N',
            contents: [
              {
                id: 'sOIA_u7IBD',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/33ae9ddb-f9c7-446b-b11b-7297a7738c48',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'instagram',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/BJ4BBSoEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_27',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_33', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'BKudhtcc4r',
            contents: [
              {
                id: 'nCNxFmMcqj',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/f862219c-bbf7-47ab-853f-a0e78e6bd24a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitch',
                  action: {
                    name: 'web',
                    attrs: { href: '{{href}}', target: '{{target}}' },
                    values: {
                      href: 'https://1v1.me/wsKKtQmEMyb',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_28',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_34', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '4hD1dm-2wc',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_36', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_16', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: {
            mobile: {
              noStackMobile: true,
              backgroundColor: '#000000',
              hideMobile: false,
            },
            desktop: { hideDesktop: false },
          },
        },
      },
      {
        id: 'tYRgQpRhOY',
        cells: [1],
        columns: [
          {
            id: 'yPGkfQS7Pu',
            contents: [
              {
                id: 'HxwSHKZ1Nh',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_7',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Don’t want these emails? <a rel="noopener" href="<%= unsubscribe %>" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6IjwlPSB1bnN1YnNjcmliZSAlPiIsInRhcmdldCI6Il9ibGFuayJ9fQ=="><span style="line-height: 19.6px;">Unsubscribe</span></a></p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_20', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_11', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: 'center',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      textColor: '#ffffff',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: '#000000',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      preheaderText: 'WELCOME TO THE COMMUNITY!',
      linkStyle: {
        body: true,
        linkColor: '#ffc20e',
        linkHoverColor: '#0000ee',
        linkUnderline: false,
        linkHoverUnderline: true,
        inherit: false,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 13,
};
