/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import useAudit from '../../contexts/AuditLogContext/AuditLogContext';
import useUsers from '../../contexts/UserContext/UserContext';
import { formattedDate, formateAudit } from '../../utils/index';

import Modal from '../../components/BaseModal/BaseModal';
import Card from '../../components/Card/Card';

const AuditLog = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const { loading, audits, getAudits, paginateAudits } = useAudit();
  const { user } = useUsers();

  useEffect(() => {
    if (user.user) {
      getAudits(user.user.id);
    }
  }, [user]);

  const paginateData = () => {
    paginateAudits(user.user.id);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="mt-3 flex justify-between items-center">
        <h2 className="text-white text-2xl font-extrabold">Audit Log</h2>
        {audits.length && (
          <div
            className="text-secondary cursor-pointer text-sm"
            onClick={openModal}
            role="button"
          >
            See All
          </div>
        )}
      </div>

      {audits.length ? (
        audits
          .slice(0, 3)
          .map(({ title, description, icon_image_url, created_at }) => (
            <div
              className="flex mt-6"
              key={`audit-description-${description}-${created_at}`}
            >
              <div className="rounded-full w-16">
                <img src={icon_image_url} alt="profile" />
              </div>
              <div className="ml-3 w-full">
                <div className="flex justify-between w-full">
                  <h2 className="text-white text-xs text-zinc-500 uppercase">
                    {title}
                  </h2>
                  <h2 className="ml-3 text-white text-xs text-zinc-500 uppercase">
                    {formattedDate(created_at)}
                  </h2>
                </div>

                <h2 className="text-white text-xl">
                  {formateAudit(user.user.id, description, title)}
                </h2>
              </div>
            </div>
          ))
      ) : (
        <Card bordered>
          <p className="text-zinc-500 text-center py-8">
            No audit logs available
          </p>
        </Card>
      )}
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        header="Audit Log"
        paginateData={paginateData}
        loading={loading}
      >
        <div className="">
          {audits.map(
            ({ title, description, icon_image_url, created_at }, index) => (
              <div
                className="flex mt-6"
                key={`audit-description-${description}-${index}`}
              >
                <div className="rounded-full w-16">
                  <img src={icon_image_url} alt="profile" />
                </div>
                <div className="ml-3 w-full">
                  <div className="flex justify-between w-full">
                    <h2 className="text-white text-xs text-zinc-500 uppercase">
                      {title}
                    </h2>
                    <h2 className="ml-3 text-white text-xs text-zinc-500 uppercase">
                      {formattedDate(created_at)}
                    </h2>
                  </div>

                  <h2 className="text-white text-xl">
                    {formateAudit(user.user.id, description, title)}
                  </h2>
                </div>
              </div>
            )
          )}
        </div>
      </Modal>
    </>
  );
};

export default AuditLog;
