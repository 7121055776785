/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';

const btnClass =
  '!pointer-event-none cursor-default transition-all ease-in-out !ring-0 !outline-none sm:text-base text-sm focus-visible:!outline-2 focus-visible:!outline-gold w-full bg-grey-3 text-grey-2 rounded-lg h-full py-2 uppercase font-bold whitespace-nowrap disabled:opacity-40 disabled:pointer-events-none';

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const Schedule = ({
  dates,
  game,
  title,
  icon,
  color,
  slots,
  playersPerTeam,
  disabled,
  teammates,
  user,
  onUpdate,
  offset,
  leagueId,
}) => {
  const [slotsCopy, setSlotsCopy] = useState(() => slots);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const canEdit = offset === 0 || offset === 1;

  useEffect(() => {
    setSlotsCopy(slots);
  }, [slots]);

  const handleToggleSlot = (slotId, day) => {
    setSuccess(false);
    setSlotsCopy((sc) =>
      sc.map((slot) =>
        slot.id === slotId
          ? {
              ...slot,
              days_of_week: slot.days_of_week.map((d) =>
                d.day_of_week === day ? { ...d, selected: !d.selected } : d
              ),
            }
          : slot
      )
    );
  };

  const canSubmit = useMemo(() => {
    if (isEqual(slots, slotsCopy)) {
      return false;
    }

    return true;
  }, [slotsCopy, slotsCopy]);

  const selectedTeam = useMemo(
    () => teammates.filter((t) => t.default),
    [teammates]
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    setSuccess(false);
    try {
      const res = window.confirm('Are you sure?');
      if (res) {
        setIsLoading(true);
        const s = slotsCopy.reduce(
          (prev, curr) => [
            ...prev,
            ...curr.days_of_week.filter((d) => d.selected).map((d) => d.id),
          ],
          []
        );
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/partners/update_availability`,
          {
            user_id: user.user_profile.id,
            period_offset: offset,
            league_id: leagueId,
            timeslot_dow_ids: s,
            teammates: selectedTeam.map((t) => t.user_profile.id),
          }
        );
        onUpdate(leagueId, slotsCopy);
        setSuccess(true);
      }
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const timeToDate = (time) => {
    const [hours, minutes] = time.split(':');
    return `${
      Number(hours) === 0
        ? 12
        : Number(hours) >= 13
        ? Number(hours) - 12
        : hours
    }:${minutes}${Number(hours) >= 12 ? 'PM' : 'AM'}`;
  };

  return (
    <>
      <h3 className="mb-2 font-bold text-white text-xl">
        {canEdit ? 'Edit Available' : 'Edit Unavailable'}
      </h3>
      <div className="mb-12 rounded-3xl overflow-hidden bg-grey-4">
        <div
          style={{ borderColor: color }}
          className="border-l-[16px] flex items-center justify-between pr-4"
        >
          <div className="flex flex-1 shrink-0 gap-2 sm:gap-4 items-start p-4 pr-0 border-b border-grey-3">
            <img
              src={icon}
              alt="player"
              className="w-[32px] h-[32px] sm:h-[48px] sm:w-[48px] object-cover rounded-full mt-1 sm:mt-0 sm:inline-block hidden"
            />
            <div className="sm:w-auto w-full">
              <p className="uppercase text-grey-2 text-xs font-bold flex items-end gap-1 ">
                <img
                  src={icon}
                  alt="player"
                  className="w-[16px] h-[16px] object-cover  rounded-full mt-1 sm:hidden"
                />
                {game}
              </p>
              <h3 className="text-white font-extrabold uppercase  sm:text-lg  gap-4">
                <span className="flex-1">{title}</span>
              </h3>
            </div>
          </div>
          {success && (
            <p className="w-1/4 fade-in-fast text-success bg-success/10 text-center rounded-lg px-2 py-1 text-sm">
              Availablity Submitted For League
            </p>
          )}
          {canSubmit && !success && (
            <div className="w-1/4 fade-in-fast">
              <Button
                large
                rounded
                text="Submit Edits"
                variant="primary"
                isLoading={isLoading}
                disabled={isLoading}
                className="w-full"
                onClick={handleSubmit}
              />
            </div>
          )}
        </div>
        <div className="relative overflow-x-auto short-scroll">
          <div className="grid grid-cols-2 sm:grid-cols-8 pr-4 py-4 gap-2 sm:min-w-[1100px]">
            <div className="sm:sticky left-0 bg-grey-4 z-10" />
            {days.map((day) => (
              <div
                key={`${title}_${day}`}
                className={classNames(' shrink-0 flex-1 block', {})}
              >
                <h4 className="text-center uppercase text-white font-extrabold whitespace-nowrap sm:text-base text-sm">
                  {day}
                </h4>
                <p className="text-center text-xs uppercase whitespace-nowrap">
                  {format(dates[day], 'MMMM dd')}
                </p>
              </div>
            ))}

            {slotsCopy.map((slot, i) => (
              <React.Fragment key={`${title}_slot_${i}`}>
                <div className="px-4 shrink-0 flex-1 sm:sticky left-0 bg-grey-4 z-10">
                  <h4 className="uppercase text-right text-white font-extrabold whitespace-nowrap sm:text-base text-sm">
                    Slot {i + 1}
                  </h4>
                  <p className=" text-xs uppercase text-right whitespace-nowrap leading-none">
                    {timeToDate(slot.start_time)} - {timeToDate(slot.end_time)}{' '}
                    <span className="3xl:inline block">(EST)</span>
                  </p>
                </div>
                {days.map((day) => {
                  const foundDay = slot.days_of_week.find(
                    (d) => d.day_of_week === day
                  );
                  const state = foundDay
                    ? foundDay.selected
                      ? 'Selected'
                      : 'Open'
                    : 'Unavailable';
                  return (
                    <div
                      key={`${title}_slot_${i}_${day}`}
                      className={classNames(' shrink-0 flex-1 block', {})}
                    >
                      <button
                        disabled={!foundDay || disabled}
                        onClick={
                          canEdit
                            ? () => handleToggleSlot(slot.id, day)
                            : undefined
                        }
                        className={classNames(btnClass, {
                          '!bg-gold !text-black': state === 'Selected',
                          '!cursor-pointer hover:opacity-75': canEdit,
                        })}
                      >
                        {state === 'Selected' && 'I CAN PLAY'}
                        {state === 'Open' && 'Open'}
                        {state === 'Unavailable' && 'Unavailable'}
                      </button>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </div>
        {playersPerTeam > 1 ? (
          <div className="flex flex-col sm:flex-row items-center justify-between pb-6 px-4 mt-8 gap-4">
            <h4 className="uppercase text-left text-white font-extrabold whitespace-nowrap sm:w-auto w-full">
              Roster
            </h4>
            <div className="flex-1 flex sm:flex-row flex-col items-center justify-end gap-4 sm:w-auto w-full">
              <div className="flex-1 sm:w-auto w-full justify-end sm:flex-row flex-col flex items-center gap-4 overflow-auto short-scroll">
                <Tag user={user.user_profile} color={color} />
                {selectedTeam.map((t) => (
                  <Tag
                    key={`teammate_${t.user_profile.id}_${title}`}
                    user={t.user_profile}
                    color={color}
                    goesFull
                  />
                ))}
              </div>

              {selectedTeam.length + 1 < playersPerTeam ? (
                <p className="text-xs text-center font-bold text-warning">
                  {playersPerTeam - (selectedTeam.length + 1)} More Required
                </p>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="p-2" />
        )}
      </div>
    </>
  );
};

const Tag = ({ user, disabled, color, goesFull }) => (
  <span
    className={classNames(
      'text-white flex items-center gap-2 rounded-full pr-4  font-bold relative shrink-0 ',
      {
        '!text-black': color.toLowerCase() === '#ffffff',
        '!opacity-50': disabled,
        'h-[40px] sm:h-[32px] sm:justify-start justify-center sm:w-auto w-full sm:text-xs':
          goesFull,
        'text-xs': !goesFull,
      }
    )}
    style={{ backgroundColor: color }}
  >
    <img
      src={user.profile_image_url}
      className={classNames('rounded-full object-cover', {
        'w-[40px] sm:w-[32px] h-[40px] sm:h-[32px] sm:static absolute left-0':
          goesFull,
        'w-[32px] h-[32px] static': !goesFull,
      })}
      alt="avatar"
    />
    {user.username}
  </span>
);

export default Schedule;
