/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { Switch } from '@headlessui/react';
import React, { FC } from 'react';
import Button from '../../../components/Buttons/Button/Button';
import CurrencyInput from '../../../components/CurrencyInput';

const Stakeable: FC<{
  editing?: boolean;
  playerPool?: number;
  onPoolChange?: (value: number) => void;
  entryFee?: number;
  onEntryFeeChange?: (value: number) => void;
  onConfirm: () => void;
  promotionalPayout: boolean;
  onPromoPayoutChange: (value: boolean) => void;
  businessFee?: number;
  onBusinessFeeChange?: (value: number) => void;
  maxStake?: number;
  onMaxStakeChange?: (value: number) => void;
}> = ({
  editing,
  playerPool,
  onPoolChange,
  onConfirm,
  businessFee,
  onBusinessFeeChange,
  maxStake,
  onMaxStakeChange,
  promotionalPayout,
  onPromoPayoutChange,
  entryFee,
  onEntryFeeChange,
}) => (
  <div className="overflow-y-auto no-scrollbar transition-all duration-300 ease-in-out translate-x-0 mb-12 fade-in-fast">
    <h3 className="text-white font-bold text-2xl mb-6">Money Fields</h3>

    <div className="w-full lg:w-5/12 m-auto border border-grey-3 rounded-2xl p-6">
      <p className="text-grey-2 text-center uppercase">Entry Fee</p>
      <CurrencyInput
        value={entryFee}
        onValueChange={onEntryFeeChange}
        name="amount"
      />

      <div className="mt-8 relative">
        <p className="text-white font-bold mb-3">Promotional Payout?</p>
        <div className="flex items-center gap-2">
          <Switch
            checked={promotionalPayout}
            onChange={onPromoPayoutChange}
            className={`${
              promotionalPayout ? 'bg-blue' : 'bg-grey-3'
            } relative inline-flex h-8 w-16 items-center rounded-full`}
          >
            <span
              className={`${
                promotionalPayout ? 'translate-x-9' : 'translate-x-1'
              } inline-block h-6 w-6 transform rounded-full bg-white transition`}
            />
          </Switch>
          <span className="text-white text-xs uppercase">
            {promotionalPayout ? 'Yes' : 'No'}
          </span>
        </div>
      </div>
      <Button
        className="w-full mt-14"
        rounded
        text="Continue"
        variant="primary"
        onClick={() => onConfirm()}
      />
    </div>
  </div>
);

export default Stakeable;
