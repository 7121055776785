const formatMentionMessage = (message) => {
  const findAllCharacters = message.split('¡');

  const removeExtraCharacter = findAllCharacters.map((character) =>
    character.replace('¡', '')
  );

  const changeStringMentionToObject = removeExtraCharacter.map((string) => {
    let totalString = '';
    try {
      // Turn it into an actual object to use
      const mentionMessageToObject = JSON.parse(string);
      totalString += `@${mentionMessageToObject.username}`;
    } catch (e) {
      totalString += string;
    }

    return totalString;
  });

  return changeStringMentionToObject.join('');
};

export default formatMentionMessage;
