/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';

// State
import useAuth from '../../contexts/AuthContext/AuthContext';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext';
import useUserSearch from '../../contexts/UserContext/UserContext';

// Components
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Buttons/Button/Button';
import Modal from '../../components/BaseModal/BaseModal';
import UserInvite from '../../components/UserInvite/UserInvite';

// Images
import UserIcon from '../../components/Icons/user-icon';
import ActualUserIcon from '../../assets/Icons/user-icon.svg';
import TrashIcon from '../../assets/Icons/trash.svg';
import DuplicateIcon from '../../assets/Icons/duplicate.svg';
import RenameIcon from '../../assets/Icons/rename.svg';
import SearchIcon from '../../assets/Icons/search-icon.svg';

const ERROR_MESSAGES = {
  createInviteListError: 'Need a invite list name',
  duplicateError: 'List by that name already exists',
};

const InviteManagement = () => {
  const { god } = useAuth();
  const {
    inviteList,
    tournamentInviteLists,
    getTournamentInviteLists,
    getTournamentInvites,
    createTournamentList,
    createInvite,
    removeInvite,
    updateTournamentInviteName,
    duplicateInviteList,
    deleteInviteList,
    userInviteError,
    setUserInviteError,
    loading,
  } = useTournament();
  const { getUsers, users } = useUserSearch();
  const [inviteParams, setInviteParams] = useSearchParams();

  const sideInfoRef = useRef();
  const initialValues = {
    inviteListName: '',
  };

  const [values, setValues] = useState(initialValues);
  const [tournamentInviteListCreated, setTournamentInviteListCreated] =
    useState(false);
  const [inviteListToShow, setInviteListToShow] = useState({
    inviteId: '',
    tournamentInviteName: '',
  });
  const [searchValue, setSearchValue] = useState('');
  const [userToInvite, setUserToInvite] = useState({});
  const [userInvited, setUserInvited] = useState();
  const [userIndex, setUserIndex] = useState(0);
  const [usersList, setUsersList] = useState(users);

  // Modals
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openUserSearchModal, setUserSearchModal] = useState(false);
  const [openRenameInviteModal, setRenameInviteModal] = useState(false);
  const [openDuplicateListModal, setDuplicateListModal] = useState(false);
  const [searchIsOpen, setIsSearchOpen] = useState(false);

  const [error, setError = false] = useState('');

  const scrollRef = useRef(null);

  useEffect(() => {
    getTournamentInviteLists();
  }, []);

  useEffect(() => {
    const inviteParamsId = Number(inviteParams.get('inviteId'));
    if (
      tournamentInviteLists.length > 0 &&
      !tournamentInviteListCreated &&
      !inviteParamsId
    ) {
      setInviteParams(`?inviteId=${tournamentInviteLists[0].id}`, {
        replace: true,
      });
      setInviteListToShow({
        inviteId: tournamentInviteLists[0].id,
        tournamentInviteName: tournamentInviteLists[0].name,
      });
      getTournamentInvites(tournamentInviteLists[0].id);
    } else if (
      tournamentInviteLists.length > 0 &&
      tournamentInviteListCreated &&
      inviteParamsId
    ) {
      setInviteParams(`?inviteId=${tournamentInviteLists[0].id}`, {
        replace: true,
      });
      setInviteListToShow({
        inviteId: tournamentInviteLists[0].id,
        tournamentInviteName: tournamentInviteLists[0].name,
      });
      getTournamentInviteLists();
      setTournamentInviteListCreated(false);
    } else {
      const lists = tournamentInviteLists.filter(
        (list) => list.id === inviteParamsId
      );

      if (lists.length > 0) {
        setInviteListToShow({
          inviteId: inviteParamsId,
          tournamentInviteName: lists[0].name,
        });
        getTournamentInvites(inviteParamsId);
      }
    }
  }, [tournamentInviteLists]);

  useEffect(() => {
    setUsersList(users);
    setUserToInvite(users[0]);
  }, [users]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const debouncedUserSearch = useDebouncedCallback(
    (value) => {
      if (value) {
        getUsers(value);
        setIsSearchOpen(true);
      } else {
        setIsSearchOpen(false);
      }
    },
    // delay in ms
    500
  );

  const handleSearchChange = (e) => {
    const {
      target: { value },
    } = e;
    setSearchValue(value);
    debouncedUserSearch(value);
  };

  const handleGetTournmentList = (inviteId, tournamentInviteName) => {
    setInviteParams(`?inviteId=${inviteId}`, {
      replace: true,
    });

    setInviteListToShow({ inviteId, tournamentInviteName });
    getTournamentInvites(inviteId);
  };

  const handleCreateTournamentList = () => {
    if (values.inviteListName !== '') {
      createTournamentList(values.inviteListName);
      setOpenInviteModal(false);
      setTournamentInviteListCreated(true);
      setValues({ inviteListName: '' });
      setError('');
    } else {
      setError(ERROR_MESSAGES.createInviteListError);
    }
  };

  const addUserToInviteList = (user_id) => {
    createInvite(inviteListToShow.inviteId, user_id);
  };

  const removeUserFromInviteList = (inviteId, username) => {
    const res = window.confirm(
      `Are you sure you want to remove ${username} from the “Fortunes Keep $5k Kickoff” Invite List?`
    );
    if (res) {
      removeInvite(inviteListToShow.inviteId, inviteId);
    }
  };

  const handleUpdateTournamentInviteName = () => {
    updateTournamentInviteName(
      inviteListToShow.inviteId,
      values.inviteListName
    );
    setInviteListToShow({
      inviteId: inviteListToShow.inviteId,
      tournamentInviteName: values.inviteListName,
    });
    setRenameInviteModal(false);
  };

  const handleDeleteTournamentInviteList = () => {
    const res = window.confirm(
      `Are you sure you want to delete “${inviteListToShow.tournamentInviteName}” Invite List?`
    );
    if (res) {
      deleteInviteList(
        inviteListToShow.inviteId,
        inviteListToShow.tournamentInviteName
      );
      setRenameInviteModal(false);
      setInviteListToShow({ inviteId: '', tournamentInviteName: '' });
      setInviteParams('?', {
        replace: true,
      });
    }
  };

  const handleRenameInviteList = () => {
    setRenameInviteModal(true);
    setValues({ inviteListName: inviteListToShow.tournamentInviteName });
  };

  const handleOpenDuplicateListModal = () => {
    setDuplicateListModal(true);

    setValues({ inviteListName: inviteListToShow.tournamentInviteName });
  };

  const handleDuplicateList = () => {
    if (values.inviteListName !== inviteListToShow.tournamentInviteName) {
      setDuplicateListModal(false);
      duplicateInviteList(inviteListToShow.inviteId, values.inviteListName);
      setTournamentInviteListCreated(true);
      setError('');
    } else {
      setError(ERROR_MESSAGES.duplicateError);
    }
  };

  const removeUserByInviteId = (userId) => {
    const userToRemove = inviteList.filter(
      ({ user_profile }) => user_profile.id === userId
    );

    if (userToRemove.length > 0) {
      removeUserFromInviteList(
        userToRemove[0].id,
        userToRemove[0].user_profile.username
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const usersInvited = inviteList.filter(
        (user) => user.user_profile.id === userToInvite.user.id
      );

      if (usersInvited.length === 0) {
        setUserInvited(userToInvite.user.id);
        addUserToInviteList(userToInvite.user.id);
      } else {
        removeUserByInviteId(userToInvite.user.id);
      }
    }

    if (event.key === 'ArrowDown') {
      if (userIndex + 1 === users.length) {
        setUserIndex(0);
        setUserToInvite(users[0]);
        scrollRef.current.scrollTo(0, 0);
      } else {
        setUserIndex(userIndex + 1);
        setUserToInvite(users[userIndex + 1]);
        if (userIndex + 1 > 5) {
          scrollRef.current.scrollTo(0, userIndex + 1 * 300);
        }
      }
    }
    if (event.key === 'ArrowUp') {
      if (userIndex > 0) {
        setUserIndex(userIndex - 1);
        setUserToInvite(users[userIndex - 1]);
        if (userIndex + 1 < 6) {
          scrollRef.current.scrollTo(0, userIndex + 1 * -300);
        }
      }
    }
  };

  const handleCloseInviteModal = () => {
    setUserSearchModal(false);
    setUserIndex(0);
    setUserToInvite({});
    setSearchValue('');
    setUsersList([]);
    setUserInviteError({});
    setUserInvited(undefined);
  };

  if (Object.keys(god).length === 0) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  return (
    <Layout userId={god.id} showHeaderElements className="md:px-0">
      <div className="grid grid-cols-12 2xl:w-10/12 2xl:m-auto">
        <div
          className="border-r border-grey-3 pr-4 relative col-span-3"
          style={{ height: 'calc(100vh - 80px)' }}
        >
          <div className="absolute top-0 bottom-0 flex flex-col w-full">
            <div className="section">
              <div ref={sideInfoRef} className="scrollable-content pb-3">
                {tournamentInviteLists.map(({ id, name, num_of_invitees }) => (
                  <div
                    className={cn('hover:bg-grey-4 pt-3 pb-4', {
                      'bg-grey-4': id === inviteListToShow.inviteId,
                    })}
                    onClick={() => handleGetTournmentList(id, name)}
                    role="button"
                    key={id}
                  >
                    <div className="flex px-3">
                      <div className="relative w-12">
                        <div className="bg-white rounded-full p-3 z-20 absolute">
                          <UserIcon color="black" className="w-4" />
                        </div>
                        <div className="bg-grey-2 rounded-full p-3 z-10 absolute top-3 left-4">
                          <UserIcon color="black" className="w-4" />
                        </div>
                      </div>
                      <div className="ml-6">
                        <p className="text-white font-bold text-lg">{name}</p>
                        <p className="text-grey-2">{num_of_invitees} Members</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full px-4 border-t-2 border-grey-3 rounded-lg pt-3 h-20">
                <Button
                  className="w-11/12 m-auto mt-1"
                  rounded
                  text="Create Invite List"
                  variant="primary"
                  onClick={() => setOpenInviteModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
        {inviteListToShow.inviteId && (
          <div
            className="mx-3 border-grey-3 relative col-span-9 overflow-y-auto no-scrollbar pb-3"
            style={{ height: 'calc(100vh - 80px)' }}
          >
            <h3 className="text-white font-bold text-2xl">
              {inviteListToShow.tournamentInviteName} Invite List
            </h3>
            <div className="flex justify-between">
              <Button
                className="w-full h-14 mr-3 mt-3 mb-6"
                text="Invite Users"
                variant="tertiary"
                rounded={false}
                image={ActualUserIcon}
                imageClass="w-5"
                onClick={() => setUserSearchModal(true)}
              />
              <Button
                className="w-full h-14 mr-3 mt-3 mb-6"
                text="Rename List"
                variant="tertiary"
                rounded={false}
                image={RenameIcon}
                onClick={() => handleRenameInviteList()}
              />
              <Button
                className="w-full h-14 mr-3 mt-3 mb-6"
                text="Duplicate List"
                variant="tertiary"
                rounded={false}
                image={DuplicateIcon}
                onClick={() => handleOpenDuplicateListModal()}
              />
              <Button
                className="w-full h-14 mt-3 mb-6"
                text="Delete List"
                variant="tertiary"
                rounded={false}
                image={TrashIcon}
                onClick={() => handleDeleteTournamentInviteList()}
              />
            </div>

            {loading ? (
              <div className="mt-12">
                <Loader />
              </div>
            ) : (
              <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
                {inviteList.length > 0 ? (
                  inviteList.map(({ user_profile, id }) => (
                    <UserInvite
                      inviteList
                      user={{
                        id: user_profile.id,
                        profile_image_url: user_profile.profile_image_url,
                        username: user_profile.username,
                        user_stats: user_profile.user_stats,
                        inviteId: id,
                      }}
                      key={user_profile.id}
                      className="bg-grey-4 rounded-lg"
                      removeUserFromInviteList={removeUserFromInviteList}
                    />
                  ))
                ) : (
                  <div>
                    <h3 className="text-white font-bold text-2xl">
                      No Users In {inviteListToShow.tournamentInviteName}
                    </h3>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        height="150px"
        modalIsOpen={
          openInviteModal || openRenameInviteModal || openDuplicateListModal
        }
        closeModal={() => {
          setOpenInviteModal(false);
          setRenameInviteModal(false);
          setDuplicateListModal(false);
          setValues({ inviteListName: '' });
          setError('');
        }}
        header={
          openInviteModal
            ? 'Create Invite Lists'
            : openRenameInviteModal
            ? 'Rename Invite Lists'
            : `Duplicate List ${inviteListToShow.tournamentInviteName}`
        }
      >
        <div className="relative">
          <input
            onChange={handleInputChange}
            name="inviteListName"
            value={values.inviteListName}
            type="Text"
            placeholder="Invite List Name"
            className="w-full rounded-full bg-zinc-700 text-white py-2 px-3 mt-2"
          />
          <p className="text-sm ml-3 text-warning mt-2 absolute">{error}</p>
          <Button
            className="w-full mt-12"
            rounded
            text={
              openInviteModal
                ? 'Create'
                : openRenameInviteModal
                ? 'Rename'
                : 'Duplicate'
            }
            variant="primary"
            onClick={
              openInviteModal
                ? handleCreateTournamentList
                : openRenameInviteModal
                ? handleUpdateTournamentInviteName
                : handleDuplicateList
            }
            disabled={values.inviteListName === ''}
          />
        </div>
      </Modal>
      <Modal
        scrollRef={scrollRef}
        height="500px"
        modalIsOpen={openUserSearchModal}
        closeModal={() => handleCloseInviteModal()}
        header="Invite Users"
        fullWidth
        customComponent={
          <div className="relative text-gray-600 focus-within:text-gray-400 w-full pb-3">
            <div className="z-50 inset-x-0" style={{ top: '-20px' }}>
              <span className="absolute left-3 top-3">
                <img src={SearchIcon} alt="search icon" className="w-5" />
              </span>
              <input
                className={cn(
                  'py-3 text-sm text-white bg-grey-3 focus:outline-none text-center w-full rounded-full'
                )}
                placeholder="Search"
                onChange={handleSearchChange}
                onClick={() => setSearchValue('')}
                value={searchValue}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        }
      >
        <div className="mt-3">
          {usersList.length > 0 && searchIsOpen === true ? (
            <div className="w-full">
              {usersList.map(({ user }, index) => (
                <UserInvite
                  key={user.id}
                  user={user}
                  addUserToInviteList={addUserToInviteList}
                  removeUserByInviteId={removeUserByInviteId}
                  index={index}
                  selectedIndex={userIndex}
                  selectedUser={userInvited}
                  className="sm:px-2"
                  userInviteError={userInviteError}
                  usersInvited={inviteList}
                  usersResult={usersList}
                  inviteId={inviteListToShow.inviteId}
                />
              ))}
            </div>
          ) : null}
        </div>
      </Modal>
    </Layout>
  );
};

export default InviteManagement;
