/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const GlobalSchedule = ({ dates, slots, selectedSlot, onSelectSlot }) => {
  const timeToDate = (time) => {
    const [hours, minutes] = time.split(':');
    return `${
      Number(hours) === 0
        ? 12
        : Number(hours) >= 13
        ? Number(hours) - 12
        : hours
    }:${minutes}${Number(hours) >= 12 ? 'PM' : 'AM'}`;
  };

  return (
    <div className="mb-12 rounded-3xl overflow-x-auto short-scroll bg-grey-4 fade-in-fast pb-2">
      <div className="grid grid-cols-2 sm:grid-cols-8 pr-4 py-4 gap-2 sm:min-w-[1100px]">
        <div className="sm:sticky left-0 bg-grey-4 z-10" />
        {days.map((day) => (
          <div
            key={`${day}`}
            className={classNames(' shrink-0 flex-1 block', {})}
          >
            <h4 className="text-center uppercase text-white font-extrabold whitespace-nowrap sm:text-base text-sm">
              {day}
            </h4>
            <p className="text-center text-xs uppercase whitespace-nowrap">
              {format(dates[day], 'MMMM dd')}
            </p>
          </div>
        ))}

        {slots.map((slot, i) => (
          <React.Fragment key={`${slot.id}_slot_${i}`}>
            <div className="px-4 shrink-0 flex-1 sm:sticky left-0 bg-grey-4 z-10">
              <h4 className="uppercase text-right text-white font-extrabold whitespace-nowrap sm:text-base text-sm">
                Slot {i + 1}
              </h4>
              <p className=" text-xs uppercase text-right whitespace-nowrap leading-none">
                {timeToDate(slot.start_time)} - {timeToDate(slot.end_time)}{' '}
                <span className="3xl:inline block">(EST)</span>
              </p>
            </div>
            {days.map((day) => {
              const foundDay = slot.days_of_week.find(
                (d) => d.day_of_week === day
              );
              const isSelected = foundDay?.id === selectedSlot;

              return (
                <div
                  key={`${slot.id}_slot_${i}_${day}`}
                  className={classNames(' shrink-0 flex-1 block', {})}
                >
                  <button
                    disabled={!foundDay}
                    onClick={() =>
                      onSelectSlot(isSelected ? null : foundDay?.id)
                    }
                    className={classNames(
                      'transition-all ease-in-out !ring-0 !outline-none sm:text-base text-sm focus-visible:!outline-2 focus-visible:!outline-gold w-full bg-grey-3 text-grey-2 rounded-lg h-full py-2 uppercase font-bold whitespace-nowrap disabled:!opacity-40 disabled:pointer-events-none',
                      {
                        '!bg-white !text-black': isSelected,
                        'opacity-60': selectedSlot && !isSelected,
                        '!bg-warning':
                          foundDay?.num_of_matches === 0 && !isSelected,
                        '!text-white':
                          foundDay?.num_of_matches >= 0 &&
                          foundDay?.num_of_matches < foundDay?.max_matches &&
                          !isSelected,
                        '!bg-gold-4 !text-gold':
                          foundDay?.num_of_matches >= foundDay?.max_matches &&
                          !isSelected,
                      }
                    )}
                  >
                    {!foundDay && 'Unavailable'}
                    {foundDay?.num_of_matches === 0 && 'Open'}
                    {foundDay?.num_of_matches === 1 && '1 Match'}
                    {foundDay?.num_of_matches > 1 &&
                      `${foundDay?.num_of_matches} Matches`}
                  </button>
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default GlobalSchedule;
