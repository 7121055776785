import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  onChange,
  onClick = () => {},
  value,
  placeholder,
  name,
  className,
}) => (
  <input
    className={classNames(
      'py-3 px-6 text-sm text-white bg-grey-4 focus:outline-none w-full rounded-full',
      className
    )}
    placeholder={placeholder}
    onChange={onChange}
    onClick={onClick}
    value={value}
    name={name}
  />
);

Input.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default Input;
