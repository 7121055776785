/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';

// Steps
import TournamentList from './Steps/TournamentsList';
import SelectGame from './Steps/SelectGame';
import Stakeable from './Steps/Stakable';
import StakeableFields from './Steps/StakeableFields.tsx';
import InviteList from './Steps/InviteList';
import Dates from './Steps/Dates';
import Review from './Steps/Review';

// Components
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Buttons/Button/Button';
import Modal from '../../components/BaseModal/BaseModal';

import useAuth from '../../contexts/AuthContext/AuthContext';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext';
import ConsoleSelect from './Steps/ConsoleSelect.tsx';
import ModeSelect from './Steps/ModeSelect.tsx';
import ModeConfirm from './Steps/ModeConfirm.tsx';
import Meta from './Steps/Meta.tsx';
import client from '../../services/client';

const initialValues = {
  package_name: '',
  amount: 0,
  promotion_payout: false,
  entry_fee: 0,
  num_of_divisions: '',
  start_date: '',
  max_stakeable_amount: '',
  overage_multiplier: '',
  stakers_percentage: '',
  invite_list_id: '',
  business_fee: 0.1,
};

const defaultRules = {
  ruleIds: {},
  maps: [],
  format: null,
};

const Tournaments = () => {
  const {
    error,
    loading,
    tournaments,
    games,
    tournamentInviteLists,
    getTournaments,
    getTournamentInviteLists,
    getGames,
    createTournamentList,
    createTournament,
  } = useTournament();
  const { god } = useAuth();

  const [values, setValues] = useState(initialValues);
  const [step, setStep] = useState(1);
  const [prevStep, setPrevStep] = useState(1);
  const [gameSelected, setGameSelected] = useState({});
  const [isStakeable, setIsStakeable] = useState(false);
  const [inviteListName, setInviteListName] = useState('');
  const [console, setConsole] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [payoutId, setPayoutId] = useState(null);
  const [matchRules, setMatchRules] = useState(defaultRules);
  const [playerPool, setPlayerPool] = useState(0);
  const [entryFee, setEntryFee] = useState(0);
  const [promotionalPayout, setPromotionalPayout] = useState(0);
  const [businessFee, setBusinessFee] = useState(0);
  const [maxStake, setMaxStake] = useState(0);
  const [inviteOnly, setInviteOnly] = useState(false);
  const [type, setType] = useState('Tournament::MatchEvent');
  const [title, setTitle] = useState('');
  const [squareImage, setSquareImage] = useState('');
  const [landscapeImage, setLandscapeImage] = useState('');
  const [cmsId, setCmsId] = useState('');
  const [bracketSize, setBracketSize] = useState(4);
  const [bracketType, setBracketType] = useState('single');
  const [bracketPayout, setBracketPayout] = useState(null);
  const [shoutcast, setShoutcast] = useState('');

  const [loadingCreate, setLoading] = useState(false);
  const [modalIsOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getTournaments();
    getGames();
    getTournamentInviteLists();
  }, []);

  // useEffect(() => {
  //   if (Object.keys(gameSelected).length > 0) {
  //     getGameModePackages(gameSelected.id);
  //   }
  // }, [gameSelected]);

  useEffect(() => {
    if (error) {
      setIsModalOpen(true);
    }
  }, [error]);

  useEffect(() => {
    setConsole(null);
  }, [gameSelected]);

  useEffect(() => {
    setSelectedMode(null);
    setPayoutId(null);
  }, [console]);

  useEffect(() => {
    setMatchRules(defaultRules);
  }, [selectedMode]);

  useEffect(() => {
    setIsStakeable(false);
  }, [matchRules]);

  useEffect(() => {
    setEntryFee(0);
    setPlayerPool(0);
    setPromotionalPayout(0);
    setBusinessFee(0);
    setMaxStake(0);
  }, [isStakeable]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleAddInviteList = (inviteId, name) => {
    setValues({
      ...values,
      invite_list_id: inviteId,
    });
    setInviteListName(name);
    setStep(Math.trunc(step + 1));
    setPrevStep(6.1);
  };

  const handlePrevStep = () => {
    setStep(step - 1 === 8 && isStakeable ? step - 2 : step - 1);
  };

  const handleNextStep = (customStep) => {
    if (customStep === undefined) {
      setStep(Math.trunc(step + 1));
      setPrevStep(Math.trunc(step + 1 - 1));
    } else if (customStep === 6.1) {
      setStep(6.1);
      setPrevStep(6);
    } else {
      setStep(customStep);
      setPrevStep(customStep - 1);
    }
  };

  const handleCreateTournament = async () => {
    try {
      setLoading(true);
      const elementSet = {
        console_id: console,
        game_mode_id: selectedMode,
        format_id: matchRules.format,
        payout_id: payoutId,
        map_ids: matchRules.maps,
        rule_option_ids: Object.values(matchRules.ruleIds),
        mutual_agreements: [],
      };
      const { data } = await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/element_sets`,
        elementSet
      );

      let tournament = {
        type,
        // title,
        description: '',
        player_pool: playerPool,
        start_date: new Date(values.start_date).toISOString(),
        promotional_payout: promotionalPayout,
        // square_image_url: squareImage,
        // landscape_image_url: landscapeImage,
        element_set_id: data.element_set_id,
        invite_list_id: values.invite_list_id || null,
        staking: null,
        bracket_payout_id: null,
        shoutcast: shoutcast || null,
      };

      if (title) {
        tournament.title = title;
      }
      if (landscapeImage) {
        tournament.wide_image_url = landscapeImage;
      }
      if (squareImage) {
        tournament.image_url = squareImage;
      }

      if (cmsId) {
        tournament = { ...tournament, cms_faq_id: cmsId };
      }

      if (type === 'Tournament::Bracket') {
        tournament = {
          ...tournament,
          bracket_type: bracketType,
          bracket_size: bracketSize,
          bracket_payout_id: bracketPayout,
        };
      }

      if (isStakeable) {
        tournament = {
          ...tournament,
          staking: true,
        };
      } else {
        tournament.entry_fee = entryFee;
      }

      await createTournament(tournament);

      setStep(1);
      setType('Tournament::MatchEvent');
      setTitle('');
      setShoutcast('');
      setSquareImage('');
      setLandscapeImage('');
      setCmsId('');
      setValues({
        package_name: '',
        amount: 0,
        promotion_payout: false,
        entry_fee: 0,
        num_of_divisions: '',
        start_date: '',
        max_stakeable_amount: '',
        overage_multiplier: '',
        stakers_percentage: '',
        invite_list_id: '',
        business_fee: 0.1,
      });
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  if (Object.keys(god).length === 0 || loading) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <TournamentList
            onRefetch={getTournaments}
            tournaments={tournaments}
            handleNextStep={handleNextStep}
          />
        );
      case 2:
        return (
          <Meta
            type={type}
            onTypeChange={setType}
            title={title}
            onTitleChange={setTitle}
            squareImage={squareImage}
            onSquareImageChange={setSquareImage}
            landscapeImage={landscapeImage}
            onLandscapeImageChange={setLandscapeImage}
            cmsId={cmsId}
            onCmsIdChange={setCmsId}
            onConfirm={handleNextStep}
            bracketType={bracketType}
            onBracketTypeChange={setBracketType}
            bracketSize={bracketSize}
            onBracketSizeChange={setBracketSize}
            payoutId={bracketPayout}
            onPayoutIdChange={setBracketPayout}
            shoutcast={shoutcast}
            onShoutcastChange={setShoutcast}
          />
        );
      case 3:
        return (
          <SelectGame
            games={games}
            handleNextStep={handleNextStep}
            setGameSelected={setGameSelected}
            prevStep={prevStep}
            step={step}
          />
        );
      case 4:
        return (
          <ConsoleSelect
            games={games}
            onConfirm={handleNextStep}
            onSelect={setConsole}
            selectedConsole={console}
            gameId={gameSelected?.id}
          />
        );
      case 5:
        return (
          <ModeSelect
            key="modeselect"
            onSelect={(mId, size, pId) => {
              setSelectedMode(mId);
              handleNextStep();
              setPayoutId(pId);
            }}
            gameId={gameSelected?.id}
            selectedMode={selectedMode}
          />
        );
      case 6:
        return (
          <ModeConfirm
            onUpdate={setMatchRules}
            onConfirm={handleNextStep}
            rules={matchRules}
            modeId={selectedMode}
          />
        );

      case 7:
        return (
          <Stakeable
            handleNextStep={(stakeable) => {
              setIsStakeable(stakeable);
              setStep(stakeable ? 9 : 8);
            }}
            setIsStakeable={setIsStakeable}
          />
        );
      case 8:
        return (
          <StakeableFields
            playerPool={playerPool}
            onPoolChange={setPlayerPool}
            entryFee={entryFee}
            onEntryFeeChange={setEntryFee}
            onConfirm={handleNextStep}
            promotionalPayout={promotionalPayout}
            onPromoPayoutChange={setPromotionalPayout}
            businessFee={businessFee}
            onBusinessFeeChange={setBusinessFee}
            maxStake={maxStake}
            onMaxStakeChange={setMaxStake}
          />
        );

      case 9:
        return (
          <InviteList
            onConfirm={handleNextStep}
            handleAddInviteList={handleAddInviteList}
            createTournamentList={createTournamentList}
            tournamentInviteLists={tournamentInviteLists}
            inviteOnly={inviteOnly}
            setInviteOnly={setInviteOnly}
          />
        );
      case 10:
        return (
          <Dates
            handleNextStep={handleNextStep}
            handleInputChange={handleInputChange}
            startDate={values.start_date}
            prevStep={prevStep}
            step={step}
          />
        );
      case 11:
        return (
          <Review
            title={title}
            game={gameSelected.name}
            landscapeImage={landscapeImage}
            isStakeable={isStakeable}
            displayDate={values.start_date}
            amount={playerPool}
            mode={selectedMode}
            inviteListName={inviteListName}
            cmsFaqId={cmsId}
            matchType={type}
            bracketType={bracketType}
            bracketSize={bracketSize}
            console={console}
            handleCreateTournament={handleCreateTournament}
            disabled={loadingCreate}
            entryFee={entryFee}
          />
        );
      // never forget the default case, otherwise VS code would be mad!
      default:
        // do nothing
        return <div>step 4</div>;
    }
  };

  return (
    <>
      <Layout userId={god.id} showHeaderElements className="relative  !pb-0">
        {renderSteps()}
      </Layout>
      {step > 2 && (
        <div className="border-t border-grey-3 flex justify-end mt-12 w-full fixed bottom-0 z-20 bg-black">
          <Button
            className="w-32 my-6 mr-4 md:mr-6 bg-grey-4"
            rounded
            text="Back"
            variant="tertiary"
            onClick={handlePrevStep}
          />
        </div>
      )}
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsModalOpen(false)}
        header="Error"
        height={100}
      >
        <h3 className="text-white text-2xl">Oops...</h3>
        <p className="text-white mt-3">
          Looks like an error occured while creating a tournament contact your
          local dev to get this resolved
        </p>
      </Modal>
    </>
  );
};

export default Tournaments;
