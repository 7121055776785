import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Layout from '../../components/Layout/Layout';
import GoogleButton from '../../components/Buttons/GoogleButton/GoogleButton';

import useAuth from '../../contexts/AuthContext/AuthContext';
import { getSessionCookie } from '../../utils/cookie';
import authenticate from '../../api/auth/auth';

const LoginPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { error, login, god, setAuthed, setGod } = useAuth();

  const handleLogin = () => {
    login();
  };

  useEffect(() => {
    const authToken = getSessionCookie('token');
    const user = getSessionCookie('1v1meadminuser');

    if (typeof user === 'string' && authToken?.length) {
      setAuthed(true);
      setGod({ id: Number(user) });
      // if (window.location.search.includes('redirectTo=')) {
      //   window.location.href = decodeURIComponent(
      //     window.location.search.split('redirectTo=')[1]
      //   );
      // }
      return;
    }
    const token = getSessionCookie('googleToken');

    if (token?.length) {
      authenticate(token)
        .then((data) => {
          if (
            data.status === 500 ||
            data.status === 403 ||
            data.response?.status === 500 ||
            data.response?.status === 403
          ) {
            Cookies.remove('googleToken');
            Cookies.remove('token');
          } else {
            const {
              data: { admin_user },
            } = data;
            setAuthed(true);
            setGod({ id: admin_user.user.id });
            if (window.location.search.includes('redirectTo=')) {
              window.location.href = decodeURIComponent(
                window.location.search.split('redirectTo=')[1]
              );
            }
          }
        })
        .catch(() => {
          // noop
        });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(god).length !== 0) {
      navigate(state?.path || `/godmode/users/${god.id}`);
    }
  }, [god]);

  return (
    <Layout showHeaderElements={false}>
      <div className="flex flex-col items-center justify-center login-screen">
        <h1 className="text-4xl text-white font-bold text-center">Sign In</h1>
        <GoogleButton className="mt-12" onClick={handleLogin} />
        {error && <p className="text-primary text-1xl mt-6">GO AWAY</p>}
      </div>
    </Layout>
  );
};

export default LoginPage;
