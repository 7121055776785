/* eslint-disable react/forbid-prop-types */
import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import Loader from '../Loader/Loader';
import CloseIcon from '../../assets/Icons/close-icon.svg';

ReactModal.setAppElement('#modal-root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    borderRadius: '0.375rem',
    borderColor: 'rgb(24 24 27 / 1)',
    padding: '0px',
    border: 'none',
    backgroundColor: 'black',
  },
};

const Modal = ({
  header,
  children,
  modalIsOpen,
  closeModal,
  search,
  paginateData,
  loading,
  height = '400px',
  customComponent,
  scrollRef,
  backgroundColor = '#191919',
}) => {
  const debouncedUserSearch = useDebouncedCallback(
    () => {
      paginateData();
    },
    // delay in ms
    500
  );

  const onScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      debouncedUserSearch();
    }
  };

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      overlayClassName="modal-overlay"
    >
      <div className="px-4 mt-3">
        <div
          className={cn('flex justify-between', {
            hidden: search,
          })}
        >
          <h2 className="text-white text-2xl font-extrabold">{header}</h2>
          <button onClick={closeModal} type="button">
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
        <div className="mt-3">{customComponent}</div>
      </div>
      <div
        className="mt-6 pb-12 modal-content relative"
        onScroll={onScroll}
        style={{ height, overflowY: 'scroll' }}
        ref={scrollRef}
      >
        {children}
        {loading && <Loader />}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  customComponent: PropTypes.node,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  search: PropTypes.bool,
  paginateData: PropTypes.func,
  loading: PropTypes.bool,
  height: PropTypes.string,
  scrollRef: PropTypes.object,
};

export default Modal;
