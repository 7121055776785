import axios from 'axios';

const authenticate = async (idToken) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/authenticate`,
      {
        provider: 'google',
        token: idToken,
        client_type: 'support-web',
        app_version: '1.0.0',
      },
      { headers: { 'Content-Type': 'application/json' } }
    );

    return res;
  } catch (error) {
    return error;
  }
};

export default authenticate;
