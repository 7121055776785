/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import classNames from 'classnames';
import { format } from 'date-fns';
import Button from '../../components/Buttons/Button/Button';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import useAuth from '../../contexts/AuthContext/AuthContext';
import client from '../../services/client';
import HoldButton from '../../components/HoldButton';
import BaseModal from '../../components/BaseModal/BaseModal';
import Search from './Search';

const PartnerSettings = () => {
  const params = useParams();
  const { god } = useAuth();
  const navigate = useNavigate();

  const selectedPartner = Number(params.partnerId);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState(null);

  const [memberLeagues, setMemberLeagues] = useState([]);
  const [availLeagues, setAvailLeagues] = useState([]);

  const [suspending, setSuspending] = useState(false);
  const [weeksToSuspend, setWeeksToSuspend] = useState(1);
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);

  const [selectedLeagueId, setSelectedLeagueId] = useState(null);

  const getPartner = (id) => {
    setError(false);
    setLoading(true);
    client
      .get(`${process.env.REACT_APP_API_URL}/admin/v1/partners/${id}`)
      .then(({ data }) => setPartner(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const getMemberLeagues = (id) => {
    setError(false);
    setLoading(true);
    client
      .get(`${process.env.REACT_APP_API_URL}/admin/v1/partners/${id}/leagues`)
      .then(({ data }) => setMemberLeagues(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const getAvailLeagues = (id) => {
    setError(false);
    setLoading(true);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/${id}/leagues/available`
      )
      .then(({ data }) => setAvailLeagues(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPartner(selectedPartner);
    getMemberLeagues(selectedPartner);
    getAvailLeagues(selectedPartner);
  }, [selectedPartner]);

  const handleJoinLeague = (data) => {
    setMemberLeagues((l) => [...l, data]);
    setSelectedLeagueId(data.id);
  };
  const handleRemove = (id) => {
    setError(false);
    const ok = confirm('Are you sure?');
    if (ok) {
      client
        .post(
          `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}/leagues/${selectedLeagueId}/remove_teammate`,
          { user_id: id }
        )
        .then(() => {
          getMemberLeagues(selectedPartner);
          getAvailLeagues(selectedPartner);
        })
        .catch((e) => {
          alert(e.response?.data?.message || 'Something went wrong');
          setError(true);
        });
    }
  };

  const handleRemoveFromLeague = () => {
    setError(false);
    const ok = confirm('Are you sure?');
    if (ok) {
      client
        .patch(
          `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}/leagues/${selectedLeagueId}`,
          { active: false }
        )
        .then(() => {
          getMemberLeagues(selectedPartner);
          getAvailLeagues(selectedPartner);
        })
        .catch((e) => {
          alert(e.response?.data?.message || 'Something went wrong');
          setError(true);
        });
    }
  };

  const handleAdd = (id) => {
    setError(false);
    client
      .post(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}/leagues/${selectedLeagueId}/add_teammate`,
        { user_id: id }
      )
      .then(() => {
        getMemberLeagues(selectedPartner);
        getAvailLeagues(selectedPartner);
        setAdding(false);
      })
      .catch((e) => {
        alert(e.response?.data?.message || 'Something went wrong');
        setError(true);
      });
  };

  const handleUpdate = ({ code }) => {
    setError(false);
    const ok = confirm('Are you sure?');
    const data = {
      // rev_share_min_amount: min,
      // rev_share_percentage: percentage,
    };
    if (code) {
      data.code = code;
    }
    if (ok) {
      client
        .patch(
          `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}`,
          data
        )
        .then(() => {
          getPartner(selectedPartner);
          setEditing(false);
        })
        .catch((e) => {
          alert(e.response?.data?.message || 'Something went wrong');
          setError(true);
        });
    }
  };

  const handleDelete = () => {
    setError(false);
    const ok = confirm('Are you sure?');
    if (ok) {
      client
        .patch(
          `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}`,
          { active: false }
        )
        .then(() => {
          navigate('/godmode/tournaments?tab=partners');
        })
        .catch((e) => {
          alert(e.response?.data?.message || 'Something went wrong');
          setError(true);
        });
    }
  };

  const handleSuspend = () => {
    setError(false);
    const ok = confirm('Are you sure?');
    if (ok) {
      client
        .post(
          `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}/suspend`,
          { num_of_weeks: weeksToSuspend }
        )
        .then(() => {
          getPartner(selectedPartner);
          setSuspending(false);
        })
        .catch((e) => {
          alert(e.response?.data?.message || 'Something went wrong');
        });
    }
  };

  const selectedLeague = useMemo(
    () => memberLeagues.find((l) => l.id === selectedLeagueId),
    [selectedLeagueId, memberLeagues]
  );

  const joinableLeagues = useMemo(
    () =>
      availLeagues.filter(
        (l) =>
          !memberLeagues.some((m) => m.league_id === l.league_id && m.active)
      ),
    [availLeagues, memberLeagues]
  );

  if (Object.keys(god).length === 0) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }
  return (
    <Layout showHeaderElements userId={god.id} className="md:px-0 text-grey-2">
      <div className="flex gap-8 h-[calc(100vh-80px)] overflow-hidden">
        <div className="w-[360px] border-r border-grey-4 flex flex-col overflow-auto">
          <div className="flex-1 p-4">
            {loading && <Loader />}
            {error && (
              <p className="text-center py-12 px-4 text-warning">
                Something went wrong
              </p>
            )}
            {partner && (
              <>
                <h3 className="flex items-center gap-4 mb-2">
                  <img
                    src={partner.user_profile.profile_image_url}
                    className="rounded-full h-[32px] w-[32px] object-cover"
                    alt={partner.user_profile.username}
                  />
                  <span className="flex-1 font-extrabold text-white text-2xl truncate">
                    {partner.user_profile.username}
                    {partner.code && (
                      <span className="text-xs pl-1">({partner.code})</span>
                    )}
                  </span>
                </h3>
                <p className="mb-8 text-sm">
                  <b>Remaining Cancels:</b> {partner.remaining_cancels || 0}
                  <br />
                  <b>Remaining Reschedules:</b>{' '}
                  {partner.remaining_reschedules || 0}
                  <br />
                  <b>Remaining Lates:</b> {partner.remaining_lates || 0}
                </p>
                <div className="flex  gap-2 mb-8 flex-col">
                  <Button
                    text="Edit Partner"
                    onClick={() => setEditing(true)}
                    variant="tertiary"
                    large
                    className="!flex-1 whitespace-nowrap !px-2 mb-2"
                  />
                  <Button
                    text={
                      partner.suspended_until
                        ? `Suspended Until ${format(
                            new Date(partner.suspended_until),
                            'MMMM do, yyyy'
                          )}`
                        : 'Suspend Partner'
                    }
                    onClick={() => setSuspending(true)}
                    variant="warning"
                    large
                    className="!flex-1 whitespace-nowrap !px-2"
                    disabled={partner.suspended_until}
                  />
                  <HoldButton
                    onConfirm={() => handleDelete()}
                    text="Remove Partner"
                    variant="warning"
                    timeout={150}
                    square
                    small
                  />
                </div>
              </>
            )}

            {memberLeagues.length > 0 && (
              <>
                <p className="uppercase text-grey-2 mb-4">Member</p>
                <div className="mb-8">
                  {memberLeagues.map((l) => (
                    <button
                      type="button"
                      key={`league_${l.league_id}`}
                      onClick={() => setSelectedLeagueId(l.id)}
                      disabled={selectedLeagueId === l.id}
                      className="flex items-start gap-3 p-4 disabled:bg-grey-4 hover:bg-grey-4 transition-colors w-full rounded-2xl mb-4"
                    >
                      <img
                        src={l.play_info.game_icon}
                        className="rounded-full h-[32px] w-[32px] object-cover"
                        alt={l.play_info.game_mode_title}
                      />
                      <span className="flex-1 text-left text-xs font-bold leading-none">
                        {l.play_info.game_name}
                        <b className="font-extrabold text-white text-lg block leading-snug">
                          {l.play_info.game_mode_title}
                        </b>
                      </span>
                    </button>
                  ))}
                </div>
              </>
            )}
            <br />
            {joinableLeagues.length > 0 && (
              <>
                <p className="uppercase text-grey-2 mb-4">Other Leagues</p>
                {joinableLeagues.map((l) => (
                  <JoinableLeague
                    key={`avail_league_${l.league_id}`}
                    className="rounded-2xl mb-8"
                    league={l}
                    onJoin={handleJoinLeague}
                    selectedPartner={selectedPartner}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <div className="flex gap-8 border-t border-grey-4">
            <div className="flex-1 border-r border-grey-4 h-[calc(100vh-80px)] overflow-y-auto">
              <div className="p-4 max-w-7xl mx-auto">
                {selectedLeague ? (
                  <div>
                    <div className="flex items-start gap-6 p-4  w-full rounded-2xl mb-8">
                      <img
                        src={selectedLeague.play_info.game_icon}
                        className="rounded-full h-[64px] w-[64px] object-cover"
                        alt={selectedLeague.play_info.game_mode_title}
                      />
                      <span className="flex-1 text-left font-bold leading-none">
                        {selectedLeague.play_info.game_name}
                        <b className="font-extrabold text-white text-4xl block leading-tight">
                          {selectedLeague.play_info.game_mode_title}
                        </b>
                      </span>
                    </div>
                    <p className="text-2xl font-extrabold text-white mb-4">
                      Lineup
                    </p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-8">
                      {partner && (
                        <PlayerCard
                          avatar={partner.user_profile.profile_image_url}
                          username={partner.user_profile.username}
                        />
                      )}
                      {selectedLeague.teammates
                        .filter((t) => t.default)
                        .map((t) => (
                          <PlayerCard
                            key={`lineup_${t.user_profile.id}`}
                            avatar={t.user_profile.profile_image_url}
                            username={t.user_profile.username}
                          />
                        ))}
                    </div>
                    <p className="text-2xl font-extrabold text-white mb-4">
                      Bench
                    </p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-8">
                      {selectedLeague.teammates
                        .filter((t) => !t.default)
                        .map((t) => (
                          <PlayerCard
                            key={`lineup_${t.user_profile.id}`}
                            avatar={t.user_profile.profile_image_url}
                            username={t.user_profile.username}
                            onRemove={() => handleRemove(t.user_profile.id)}
                          />
                        ))}
                      <div className="h-full flex items-center justify-center p-4 border rounded-2xl border-grey-4">
                        <Button
                          className="!w-full"
                          rounded
                          text="Add"
                          variant="primary"
                          onClick={() => setAdding(true)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="p-8 text-xl text-center border-2 border-grey-4 border-dashed rounded-xl">
                    Select a League
                  </p>
                )}
              </div>
            </div>
            <div className="w-[300px] flex flex-col gap-4 pt-8 pr-4">
              {selectedLeague && (
                <HoldButton
                  onConfirm={handleRemoveFromLeague}
                  text="Remove From League"
                  variant="warning"
                  timeout={250}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedLeague && (
        <BaseModal
          header="Add Teammate"
          modalIsOpen={adding}
          closeModal={() => setAdding(false)}
          paginateData={() => {}}
          height="auto"
        >
          <div>
            <Search
              onClick={handleAdd}
              filteredIds={
                selectedLeague.teammates.map((t) => t.user_profile.id) || []
              }
            />
          </div>
        </BaseModal>
      )}
      {partner && (
        <>
          <EditModal
            isOpen={editing}
            onClose={() => setEditing(false)}
            // percentage={partner.rev_share_percentage}
            // min={partner.rev_share_min_amount}
            teamCode={partner.code}
            onSave={handleUpdate}
          />
          <BaseModal
            modalIsOpen={suspending}
            closeModal={() => setSuspending(false)}
            header={`Suspend ${partner.user_profile.username}?`}
            height="auto"
          >
            <div className="text-grey-2 pb-4">
              <p className="mb-4">
                How many weeks should <b>{partner.user_profile.username}</b> be
                suspended for?
              </p>
              <div className="grid grid-cols-4 border border-zinc-700  rounded-full overflow-hidden mb-8">
                {[1, 2, 3, 4].map((weekCount) => (
                  <button
                    key={`weekcount_${weekCount}`}
                    type="button"
                    className={classNames(
                      'w-full text-zinc-500 py-2 px-3 transition-colors ',
                      {
                        '!bg-white !text-black': weekCount === weeksToSuspend,
                      }
                    )}
                    onClick={() => setWeeksToSuspend(weekCount)}
                  >
                    {weekCount}W
                  </button>
                ))}
              </div>
              <div className="w-full relative">
                <HoldButton
                  onConfirm={handleSuspend}
                  text="Click & Hold To Confirm"
                  variant="warning"
                  small
                  timeout={150}
                />
              </div>
            </div>
          </BaseModal>
        </>
      )}
    </Layout>
  );
};

const JoinableLeague = ({ league, onJoin, selectedPartner }) => {
  const [showModal, setShowModal] = useState(false);
  const [showOverride, setShowOverride] = useState(false);
  const [rpOverride, setRPOverride] = useState(800);

  const handleJoinLeague = (id) => {
    const payload = {};
    if (showOverride) {
      payload.rp = rpOverride;
    }
    client
      .post(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}/leagues?league_id=${id}`,
        payload
      )
      .then(({ data }) => {
        onJoin(data);
        setRPOverride(800);
        setShowOverride(false);
        setShowModal(false);
      })
      .catch((e) => {
        alert(e.response?.data?.message || 'Something went wrong');
      });
  };

  return (
    <>
      <div className="rounded-2xl mb-8">
        <div className="flex items-start gap-3 w-full mb-3 ">
          <img
            src={league.play_info.game_icon}
            className="rounded-full h-[32px] w-[32px] object-cover"
            alt={league.play_info.game_mode_title}
          />
          <span className="flex-1 text-left text-xs font-bold leading-none">
            {league.play_info.game_name}
            <b className="font-extrabold text-white text-lg block leading-snug">
              {league.play_info.game_mode_title}
            </b>
          </span>
        </div>
        <Button
          rounded
          variant="tertiary"
          onClick={() => setShowModal(true)}
          text="Join"
          className="!w-full"
        />
      </div>
      <BaseModal
        modalIsOpen={showModal}
        closeModal={() => setShowModal(false)}
        header="Join League"
        height="260px"
      >
        <p className="text-grey-2 mb-2">
          Would you like to override this partners RP?
        </p>
        <div className="mb-4">
          <label
            htmlFor="yes"
            className="text-grey-2 flex items-center gap-3 mb-1"
            key="yes"
          >
            <input
              type="radio"
              id="yes"
              onChange={() => setShowOverride(true)}
              checked={showOverride}
              className="accent-blue w-4 h-4"
            />
            <span>Yes</span>
          </label>
          <label
            htmlFor="no"
            className="text-grey-2 flex items-center gap-3 mb-4"
            key="no"
          >
            <input
              type="radio"
              id="no"
              onChange={() => setShowOverride(false)}
              checked={!showOverride}
              className="accent-blue w-4 h-4"
            />
            <span>No</span>
          </label>
          {showOverride && (
            <label>
              <span className="text-white font-bold text-xs">RP Override</span>
              <input
                type="number"
                className="py-3 text-sm text-white bg-grey-3 focus:outline-none px-4 w-full rounded-full mb-2"
                name="team_name"
                onChange={(e) =>
                  setRPOverride(Math.max(Number(e.target.value), 0))
                }
                value={rpOverride}
                placeholder="Team Name"
              />
            </label>
          )}
        </div>
        <Button
          text="Join League"
          variant="primary"
          rounded
          large
          className="!w-full"
          onClick={() => handleJoinLeague(league.league_id)}
        />
      </BaseModal>
    </>
  );
};

const EditModal = ({ isOpen, onClose, onSave, teamCode }) => {
  const [code, setCode] = useState(teamCode || '');
  return (
    <BaseModal
      header="Edit Partner"
      modalIsOpen={isOpen}
      closeModal={onClose}
      paginateData={() => {}}
    >
      <div className="pt-4">
        {/* <label className="mb-4 block">
          <span className="text-white text-xs font-bold block mb-2">
            Rev Share Percentage (0 - 100)
          </span>
          <input
            type="number"
            min={0}
            max={100}
            className="py-2 flex-1 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full "
            name="percentage"
            placeholder="Percentage"
            value={revPercentage}
            onChange={(e) => setRevPercentage(e.target.value)}
          />
        </label>
        <label className="mb-4 block">
          <span className="text-white text-xs font-bold block mb-2">
            Rev Share Minimum
          </span>
          <WithCents onValueChange={setRevMin} value={revMin} simple />
        </label> */}
        <label className="mb-12 block">
          <span className="text-white text-xs font-bold block mb-2">
            Team Code
          </span>
          <input
            type="text"
            className="py-2 flex-1 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full "
            name="code"
            placeholder="CODE"
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase().slice(0, 3))}
          />
        </label>
        <Button
          variant="primary"
          rounded
          large
          text="Save"
          className="!w-full"
          onClick={() =>
            onSave({
              // min: Number(revMin),
              // percentage: Number(revPercentage),
              code,
            })
          }
        />
      </div>
    </BaseModal>
  );
};

const PlayerCard = ({ avatar, username, onRemove }) => (
  <div className="bg-grey-4 rounded-2xl px-4 py-8 flex flex-col items-center gap-4">
    <img
      src={avatar}
      className="rounded-full h-[48px] w-[48px] object-cover"
      alt={username}
    />
    <p className="flex-1 font-extrabold text-white">{username}</p>

    {onRemove && (
      <div className="w-full relative pt-4">
        <HoldButton
          onConfirm={onRemove}
          text="Remove"
          variant="warning"
          small
          timeout={150}
        />
      </div>
    )}
  </div>
);

export default PartnerSettings;
