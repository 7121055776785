export const precisionRound = (number, precision) => {
  if (Number.isNaN(Number(number))) return number;
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
};

export const returnEven = (num) => (num !== 0 ? num - 1 * (num % 2) : 0);
export const returnOdd = (num) => (num !== 0 ? num - 1 + 1 * (num % 2) : 0);

/**
 * @param {Number} n
 * @returns {String}
 */
export const getNumberWithOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
