import React, { useEffect, useMemo, useState } from 'react';
import InputRange from 'react-input-range';

import { useParams } from 'react-router-dom';

import Modal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import Loader from '../../components/Loader/Loader';
import Pill from '../../components/Pill/Pill';
import TabGroup from '../../components/TabGroup.tsx';
import client from '../../services/client';
import convertToDollarAmount from '../../utils/convertToDollar';
import MatchCard from './MatchCard.tsx';

const baseFilters = {
  games: [],
  modes: [],
  consoles: [],
  min: 0,
  max: 100500,
};

const MatchHistory = ({ username }) => {
  const { userId } = useParams();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const [matches, setMatches] = useState([]);
  const [page, setPage] = useState(1);
  const [fetchingComplete, setFetchingComplete] = useState(false);
  const [filters, setFilters] = useState(baseFilters);

  const [games, setGames] = useState([]);
  const [gameModes, setGameModes] = useState([]);
  const [consoles, setConsoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const gameNames = useMemo(
    () => games.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.id }), {}),
    [games]
  );
  const gameIds = useMemo(
    () => games.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.name }), {}),
    [games]
  );

  const modeIds = useMemo(
    () =>
      gameModes.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr.title }),
        {}
      ),
    [gameModes]
  );
  const consoleIds = useMemo(
    () =>
      consoles.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.name }), {}),
    [consoles]
  );

  useEffect(() => {
    const getGames = async () => {
      const { data } = await client.get(
        `${process.env.REACT_APP_API_URL}/api/v1/statistics/profile?user_id=${userId}`
      );
      const filtered = data?.filter((s) => !!s.game_id);

      setGames(
        await Promise.all(
          filtered.map(async ({ game_id }) => {
            const cacheGame = sessionStorage.getItem(`game_${game_id}`);
            if (cacheGame) {
              return JSON.parse(cacheGame);
            }
            const { data: gameData } = await client.get(
              `${process.env.REACT_APP_API_URL}/api/v1/games/${game_id}`
            );

            sessionStorage.setItem(`game_${game_id}`, JSON.stringify(gameData));
            return gameData;
          })
        )
      );
    };

    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/game_modes/`)
      .then(({ data }) => {
        setGameModes(data);
      });

    const cacheConsoles = sessionStorage.getItem('consoles');
    if (cacheConsoles) {
      setConsoles(JSON.parse(cacheConsoles));
    } else {
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/consoles/`)
        .then(({ data }) => {
          sessionStorage.setItem('consoles', JSON.stringify(data));
          setConsoles(data);
        });
    }

    getGames();
  }, []);

  useEffect(() => {
    setFetchingComplete(false);
    setMatches([]);
  }, [filters]);

  useEffect(() => {
    if (fetchingComplete) {
      return;
    }
    setLoading(true);
    let url = `/api/v1/matches/profile?show_all=true&participant_user_id=${userId}&page=${page}&page_size=24`;
    if (filters.games.length) {
      url += `&game_id=${filters.games[0]}`;
    }
    if (filters.modes.length) {
      url += `&game_modes[]=${filters.modes.join('&game_modes[]=')}`;
    }
    if (filters.consoles.length) {
      url += `&consoles[]=${filters.consoles.join('&consoles[]=')}`;
    }
    if (filters.min) {
      url += `&min_amount=${filters.min}`;
    }
    if (filters.max !== 100500) {
      url += `&max_amount=${filters.max}`;
    }
    client
      .get(`${process.env.REACT_APP_API_URL}${url}`)
      .then(({ data }) => {
        if (data.length < 24) {
          setFetchingComplete(true);
        }
        setMatches((m) => (page === 1 ? data : [...m, ...data]));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userId, page, filters, fetchingComplete]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        onClick={openModal}
        role="button"
        className=" flex justify-between items-center bg-zinc-900 rounded-md text-white text-lg font-extrabold p-4 cursor-pointer"
      >
        <p>Match History</p>
      </div>
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        header=""
        fullPage
        backgroundColor="bg-black"
        height="calc(100vh - 52px)"
        paginateData={fetchingComplete ? undefined : () => setPage(page + 1)}
      >
        <div className="px-4 pt-14">
          <h3 className="font-extrabold text-white text-3xl mb-4 flex gap-4 justify-between">
            {username}&lsquo;s Recent Matches
            <Button
              text="Filter"
              variant="secondary"
              className="!text-blue"
              onClick={() => setFilterOpen(true)}
            />
          </h3>
          <div className="flex items-center gap-2 mb-8">
            {filters.games.length === 0 ? (
              <div className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm">
                All Games
              </div>
            ) : (
              filters.games.map((g) => (
                <div
                  className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm"
                  key={`game_${g}`}
                >
                  {gameIds[g]}
                </div>
              ))
            )}
            {filters.modes.length === 0 ? (
              <div className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm">
                All Games Modes
              </div>
            ) : (
              filters.modes.map((g) => (
                <div
                  className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm"
                  key={`mode_${g}`}
                >
                  {modeIds[g]}
                </div>
              ))
            )}
            {filters.consoles.length === 0 ? (
              <div className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm">
                All Consoles
              </div>
            ) : (
              filters.consoles.map((g) => (
                <div
                  className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm"
                  key={`console_${g}`}
                >
                  {consoleIds[g]}
                </div>
              ))
            )}
            <div className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm">
              Min {convertToDollarAmount(filters.min)}
            </div>
            <div className="bg-grey-4 text-grey-2 rounded-full py-2 px-4 text-sm">
              {filters.max === 100500
                ? 'No Max'
                : `Max ${convertToDollarAmount(filters.max)}`}
            </div>
          </div>
          {loading && page === 1 && <Loader />}
          {!matches.length && !loading && (
            <p className="text-grey-2 text-center py-12">
              No matches to display
            </p>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {matches.map((match) => (
              <MatchCard
                match={match}
                key={`match_${match.id}`}
                userId={Number(userId)}
                onUpdateMatch={(m) =>
                  setMatches(matches.map((ma) => (ma.id === m.id ? m : ma)))
                }
              />
            ))}
          </div>
        </div>
      </Modal>

      <FilterModal
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onSubmit={setFilters}
        onClear={() => setFilters(baseFilters)}
        committedFilters={filters}
        consoles={consoles}
        gameModes={gameModes}
        games={games}
      />
    </>
  );
};

const FilterModal = ({
  isOpen,
  onClose,
  onSubmit,
  onClear,
  committedFilters,
  games,
  gameModes,
  consoles,
}) => {
  const [filters, setFilters] = useState(baseFilters);

  useEffect(() => {
    if (isOpen) {
      setFilters(committedFilters);
    }
  }, [isOpen]);

  const gameNames = useMemo(
    () => games.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.id }), {}),
    [games]
  );
  const gameIds = useMemo(
    () => games.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.name }), {}),
    [games]
  );

  const modeIds = useMemo(
    () =>
      gameModes.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr.title }),
        {}
      ),
    [gameModes]
  );

  const consoleNames = useMemo(
    () =>
      consoles.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.id }), {}),
    [consoles]
  );
  const consoleIds = useMemo(
    () =>
      consoles.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.name }), {}),
    [consoles]
  );

  const allowedConsoles = useMemo(
    () =>
      filters.games.length > 0
        ? games.find((g) => g.id === filters.games[0])?.console_ids || []
        : Array.from(
            new Set(
              games.reduce((prev, curr) => [...prev, ...curr.console_ids], [])
            )
          ),
    [games, filters.games]
  );

  const handleConsoleByName = (name) => {
    if (name === 'All') {
      setFilters((f) => ({ ...f, consoles: [] }));
    } else {
      const id = consoleNames[name];
      if (filters.consoles.includes(id)) {
        setFilters((f) => ({
          ...f,
          consoles: f.consoles.filter((g) => g !== id),
        }));
      } else {
        setFilters((f) => ({ ...f, consoles: [...f.consoles, id] }));
      }
    }
  };

  const handleModeByName = (name) => {
    if (name === 'All') {
      setFilters((f) => ({ ...f, modes: [] }));
    } else {
      const mode = gameModes.find(
        (m) => m.title === name && m.game_id === filters.games[0]
      );

      if (filters.modes.includes(mode.id)) {
        setFilters((f) => ({
          ...f,
          modes: f.modes.filter((g) => g !== mode.id),
        }));
      } else {
        setFilters((f) => ({ ...f, modes: [...f.modes, mode.id] }));
      }
    }
  };

  const handleGameByName = (name) => {
    if (name === 'All') {
      setFilters((f) => ({ ...f, games: [], modes: [] }));
    } else {
      const id = gameNames[name];
      setFilters((f) => ({ ...f, games: [id], modes: [] }));
    }
  };

  return (
    <Modal
      large
      header="Filter"
      height="600px"
      modalIsOpen={isOpen}
      closeModal={onClose}
    >
      <div className="px-4 sm:px-8 flex flex-col h-full overflow-auto">
        <div className="flex-1 pt-4">
          <h4 className="font-extrabold text-white text-2xl mb-2">Game</h4>
          <div className="overflow-auto mb-4 short-scroll">
            <TabGroup
              activeTab={
                filters.games.length > 0 ? gameIds[filters.games[0]] : 'All'
              }
              tabs={['All', ...games.map((g) => g.name)]}
              onChange={handleGameByName}
            />
          </div>

          <h4 className="font-extrabold text-white text-2xl mb-2">Game Mode</h4>
          <div className="overflow-auto mb-4 short-scroll">
            <TabGroup
              activeTab={filters.modes.length > 0 ? '' : 'All'}
              activeTabs={filters.modes.map((id) => modeIds[id])}
              tabs={
                filters.games.length > 0
                  ? [
                      'All',
                      ...gameModes
                        .filter((m) => filters.games.includes(m.game_id))
                        .map((g) => g.title),
                    ]
                  : ['All']
              }
              onChange={handleModeByName}
              multi={filters.games.length > 0}
            />
          </div>

          <h4 className="font-extrabold text-white text-2xl mb-2">Console</h4>
          <div className="overflow-auto mb-4 short-scroll">
            <TabGroup
              activeTab={filters.consoles.length > 0 ? '' : 'All'}
              activeTabs={filters.consoles.map((id) => consoleIds[id])}
              tabs={[
                'All',
                ...consoles
                  .filter((c) => allowedConsoles.includes(c.id))
                  .map((c) => c.name),
              ]}
              onChange={handleConsoleByName}
              multi
            />
          </div>

          <h4 className="font-extrabold text-white text-2xl mb-2">Amount</h4>

          <div className="flex justify-between w-full mb-4">
            <p className="text-base text-grey-2 py-2 bg-grey-4 px-4 rounded-xl w-28 text-center">
              {convertToDollarAmount(filters.min)}
            </p>
            <p className="text-base text-grey-2 py-2 bg-grey-4 px-4 rounded-xl w-28 text-center">
              {filters.max === 100500
                ? 'No Max'
                : convertToDollarAmount(filters.max)}
            </p>
          </div>
          <div className=" pl-2 pr-2">
            <InputRange
              maxValue={100500}
              minValue={0}
              value={{ min: filters.min, max: filters.max }}
              onChange={(val) =>
                setFilters({ ...filters, max: val.max, min: val.min })
              }
              step={500}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <Button
            text="Clear Filter"
            variant="tertiary"
            rounded
            large
            className="!flex-1"
            onClick={() => {
              onClear();
              onClose();
            }}
          />
          <Button
            text="Apply Filter"
            variant="primary"
            rounded
            large
            className="!flex-1"
            onClick={() => {
              onSubmit(filters);
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MatchHistory;
