/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import BaseModal from '../../components/BaseModal/BaseModal';
import Loader from '../../components/Loader/Loader';

import QuestionIcon from '../../assets/Icons/question.svg';
import GreenCheckmark from '../../assets/Icons/green-checkmark.svg';
import CloseIcon from '../../components/Icons/CloseIcon';
import useWallet from '../../contexts/WalletContext/WalletContext';
import usePrevious from '../../hooks/usePrevoius';

const LinkedAccountsModal = ({
  isRefundTransactionModalOpen,
  setRefundTransactionModalOpen,
  transactionToRefund,
}) => {
  const {
    refundStake,
    isRefundLoading,
    refundError,
    refundErrorMessage,
    resetRefundError,
  } = useWallet();
  const prev = usePrevious(isRefundLoading);

  const intervalRef = useRef(null);
  const [willDelete, setDelete] = useState(false);
  const [counter, setCounter] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (willDelete) {
      refundStake(transactionToRefund);
      setPage(1);
    }
  }, [willDelete]);

  useEffect(() => {
    if (prev === true && isRefundLoading === false) {
      setPage(2);
    }
  }, [prev]);

  useEffect(() => {
    if (refundError) {
      setPage(3);
    }
  }, [refundError]);

  const transform = useMemo(() => {
    if (page === 1) return -1;
    if (page === 2) return -2;
    if (page === 3) return -3;
    return 0;
  }, [page]);

  const startCounter = () => {
    if (intervalRef.current || willDelete) return;
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 10);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      setCounter(0);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if ((counter / 250) * 100 === 100) {
      setDelete(true);
      stopCounter();
    }
  }, [counter]);

  return (
    <BaseModal
      height="500px"
      modalIsOpen={isRefundTransactionModalOpen}
      closeModal={() => {
        setRefundTransactionModalOpen(false);
        setPage(0);
        resetRefundError();
        setDelete(false);
      }}
      backgroundColor="bg-black"
    >
      <div
        className="transition-all duration-300 ease-in-out flex flex-nowrap items-stretch h-full"
        style={{
          transform: `translateX(calc(100% * ${transform}))`,
        }}
      >
        <div className="w-full shrink-0 relative h-full">
          <div>
            <img src={QuestionIcon} alt="question" className="m-auto mt-12" />
          </div>
          <div className="text-center mt-12">
            <h3 className="text-white text-3xl font-bold">Are You Sure?</h3>
            <p className="text-grey-2 mt-3">
              Are you sure you want to refund this stake?{' '}
            </p>
          </div>
          <div className="w-full absolute bottom-0">
            <p className="text-white text-center my-3">
              This action cannot be undone
            </p>
            <div className="relative bg-warning bg-opacity-25 w-full rounded-full overflow-hidden">
              <div
                className="absolute h-full bg-warning -z-10 transition-all ease-in-out"
                style={{
                  width: `${
                    willDelete ? 100 : Math.round((counter / 250) * 100)
                  }%`,
                }}
              />
              <button
                className="w-full text-white py-3 z-10 relative disabled:opacity-75 select-none"
                onMouseDown={startCounter}
                onMouseUp={stopCounter}
                onTouchStart={startCounter}
                onTouchEnd={stopCounter}
                onMouseLeave={stopCounter}
                disabled={willDelete}
              >
                Hold To Confirm
              </button>
            </div>
          </div>
        </div>
        <div className="relative w-full shrink-0">
          <div className="mt-24">
            <Loader />
            <div className="text-center mt-12">
              <h3 className="text-white text-3xl font-bold">Refunding</h3>
              <p className="text-grey-2 mt-3">This may take a few seconds</p>
            </div>
          </div>
        </div>
        <div className="relative w-full shrink-0">
          <div className="absolute  top-1/3 w-full">
            <img src={GreenCheckmark} alt="checkmark" className="m-auto" />
            <div className="text-center mt-12">
              <h3 className="text-white text-3xl font-bold">
                Transaction Refunded
              </h3>
            </div>
          </div>
        </div>
        <div className="relative w-full shrink-0">
          <div className="absolute top-1/3 w-full">
            <CloseIcon color="#FF124F" className="w-16 m-auto" />
            <div className="text-center mt-12">
              <h3 className="text-white text-3xl font-bold">
                {refundErrorMessage}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default LinkedAccountsModal;
