/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const Pill = ({ children, className, onClick }) => (
  <div role={onClick !== undefined ? 'button' : 'menuitem'} onClick={onClick} className={cn('rounded-full px-3 py-1', className)}>{children}</div>
);

Pill.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Pill;
