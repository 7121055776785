/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
import React, { useState, createContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createConsumer } from '@rails/actioncable';

import client from '../../services/client';
import cleanMentionMessage from '../../utils/cleanMentionMessage';
import { formatRound } from '../../utils/formatBrackets';

const tournamentsContext = createContext({
  tournaments: [],
  loading: false,
});

const useTournament = (disabledSocket) => {
  const [tournaments, setTournaments] = useState([]);
  const [tournament, setTournament] = useState();
  const [event, setEvent] = useState();
  const [tournamentBracket, setTournamentBracket] = useState({});
  const [teams, setTeams] = useState([]);
  const [rules, setRules] = useState();
  const [gameMode, setGameMode] = useState();
  // const [gameModePackages, setGameModePackages] = useState();
  const [comments, setComments] = useState([]);
  const [clips, setClips] = useState([]);
  const [disputes, setDisputes] = useState([]);
  const [tournamentInviteLists, setTournamentInviteLists] = useState([]);
  const [inviteList, setInviteList] = useState([]);
  const [page, setPage] = useState(5);
  const [isMore, setIsMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userInviteError, setUserInviteError] = useState({});
  const [games, setGames] = useState([]);
  const [error, setError] = useState(false);
  const [matches, setMatches] = useState([]);

  const initChatSocket = async (commentId) => {
    const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

    const chatParams = {
      channel: 'CommentsChannel',
      comment_id: commentId,
    };

    const chatHandler = {
      received(data) {
        // We have 2 types of messages so we figure out which of the 2 got sent
        let messageObject;
        if (data['comment_message/text_comment_message'] !== undefined) {
          const message = data['comment_message/text_comment_message'];

          messageObject = {
            id: message.user_profile.id,
            message: message.data.text,
            user_profile_image: message.user_profile.profile_image_url,
            username: message.user_profile.username,
            senderName: message.user_profile.username,
            messageId: message.data.id,
          };
        } else {
          const mentionMessage =
            data['comment_message/mention_comment_message'];

          const message = mentionMessage.data.text;

          messageObject = {
            id: mentionMessage.user_profile.id,
            message: cleanMentionMessage(message),
            user_profile_image: mentionMessage.user_profile.profile_image_url,
            username: mentionMessage.user_profile.username,
            senderName: mentionMessage.user_profile.username,
            messageId: mentionMessage.data.id,
          };
        }
        setComments((prevMessages) => [...prevMessages, messageObject]);
      },
      disconnected() {
        setComments([]);
      },
    };

    const liveChatSubscription = cable.subscriptions.create(
      chatParams,
      chatHandler
    );

    return function cleanup() {
      liveChatSubscription.unsubscribe();
    };
  };

  const getMatches = async (id) => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${id}/matches`
    );

    setMatches(data);
    setLoading(false);
  };

  const initTeamsSocket = async (tournamentId, teamsFromServer) => {
    const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);
    let newTeams = [...teamsFromServer];

    const teamsParams = {
      channel: 'TournamentTeamsChannel',
      tournament_id: tournamentId,
    };

    const teamHandler = {
      received(data) {
        const teamIndex = newTeams.findIndex(
          (team) => team.id === data.tournament_team.id
        );

        if (teamIndex !== -1) {
          newTeams[teamIndex] = data.tournament_team;

          const sortedTeams = newTeams.sort(
            (teamA, teamB) =>
              (teamB.state === 'ready' &&
                teamB.active_television_channel_id !== null) -
                (teamA.state === 'ready' &&
                  teamA.active_television_channel_id !== null) ||
              (teamB.state === 'ready' &&
                teamB.active_television_channel_id === null) -
                (teamA.state === 'ready' &&
                  teamA.active_television_channel_id === null)
          );

          setTeams(sortedTeams);
          newTeams = sortedTeams;
        } else if (!tournament.completed) {
          setTeams([...newTeams, data.tournament_team]);
          newTeams = [...newTeams, data.tournament_team];
        }
      },
    };

    const tourneySubscription = cable.subscriptions.create(
      teamsParams,
      teamHandler
    );

    return function cleanup() {
      tourneySubscription.unsubscribe();
    };
  };

  const initDisputesSocket = async (tournamentId) => {
    const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

    const disputesParams = {
      channel: 'TournamentBracketDisputesChannel',
      tournament_id: tournamentId,
    };

    const disputeHandler = {
      received(data) {
        const dispute = data;

        setDisputes([...disputes, dispute]);
      },
    };

    const disputeSubscription = cable.subscriptions.create(
      disputesParams,
      disputeHandler
    );

    return function cleanup() {
      disputeSubscription.unsubscribe();
    };
  };

  // Get Tournaments
  const getTournaments = async () => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v3/tournaments?ascending=true`
    );

    setTournaments(data);
    setLoading(false);
  };

  // Get Tournament
  const getTournament = async (tournamentId) => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v3/tournaments/${tournamentId}`
    );

    setTournament(data);
    setLoading(false);
    if (!disabledSocket && data.comment_id) {
      initChatSocket(data.comment_id);
      initDisputesSocket(tournamentId);
    }
  };

  const getEvent = async (tournamentId, noLoad) => {
    setLoading(!noLoad);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/stakes/${tournamentId}`
    );

    setEvent(data);
    setLoading(false);
  };

  const eventSubscription = useRef();
  useEffect(() => {
    if (event?.id) {
      const cable = createConsumer(process.env.NEXT_PUBLIC_WEBSOCKET_URL);

      const updateSubscription = cable.subscriptions.create(
        {
          channel: 'EventsUpdateChannel',
          event_id: event.id,
        },
        {
          received() {
            getEvent(event.id, true);
          },
        }
      );

      eventSubscription.current = updateSubscription;
    }

    return () => {
      if (eventSubscription.current) {
        eventSubscription.current.unsubscribe();
      }
    };
  }, [event?.id]);

  // Get Tournament Brackets
  const getTournamentBracket = async (tournamentId) => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/tournaments/${tournamentId}/brackets`
    );

    setTournamentBracket(data);
    setLoading(false);
  };

  const initBracketSocket = async (tournamentId) => {
    const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

    const bracketsParams = {
      channel: 'TournamentBracketsChannel',
      tournament_id: tournamentId,
    };

    const bracketHandler = {
      received(data) {
        getTournamentBracket(tournamentId);
      },
    };

    const bracketSubscription = cable.subscriptions.create(
      bracketsParams,
      bracketHandler
    );

    return function cleanup() {
      bracketSubscription.unsubscribe();
    };
  };

  // Get Tournament Teams
  const getTeams = async (tournamentId) => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/tournaments/${tournamentId}/teams`
    );

    const sortedTeams = data.sort(
      (teamA, teamB) =>
        (teamB.state === 'ready' &&
          teamB.active_television_channel_id !== null) -
          (teamA.state === 'ready' &&
            teamA.active_television_channel_id !== null) ||
        (teamB.state === 'ready' &&
          teamB.active_television_channel_id === null) -
          (teamA.state === 'ready' &&
            teamA.active_television_channel_id === null)
    );

    setTeams(sortedTeams);
    initTeamsSocket(tournamentId, sortedTeams);
    setLoading(false);
  };

  // Get Tournament Game Mode
  // const getGameModePackages = async (gameId) => {
  //   setLoading(true);

  //   const { data } = await client.get(
  //     `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/packages?game_id=${gameId}`
  //   );

  //   setGameModePackages(data);
  //   setLoading(false);
  // };

  // Get Tournament Rules
  const getRulesSet = async (tournamentId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/matches/element_sets/${tournamentId}`
    );

    setRules(data);
  };

  // Get game modes
  const getGameMode = async (gameModeId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${gameModeId}`
    );

    setGameMode(data);
  };

  // Get Comments
  const getComments = async (commentId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/comment_messages?comment_id=${commentId}&page=1&page_size=20`
    );

    const formattedMessagesObject = data
      .map(
        ({
          user_profile: { id: userId, profile_image_url, username },
          data: { text, id: messageId },
        }) => {
          if (text.includes('¡{"id":')) {
            return {
              id: userId,
              user_profile_image: profile_image_url,
              senderName: username,
              username,
              message: cleanMentionMessage(text),
              messageId,
            };
          }
          return {
            id: userId,
            message: text,
            messageId,
            user_profile_image: profile_image_url,
            senderName: username,
            username,
          };
        }
      )
      .reverse();

    setComments(formattedMessagesObject);
  };

  // Paginated messages
  const getMoreMessages = async () => {
    if (isMore) {
      const { data: commentsData } = await client.get(
        `${process.env.REACT_APP_API_URL}/api/v1/comment_messages?comment_id=${tournament.comment_id}&page=${page}&page_size=5`
        // `${process.env.NEXT_PUBLIC_API_URL}/api/v1/comment_messages?comment_id=139&page=${page}&page_size=5`
      );

      const formattedMessagesObject = commentsData
        .map(
          ({
            user_profile: { id: userId, profile_image_url, username },
            data: { text },
          }) => {
            if (text.includes('¡{"id":')) {
              return {
                id: userId,
                user_profile_image: profile_image_url,
                senderName: username,
                username,
                message: cleanMentionMessage(text),
              };
            }
            return {
              id: userId,
              message: text,
              user_profile_image: profile_image_url,
              senderName: username,
              username,
            };
          }
        )
        .reverse();

      if (commentsData.length === 0 || commentsData.length < 5) {
        setIsMore(false);
      }

      setPage(page + 1);
      setComments([...formattedMessagesObject, ...comments]);
    }
  };

  // Clips
  const getClips = async (tournamentId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/clips/hot?type=tournamentclip&page=1&page_size=2&type_id=${tournamentId}`
    );

    setClips(data);
    // initClipsSocket();
  };

  const getDisputes = async (tournamentId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/disputes`
    );

    setDisputes(data);
  };

  const getTournamentInviteLists = async () => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists?page=1&page_size=20`
    );

    setTournamentInviteLists(data);
  };

  const getTournamentInvites = async (inviteId) => {
    setLoading(true);
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists/${inviteId}/invites`
    );

    if (data) {
      setLoading(false);
      setInviteList(data);
    }
  };

  const getGames = async () => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/games`
    );

    setGames(data);
  };

  const createTournament = async (data) => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments`,
        data
      );
      getTournaments();
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e.response?.data?.message || 'Something went wrong');
      setError(e.response);
    }
  };

  const disqualifyTeam = async (tournamentId, teamId) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/disqualify?tournament_team_id=${teamId}`
    );
  };

  const replayMatch = async (tournamentId, teamId) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/replay_round?tournament_team_id=${teamId}`
    );
  };

  const deleteComment = async (commentId) => {
    await client.delete(
      `${process.env.REACT_APP_API_URL}/admin/v1/comments/${commentId}`
    );

    const removedComment = comments.filter(
      (comment) => comment.messageId !== commentId
    );

    setComments(removedComment);
  };

  const silenceUser = async (userId) => {
    const date = new Date(Date.now() + 3600 * 1000 * 24).toISOString();
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/silence?time=${date}`
    );
  };

  const submitMatchPlacement = async (disputeId, winnerTeamId, loserTeamId) => {
    // eslint-disable-next-line no-alert
    const ok = confirm('Are you sure?');
    if (ok) {
      try {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/submit`,
          {
            team_uuid: winnerTeamId,
            placement: 1,
          }
        );
        if (loserTeamId) {
          await client.post(
            `${process.env.REACT_APP_API_URL}/admin/v1/matches/submit`,
            {
              team_uuid: loserTeamId,
              placement: null,
            }
          );
        }
      } catch (e) {
        // noop
      }

      const removedDispute = disputes.filter(
        (dispute) => dispute.match.id !== disputeId
      );

      setDisputes(removedDispute);
    }
  };

  const createTournamentList = async (name) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists`,
      {
        name,
      }
    );
    getTournamentInviteLists();
  };

  const createInvite = async (inviteListId, user_id) => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists/${inviteListId}/invites`,
        {
          user_id,
        }
      );

      getTournamentInvites(inviteListId);

      const filteredInviteList = tournamentInviteLists.filter(
        (listItem) => listItem.id === inviteListId
      );

      filteredInviteList[0].num_of_invitees =
        filteredInviteList[0].num_of_invitees += 1;

      const inviteListIndex = tournamentInviteLists.findIndex(
        (listItem) => listItem.id === inviteListId
      );

      tournamentInviteLists[inviteListIndex] = { ...filteredInviteList[0] };

      setTournamentInviteLists(tournamentInviteLists);
    } catch (e) {
      setUserInviteError({
        errorMessage: e.response.data.message,
        user_id,
      });
    }
  };

  const removeInvite = async (inviteListId, inviteId) => {
    const filteredInvites = inviteList.filter((user) => user.id !== inviteId);

    setInviteList(filteredInvites);

    const filteredInviteList = tournamentInviteLists.filter(
      (listItem) => listItem.id === inviteListId
    );

    filteredInviteList[0].num_of_invitees =
      filteredInviteList[0].num_of_invitees -= 1;

    const inviteListIndex = tournamentInviteLists.findIndex(
      (listItem) => listItem.id === inviteListId
    );

    tournamentInviteLists[inviteListIndex] = { ...filteredInviteList[0] };

    await client.delete(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists/${inviteListId}/invites/${inviteId}`
    );
  };

  const updateTournamentInviteName = async (inviteListId, name) => {
    const filteredInviteList = tournamentInviteLists.filter(
      (listItem) => listItem.id === inviteListId
    );
    filteredInviteList[0].name = name;

    const inviteListIndex = tournamentInviteLists.findIndex(
      (listItem) => listItem.id === inviteListId
    );

    tournamentInviteLists[inviteListIndex] = { ...filteredInviteList[0] };

    setTournamentInviteLists(tournamentInviteLists);

    await client.put(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists/${inviteListId}`,
      {
        name,
      }
    );
  };

  const duplicateInviteList = async (inviteListId, name) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists/${inviteListId}/duplicate`,
      {
        name: `${name}`,
      }
    );

    getTournamentInviteLists();
  };

  const deleteInviteList = async (inviteListId, name) => {
    const filteredInviteList = tournamentInviteLists.filter(
      (listItem) => listItem.id !== inviteListId
    );

    setTournamentInviteLists(filteredInviteList);

    await client.delete(
      `${process.env.REACT_APP_API_URL}/admin/v1/tournament_invite_lists/${inviteListId}`,
      {
        name,
      }
    );
  };

  return {
    loading,
    userInviteError,
    isMore,
    tournaments,
    tournament,
    matches,
    getMatches,
    tournamentBracket,
    teams,
    rules,
    gameMode,
    // gameModePackages,
    comments,
    clips,
    disputes,
    tournamentInviteLists,
    inviteList,
    games,
    error,
    getTournaments,
    getTournament,
    getTournamentBracket,
    getTeams,
    // getGameModePackages,
    getRulesSet,
    getGameMode,
    getComments,
    getMoreMessages,
    getDisputes,
    getClips,
    getTournamentInviteLists,
    getTournamentInvites,
    getGames,
    createTournament,
    disqualifyTeam,
    replayMatch,
    deleteComment,
    silenceUser,
    submitMatchPlacement,
    createTournamentList,
    createInvite,
    removeInvite,
    updateTournamentInviteName,
    duplicateInviteList,
    deleteInviteList,
    setUserInviteError,
    setError,
    getEvent,
    event,
    setEvent,
    initBracketSocket,
  };
};

export function TournamentsProvider({ children }) {
  const audits = useTournament();

  return (
    <tournamentsContext.Provider value={audits}>
      {children}
    </tournamentsContext.Provider>
  );
}

export default function TournamentConsumer() {
  return React.useContext(tournamentsContext);
}

TournamentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
