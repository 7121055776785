/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

/**
 * @param {{
 *   onConfirm: () => void;
 *   disabled?: boolean;
 *   text: string;
 *   variant: string;
 *   timeout: number
 *   square?: boolean
 *   small?: boolean
 * }} param0
 * @returns
 */
const HoldButton = ({
  onConfirm,
  disabled,
  text,
  variant,
  timeout,
  square,
  small,
}) => {
  const [counter, setCounter] = useState(0);
  const [complete, setComplete] = useState(false);
  const intervalRef = React.useRef(null);

  useEffect(() => {
    if (!disabled) {
      setComplete(false);
    }
  }, [disabled]);

  const stopCounter = () => {
    if (intervalRef.current) {
      setCounter(0);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };
  useEffect(() => {
    if ((counter / (timeout + 10)) * 100 === 100) {
      setComplete(true);
      onConfirm();
      stopCounter();
    }
  }, [counter, onConfirm, timeout]);

  const startCounter = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 10);
  };

  return (
    <div
      className={classNames(
        'relative  bg-opacity-25 w-full rounded-full overflow-hidden border transition-opacity ease-out',
        {
          'bg-success border-success': variant === 'success',
          'bg-warning border-warning': variant === 'warning',
          'bg-primary border-primary !bg-opacity-50': variant === 'primary',
          'bg-grey-3 border-grey-3': variant === 'grey',
          'opacity-75': complete,
          '!rounded-md !flex-1': square,
        }
      )}
    >
      <div
        className={classNames('absolute h-full z-0 ease-in-out', {
          'transition-all': counter === 0,
          'bg-success': variant === 'success',
          'bg-warning': variant === 'warning',
          'bg-primary': variant === 'primary',
          'bg-grey-3': variant === 'grey',
        })}
        style={{
          width: `${complete ? 100 : Math.round((counter / timeout) * 100)}%`,
        }}
      />
      <button
        className={classNames(
          'w-full py-3 z-10 font-semibold relative disabled:opacity-75 select-none !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-gold',
          {
            'text-white': variant !== 'primary',
            'text-black': variant === 'primary',
            '!py-3 !text-sm': square || small,
          }
        )}
        onMouseDown={startCounter}
        onMouseUp={stopCounter}
        onTouchStart={startCounter}
        onTouchEnd={stopCounter}
        onMouseLeave={stopCounter}
        disabled={disabled}
        type="button"
      >
        {text}
      </button>
    </div>
  );
};

export default HoldButton;
