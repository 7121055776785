import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import convertToDollarAmount from '../../utils/convertToDollar';

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const NoCentsCurrencyInput = ({ onValueChange, value, name }) => {
  const ref = useRef(null);

  const handleChange = (e) => {
    if (/[^0-9]|^0+(?!$)/g.test(e.target.value)) {
      onValueChange(
        Number(e.target.value.replace(/[^0-9]|^0+(?!$)/g, '')) * 100
      );
    } else {
      onValueChange(Number(e.target.value) * 100);
    }
  };

  const [dollars] = convertToDollarAmount(value, true).split('.');

  return (
    <div className="relative mb-2 sm:mb-6">
      <input
        ref={ref}
        className={classNames(
          'font-black bg-transparent w-full h-full text-center py-3',
          {
            'text-grey-2': dollars.length === 2 && dollars === '$0',
            'text-white': dollars !== '$0',
            'text-7xl sm:text-8xl': dollars.length <= 6,
            'text-6xl sm:text-7xl': dollars.length === 7,
            'text-5xl sm:text-6xl': dollars.length === 8,
            'text-4xl sm:text-5xl': dollars.length === 9,
            'text-3xl sm:text-4xl': dollars.length >= 10,
          }
        )}
        inputMode="numeric"
        onChange={handleChange}
        value={dollars}
        name={name}
      />
      <div className="border-b border-grey-3 w-72 m-auto" />
    </div>
  );
};

export const WithCentsCurrencyInput = ({
  onValueChange,
  value,
  onEnter,
  error,
  small,
  promo,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current.focus();
    }, 500);

    return () => clearTimeout(timeout);
  }, []);
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  const [dollars, cents] = convertToDollarAmount(value, true).split('.');
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    throw new Error('invalid value property');
  }
  const handleKeyDown = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (key === 'Enter') {
        onEnter?.();
      }
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
      }
      onValueChange(nextValue);
    },
    [onValueChange, value]
  );
  const handleChange = useCallback(() => {}, []);
  const valueDisplay = (value / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (small) {
    return (
      <input
        ref={ref}
        className={classNames(
          'w-[150px] h-full text-white text-left px-4 border rounded-full border-grey-3 bg-grey-4 py-3  transition-all ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-gold',
          {
            ' !text-warning focus-visible:!outline-warning': !!error,
          }
        )}
        inputMode="numeric"
        value={`${dollars}.${cents}`}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    );
  }

  return (
    <div className="w-full relative pt-[4px]">
      <input
        ref={ref}
        style={
          promo
            ? {
                textShadow: '0px 0px 10px #FFC20E, 0px 0px 20px #FFC20E',
              }
            : {}
        }
        className={classNames(
          'font-black w-full h-full text-white text-center border rounded-2xl border-grey-3 bg-grey-4 py-3 transition-all ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-gold',
          {
            'text-7xl sm:text-8xl': dollars.length <= 6,
            'text-6xl sm:text-7xl': dollars.length === 7,
            'text-5xl sm:text-6xl': dollars.length === 8,
            'text-4xl sm:text-5xl': dollars.length === 9,
            'text-3xl sm:text-4xl': dollars.length >= 10,
            'focus-visible:!outline-warning': !!error,
          }
        )}
        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={`${dollars}.${cents}`}
      />
    </div>
  );
};

export default NoCentsCurrencyInput;
