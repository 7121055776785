/* eslint-disable no-unneeded-ternary */
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { createConsumer } from '@rails/actioncable';

import client from '../../services/client';

const disputesContext = createContext({
  tournaments: [],
  loading: false,
});

const useDisputes = () => {
  const [disputes, setDisputes] = useState([]);
  const [fetchedAll, setFetchedAll] = useState(false);
  const [disputeClip, setDisputeClip] = useState({});
  const [rules, setRules] = useState({});
  const [gameMode, setGameMode] = useState({});

  const initDisputesSocket = async (disputesFromServer) => {
    const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

    const disputesParams = {
      channel: 'WagerDisputesChannel',
    };

    const disputeHandler = {
      received(data) {
        const dispute = data['match/wager_match'];
        if (dispute.state === 'disputed') {
          setDisputes([...disputesFromServer, dispute]);
        } else {
          setDisputes((d) => d.filter((m) => m.id !== d.id));
        }
      },
    };

    const disputeSubscription = cable.subscriptions.create(
      disputesParams,
      disputeHandler
    );

    return function cleanup() {
      disputeSubscription.unsubscribe();
    };
  };

  const getDisputeClip = async (disputeId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/matches/${disputeId}/dispute_television`
    );

    setDisputeClip(data);
  };

  const getDisputes = async (page) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/matches/disputes?page=${page}&page_size=20`
    );

    if (data.length < 20) {
      setFetchedAll(true);
    }
    setDisputes(page === 1 ? data : [...disputes, ...data]);
    if (page === 1) {
      initDisputesSocket(data);
    }
  };

  const getGameMode = async (gameModeId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${gameModeId}`
    );

    setGameMode(data);
  };

  const submitMatchPlacement = async (
    disputeId,
    winnerTeamId,
    loserTeamId,
    category
  ) => {
    const body = {
      team_uuid: winnerTeamId,
      placement: 1,
    };
    if (category) {
      body.category = category;
    }
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/matches/submit`,
      body
    );
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/submit`,
        {
          team_uuid: loserTeamId,
          placement: null,
        }
      );
    } catch (e) {
      // noop
    }

    const removedDispute = disputes.filter(
      (dispute) => dispute.id !== disputeId
    );

    setDisputes(removedDispute);
  };

  const cancelMatch = async (matchId) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/cancel`,
      { category: 'Mutual Cancel' }
    );

    const removedDispute = disputes.filter((dispute) => dispute.id !== matchId);

    setDisputes(removedDispute);
  };

  const getRulesSet = async (elementSetId, matchId) => {
    const { data } = await client.get(
      `${
        process.env.REACT_APP_API_URL
      }/api/v1/matches/element_sets/${elementSetId}${
        matchId ? `?match_id=${matchId}` : ''
      }`
    );

    setRules(data);
  };

  return {
    disputes,
    rules,
    gameMode,
    disputeClip,
    getRulesSet,
    getDisputes,
    getGameMode,
    getDisputeClip,
    submitMatchPlacement,
    cancelMatch,
    fetchedAll,
  };
};

export function DisputesProvider({ children }) {
  const audits = useDisputes();

  return (
    <disputesContext.Provider value={audits}>
      {children}
    </disputesContext.Provider>
  );
}

export default function DisputesConsumer() {
  return React.useContext(disputesContext);
}

DisputesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
