const BOOST = {
  counters: {
    u_column: 36,
    u_row: 17,
    u_content_image: 28,
    u_content_text: 8,
    u_content_heading: 10,
    u_content_button: 2,
    u_content_menu: 1,
  },
  body: {
    id: '34nYduowRQ',
    rows: [
      {
        id: 'IWAqxLIsrY',
        cells: [1],
        columns: [
          {
            id: 'ifAWbXVslT',
            contents: [
              {
                id: 'eZcFMSuCDb',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/da53a24d-6a71-49a8-85c0-4cf85f2f38b1',
                    width: 2398,
                    height: 332,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'mvSYYpnL5E',
        cells: [1],
        columns: [
          {
            id: 'CvW3CFUdGi',
            contents: [
              {
                id: 'E8hn4NW15z',
                type: 'image',
                values: {
                  containerPadding: '16px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1d52a5e3-2b5b-4392-a203-515a630cd5a7',
                    width: 700,
                    height: 327,
                    autoWidth: false,
                    maxWidth: '48%',
                  },
                  textAlign: 'center',
                  altText: 'Get paid instantly',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_9',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: 'ALAiVQEQpi',
                type: 'heading',
                values: {
                  containerPadding: '10px 10px 0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '26px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_1',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'BOOOOOST',
                },
              },
              {
                id: '0qqe2QYdfM',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '16px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Get a 10% stake bonus when you place your first stake in the next 24 hours. </p>\n<p style="line-height: 140%;"> </p>\n<p style="line-height: 140%;">Example, stake $100 and get $10 FREE</p>',
                },
              },
              {
                id: '-Mf1wGmfeR',
                type: 'image',
                values: {
                  containerPadding: '10px 38px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/32f48975-f669-4a1c-a587-651542279e52',
                    width: 965,
                    height: 193,
                    autoWidth: false,
                    maxWidth: '60%',
                  },
                  textAlign: 'center',
                  altText: 'Claim My Boost',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_12',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_2', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_2', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'I-wX3TNkhp',
        cells: [1, 1],
        columns: [
          {
            id: 'IMpFDkPZS1',
            contents: [
              {
                id: 'SqSfH0my7v',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/dcc6bb49-3751-4612-b3d3-0320d3f5c96c',
                    width: 709,
                    height: 384,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_10',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_8', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'AYi8vDVDXY',
            contents: [
              {
                id: 'Ftk3G-q6ub',
                type: 'heading',
                values: {
                  containerPadding: '48px 0px 12px',
                  anchor: '',
                  headingType: 'h3',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_7',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'DOWNLOAD THE APP',
                },
              },
              {
                id: 'zu-GlKqmLL',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6f2a8e3f-9d12-43e2-a64d-46118a7d5f0e',
                    width: 400,
                    height: 131,
                    autoWidth: false,
                    maxWidth: '48%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/AxN12BiEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_11',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_8', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'z7NauV6I9m',
        cells: [1],
        columns: [
          {
            id: 'FbDEsm_j83',
            contents: [
              {
                id: 'BJbBsIqn1-',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6b939366-6cd7-4c64-b091-fadc021680b8',
                    width: 400,
                    height: 124,
                    autoWidth: false,
                    maxWidth: '19%',
                  },
                  textAlign: 'center',
                  altText: '1v1Me',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_23',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: { mobile: { hideMobile: false } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_35', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_17', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { desktop: { hideDesktop: false } },
        },
      },
      {
        id: 'oudVuuSmXB',
        cells: [25.76, 10, 10, 10, 10, 10, 24.24],
        columns: [
          {
            id: 'OhUm-JNmeN',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_29', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'QeAT1lN5yn',
            contents: [
              {
                id: 'MsGoiy4bew',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/d2c56c65-0fbf-475f-9572-90134f7bc268',
                    width: 96,
                    height: 96,
                    autoWidth: true,
                    maxWidth: '100%',
                  },
                  textAlign: 'center',
                  altText: 'tiktok',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/93FpFYkEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_24',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_30', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'lBsm5bHyM-',
            contents: [
              {
                id: 'ViNkcSi7sv',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1681b2d9-916f-4e3a-be71-2ced09bf968a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitter',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/V1PW9RqEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_25',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_31', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '8TzlLLrBwQ',
            contents: [
              {
                id: 'YQ0_d2ulfA',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/856f006d-6370-4054-9a55-e15192f4da7c',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Facebook',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/st8hFXsEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_26',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_32', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'E7-VwyL17N',
            contents: [
              {
                id: 'sOIA_u7IBD',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/33ae9ddb-f9c7-446b-b11b-7297a7738c48',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'instagram',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/BJ4BBSoEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_27',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_33', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'BKudhtcc4r',
            contents: [
              {
                id: 'nCNxFmMcqj',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/f862219c-bbf7-47ab-853f-a0e78e6bd24a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitch',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/wsKKtQmEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_28',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_34', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '4hD1dm-2wc',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_36', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_16', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: {
            mobile: {
              noStackMobile: true,
              backgroundColor: '#000000',
              hideMobile: false,
            },
            desktop: { hideDesktop: false },
          },
        },
      },
      {
        id: 'tYRgQpRhOY',
        cells: [1],
        columns: [
          {
            id: 'yPGkfQS7Pu',
            contents: [
              {
                id: 'HxwSHKZ1Nh',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_7',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Don’t want these emails? <a rel="noopener" href="<%= unsubscribe %>" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6IjwlPSB1bnN1YnNjcmliZSAlPiIsInRhcmdldCI6Il9ibGFuayJ9fQ=="><span style="line-height: 19.6px;">Unsubscribe</span></a></p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_20', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_11', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: 'center',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      textColor: '#ffffff',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: '#000000',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      preheaderText: '10% Stake Boost',
      linkStyle: {
        body: true,
        linkColor: '#ffc20e',
        linkHoverColor: '#0000ee',
        linkUnderline: false,
        linkHoverUnderline: true,
        inherit: false,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 13,
};

export default BOOST;
