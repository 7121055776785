/* eslint-disable */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TournamentTeamType, TournamentType } from '../../types';
import AvatarGroup from '../../components/AvatarGroup.tsx';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';

interface Props {
  team: TournamentTeamType;
  userId: number;
  senderId: number;
}

const BountyCard: FC<Props> = ({ team, userId, senderId }) => {
  const [tourny, setTourny] = useState<TournamentType | null>(null);

  useEffect(() => {
    client
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/tournaments/${team.tournament_id}`
      )
      .then(({ data }) => setTourny(data));
  }, [team]);

  const user = team.tournament_players.find(
    (p) => p.user_profile.id === userId
  );

  const topText = useMemo(() => {
    if (user?.type === 'leader') {
      return 'Invite Sent';
    }
    if (user?.state === 'joined') {
      return `Accepted Invite`;
    }
    if (user?.state === 'declined') {
      return `Declined Invite`;
    }
    return `Pending Invite`;
  }, [user?.state, user?.type]);

  const bottomText = useMemo(() => {
    if (!tourny) {
      return null;
    }
    const users = team.tournament_players
      .filter((p) => p.user_profile.id !== userId)
      .map((p) => p.user_profile.username);

    const names =
      users.length <= 2
        ? users.join(' & ')
        : `${users.slice(0, users.length - 1).join(', ')} & ${
            users[users.length - 1]
          }`;

    if (user?.type === 'leader') {
      return `You invited ${names} to the ${tourny.name}`;
    }
    if (user?.state === 'joined') {
      return `You joined ${names} for the ${tourny.name}`;
    }
    if (user?.state === 'declined') {
      return `You declined playing with ${names} in the ${tourny.name}`;
    }
    return `Join ${names} in the ${tourny.name}`;
  }, [team.tournament_players, tourny, user?.state, user?.type, userId]);

  if (!tourny) {
    return null;
  }
  return (
    <div className="min-w-[400px] rounded-lg overflow-hidden">
      <div className="h-48 relative">
        <img
          src={tourny.invite_image_url}
          className="object-cover w-full h-full absolute top-0 left-0"
          alt={tourny.name}
        />
      </div>
      <div className="bg-grey-4 p-4 text-center">
        <div className="flex items-center justify-center -mt-8 mb-2">
          <AvatarGroup
            users={team.tournament_players.map((p) => p.user_profile)}
            limit={4}
            medium
          />
        </div>
        <h3 className="font-bold text-xl capitalize">{topText}</h3>
        <p className="text-xs text-grey-2 max-w-[300px] mx-auto mb-6">
          {bottomText}
        </p>
        {user?.state === 'pending' ? (
          <div className="flex items-center justify-center gap-4">
            <Button
              variant="grey"
              text="Decline"
              rounded
              className="!bg-grey-3 !text-warning !flex-1"
              onClick={() => {}}
            />
            <Button
              variant="primary"
              text="Details"
              rounded
              onClick={() => {}}
              className="!flex-1"
            />
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <Button
              text={user?.type === 'leader' ? 'Invite Sent' : user?.state}
              disabled
              rounded
              variant="grey"
              onClick={() => {}}
              className="!capitalize !bg-grey-3 w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BountyCard;
