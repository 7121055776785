import React, { useEffect, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import useUsers from '../../contexts/UserContext/UserContext';

import Modal from '../../components/BaseModal/BaseModal';
import StakeCard from './StakeCard';
import client from '../../services/client';
import Button from '../../components/Buttons/Button/Button';
import Loader from '../../components/Loader/Loader';

const Slips = () => {
  const { user } = useUsers();
  const [slips, setSlips] = useState([]);
  const [page, setPage] = useState(1);
  const [doneFetching, setDoneFetching] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useSearchParams();
  const [eventSlips, setEventSlips] = useState(null);

  useEffect(() => {
    if (!user?.user?.id) {
      return;
    }

    if (search.get('open_slip')) {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/stakes/${search.get(
            'open_slip'
          )}/slip?user_id=${user.user.id}`
        )
        .then(({ data }) => setEventSlips(data));
    } else {
      setEventSlips(null);
    }
  }, [search, user]);

  useEffect(() => {
    if (!user?.user?.id) {
      return;
    }
    if (page === 1) {
      setLoadingInitial(true);
    } else {
      setLoading(true);
    }
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/stakes/slips?user_id=${user.user.id}&page=${page}&page_size=10`
      )
      .then(({ data }) => {
        if (data.length < 10) {
          setDoneFetching(true);
        }
        if (page === 1) {
          setSlips(data);
        } else {
          setSlips((s) => [...s, ...data]);
        }
      })
      .finally(() => {
        setLoadingInitial(false);
        setLoading(false);
      });
  }, [page, user]);

  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        onClick={openModal}
        role="button"
        className="flex justify-between items-center bg-zinc-900 rounded-md text-white text-lg font-extrabold p-4 cursor-pointer"
      >
        <p>Stake Slips</p>
      </div>
      <Modal
        header={`Event ${search.get('open_slip')} Slips`}
        backgroundColor="bg-black"
        modalIsOpen={!!search.get('open_slip')}
        closeModal={() => setSearch({})}
      >
        {eventSlips && (
          <div className="mt-3">
            <StakeCard stake={eventSlips} />
          </div>
        )}
      </Modal>
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        header="Stake Slips"
        backgroundColor="bg-black"
        height="80vh"
      >
        <div className="mt-3">
          {loadingInitial && <Loader />}
          {!loadingInitial && slips.length === 0 && (
            <p className="text-grey-2">Nothing to see here</p>
          )}
          {slips.map((stake) => (
            <StakeCard stake={stake} key={stake.tournament_id} />
          ))}
        </div>
        {!doneFetching && slips.length > 0 && (
          <div className="flex items-center justify-center pb-8 mt-8">
            <Button
              variant="secondary"
              text="Load More"
              onClick={() => setPage(page + 1)}
              disabled={loading}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default Slips;
