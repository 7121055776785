/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch';
import classNames from 'classnames';
import { format } from 'date-fns';
import useAuth from '../../contexts/AuthContext/AuthContext';

import Layout from '../../components/Layout/Layout';

import Button from '../../components/Buttons/Button/Button';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';
import useDebounce from '../../utils/useDebounce';
import { ONE_HOUR } from '../../utils/time';

const CHANNELS = [
  'Creator',
  'Twitter',
  'Twitch-panel',
  'Website',
  'Affiliate',
  'Medium',
  'YouTube',
  'TikTok',
  'App Store',
  'Facebook',
  'Organic',
  'Discord',
  'Email',
  'Twitch-chatbot',
  'Twitch-about',
];

const FEATURES = ['affiliate', 'internal', 'owned', 'paid-influencer'];

const ReferralGenerator = () => {
  const { god } = useAuth();

  const [value, setValue] = useState('');
  const [algoliaId, setAlgoliaId] = useState(null);
  const [algoliaKey, setAlgoliaKey] = useState(null);
  const [results, setResults] = useState<any[]>([]);
  const debouncedVal = useDebounce(value);
  const [page, setPage] = useState(1);
  const [codes, setCodes] = useState<any[]>([]);
  const [doneFetching, setDoneFetching] = useState(false);

  useEffect(() => {
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/referrals?page=${page}&page_size=20`
      )
      .then(({ data }) => {
        if (data.length < 20) {
          setDoneFetching(true);
        }
        setCodes([...codes, ...data]);
      });
  }, [page]);

  useEffect(() => {
    const getAlgolia = () =>
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/config`)
        .then(({ data }) => {
          setAlgoliaId(data.algolia_app_id);
          setAlgoliaKey(data.algolia_search_key);
        });
    const interval = setInterval(getAlgolia, ONE_HOUR);
    getAlgolia();
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (algoliaId && algoliaKey && debouncedVal) {
      const algolia = algoliasearch(algoliaId, algoliaKey);
      const index = algolia.initIndex('User');
      index
        .search(debouncedVal, {
          length: 24,
          offset: 0,
        })
        .then(({ hits }) => {
          setResults(hits);
        })
        .catch(() => setResults([]));
    }
  }, [algoliaId, algoliaKey, debouncedVal]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userId, setUserId] = useState('');
  const [code, setCode] = useState('');
  const [channel, setChannel] = useState(CHANNELS[0]);
  const [feature, setFeature] = useState(FEATURES[0]);
  const [date, setDate] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const endpoint = `${process.env.REACT_APP_API_URL}/admin/v1/referrals/marketing`;
      const body = {
        user_id: userId || value || undefined,
        referral_code: code,
        feature,
        channel,
        date: format(new Date(date), 'yyyy-MM-dd'),
      };
      const { data } = await client.post(endpoint, body);
      setUserId('');
      setValue('');
      setDate('');
      setCode('');
      setCodes([data, ...codes]);
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnable = async (id) => {
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/admin/v1/referrals/${id}/enable`;
      const { data } = await client.post(endpoint);
      setCodes(codes.map((c) => (c.id === data.id ? data : c)));
    } catch (e) {
      // noop
    }
  };
  const handleDisable = async (id) => {
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/admin/v1/referrals/${id}/disable`;
      const { data } = await client.post(endpoint);
      setCodes(codes.map((c) => (c.id === data.id ? data : c)));
    } catch (e) {
      // noop
    }
  };

  if (Object.keys(god).length === 0) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <Layout className="" showHeaderElements userId={god.id}>
      <div className="mx-auto max-w-[100rem] px-4 overflow-hidden">
        <div className="grid sm:grid-cols-3 py-12 gap-8">
          <div className="w-full">
            <h2 className="text-white font-bold text-2xl mb-3">Create Code</h2>
            <div className="mb-4 relative">
              <p className="text-white font-bold mb-3">User ID</p>
              <input
                type="text"
                placeholder="Enter a user"
                className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
                onChange={(e) => setValue(e.target.value)}
                onFocus={() => setUserId('')}
                value={userId || value}
              />
              <div className="max-h-[200px] overflow-auto bg-black">
                {results.map((r) => (
                  <div
                    key={`user_${r.id}`}
                    className={classNames(
                      'flex items-center justify-between mb-4'
                    )}
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={r.profile_image_url}
                        alt={r.username}
                        height={48}
                        width={48}
                        className="object-cover rounded-full"
                        onError={(e) => {
                          // @ts-ignore
                          e.target.src =
                            'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                        }}
                      />
                      <p className="text-white font-extrabold">{r.username}</p>
                    </div>
                    <Button
                      text="Select"
                      variant="primary"
                      rounded
                      onClick={() => {
                        setResults([]);
                        setValue('');
                        setUserId(r.id);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <p className="text-white font-bold mb-3">Referral Code*</p>
              <input
                type="text"
                placeholder="Enter a code..."
                className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
                onChange={(e) => setCode(e.target.value)}
                value={code}
              />
            </div>
            <div className="mb-4">
              <p className="text-white font-bold mb-3">Channel*</p>
              <select
                value={channel}
                onChange={(e) => setChannel(e.target.value)}
                className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
              >
                {CHANNELS.map((c) => (
                  <option value={c} key={`channel_${c}`}>
                    {c}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <p className="text-white font-bold mb-3">Feature*</p>
              <select
                value={feature}
                onChange={(e) => setFeature(e.target.value)}
                className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
              >
                {FEATURES.map((f) => (
                  <option value={f} key={`feature_${f}`}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-8">
              <p className="text-white font-bold mb-3">Campaign Date*</p>
              <input
                type="date"
                className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
                placeholder="Click to enter the date"
                name="stakes_open_at"
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
            </div>
            {error && (
              <p className="text-center text-red-600 pb-4">
                There was an error creating your code
              </p>
            )}
            <Button
              onClick={handleSubmit}
              text={isLoading ? 'Loading...' : 'Create Code'}
              large
              rounded
              variant="primary"
              className="!w-full mb-8"
              disabled={!date || !feature || !channel || !code || isLoading}
            />
          </div>
          <div className="sm:col-span-2 overflow-auto">
            <h2 className="text-white font-bold text-2xl mb-3">All Codes</h2>
            <div className="mb-8">
              {codes.map((c) => (
                <Code
                  url={c.share_url}
                  key={c.share_url}
                  onDisable={() => handleDisable(c.id)}
                  onEnable={() => handleEnable(c.id)}
                  isEnabled={c.active}
                />
              ))}
            </div>
            {!doneFetching && codes.length > 0 && (
              <div className="flex items-center justify-center">
                <Button
                  onClick={() => setPage(page + 1)}
                  text="Load More"
                  large
                  rounded
                  variant="secondary"
                  className=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const Code = ({ url, onEnable, onDisable, isEnabled }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };
  return (
    <div>
      <div className="sm:flex items-stretch">
        <div className="py-3 flex-1 p-4 bg-grey-4 text-grey-2 rounded-l-lg whitespace-nowrap overflow-auto sm:mb-0 mb-2">
          {url}
        </div>
        {isEnabled ? (
          <button
            className="bg-grey-3 border-r-2 uppercase border-grey-4 disabled:opacity-50 hover:opacity-75 sm:rounded-l-none rounded-l-lg transition-opacity text-warning font-bold text-sm min-h-[24px]  w-28 overflow-hidden inline-flex items-center justify-center"
            type="button"
            onClick={onDisable}
          >
            Disable
          </button>
        ) : (
          <button
            className="bg-grey-3 uppercase border-r-2 border-grey-4 disabled:opacity-50 hover:opacity-75 sm:rounded-l-none rounded-l-lg transition-opacity text-success font-bold text-sm  min-h-[24px] w-28 overflow-hidden inline-flex items-center justify-center"
            type="button"
            onClick={onEnable}
          >
            Enable
          </button>
        )}
        <button
          className="bg-grey-3 uppercase disabled:opacity-50 hover:opacity-75 transition-opacity text-white font-bold text-sm rounded-r-lg w-28 overflow-hidden min-h-[24px] inline-flex items-center justify-center"
          type="button"
          disabled={copied}
          onClick={handleCopy}
        >
          COPY
        </button>
      </div>
      <p
        className={classNames('text-success transition-opacity text-xs pt-1', {
          'pointer-events-none opacity-0': !copied,
        })}
      >
        Link Copied!
      </p>
    </div>
  );
};

export default ReferralGenerator;
