import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/client';

const reportsContext = createContext({
  wallet: [],
});

const useReports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isMore, setIsMore] = useState(true);

  const getReports = async (userId) => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/blocks?page=${page}&page_size=7`
    );

    setReports(data);
    setLoading(false);
  };

  const paginateReports = async (userId) => {
    if (isMore) {
      setLoading(true);
      const { data } = await client.get(
        `${
          process.env.REACT_APP_API_URL
        }/admin/v1/users/${userId}/blocks?page=${page + 1}&page_size=7`
      );

      if (data.length === 0 || data.length < 7) {
        setIsMore(false);
      }

      setPage(page + 1);
      setReports([...reports, ...data]);
    }
    setLoading(false);
  };

  return {
    loading,
    reports,
    getReports,
    paginateReports,
  };
};

export function ReportsProvider({ children }) {
  const reports = useReports();

  return (
    <reportsContext.Provider value={reports}>
      {children}
    </reportsContext.Provider>
  );
}

export default function ReportsConsumer() {
  return React.useContext(reportsContext);
}

ReportsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
