const SOCIAL_PROOF = {
  counters: {
    u_column: 79,
    u_row: 33,
    u_content_image: 54,
    u_content_text: 24,
    u_content_heading: 26,
    u_content_button: 13,
    u_content_menu: 1,
    u_content_html: 1,
    u_content_divider: 2,
  },
  body: {
    id: '34nYduowRQ',
    rows: [
      {
        id: 'IWAqxLIsrY',
        cells: [1],
        columns: [
          {
            id: 'ifAWbXVslT',
            contents: [
              {
                id: 'eZcFMSuCDb',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/da53a24d-6a71-49a8-85c0-4cf85f2f38b1',
                    width: 2398,
                    height: 332,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'shvpketRLu',
        cells: [1],
        columns: [
          {
            id: '5S7ezG6Gyr',
            contents: [
              {
                id: '6ebtjzNZwP',
                type: 'image',
                values: {
                  containerPadding: '8px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/c93458ae-9932-4bfc-ba54-0ce76fb66365',
                    width: 499,
                    height: 366,
                    autoWidth: false,
                    maxWidth: '24%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_29',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: 'byW8_HjwHC',
                type: 'heading',
                values: {
                  containerPadding: '0px 0px 8px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '24px',
                  textAlign: 'center',
                  lineHeight: '130%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_12',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'STAKE &amp; WIN BIG',
                },
              },
              {
                id: 'HtAVCJRa6U',
                type: 'text',
                values: {
                  containerPadding: '0px 0px 16px',
                  anchor: '',
                  textAlign: 'center',
                  lineHeight: '170%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_8',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 170%;">Don’t miss your chance! <br />Join thousands making 💰💰💰 on 1v1Me</p>',
                },
              },
              {
                id: 'yX3z-Y6LaU',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1a36f25d-9ba0-4e22-815f-50e76f93dd14',
                    width: 1013,
                    height: 192,
                    autoWidth: false,
                    maxWidth: '50%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_31',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_37', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_18', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: '-k6mx56sOI',
        cells: [1],
        columns: [
          {
            id: 'sbMmRrVRkb',
            contents: [
              {
                id: 'MV1xGMeUSQ',
                type: 'heading',
                values: {
                  containerPadding: '0px 0px 8px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'center',
                  lineHeight: '130%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_14',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'TOP STAKERS',
                },
              },
              {
                id: 'FnKoNuACG3',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '16px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_11',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">People are making THOUSANDS on 1v1Me</p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_40', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_21', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'HBotTukqQ3',
        cells: [10, 11.54, 45.46, 33],
        columns: [
          {
            id: 'l4qHrxJknn',
            contents: [
              {
                id: 'fhXjDq6iA5',
                type: 'image',
                values: {
                  containerPadding: '14px 0px 12px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/30e78b8c-2372-4fee-b095-669b9e24cdd0',
                    width: 26,
                    height: 46,
                    autoWidth: false,
                    maxWidth: '11%',
                  },
                  textAlign: 'right',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_36',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '24px',
              _meta: { htmlID: 'u_column_43', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'Ann9VCpkXA',
            contents: [
              {
                id: '8JtkwnAnCf',
                type: 'image',
                values: {
                  containerPadding: '0px 0px 0px 4px',
                  anchor: '',
                  src: {
                    url: '<%= items[0][:icon] %>',
                    width: 800,
                    height: 200,
                    autoWidth: false,
                    maxWidth: '69%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_37',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_44', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'GCCP2V4V2b',
            contents: [
              {
                id: 'lGaVPkFWIN',
                type: 'heading',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_18',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<%= items[0][:username] %>',
                  _override: { mobile: { fontSize: '14px' } },
                },
              },
              {
                id: '00xfIu9UHW',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '12px',
                  color: '#979797',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_15',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><%= items[0][:subtitle] %></p>',
                  _override: { mobile: { fontSize: '10px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_45', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'VfR0vcsWWY',
            contents: [
              {
                id: 'wA5hwvXBkk',
                type: 'button',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  href: {
                    name: 'web',
                    values: { href: '', target: '_self' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  buttonColors: {
                    color: '#00e024',
                    backgroundColor: '#003312',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: { autoWidth: true, width: '100%' },
                  fontWeight: 700,
                  fontSize: '12px',
                  textAlign: 'center',
                  lineHeight: '100%',
                  padding: '12px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_4',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '$<%= items[0][:amount] %>',
                  calculatedWidth: 140,
                  calculatedHeight: 36,
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_46', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_24', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          borderTopLeftRadius: 24,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'W2Tp_RQstK',
        cells: [10, 11.54, 45.46, 33],
        columns: [
          {
            id: 'wLxay_aQaT',
            contents: [
              {
                id: 'YDHcej3IWO',
                type: 'image',
                values: {
                  containerPadding: '14px 0px 12px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/56fbc4cb-001e-4a6d-a9d6-32a41ac0a1d8',
                    width: 38,
                    height: 47,
                    autoWidth: false,
                    maxWidth: '15%',
                  },
                  textAlign: 'right',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_53',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '24px',
              _meta: { htmlID: 'u_column_76', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'UvP3MFdzSc',
            contents: [
              {
                id: 'jyylX5GPAI',
                type: 'image',
                values: {
                  containerPadding: '0px 0px 0px 4px',
                  anchor: '',
                  src: {
                    url: '<%= items[1][:icon] %>',
                    width: 800,
                    height: 200,
                    autoWidth: false,
                    maxWidth: '69%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_54',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_77', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'rDOTW_p6Y2',
            contents: [
              {
                id: 'oswwgvQQQn',
                type: 'heading',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_26',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<%= items[1][:username] %>',
                  _override: { mobile: { fontSize: '14px' } },
                },
              },
              {
                id: '_nGnttS0fw',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '12px',
                  color: '#979797',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_24',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><%= items[1][:subtitle] %></p>',
                  _override: { mobile: { fontSize: '10px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_78', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '2wb8DI9CO6',
            contents: [
              {
                id: 'N5QcNfI-iZ',
                type: 'button',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  href: {
                    name: 'web',
                    values: { href: '', target: '_self' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  buttonColors: {
                    color: '#00e024',
                    backgroundColor: '#003312',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: { autoWidth: true, width: '100%' },
                  fontWeight: 700,
                  fontSize: '12px',
                  textAlign: 'center',
                  lineHeight: '100%',
                  padding: '12px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_13',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '$<%= items[1][:amount] %>',
                  calculatedWidth: 140,
                  calculatedHeight: 36,
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_79', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_33', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          borderTopLeftRadius: 24,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'pfSZ3bNYHM',
        cells: [10, 11.54, 45.46, 33],
        columns: [
          {
            id: 'QxLWcCKhJN',
            contents: [
              {
                id: 'MVuoZ4H7nB',
                type: 'image',
                values: {
                  containerPadding: '14px 0px 12px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/25a3e448-bad0-4fae-8085-f49814358a66',
                    width: 30,
                    height: 36,
                    autoWidth: false,
                    maxWidth: '15%',
                  },
                  textAlign: 'right',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_51',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '24px',
              _meta: { htmlID: 'u_column_72', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'PFDU9tgJ-7',
            contents: [
              {
                id: 'W9ENSuSmmK',
                type: 'image',
                values: {
                  containerPadding: '0px 0px 0px 4px',
                  anchor: '',
                  src: {
                    url: '<%= items[2][:icon] %>',
                    width: 800,
                    height: 200,
                    autoWidth: false,
                    maxWidth: '69%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_52',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_73', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'oH85dZrp9v',
            contents: [
              {
                id: '74FtTlkE0S',
                type: 'heading',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_25',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<%= items[2][:username] %>',
                  _override: { mobile: { fontSize: '14px' } },
                },
              },
              {
                id: 'x2-jZhwmwa',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '12px',
                  color: '#979797',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_23',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><%= items[2][:subtitle] %></p>',
                  _override: { mobile: { fontSize: '10px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_74', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'pNUL39EW4y',
            contents: [
              {
                id: 'eBbIMbqYnc',
                type: 'button',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  href: {
                    name: 'web',
                    values: { href: '', target: '_self' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  buttonColors: {
                    color: '#00e024',
                    backgroundColor: '#003312',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: { autoWidth: true, width: '100%' },
                  fontWeight: 700,
                  fontSize: '12px',
                  textAlign: 'center',
                  lineHeight: '100%',
                  padding: '12px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_12',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '$<%= items[2][:amount] %>',
                  calculatedWidth: 140,
                  calculatedHeight: 36,
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_75', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_32', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          borderTopLeftRadius: 24,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'WD3ZwaeRII',
        cells: [10, 11.54, 45.46, 33],
        columns: [
          {
            id: 'gzw8RYq5Jl',
            contents: [
              {
                id: 'mGuaGwGLFi',
                type: 'image',
                values: {
                  containerPadding: '14px 0px 12px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/ffe02dc6-4de9-4b7a-9a69-9f4f7a0eaee8',
                    width: 42,
                    height: 46,
                    autoWidth: false,
                    maxWidth: '15%',
                  },
                  textAlign: 'right',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_49',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '24px',
              _meta: { htmlID: 'u_column_68', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'ecJm9gOw1b',
            contents: [
              {
                id: 'dw69BFCqr6',
                type: 'image',
                values: {
                  containerPadding: '0px 0px 0px 4px',
                  anchor: '',
                  src: {
                    url: '<%= items[3][:icon] %>',
                    width: 800,
                    height: 200,
                    autoWidth: false,
                    maxWidth: '69%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_50',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_69', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '6gafpH52U7',
            contents: [
              {
                id: 'cbyyrZho5o',
                type: 'heading',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_24',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<%= items[3][:username] %>',
                  _override: { mobile: { fontSize: '14px' } },
                },
              },
              {
                id: 'u370axVJ4K',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '12px',
                  color: '#979797',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_22',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><%= items[3][:subtitle] %></p>',
                  _override: { mobile: { fontSize: '10px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_70', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'iNwME4ShBx',
            contents: [
              {
                id: 'fTucLiPlTK',
                type: 'button',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  href: {
                    name: 'web',
                    values: { href: '', target: '_self' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  buttonColors: {
                    color: '#00e024',
                    backgroundColor: '#003312',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: { autoWidth: true, width: '100%' },
                  fontWeight: 700,
                  fontSize: '12px',
                  textAlign: 'center',
                  lineHeight: '100%',
                  padding: '12px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_11',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '$<%= items[3][:amount] %>',
                  calculatedWidth: 140,
                  calculatedHeight: 36,
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_71', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_31', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          borderTopLeftRadius: 24,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'sqp6pdXNvN',
        cells: [10, 11.54, 45.46, 33],
        columns: [
          {
            id: 'bX3hpKLcwq',
            contents: [
              {
                id: 'tpRo4JQg5m',
                type: 'image',
                values: {
                  containerPadding: '14px 0px 12px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/173515d2-3d19-4cb6-916f-41e681609f22',
                    width: 38,
                    height: 47,
                    autoWidth: false,
                    maxWidth: '15%',
                  },
                  textAlign: 'right',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_47',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '24px',
              _meta: { htmlID: 'u_column_64', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'hRMjC65Dm5',
            contents: [
              {
                id: 'hY2Xtk-5eA',
                type: 'image',
                values: {
                  containerPadding: '0px 0px 0px 4px',
                  anchor: '',
                  src: {
                    url: '<%= items[4][:icon] %>',
                    width: 800,
                    height: 200,
                    autoWidth: false,
                    maxWidth: '69%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_48',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_65', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '29kZWnD1Em',
            contents: [
              {
                id: 'djiJCP1VkB',
                type: 'heading',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_23',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<%= items[4][:username] %>',
                  _override: { mobile: { fontSize: '14px' } },
                },
              },
              {
                id: 'hEg093Tjq1',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '12px',
                  color: '#979797',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_21',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><%= items[4][:subtitle] %></p>',
                  _override: { mobile: { fontSize: '10px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_66', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'q_NmM0Ybgv',
            contents: [
              {
                id: '5fXDh6jUS-',
                type: 'button',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  href: {
                    name: 'web',
                    values: { href: '', target: '_self' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  buttonColors: {
                    color: '#00e024',
                    backgroundColor: '#003312',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: { autoWidth: true, width: '100%' },
                  fontWeight: 700,
                  fontSize: '12px',
                  textAlign: 'center',
                  lineHeight: '100%',
                  padding: '12px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_10',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '$<%= items[4][:amount] %>',
                  calculatedWidth: 140,
                  calculatedHeight: 36,
                  _override: { mobile: { fontSize: '12px' } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_67', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_30', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          borderTopLeftRadius: 24,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'GwhtTj5SwS',
        cells: [1],
        columns: [
          {
            id: 'fh4B9lN8xg',
            contents: [
              {
                id: '9ytCTXcvad',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_20',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px 12px 12px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_63', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_29', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: '7_RsmhcFwH',
        cells: [1],
        columns: [
          {
            id: 'xW1XiuLUhQ',
            contents: [
              {
                id: 'Qk7X-6WIEz',
                type: 'heading',
                values: {
                  containerPadding: '0px 0px 8px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'center',
                  lineHeight: '130%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_13',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'WHAT PEOPLE ARE SAYING',
                },
              },
              {
                id: '5A9qX8A1NS',
                type: 'text',
                values: {
                  containerPadding: '0px 0px 16px',
                  anchor: '',
                  fontSize: '16px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_10',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">4.7 ★ on the App Store</p>',
                },
              },
              {
                id: '6PWHbferwq',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/383b4e45-b655-419e-b85a-e9811c656d0b',
                    width: 1405,
                    height: 702,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_33',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: '_tRmFUAJFP',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/3880b94e-e2cd-41d6-9639-b3867e36d559',
                    width: 1405,
                    height: 590,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_34',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: 'nsqmw9BF0y',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/2720a808-587a-4704-9d3e-8681ac2a8974',
                    width: 1373,
                    height: 457,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_32',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_39', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '24px 0px 0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_20', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'gickF7ZcSb',
        cells: [1],
        columns: [
          {
            id: 'iUq5lt9GUz',
            contents: [
              {
                id: 'u52ygm-huZ',
                type: 'heading',
                values: {
                  containerPadding: '0px 0px 8px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'center',
                  lineHeight: '130%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_11',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'MILLIONS PAID OUT',
                },
              },
              {
                id: 'Gpwr4Wgv3J',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '16px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_9',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Don’t miss your chance to win big on 1v1Me</p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_38', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_19', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'ID0EZ8bhll',
        cells: [1],
        columns: [
          {
            id: 'msabc8V4el',
            contents: [
              {
                id: 'o02h110Hw2',
                type: 'html',
                values: {
                  html: '<div style="background-position: center; padding: 36px 0; width: 75%; margin: 0 auto; background-repeat: no-repeat; background-size: contain; background-image: url(\'https://mobile-assets.1v1me.com/uploads/9a0ac7c4-e820-4d8a-9d94-d75a6d1b00c2\');">\n  <p style="text-align: center; font-size: 14px; color: #979797; margin: 0; padding: 0; line-height: 8px">PAID OUT</p>\n  <h3 style="text-align: center; font-size: 24px; color: #FFC20E; margin: 0; padding: 0; line-height: 42px"><%= paid_out %></h3>\n</div>\n',
                  hideDesktop: false,
                  displayCondition: null,
                  containerPadding: '0px',
                  anchor: '',
                  _meta: {
                    htmlID: 'u_content_html_1',
                    htmlClassNames: 'u_content_html',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#ffc20e',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#ffc20e',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#ffc20e',
                borderBottomWidth: '0px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#ffc20e',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_42', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
            customPosition: ['50%', '50%'],
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_23', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { backgroundColor: '' } },
        },
      },
      {
        id: 'h8DTO6_fAR',
        cells: [1],
        columns: [
          {
            id: 'KbLyiLwBBk',
            contents: [
              {
                id: 'LMeM5CCuz7',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1a36f25d-9ba0-4e22-815f-50e76f93dd14',
                    width: 1013,
                    height: 192,
                    autoWidth: false,
                    maxWidth: '50%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_35',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_41', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_22', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'I-wX3TNkhp',
        cells: [1, 1],
        columns: [
          {
            id: 'IMpFDkPZS1',
            contents: [
              {
                id: 'SqSfH0my7v',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/dcc6bb49-3751-4612-b3d3-0320d3f5c96c',
                    width: 709,
                    height: 384,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_10',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_8', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'AYi8vDVDXY',
            contents: [
              {
                id: 'Ftk3G-q6ub',
                type: 'heading',
                values: {
                  containerPadding: '48px 0px 12px',
                  anchor: '',
                  headingType: 'h3',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_7',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'DOWNLOAD THE APP',
                },
              },
              {
                id: 'zu-GlKqmLL',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6f2a8e3f-9d12-43e2-a64d-46118a7d5f0e',
                    width: 400,
                    height: 131,
                    autoWidth: false,
                    maxWidth: '48%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/AxN12BiEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_11',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_8', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'z7NauV6I9m',
        cells: [1],
        columns: [
          {
            id: 'FbDEsm_j83',
            contents: [
              {
                id: 'BJbBsIqn1-',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6b939366-6cd7-4c64-b091-fadc021680b8',
                    width: 400,
                    height: 124,
                    autoWidth: false,
                    maxWidth: '19%',
                  },
                  textAlign: 'center',
                  altText: '1v1Me',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_23',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: { mobile: { hideMobile: false } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_35', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_17', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { desktop: { hideDesktop: false } },
        },
      },
      {
        id: 'oudVuuSmXB',
        cells: [25.76, 10, 10, 10, 10, 10, 24.24],
        columns: [
          {
            id: 'OhUm-JNmeN',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_29', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'QeAT1lN5yn',
            contents: [
              {
                id: 'MsGoiy4bew',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/d2c56c65-0fbf-475f-9572-90134f7bc268',
                    width: 96,
                    height: 96,
                    autoWidth: true,
                    maxWidth: '100%',
                  },
                  textAlign: 'center',
                  altText: 'tiktok',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/93FpFYkEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_24',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_30', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'lBsm5bHyM-',
            contents: [
              {
                id: 'ViNkcSi7sv',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1681b2d9-916f-4e3a-be71-2ced09bf968a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitter',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/V1PW9RqEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_25',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_31', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '8TzlLLrBwQ',
            contents: [
              {
                id: 'YQ0_d2ulfA',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/856f006d-6370-4054-9a55-e15192f4da7c',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Facebook',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/st8hFXsEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_26',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_32', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'E7-VwyL17N',
            contents: [
              {
                id: 'sOIA_u7IBD',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/33ae9ddb-f9c7-446b-b11b-7297a7738c48',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'instagram',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/BJ4BBSoEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_27',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_33', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'BKudhtcc4r',
            contents: [
              {
                id: 'nCNxFmMcqj',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/f862219c-bbf7-47ab-853f-a0e78e6bd24a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitch',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/wsKKtQmEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_28',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_34', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '4hD1dm-2wc',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_36', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_16', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: {
            mobile: {
              noStackMobile: true,
              backgroundColor: '#000000',
              hideMobile: false,
            },
            desktop: { hideDesktop: false },
          },
        },
      },
      {
        id: 'tYRgQpRhOY',
        cells: [1],
        columns: [
          {
            id: 'yPGkfQS7Pu',
            contents: [
              {
                id: 'HxwSHKZ1Nh',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_7',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Don’t want these emails? <a rel="noopener" href="<%= unsubscribe %>" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6IjwlPSB1bnN1YnNjcmliZSAlPiIsInRhcmdldCI6Il9ibGFuayJ9fQ=="><span style="line-height: 19.6px;">Unsubscribe</span></a></p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_20', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_11', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: 'center',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      textColor: '#ffffff',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: '#000000',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      preheaderText: 'STAKE & WIN BIG',
      linkStyle: {
        body: true,
        linkColor: '#ffc20e',
        linkHoverColor: '#0000ee',
        linkUnderline: false,
        linkHoverUnderline: true,
        inherit: false,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 13,
};

export default SOCIAL_PROOF;
