import React, { FC } from 'react';
import classNames from 'classnames';
import Button from './Buttons/Button/Button';

const PlayerCard: FC<{
  avatar: string;
  username: string;
  isLeader?: boolean;
  promoteDisabled?: boolean;
  isDropped?: boolean;
  onPromote?: () => void;
  onDrop?: () => void;
  isLoading?: boolean;
}> = ({
  avatar,
  username,
  isLeader,
  promoteDisabled,
  isDropped,
  onPromote,
  onDrop,
  isLoading,
}) => (
  <div
    key={`user_${username}`}
    className="flex items-center justify-between mb-4"
  >
    <div className="flex items-center gap-2">
      <img
        src={avatar}
        alt={username}
        height={36}
        width={36}
        className="object-cover rounded-full"
        onError={(e) => {
          // @ts-ignore
          e.target.src =
            'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
        }}
      />
      <p className="text-white font-extrabold leading-snug">{username}</p>
    </div>
    <div className="flex items-center gap-4">
      {onPromote && (
        <Button
          variant="grey"
          text="Promote To Lineup"
          onClick={onPromote}
          disabled={promoteDisabled || isLoading}
          className="!px-3 !py-2 !w-full"
          isLoading={isLoading}
        />
      )}
      {onDrop && (
        <Button
          variant={isDropped ? 'grey' : 'warning'}
          text={isDropped ? 'Dropped Next Week' : 'Drop Player'}
          rounded
          disabled={isDropped || isLoading}
          onClick={onDrop}
          isLoading={isLoading}
          className={
            isDropped
              ? '!px-3 !py-2 !w-full !text-warning !bg-grey-3'
              : '!px-3 !py-2 !w-full'
          }
        />
      )}
    </div>
  </div>
);

export default PlayerCard;
