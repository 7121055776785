/* eslint-disable */

import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import algoliasearch from 'algoliasearch';
import AvatarGroup from '../../components/AvatarGroup.tsx';
import client from '../../services/client';
import ConversationChat from './Chat.tsx';
import Button from '../../components/Buttons/Button/Button';
import useDebounce from '../../utils/useDebounce';
import { Conversation } from '../../types';
import SearchIcon from '../../assets/Icons/search-icon.svg';
import { ONE_HOUR } from '../../utils/time';

interface Props {
  userId: number;
}

const InboxLayout: FC<Props> = (props) => {
  const userId = Number(props.userId);
  const [fetchedAllConvos, setFetchedAllConvos] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [convos, setConvos] = useState<Conversation[]>([]); // { page: 1, }
  const [pinnedConvos, setPinnedConvos] = useState<Conversation[]>([]); // { page: 1, }

  const [selectedChat, setSeletedChat] = useState<Conversation | null>(null);
  const [algoliaId, setAlgoliaId] = useState(null);
  const [algoliaKey, setAlgoliaKey] = useState(null);
  const [results, setResults] = useState<any[]>([]);
  const [value, setValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<null | any>(null);

  const debouncedVal = useDebounce(value);

  useEffect(() => {
    const getAlgolia = () =>
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/config`)
        .then(({ data }) => {
          console.log(data);
          setAlgoliaId(data.algolia_app_id);
          setAlgoliaKey(data.algolia_search_key);
        });
    const interval = setInterval(getAlgolia, ONE_HOUR);
    getAlgolia();
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (algoliaId && algoliaKey && debouncedVal.length > 2) {
      const algolia = algoliasearch(algoliaId, algoliaKey);
      const index = algolia.initIndex('User');
      index
        .search(debouncedVal, {
          length: 10,
          offset: 0,
        })
        .then(({ hits }) => {
          setResults(hits);
        })
        .catch(() => setResults([]));
    } else {
      setResults([]);
    }
  }, [algoliaId, algoliaKey, debouncedVal]);

  useEffect(() => {
    setPage(1);
  }, [userId, selectedUser]);
  console.log(page);
  useEffect(() => {
    if (selectedUser) {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/conversations/search?main_user=${userId}&search_user=${selectedUser.id}&page=${page}&page_size=20`
        )
        .then(({ data }) => {
          if (data.length < 20) {
            setFetchedAllConvos(true);
          }
          setConvos((c) => (page === 1 ? data : [...c, ...data]));
        });
    }
  }, [userId, page, selectedUser]);

  useEffect(() => {
    if (!selectedUser) {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/conversations?user_id=${userId}&page=${page}&page_size=10&pinned=false`
        )
        .then(({ data }) => {
          if (data.length < 10) {
            setFetchedAllConvos(true);
          }
          setConvos((c) => (page === 1 ? data : [...c, ...data]));
        });
    }
  }, [userId, page, selectedUser]);

  useEffect(() => {
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/conversations?user_id=${userId}&page=1&page_size=20&pinned=true`
      )
      .then(({ data }) => setPinnedConvos(data));
  }, [userId]);

  return (
    <div className="h-[calc(90vh-1.5rem)] flex flex-col">
      <div className="border-b border-grey-4 pt-8 py-6 flex items-center justify-between pr-16">
        <div className="sm:w-[325px] 2xl:w-[460px] px-4 flex items-center justify-end"></div>
        {selectedChat && (
          <div className="flex-1 px-4 flex items-center justify-between gap-4">
            <div
              className={classNames('flex items-center justify-center gap-4', {
                'cursor-pointer': selectedChat.type === 'direct',
              })}
            >
              <Avatars convo={selectedChat} userId={userId} small />
              <h3 className="font-bold text-white">
                {selectedChat?.title}{' '}
                {(selectedChat?.is_verified ||
                  (selectedChat?.type !== 'room' &&
                    selectedChat?.preview_participants[0].user_profile
                      .is_verified)) && (
                  <img
                    src="https://mobile-assets.1v1me.com/users/v1/verified-icon.svg"
                    height={12}
                    width={12}
                    alt="Verified"
                    className="inline-block"
                  />
                )}
              </h3>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-stretch flex-1 overflow-hidden">
        <div
          ref={listRef}
          className="sm:w-[325px] 2xl:w-[460px] px-4 pb-4 border-r border-grey-4 overflow-y-auto relative"
        >
          <h1 className="font-bold text-white text-3xl -mx-4 p-4 sticky top-0 bg-black z-10">
            Inbox
          </h1>
          <div className="relative">
            <label className="block relative pb-8" htmlFor="#search">
              <div className="absolute top-0 left-0 ml-4 pt-4">
                <img
                  src={SearchIcon}
                  height={16}
                  width={16}
                  className="object-contain"
                  alt="search"
                />
              </div>
              <input
                className="flex-1 py-3 pr-6 pl-12 bg-grey-4 rounded-full w-full block !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue text-white mt-1"
                placeholder="Search for players"
                id="search"
                value={selectedUser?.username || value}
                onClick={() => {
                  setSelectedUser(null);
                }}
                onChange={(e) => setValue(e.target.value)}
              />
            </label>
            {results.length > 0 && (
              <div className="absolute top-full -mt-6 bg-black border border-grey-4 p-4 rounded z-50 w-full h-[240px] overflow-auto">
                {results.map((r) => (
                  <div
                    key={`user_${r.id}`}
                    className={classNames(
                      'flex items-center justify-between mb-4'
                    )}
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={r.profile_image_url}
                        alt={r.username}
                        height={48}
                        width={48}
                        className="object-cover rounded-full"
                        onError={(e) => {
                          // @ts-ignore
                          e.target.src =
                            'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                        }}
                      />
                      <p className="text-white font-extrabold">{r.username}</p>
                    </div>
                    <Button
                      text="Select"
                      variant="primary"
                      rounded
                      onClick={() => {
                        setSelectedUser(r);
                        setValue('');
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          {pinnedConvos.length > 0 && !selectedUser && (
            <div
              className={classNames(
                'flex items-stretch justify-start flex-wrap mb-4',
                { '!justify-center': pinnedConvos.length % 3 === 0 }
              )}
            >
              {pinnedConvos.slice(0, 6).map((convo) => (
                <button
                  onClick={() => setSeletedChat(convo)}
                  key={`convo_${convo.id}`}
                  className="mb-1 overflow-hidden rounded-md px-2 py-2 w-1/3 flex items-center justify-start flex-col hover:bg-grey-4 transition-colors !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                >
                  {convo.image_url && (
                    <div className="relative">
                      <img
                        src={
                          convo.image_url ||
                          convo.preview_participants[0]?.profile_image_url
                        }
                        height={96}
                        width={96}
                        className="rounded-full"
                        alt="Convo"
                      />
                      {!convo.is_read && (
                        <p className="absolute bottom-1 left-0 w-full flex items-center justify-center">
                          <span className="inline-block text-[10px] px-2 py-[2px] rounded-full bg-grey-4 text-blue text-center whitespace-nowrap">
                            New Messages
                          </span>
                        </p>
                      )}
                    </div>
                  )}
                  <p className="text-grey-2 pt-1 text-xs text-center">
                    {convo.title}
                  </p>
                </button>
              ))}
            </div>
          )}

          <div className="-mx-4">
            {pinnedConvos.slice(6).map((c) => (
              <ConvoCard
                convo={c}
                userId={userId}
                onClick={setSeletedChat}
                key={`convo_${c.id}`}
              />
            ))}
            {convos.map((c) => (
              <ConvoCard
                convo={c}
                userId={userId}
                key={`convo_${c.id}`}
                onClick={setSeletedChat}
                isActive={selectedChat?.id === c.id}
              />
            ))}
            {/* <InfiniteScroll
              stopWatching={fetchedAllConvos}
              listRef={listRef}
              renderItem={(page) => (
                <ConvoList
                  key={`convo_page_${page}`}
                  page={page}
                  userId={userId}
                  selectedId={selectedChat}
                  onEndOfResults={() => setFetchedAllConvos(true)}
                  pollingInterval={10000}
                />
              )}
            /> */}
            {!fetchedAllConvos && (
              <div className="flex items-center justify-center pt-4">
                <Button
                  variant="tertiary"
                  text="Load More"
                  onClick={() => setPage(page + 1)}
                />
              </div>
            )}
          </div>
        </div>
        {selectedChat && (
          <div className="flex-1 overflow-x-hidden">
            {selectedChat && (
              <ConversationChat
                convo={selectedChat}
                typingUsers={[]}
                userId={userId}
              />
            )}
          </div>
        )}
        {!selectedChat && (
          <p className="flex-1 p-4 flex items-center justify-center h-full text-grey-2">
            No Conversation Selected
          </p>
        )}
      </div>
    </div>
  );
};

export const Avatars: FC<{
  convo: any;
  userId: number;
  small?: boolean;
  large?: boolean;
}> = ({ convo, userId, small, large }) => {
  if (convo.image_url) {
    return (
      <img
        src={convo.image_url}
        height={small ? 28 : large ? 80 : 40}
        width={small ? 28 : large ? 80 : 40}
        className="rounded-full"
        alt={convo.title}
        onError={(e) => {
          // @ts-ignore
          e.target.src =
            'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
        }}
      />
    );
  }
  const participants = convo.preview_participants.filter(
    (p) => p.user_id !== userId
  );

  if (participants.length === 1) {
    return (
      <div className="relative">
        <img
          src={participants[0].profile_image_url}
          height={small ? 28 : large ? 80 : 40}
          width={small ? 28 : large ? 80 : 40}
          className="rounded-full object-cover"
          alt={participants[0].username}
          onError={(e) => {
            // @ts-ignore
            e.target.src =
              'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
          }}
        />
        {participants[0].user_profile.activity_status === 'Online' && (
          <div className="bg-success h-3 w-3 absolute bottom-1 right-0 rounded-full full border border-black" />
        )}
      </div>
    );
  }
  return (
    <AvatarGroup
      users={participants.map((p) => p.user_profile)}
      limit={2}
      large={large}
    />
  );
};

const ConvoCard: FC<{
  convo: any;
  userId: number;
  onClick: (convo: any) => void;
  isActive?: boolean;
}> = ({ convo, userId, isActive, onClick }) => {
  return (
    <button
      onClick={() => onClick(convo)}
      key={`convo_${convo.id}`}
      className={classNames(
        'flex w-full overflow-hidden text-left items-center py-4 px-8 gap-4 hover:bg-grey-4 transition-colors !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue relative',
        {
          'pointer-events-none bg-grey-4': isActive,
        }
      )}
    >
      {!convo.is_read && (
        <div className="absolute top-0 left-0 ml-3 h-full flex items-center justify-center">
          <div className="h-2 w-2 bg-blue rounded-full"></div>
        </div>
      )}
      <div className="flex flex-nowrap items-center justify-end relative">
        <Avatars convo={convo} userId={userId} />
      </div>
      <div className="flex-1 overflow-hidden">
        <p className="truncate text-white font-bold">
          {convo.title}{' '}
          {convo.is_verified ||
            (convo.type !== 'room' &&
              convo.preview_participants[0].user_profile.is_verified && (
                <img
                  src="https://mobile-assets.1v1me.com/users/v1/verified-icon.svg"
                  height={12}
                  width={12}
                  className="inline-block"
                  alt="Verified"
                />
              ))}
        </p>
        <p className="truncate text-xs text-grey-2">
          {convo.preview_message?.username && (
            <b>{convo.preview_message?.username}:</b>
          )}{' '}
          {convo.preview_message?.text || 'No Message'}
        </p>
      </div>
    </button>
  );
};

export default InboxLayout;
