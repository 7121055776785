/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { useMemo } from 'react';

const ScoreCard = ({ event, scores, small, host }) => {
  const isInReview = useMemo(
    () =>
      !event.completed &&
      event.teams.every((t) => typeof t.placement === 'number'),
    [event.completed, event.teams]
  );
  // eslint-disable-next-line consistent-return
  const tag = useMemo(() => {
    if (isInReview) {
      return (
        <span className="uppercase text-center bg-gold text-black font-bold whitespace-nowrap text-[10px] sm:text-sm px-2 rounded">
          In Review
        </span>
      );
    }
    if (!event.completed && event.match_event_match_id) {
      return (
        <span className="uppercase text-center bg-warning text-white font-bold whitespace-nowrap text-[10px] sm:text-sm px-2 rounded">
          Live
        </span>
      );
    }

    if (event.completed) {
      return (
        <span className="uppercase text-center bg-grey-3 text-white font-bold whitespace-nowrap text-[10px] sm:text-sm px-2 rounded">
          Final
        </span>
      );
    }
    return (
      <span className="uppercase text-center bg-grey-3 text-white font-bold whitespace-nowrap text-[10px] sm:text-sm px-2 rounded">
        Waiting
      </span>
    );
  }, [isInReview, event.completed]);

  const team1Won = useMemo(
    () => event.completed && scores.team1Wins > scores.team2Wins,
    [event.completed, scores.team1Wins, scores.team2Wins]
  );
  const team2Won = useMemo(
    () => event.completed && scores.team1Wins < scores.team2Wins,
    [event.completed, scores.team1Wins, scores.team2Wins]
  );

  return event.teams.length > 1 ? (
    <div
      className={classNames(
        'fade-in-fast rounded-2xl overflow-hidden transition-all',
        {
          'bg-grey-4': !small,
        }
      )}
    >
      {!small && (
        <div
          className={classNames('border-l-[16px] flex gap-4')}
          style={{ borderColor: event.play_info.game_mode_color }}
        >
          <div className="flex flex-1 shrink-0 gap-2  items-start p-4 pr-0 border-b border-grey-3">
            <div className="w-full">
              <h3 className="uppercase text-grey-2 text-xs font-bold flex items-end gap-1 ">
                <img
                  src={event.play_info.game_icon}
                  alt="player"
                  className="rounded-full mt-1 w-[16px] h-[16px] object-cover"
                />
                {event.play_info.game_name}{' '}
                <span className="font-extrabold text-white">
                  {event.title || event.play_info.game_mode_title}
                </span>
              </h3>
              {host && (
                <p className="text-xs text-grey-2 pt-1 leading-none">
                  Host: <b className="text-white">{host}</b>
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'flex items-start gap-2 pt-4 pb-4 px-4 justify-between',
          {
            '!gap-4 !py-0': small,
          }
        )}
      >
        <Team
          eventId={event.id}
          team={event.teams[0]}
          isExpanded={!small}
          small={small}
        />
        <div className="sm:flex-1 flex flex-col items-center justify-center">
          {!small && (
            <p className="text-center uppercase text-xs  mb-1">
              {event.play_info.match_format_description}
            </p>
          )}
          <p
            className={classNames(
              'text-center flex items-center justify-center gap-1 sm:gap-2 text-2xl sm:text-3xl mb-2',
              {
                'sm:!text-2xl !text-xl':
                  scores.team1Scores[0]?.score_display.length > 3 ||
                  scores.team2Scores[0]?.score_display.length > 3,
              }
            )}
          >
            {event.play_info.num_of_rounds === 1 ? (
              <>
                <span
                  className={`${
                    team1Won || !event.completed
                      ? 'text-white/90'
                      : 'text-grey-2'
                  } font-extrabold`}
                >
                  {String(scores.team1Scores[0]?.score_display || '-')}
                </span>{' '}
                -{' '}
                <span
                  className={`${
                    team2Won || !event.completed
                      ? 'text-white/90'
                      : 'text-grey-2'
                  } font-extrabold`}
                >
                  {String(scores.team2Scores[0]?.score_display || '-')}
                </span>
              </>
            ) : (
              <>
                <span
                  className={`${
                    team1Won || !event.completed
                      ? 'text-white/90'
                      : 'text-grey-2'
                  } font-extrabold`}
                >
                  {String(scores.team1Wins)}
                </span>{' '}
                -{' '}
                <span
                  className={`${
                    team2Won || !event.completed
                      ? 'text-white/90'
                      : 'text-grey-2'
                  } font-extrabold`}
                >
                  {String(scores.team2Wins)}
                </span>
              </>
            )}
          </p>
          {tag}
        </div>
        <Team
          eventId={event.id}
          team={event.teams[1]}
          isExpanded={!small}
          small={small}
          end
        />
      </div>
      {event.play_info.num_of_rounds !== 1 && (
        <div>
          <div className="px-4 pb-4 flex flex-col gap-2">
            <Scores
              team={event.teams[0]}
              rounds={event.play_info.num_of_rounds}
              scores={scores.team1Scores}
            />
            <Scores
              team={event.teams[1]}
              rounds={event.play_info.num_of_rounds}
              scores={scores.team2Scores}
            />
          </div>
        </div>
      )}
    </div>
  ) : null;
};

const Team = ({ team, small, end, isExpanded, eventId }) => (
  <a
    target="_blank"
    href={`https://1v1me.com/teams/${team.roster.id}?eventId=${eventId}`}
    className={classNames(
      'flex flex-col items-center flex-1 h-full p-1 gap-2 sm:gap-0 overflow-hidden',
      {
        '!flex-row': small && end,
        '!flex-row-reverse': small && !end,
        '!justify-start': small,
        '!gap-2 !pointer-events-none': !isExpanded,
        'hover:opacity-60 transition-opacity': isExpanded,
      }
    )}
    rel="noreferrer"
  >
    <img
      src={
        team.roster.players.find(
          (p) => p.type === 'leader' || p.type === 'TournamentPlayer::Leader'
        )?.user_profile.profile_image_url ||
        team.roster.players[0].user_profile.profile_image_url
      }
      fallbackSrc="https://mobile-assets.1v1me.com/users/v1/default_profile.png"
      alt="player"
      className={classNames(
        'w-[48px] h-[48px] mb-2 object-cover rounded-full',
        {
          '!w-[24px] !h-[24px] !mb-0': small,
        }
      )}
    />
    <h3
      className={classNames(
        'font-bold text-white text-center text-xs sm:text-base !leading-tight h-7 flex flex-col justify-center',
        {
          '!text-sm':
            team.roster.team_name.length > 5 &&
            team.roster.team_name.length <= 9 &&
            !small,
          '!text-xs':
            team.roster.team_name.length > 9 &&
            team.roster.team_name.length <= 13 &&
            !small,
          '!text-[10px]':
            team.roster.team_name.length > 13 &&
            team.roster.team_name.length <= 16 &&
            !small,
          '!text-[8px]': team.roster.team_name.length > 16 && !small,
          '!text-base': small,
        }
      )}
    >
      {small ? team.roster.code : team.roster.team_name}
    </h3>
    {isExpanded && (
      <div className="flex items-center gap-1 max-w-full">
        {end && team.jersey_color && <Jersey color={team.jersey_color} />}
        {team.tag && (
          <span
            style={{
              backgroundColor: team.tag.background_color,
              color: team.tag.text_color,
            }}
            className="text-[10px] bg-grey-3 flex-1 font-bold px-3 py-[2px] rounded-full mt-2 inline-flex items-center gap-1 overflow-x-hidden uppercase"
          >
            {team.tag.image_url && (
              <img
                src={team.tag.image_url}
                alt={team.tag.title}
                className="w-[16px] h-[16px] object-contain"
              />
            )}
            <span className="truncate">{team.tag.title}</span>
          </span>
        )}
        {!end && team.jersey_color && <Jersey color={team.jersey_color} />}
      </div>
    )}
  </a>
);

export const Jersey = ({ color }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mt-2"
  >
    <path
      d="M12.3357 1.57802L15.7168 5.87337L12.5788 7.52817L12.4455 7.59847L12.4454 7.74917L12.4424 13.0626H3.47643L3.47344 7.70234L3.47336 7.55147L3.33984 7.48123L0.283281 5.87332L3.66159 1.58145C3.66756 1.57632 3.6758 1.56898 3.68432 1.56047L3.69784 1.54695L3.70914 1.53153C3.96815 1.17833 4.42495 0.9375 4.95612 0.9375H5.72032C6.00319 1.93099 6.917 2.65768 8 2.65768C9.083 2.65768 9.99681 1.93099 10.2797 0.9375H11.0189C11.5289 0.9375 11.9432 1.15713 12.1898 1.48425L12.2481 1.5616L12.3357 1.57802Z"
      fill={color}
      stroke="#979797"
      strokeWidth="0.5"
    />
  </svg>
);

const Scores = ({ rounds, team, scores }) => (
  <div className="flex items-center justify-between gap-4">
    <div className="flex items-center gap-2">
      <img
        src={
          team.roster.players.find(
            (p) => p.type === 'leader' || p.type === 'TournamentPlayer::Leader'
          )?.user_profile.profile_image_url ||
          team.roster.players[0].user_profile.profile_image_url
        }
        alt="player"
        className={classNames(
          'w-[16px] sm:w-[20px] h-[16px] sm:h-[20px] object-cover rounded-full'
        )}
      />
      <h3 className="text-white text-sm uppercase">{team.roster.code}</h3>
    </div>
    <div
      className="flex-nowrap gap-1 grid"
      style={{
        gridTemplateColumns: `repeat(${rounds}, minmax(0, 26px))`,
      }}
    >
      {Array.from(Array(rounds).keys()).map((_, i) => {
        const score = scores.find((s) => s.round_number === i + 1);
        return (
          <span
            key={`has_won_${i}`}
            className={classNames(
              'font-bold text-white text-xs sm:text-sm text-center flex-grow inline-block whitespace-nowrap',
              {
                '!text-gold': score?.won && score?.completed,
                '!text-grey-2': !score?.won && score?.completed,
              }
            )}
          >
            {score ? score.score_display : '-'}
          </span>
        );
      })}
    </div>
  </div>
);

export default ScoreCard;
