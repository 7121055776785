/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import client from '../../services/client';
import AvailableIcon from '../../assets/Icons/available.svg';
import UnavailableIcon from '../../assets/Icons/unavailable.svg';
import { getDates } from './util';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Buttons/Button/Button';
import BaseModal from '../../components/BaseModal/BaseModal';
import WithCents from '../../components/CurrencyInput/WithCents.tsx';
import Search from './Search';

const PartnerAvailability = () => {
  const dates = useMemo(getDates, []);
  const navigate = useNavigate();

  const [input, setInput] = useState('');
  const [selectedDate, setSelectedDate] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avail, setAvail] = useState([]);
  const [addPartnerOpen, setAddPartnerOpen] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    setLoading(true);
    client
      .get(`${process.env.REACT_APP_API_URL}/admin/v1/partners`)
      .catch(() => {})
      .then(({ data }) => setPartners(data));
  }, []);

  useEffect(() => {
    setLoading(true);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/availabilities?week_offset=${selectedDate}`
      )
      .catch(() => setError(true))
      .then(({ data }) => setAvail(data))
      .finally(() => setLoading(false));
  }, [selectedDate]);

  const handleAdd = ({ id, code }) => {
    const data = {
      user_id: id,
      active: true,
    };
    if (code) {
      data.code = code;
    }
    const ok = confirm('Are you sure?');
    if (ok) {
      client
        .post(`${process.env.REACT_APP_API_URL}/admin/v1/partners`, data)
        .then((res) => {
          navigate(`/godmode/partners/${res.data.id}/settings`);
        })
        .catch((e) => {
          alert(e.response?.data?.message || 'Something went wrong');
          setError(true);
        });
    }
  };
  const available = useMemo(() => avail.filter((p) => p.available), [avail]);
  const unavailable = useMemo(() => avail.filter((p) => !p.available), [avail]);

  return (
    <div className="flex gap-8 border-t border-grey-4">
      <div className="flex-1 border-r border-grey-4 h-[calc(100vh-80px)] overflow-y-auto">
        <div className="p-4 max-w-7xl mx-auto">
          <h2 className="font-extrabold text-white text-xl sm:text-3xl flex-1 flex items-center justify-between gap-4 mb-8">
            Partner Availability
            <select
              aria-label="Date"
              className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 min-w-[300px] rounded-full"
              value={selectedDate}
              onChange={(e) => setSelectedDate(Number(e.target.value))}
            >
              {Object.keys(dates)
                .sort((a, b) => a - b)
                .map((d) => (
                  <option key={`date_${d}`} value={d}>
                    {dates[d]}
                  </option>
                ))}
            </select>
          </h2>
          {loading ? (
            <Loader />
          ) : error ? (
            <p className="text-center py-12 px-4 text-warning">
              Something went wrong
            </p>
          ) : (
            <>
              {available.length >= 1 && (
                <>
                  <h3 className="font-extrabold text-white text-lg sm:text-2xl mb-4">
                    {available.length} Partners Available
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 mb-8 gap-4">
                    {available.map((p) => (
                      <Card
                        partner={p}
                        key={`partner_${p.partner_id}`}
                        selectedDate={selectedDate}
                      />
                    ))}
                  </div>
                </>
              )}
              {unavailable.length >= 1 && (
                <>
                  <h3 className="font-extrabold text-white text-lg sm:text-2xl mb-4">
                    {unavailable.length} Partners Haven’t Filed
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 mb-8 gap-4">
                    {unavailable.map((p) => (
                      <Card
                        partner={p}
                        key={`partner_${p.partner_id}`}
                        selectedDate={selectedDate}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="w-[300px] flex flex-col gap-4 py-4  justify-between h-[calc(100vh-80px)] overflow-auto">
        <div>
          <div className="mb-4">
            <h2 className="font-extrabold text-white text-xl mb-2">
              All Partners
            </h2>
            <input
              type="text"
              className="py-2 w-full text-white bg-grey-4 focus:outline-none px-4 rounded-full"
              name="search"
              placeholder="Search partners..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <div className="flex-1 overflow-auto h-[calc(100vh-280px)]">
            {partners
              .filter((p) =>
                p.user_profile.username
                  .toLowerCase()
                  .includes(input.toLowerCase())
              )
              .map((partner) => (
                <Link
                  to={`/godmode/partners/${partner.id}/settings`}
                  className="flex items-center gap-3 transition-colors hover:bg-grey-4 rounded-2xl text-white py-4"
                >
                  <img
                    src={partner.user_profile.profile_image_url}
                    className="rounded-full h-[32px] w-[32px] object-cover"
                    alt={partner.user_profile.username}
                  />
                  <span className="flex-1">
                    {partner.user_profile.username}
                  </span>
                </Link>
              ))}
          </div>
        </div>
        <div className="">
          <Button
            variant="primary"
            text="Add Partner"
            large
            rounded
            className="!w-full"
            onClick={() => setAddPartnerOpen(true)}
          />
        </div>
      </div>
      <CreateModal
        isOpen={addPartnerOpen}
        onClose={() => setAddPartnerOpen(false)}
        onSave={handleAdd}
        filteredIds={partners.map((p) => p.user_profile.id && p.active)}
      />
    </div>
  );
};

const CreateModal = ({ isOpen, onClose, onSave, filteredIds, teamCode }) => {
  const [code, setCode] = useState(teamCode || '');
  const [id, setId] = useState(null);
  return (
    <BaseModal
      header="Manage Partner"
      modalIsOpen={isOpen}
      closeModal={onClose}
      paginateData={() => {}}
    >
      <div className="pt-4">
        <Search onClick={setId} filteredIds={filteredIds} selectedId={id} />
        <br />
        {/* <label className="mb-4 block">
          <span className="text-white text-xs font-bold block mb-2">
            Rev Share Percentage (0 - 100)
          </span>
          <input
            type="number"
            min={0}
            max={100}
            className="py-2 flex-1 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full "
            name="percentage"
            placeholder="Percentage"
            value={revPercentage}
            onChange={(e) => setRevPercentage(e.target.value)}
          />
        </label>
        <label className="mb-4 block">
          <span className="text-white text-xs font-bold block mb-2">
            Rev Share Minimum
          </span>
          <WithCents onValueChange={setRevMin} value={revMin} simple />
        </label> */}
        <label className="mb-12 block">
          <span className="text-white text-xs font-bold block mb-2">
            Team Code
          </span>
          <input
            type="text"
            className="py-2 flex-1 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full "
            name="code"
            placeholder="CODE"
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase().slice(0, 3))}
          />
        </label>
      </div>
      <div className="sticky -bottom-4 bg-black/85 backdrop-blur-lg left-0">
        <Button
          variant="primary"
          rounded
          large
          text="Save"
          className="!w-full"
          disabled={!id}
          onClick={() =>
            onSave({
              id,
              code,
            })
          }
        />
      </div>
    </BaseModal>
  );
};

export const Card = ({ partner, selectedDate, isActive, dark }) => (
  <Link
    to={`/godmode/partners/${partner.partner_id}/availability?dateOffset=${selectedDate}`}
    className={classNames(
      'flex items-center gap-3 hover:opacity-75 transition-opacity bg-grey-4 rounded-2xl text-white font-extrabold p-4 fade-in-fast',
      {
        '!bg-grey-4 !pointer-events-none': isActive,
        '!bg-black': dark && !isActive,
      }
    )}
  >
    <img
      src={partner.user_profile.profile_image_url}
      className="rounded-full h-[32px] w-[32px] object-cover"
      alt={partner.user_profile.username}
    />
    <span className="flex-1">{partner.user_profile.username}</span>
    <img
      src={partner.available ? AvailableIcon : UnavailableIcon}
      alt={partner.available ? 'available' : 'Not available'}
      className="h-[24px] w-[24px] object-contain"
    />
  </Link>
);

export default PartnerAvailability;
