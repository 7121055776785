/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch';
import { useNavigate, useParams } from 'react-router-dom';

import classNames from 'classnames';
import { format, isToday, isTomorrow } from 'date-fns';
import { Popover, Switch, Transition } from '@headlessui/react';
import { SwatchesPicker } from 'react-color';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import SearchIcon from '../../assets/Icons/search-icon.svg';
import ArrowRight from '../../assets/Icons/arrow-right.svg';

import useAuth from '../../contexts/AuthContext/AuthContext';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext';
import Button from '../../components/Buttons/Button/Button';
import BaseModal from '../../components/BaseModal/BaseModal';
import TabGroup from '../../components/TabGroup.tsx';
import client from '../../services/client';
import useDebounce from '../../utils/useDebounce';
import AvatarGroup from '../../components/AvatarGroup.tsx';
import HoldButton from '../../components/HoldButton.tsx';
import Meta from '../Tournaments/Steps/Meta.tsx';
import Dates from '../Tournaments/Steps/Dates';
import StakeableFields from '../Tournaments/Steps/StakeableFields.tsx';
import ModeConfirm from '../Tournaments/Steps/ModeConfirm.tsx';
import ModeSelect from '../Tournaments/Steps/ModeSelect.tsx';
import ConsoleSelect from '../Tournaments/Steps/ConsoleSelect.tsx';
import Review from '../Tournaments/Steps/Review';
import CurrencyInput from '../../components/CurrencyInput/WithCents.tsx';
import { convertToDollarAmount } from '../../utils';
import colors from './colors';
import PlayerCard from '../../components/PlayerCard.tsx';
import SwapPlayerModal from '../../components/SwapPlayerModal';
import { ONE_HOUR } from '../../utils/time';

const UpdateTournament = () => {
  const {
    // loading,
    teams,
    tournament,
    getTeams,
    getTournament,
  } = useTournament(true);
  const navigate = useNavigate();
  const { tournamentId } = useParams();
  const { god } = useAuth();
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [editEventOpen, setEditEventOpen] = useState(false);
  const [cancelEventOpen, setCancelEventOpen] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [partnerModalOpen, setPartnerModalOpen] = useState(false);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    if (isToday(date)) {
      return `Today ${format(date, '@ h:mmaa')}`;
    }
    if (isTomorrow(date)) {
      return `Tomorrow ${format(date, '@ h:mmaa')}`;
    }
    return format(date, 'MMMM do @ h:mmaa');
  };

  useEffect(() => {
    getTournament(tournamentId);
  }, []);

  useEffect(() => {
    if (tournament !== undefined) {
      getTeams(tournament.id);
    }
  }, [tournament]);

  if (Object.keys(god).length === 0 || tournament === undefined) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  const filteredTeams = teams
    ? teams.filter((t) => t.state !== 'cancelled')
    : [];

  return (
    <Layout userId={god.id} showHeaderElements>
      <div className="max-w-[100rem] mx-auto pt-12">
        <div className="flex gap-8 mb-12">
          <div className="flex-1 flex flex-col justify-center">
            <p className="text-xs text-grey-2 uppercase mb-1">
              {formatDate(tournament.start_date)}
            </p>
            <h3 className="font-extrabold text-white text-3xl mb-4">
              {tournament.title}
            </h3>
            <div className="flex gap-4">
              {tournament.stakeable && (
                <Button
                  variant="tertiary"
                  rounded
                  onClick={() => setShowNotify(true)}
                  text="Notify All Stakers"
                />
              )}
              <Button
                text="Reschedule Event"
                variant="tertiary"
                rounded
                large
                onClick={() => setEditEventOpen(true)}
              />
              <Button
                text="Cancel Event"
                variant="tertiary-danger"
                rounded
                large
                onClick={() => setCancelEventOpen(true)}
              />
            </div>
          </div>
        </div>
        <hr className="border-grey-4 my-4" />
        <div>
          <div className="flex items-center justify-between mb-4">
            <h4 className="text-2xl font-extrabold text-white">
              Manage Teams ({tournament.num_of_teams} Team
              {tournament.num_of_teams !== 1 && 's'})
            </h4>
            <Button
              text="Add Team"
              variant="primary"
              rounded
              large
              disabled={filteredTeams.length >= tournament.max_num_of_teams}
              onClick={() => {
                setPartnerModalOpen(true);
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          {filteredTeams.map((team) => (
            <div key={`team_${team.id}`} className="w-full">
              <button
                type="button"
                className="flex flex-col w-full justify-center items-center p-8 mb-4 rounded-lg border border-grey-4 gap-2 bg-grey-4"
                onClick={() => {
                  setSelectedTeamId(team.id);
                  setTeamModalOpen(true);
                }}
              >
                <AvatarGroup
                  users={team.tournament_players.map(
                    (p) => p.user_profile.profile_image_url
                  )}
                  medium
                  limit={3}
                />
                <p className="text-white font-bold">{team.name}</p>
                <p className="text-white text-xs -mt-2">({team.code})</p>
                {team.tag && (
                  <span
                    style={{
                      backgroundColor: team.tag.background_color,
                      color: team.tag.text_color,
                    }}
                    className="text-[12px] bg-grey-3 flex-1 font-bold px-3 py-[4px] rounded-full inline-flex items-center gap-1 overflow-x-hidden"
                  >
                    {team.tag.image_url && (
                      <img
                        src={team.tag.image_url}
                        alt={team.tag.title}
                        className="w-[16px] object-contain"
                      />
                    )}
                    <span className="truncate">{team.tag.title}</span>
                  </span>
                )}
              </button>
              {tournament.stakeable && (
                <Button
                  variant="tertiary"
                  rounded
                  onClick={() => setShowNotify(team.id)}
                  text="Notify Stakers"
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <AddTeamModal
        onSuccess={() => getTournament(tournamentId)}
        isOpen={partnerModalOpen}
        onClose={() => setPartnerModalOpen(false)}
        tournamentId={tournamentId}
        maxPlayers={tournament.num_players_per_team}
      />

      <RescheduleModal
        id={tournamentId}
        isOpen={editEventOpen}
        onClose={() => setEditEventOpen(false)}
        onSuccess={() => getTournament(tournamentId)}
        prevDate={tournament.start_date}
        teams={teams}
        tournament={tournament}
      />
      {/* <EditEventModal
        isOpen={editEventOpen}
        onClose={() => setEditEventOpen(false)}
        onSuccess={() => getTournament(tournamentId)}
        tournament={tournament}
      /> */}
      <CancelEventModal
        isOpen={cancelEventOpen}
        onClose={() => setCancelEventOpen(false)}
        onSuccess={() => navigate('/godmode/tournaments?tab=active')}
        tournament={tournament}
        teams={teams}
      />
      <CreateTeamModal
        isOpen={teamModalOpen}
        onClose={() => setTeamModalOpen(false)}
        team={filteredTeams.find((t) => t.name && t.id === selectedTeamId)}
        onSuccess={() => getTournament(tournamentId)}
        tournamentId={tournamentId}
        maxPlayers={tournament.num_players_per_team}
        isBracket={tournament.type === 'Tournament::Bracket'}
        maxTeams={tournament.max_num_of_teams}
      />
      {tournament.stakeable && (
        <NotifyStakersModal
          tournamentId={tournament.id}
          team={
            typeof showNotify === 'number'
              ? filteredTeams.find((t) => t.id === showNotify)
              : undefined
          }
          isOpen={!!showNotify}
          onClose={() => setShowNotify(false)}
        />
      )}
    </Layout>
  );
};

const RescheduleModal = ({
  prevDate,
  onSuccess,
  isOpen,
  onClose,
  id,
  teams,
  tournament,
}) => {
  const [value, setValue] = useState('');
  const [rescheduler, setRescheduler] = useState(null);

  useEffect(() => {
    if (!tournament.stakeable && isOpen) {
      setRescheduler('1v1Me');
    }
  }, [tournament.stakeable, isOpen]);

  const selectedName = useMemo(() => {
    if (rescheduler === '1v1Me') {
      return '1v1Me';
    }
    return teams.find((t) => t.id === rescheduler)?.display_team_name;
  }, [teams, rescheduler]);

  useEffect(() => {
    setValue(format(new Date(prevDate), "yyyy-MM-dd'T'HH:mm"));
  }, [prevDate]);

  const handleSave = async () => {
    try {
      const newDate = new Date(value).toISOString();
      const payload = {
        start_date: newDate,
      };
      if (rescheduler && rescheduler !== '1v1Me') {
        payload.tournament_team_id = rescheduler;
      }
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${id}/reschedule`,
        payload
      );
      onSuccess();
      onClose();
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <BaseModal modalIsOpen={isOpen} height="auto" closeModal={onClose}>
      {!rescheduler ? (
        <div className="text-center pb-4">
          <h3 className="font-extrabold text-white text-3xl mb-8">
            Who is Rescheduling?
          </h3>
          <div className="flex flex-col gap-2">
            {teams.map((t) => (
              <Button
                text={t.display_team_name}
                variant="grey"
                rounded
                large
                className="!bg-grey-3 !text-warning !w-full"
                onClick={() => setRescheduler(t.id)}
              />
            ))}
            <Button
              text="1v1Me"
              variant="grey"
              rounded
              large
              className="!bg-grey-3 !text-warning !w-full"
              onClick={() => setRescheduler('1v1Me')}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="mt-2 mb-12">
            <h3 className="text-xl font-extrabold text-white mb-4">
              Reschedule Event For {selectedName}
            </h3>
            <p className="text-white font-bold mb-3">
              Start Date{' '}
              <small className="text-grey-2 font-normal">
                (When does this tournament start)
              </small>
            </p>
            <input
              type="datetime-local"
              className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full border border-grey-3"
              placeholder="Click to enter the start date"
              name="start_date"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </div>

          <div className="grid grid-cols-2 items-center gap-4 pb-4">
            <Button
              variant="secondary"
              text="Switch Team"
              onClick={() => setRescheduler(null)}
              className="flex-1 !bg-grey-3"
              rounded
              large
            />
            <div className="flex-1">
              <HoldButton
                large
                text="Continue"
                variant="primary"
                onConfirm={handleSave}
                disabled={!value}
                timeout={100}
              />
            </div>
          </div>
        </>
      )}
    </BaseModal>
  );
};

const AddTeamModal = ({
  isOpen,
  tournamentId,
  onClose,
  onSuccess,
  maxPlayers,
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tab, setTab] = useState('Partners');

  const [algoliaId, setAlgoliaId] = useState(null);
  const [algoliaKey, setAlgoliaKey] = useState(null);

  const [results, setResults] = useState([]);
  const [value, setValue] = useState('');

  const [selectedUsers, setSelectedUsers] = useState([]);
  const debouncedVal = useDebounce(value);

  useEffect(() => {
    if (isOpen) {
      setTab('Partners');
      setSelectedUsers([]);
      setError(false);
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (algoliaId && algoliaKey) {
      const algolia = algoliasearch(algoliaId, algoliaKey);
      const index = algolia.initIndex('User');
      index
        .search(debouncedVal, {
          length: 24,
          offset: 0,
        })
        .then(({ hits }) => {
          setResults(hits);
        })
        .catch(() => setResults([]));
    }
  }, [algoliaId, algoliaKey, debouncedVal]);

  useEffect(() => {
    const getAlgolia = () =>
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/config`)
        .then(({ data }) => {
          setAlgoliaId(data.algolia_app_id);
          setAlgoliaKey(data.algolia_search_key);
        });
    const interval = setInterval(getAlgolia, ONE_HOUR);
    getAlgolia();

    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/teams/partners`
      )
      .then(({ data }) => setList(data))
      .catch(() => {});

    return () => {
      clearInterval(interval);
    };
  }, []);

  const addParner = (partner) => {
    setLoading(true);
    client
      .post(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/teams/add_partner`,
        {
          user_id: partner.user_id,
        }
      )
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const addOrRemoveUser = (user) => {
    setSelectedUsers(
      selectedUsers.some((u) => u.id === user.id)
        ? selectedUsers.filter((u) => u.id !== user.id)
        : [...selectedUsers, user]
    );
  };

  const handleConfirm = async (id) => {
    try {
      setLoading(true);
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/teams`,
        {
          leader_id: selectedUsers[0].id,
          teammates: selectedUsers.slice(1).map((u) => u.id),
        }
      );
      onSuccess();
      onClose();
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      header="Create a Team"
      large
      modalIsOpen={isOpen}
      closeModal={onClose}
    >
      <div className="sm:px-8 px-4 py-4">
        <TabGroup
          tabs={['Partners', 'All Players']}
          onChange={setTab}
          activeTab={tab}
        />
        <br />
        {error && (
          <p className="text-warning text-center py-2">Something went wrong</p>
        )}
        {tab === 'Partners' ? (
          <>
            {list.length === 0 && (
              <p className="text-grey-2 py-4 border-2 border-dashed rounded text-center border-grey-4 my-4">
                There are no partners available for this event
              </p>
            )}
            {list.map((partner) => (
              <div
                key={`partner_${partner.id}`}
                className={classNames('flex items-center justify-between mb-4')}
              >
                <div className="flex items-center gap-4">
                  <img
                    src={partner.profile_image_url}
                    alt={partner.username}
                    height={48}
                    width={48}
                    className="object-cover rounded-full"
                    onError={(e) => {
                      e.target.src =
                        'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                    }}
                  />
                  <p className="text-white font-extrabold">
                    {partner.username}
                  </p>
                </div>
                <Button
                  text="Select"
                  variant="primary"
                  rounded
                  onClick={() => {
                    addParner(partner);
                  }}
                  disabled={loading}
                  isLoading={loading}
                />
              </div>
            ))}
          </>
        ) : (
          <div className="h-full pt-3">
            <label className="block relative pb-8" htmlFor="#search">
              <div className="absolute top-0 left-0 ml-8 pt-3">
                <img
                  src={SearchIcon}
                  height={24}
                  width={24}
                  className="object-contain"
                  alt="search"
                />
              </div>
              <input
                className="flex-1 py-3 pr-8 pl-20 bg-grey-4 rounded-full w-full block !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue text-white mt-1"
                placeholder="Search for players"
                id="search"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </label>

            <div className="pb-8">
              {selectedUsers.map((r) => (
                <div
                  key={`user_${r.id}`}
                  className="flex items-center justify-between mb-4"
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={r.profile_image_url}
                      alt={r.username}
                      height={48}
                      width={48}
                      className="object-cover rounded-full"
                      onError={(e) => {
                        e.target.src =
                          'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                      }}
                    />
                    <p className="text-white font-extrabold">{r.username}</p>
                  </div>
                  <Button
                    text="Remove"
                    variant="tertiary-danger"
                    rounded
                    onClick={() => addOrRemoveUser(r)}
                  />
                </div>
              ))}
              {results
                .filter((r) => !selectedUsers.some((u) => u.id === r.id))
                .map((r) => (
                  <div
                    key={`user_${r.id}`}
                    className={classNames(
                      'flex items-center justify-between mb-4'
                    )}
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={r.profile_image_url}
                        alt={r.username}
                        height={48}
                        width={48}
                        className="object-cover rounded-full"
                        onError={(e) => {
                          e.target.src =
                            'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                        }}
                      />
                      <p className="text-white font-extrabold">{r.username}</p>
                    </div>
                    <Button
                      text="Select"
                      variant="primary"
                      loading={loading}
                      disabled={maxPlayers === selectedUsers.length}
                      rounded
                      onClick={() => addOrRemoveUser(r)}
                    />
                  </div>
                ))}
            </div>
            {selectedUsers.length > 0 && (
              <div className="sticky -bottom-7 w-full bg-black py-4">
                <div className="flex gap-4">
                  <Button
                    text="Confirm"
                    variant="primary"
                    rounded
                    large
                    className="!flex-1"
                    onClick={handleConfirm}
                    disabled={loading}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </BaseModal>
  );
};

const NotifyStakersModal = ({ isOpen, onClose, team, tournamentId }) => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setValue('');
      setLoading(false);
      setError(false);
    }
  }, [isOpen]);
  const handleNotify = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/push`;
      if (team) {
        url += `?event_team_id=${team.id}`;
      }
      await client.post(url, {
        message: value,
      });
      onClose();
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseModal
      header={`Notify ${team ? `${team.name} ` : ''}Stakers `}
      modalIsOpen={isOpen}
      closeModal={onClose}
      backgroundColor="bg-black"
      height="auto"
    >
      <div className="py-4">
        <textarea
          className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-lg mb-8"
          name="stakers_percentage"
          rows={4}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder="Message..."
        />
        {error && (
          <p className="text-warning text-center pb-2">
            There was an issue sending the notification
          </p>
        )}
        <Button
          text="Send Notification"
          variant="primary"
          onClick={handleNotify}
          className="!w-full"
          rounded
          disabled={loading || !value}
        />
      </div>
    </BaseModal>
  );
};

const EditEventModal = ({ isOpen, onClose, onSuccess, tournament }) => {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(tournament.type);
  const [amount, setAmount] = useState(tournament.player_pool_amount);
  const [entryFee, setEntryFee] = useState(0);
  const [title, setTitle] = useState(tournament.title);
  const [shoutcast, setShoutcast] = useState(tournament.shoutcast || '');
  const [squareImage, setSquareImage] = useState(tournament.image_url || '');
  const [landscapeImage, setLandscapeImage] = useState(
    tournament.wide_image_url || ''
  );
  const [dates, setDates] = useState({
    start_date: format(new Date(tournament.start_date), "yyyy-MM-dd'T'HH:mm"),
  });

  const [promotionalPayout, setPromotionalPayout] = useState(false);
  const [elementSet, setElementSet] = useState(null);
  const [roundElementSets, setRoundElementSets] = useState({});
  const [bracket, setBracket] = useState(null);
  const [gameId, setGameId] = useState(null);
  const { games, getGames } = useTournament(true);
  const [modeChanged, setModeChanged] = useState(false);
  const [applyToAll, setApplyToAll] = useState(true);
  const [selectedRoundId, setSelectedRoundId] = useState(null);

  useEffect(() => {
    if (isOpen) {
      const fetchBracket = async () => {
        getGames();
        const { data } = await client.get(
          `${process.env.REACT_APP_API_URL}/api/v1/tournaments/${tournament.id}/brackets`
        );
        data.forEach((section) => {
          section.rounds.forEach(async (round) => {
            if (round.element_set_id) {
              const { data: roundData } = await client.get(
                `${process.env.REACT_APP_API_URL}/api/v1/matches/element_sets/${round.element_set_id}`
              );
              setRoundElementSets((e) => ({
                ...e,
                [round.id]: {
                  console_id: roundData.console_id,
                  game_mode_id: roundData.game_mode_id,
                  format_id: roundData.match_format.id,
                  payout_id: roundData.match_payout.id,
                  map_ids: roundData.game_maps.map((m) => m.id),
                  rules: roundData.rule_types.reduce(
                    (prev, curr) => ({
                      ...prev,
                      [curr.id]: curr.rule_option.id,
                    }),
                    {}
                  ),
                  mutual_agreements: [],
                },
              }));
              setApplyToAll(false);
            }
          });
        });
        setBracket(data);
      };
      const fetchData = async () => {
        getGames();
        const { data } = await client.get(
          `${process.env.REACT_APP_API_URL}/api/v1/matches/element_sets/${tournament.element_set_id}`
        );
        setElementSet({
          console_id: data.console_id,
          game_mode_id: data.game_mode_id,
          format_id: data.match_format.id,
          payout_id: data.match_payout.id,
          map_ids: data.game_maps.map((m) => m.id),
          rules: data.rule_types.reduce(
            (prev, curr) => ({
              ...prev,
              [curr.id]: curr.rule_option.id,
            }),
            {}
          ),
          mutual_agreements: [],
        });
        const { data: mode } = await client.get(
          `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${data.game_mode_id}`
        );
        setGameId(mode.game_id);
      };
      setPage(0);
      setIsLoading(false);
      setDates({
        start_date: format(
          new Date(tournament.start_date),
          "yyyy-MM-dd'T'HH:mm"
        ),
      });
      setAmount(tournament.player_pool_amount);
      setTitle(tournament.title || '');
      setShoutcast(tournament.shoutcast || '');
      setLandscapeImage(tournament.wide_image_url || '');
      setSquareImage(tournament.image_url || '');
      setType(tournament.type);
      setApplyToAll(true);
      setBracket(null);
      setRoundElementSets({});
      setSelectedRoundId(null);
      fetchData();

      if (tournament.type === 'Tournament::Bracket') {
        fetchBracket();
      }
    }
  }, [isOpen, tournament]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    setDates({
      ...dates,
      [name]: value,
    });
  };

  const handleUpdateRules = useCallback(
    (rules) => {
      if (selectedRoundId) {
        setRoundElementSets((e) => ({
          ...e,
          [selectedRoundId]: {
            ...(e[selectedRoundId] || {}),
            map_ids: rules.maps,
            format_id: rules.format,
            rules: rules.ruleIds,
          },
        }));
      } else {
        setElementSet((e) => ({
          ...e,
          map_ids: rules.maps,
          format_id: rules.format,
          rules: rules.ruleIds,
        }));
      }
    },
    [selectedRoundId]
  );

  const handleSave = async () => {
    try {
      setIsLoading(true);

      const payload = {
        player_pool: amount,
        // title,
        description: '',
        start_date: new Date(dates.start_date).toISOString(),
        promotional_payout: promotionalPayout,
        // square_image_url: squareImage,
        // landscape_image_url: landscapeImage,
        shoutcast: shoutcast || null,
      };

      if (title) {
        payload.title = title;
      }
      if (landscapeImage) {
        payload.wide_image_url = landscapeImage;
      }
      if (squareImage) {
        payload.image_url = squareImage;
      }

      if (applyToAll) {
        const { data } = await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/element_sets`,
          {
            console_id: elementSet.console_id,
            game_mode_id: elementSet.game_mode_id,
            format_id: elementSet.format_id,
            payout_id: elementSet.payout_id,
            map_ids: elementSet.map_ids,
            rule_option_ids: Object.values(elementSet.rules),
            mutual_agreements: [],
          }
        );
        payload.element_set_id = data.element_set_id;
      }

      if (bracket) {
        const roundSets = await Promise.all(
          bracket
            .reduce((prev, curr) => [...prev, ...curr.rounds], [])
            .map(async (round) => {
              if (roundElementSets[round.id] && !applyToAll) {
                const { data } = await client.post(
                  `${process.env.REACT_APP_API_URL}/admin/v1/element_sets`,
                  {
                    console_id:
                      roundElementSets[round.id].console_id ||
                      elementSet.console_id,
                    game_mode_id:
                      roundElementSets[round.id].game_mode_id ||
                      elementSet.game_mode_id,
                    format_id: roundElementSets[round.id].format_id,
                    payout_id:
                      roundElementSets[round.id].payout_id ||
                      elementSet.payout_id,
                    map_ids: roundElementSets[round.id].map_ids,
                    rule_option_ids: Object.values(
                      roundElementSets[round.id].rules
                    ),
                    mutual_agreements: [],
                  }
                );
                return {
                  round_id: round.id,
                  element_set_id: data.element_set_id,
                };
              }
              return { round_id: round.id, element_set_id: null };
            })
        );
        payload.bracket_rules = roundSets;
      }

      await client.patch(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournament.id}`,
        payload
      );

      onSuccess();
      onClose();
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!applyToAll) {
      setSelectedRoundId(bracket[0].rounds[0].id);
    } else {
      setSelectedRoundId(null);
    }
  }, [applyToAll]);

  return (
    <BaseModal
      header=""
      modalIsOpen={isOpen}
      closeModal={onClose}
      fullPage
      backgroundColor="bg-black"
      height="calc(100vh - 52px)"
    >
      <div className="sm:px-8 pt-12 h-full w-full">
        {page === 0 && (
          <Meta
            editing
            type={type}
            onTypeChange={setType}
            title={title}
            onTitleChange={setTitle}
            shoutcast={shoutcast}
            onShoutcastChange={setShoutcast}
            squareImage={squareImage}
            onSquareImageChange={setSquareImage}
            landscapeImage={landscapeImage}
            onLandscapeImageChange={setLandscapeImage}
            onConfirm={() => setPage(1)}
          />
        )}
        {page === 1 && (
          <Dates
            handleNextStep={() =>
              tournament.stakeable ? setPage(3) : setPage(2)
            }
            handleInputChange={handleDateChange}
            startDate={dates.start_date}
            prevStep={0}
            step={page}
          />
        )}
        {page === 2 && (
          <StakeableFields
            editing
            playerPool={amount}
            onPoolChange={setAmount}
            entryFee={entryFee}
            onEntryFeeChange={setEntryFee}
            onConfirm={() => setPage(3)}
            promotionalPayout={promotionalPayout}
            onPromoPayoutChange={setPromotionalPayout}
          />
        )}
        {page === 3 &&
          (elementSet ? (
            <ConsoleSelect
              games={games || []}
              onConfirm={() => setPage(4)}
              onSelect={(cId) => {
                setElementSet((e) => ({
                  ...e,
                  console_id: cId,
                }));
              }}
              selectedConsole={elementSet.console_id}
              gameId={gameId}
            />
          ) : (
            <Loader />
          ))}
        {page === 4 &&
          (elementSet && gameId ? (
            <ModeSelect
              key="modeselect"
              onSelect={(mId, size, pId) => {
                setElementSet((e) => ({
                  ...e,
                  payout_id: pId,
                  game_mode_id: mId,
                  ruleIds: [],
                  maps: [],
                  format: null,
                }));
                setApplyToAll(true);
                setModeChanged(true);
                setPage(5);
              }}
              gameId={gameId}
              selectedMode={elementSet.game_mode_id}
            />
          ) : (
            <Loader />
          ))}
        {page === 5 &&
          type !== 'Tournament::Bracket' &&
          (elementSet ? (
            <ModeConfirm
              editing={!modeChanged}
              onUpdate={handleUpdateRules}
              onConfirm={() => setPage(6)}
              rules={{
                ruleIds: elementSet.rules,
                maps: elementSet.map_ids,
                format: elementSet.format_id,
              }}
              modeId={elementSet.game_mode_id}
            />
          ) : (
            <Loader />
          ))}
        {page === 5 &&
          type === 'Tournament::Bracket' &&
          (elementSet && bracket ? (
            <div className="flex gap-16">
              <div className="w-[400px]">
                <h3 className="text-3xl font-bold text-white mb-8">
                  Select Rules
                </h3>
                <div className="flex items-center justify-between gap-2">
                  <span className="text-white text-lg font-bold">
                    Apply To All Rounds
                  </span>
                  <Switch
                    checked={applyToAll}
                    onChange={() => setApplyToAll(!applyToAll)}
                    className={`${
                      applyToAll ? 'bg-blue' : 'bg-grey-3'
                    } relative inline-flex h-8 w-16 items-center rounded-full`}
                  >
                    <span
                      className={`${
                        applyToAll ? 'translate-x-9' : 'translate-x-1'
                      } inline-block h-6 w-6 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
                {bracket.map((section) => (
                  <div
                    key={`bracket_section_${section.id}`}
                    className={classNames({
                      'opacity-50 pointer-events-none': applyToAll,
                    })}
                  >
                    <p className="uppercase text-grey-2 text-sm py-4">
                      {section.name}
                    </p>
                    {section.rounds.map((round) => (
                      <Button
                        key={`bracket_section_${section.id}_round_${round.id}`}
                        large
                        rounded
                        className={classNames('!w-full !mb-4')}
                        variant={
                          round.id === selectedRoundId ? 'primary' : 'tertiary'
                        }
                        onClick={() => setSelectedRoundId(round.id)}
                        text={round.name}
                      />
                    ))}
                  </div>
                ))}
              </div>
              <div className="flex-1 pt-16">
                <ModeConfirm
                  editing={!modeChanged && roundElementSets[selectedRoundId]}
                  onUpdate={handleUpdateRules}
                  // onConfirm={() => setPage(6)}
                  rules={
                    selectedRoundId && roundElementSets[selectedRoundId]
                      ? {
                          ruleIds: roundElementSets[selectedRoundId].rules,
                          maps: roundElementSets[selectedRoundId].map_ids,
                          format: roundElementSets[selectedRoundId].format_id,
                        }
                      : {
                          ruleIds: elementSet.rules,
                          maps: elementSet.map_ids,
                          format: elementSet.format_id,
                        }
                  }
                  modeId={elementSet.game_mode_id}
                />
              </div>
            </div>
          ) : (
            <Loader />
          ))}
        {page === 6 && (
          <Review
            title={title}
            game={tournament.game_title}
            landscapeImage={landscapeImage}
            isStakeable={tournament.stakeable}
            displayDate={dates.start_date}
            amount={tournament.player_pool_amount}
            mode={elementSet.game_mode_id}
            inviteListName=""
            cmsFaqId=""
            matchType={type}
            console={elementSet.console_id}
            handleCreateTournament={handleSave}
            disabled={isLoading}
            entryFee={entryFee}
          />
        )}

        <div className="border-t border-grey-3 flex justify-end left-0 w-full fixed bottom-0 z-20 bg-black">
          {page > 0 && (
            <Button
              className="w-32 my-6 mr-4 md:mr-6 bg-grey-4"
              rounded
              text="Back"
              variant="tertiary"
              onClick={() =>
                setPage(
                  page - 1 === 2 && tournament.stakeable ? page - 2 : page - 1
                )
              }
            />
          )}
          {page < 6 && (
            <Button
              className="w-32 my-6 mr-4 md:mr-6"
              rounded
              text="Next"
              variant="primary"
              onClick={() =>
                setPage(
                  page + 1 === 2 && tournament.stakeable ? page + 2 : page + 1
                )
              }
            />
          )}
          {page === 6 && (
            <Button
              className="w-32 my-6 mr-4 md:mr-6"
              rounded
              text="Confirm"
              variant="primary"
              onClick={handleSave}
            />
          )}
        </div>
      </div>
    </BaseModal>
  );
};

const CancelEventModal = ({
  isOpen,
  onClose,
  onSuccess,
  tournament,
  teams,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [canceller, setCanceller] = useState(null);

  const selectedName = useMemo(() => {
    if (canceller === '1v1Me') {
      return '1v1Me';
    }
    return teams.find((t) => t.id === canceller)?.display_team_name;
  }, [teams, canceller]);

  useEffect(() => {
    if (isOpen) {
      setError('');
      setIsLoading(false);
      setCanceller(null);
    }
  }, [isOpen]);

  const handleCancel = async () => {
    let endpoint = `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournament.id}`;
    if (canceller && canceller !== '1v1Me') {
      endpoint += `?tournament_team_id=${canceller}`;
    }
    try {
      setIsLoading(true);
      await client.delete(endpoint);
      onSuccess();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <BaseModal
      header=""
      height="auto"
      modalIsOpen={isOpen}
      closeModal={onClose}
    >
      {!canceller ? (
        <div className="text-center pb-4">
          <h3 className="font-extrabold text-white text-3xl mb-8">
            Who is Cancelling?
          </h3>
          <div className="flex flex-col gap-2">
            {teams.map((t) => (
              <Button
                text={t.display_team_name}
                variant="grey"
                rounded
                large
                className="!bg-grey-3 !text-warning !w-full"
                onClick={() => setCanceller(t.id)}
              />
            ))}
            <Button
              text="1v1Me"
              variant="grey"
              rounded
              large
              className="!bg-grey-3 !text-warning !w-full"
              onClick={() => setCanceller('1v1Me')}
            />
          </div>
        </div>
      ) : (
        <div className="text-center pb-4">
          <h3 className="font-extrabold text-white text-3xl mb-2">
            Confirm {selectedName} Is Cancelling
          </h3>
          <div className="flex items-center justify-center mb-8">
            <Button
              variant="secondary"
              text="Switch Team"
              onClick={() => setCanceller(null)}
            />
          </div>
          <div className="w-full">
            {error && <p className="p-4 text-center text-warning">{error}</p>}
            <p className="text-grey-2 text-xs mb-2 text-center">
              THIS CANNOT BE UNDONE AND WILL REFUND ANY STAKERS
            </p>
            <div>
              <HoldButton
                variant="warning"
                text="Hold To Confirm"
                timeout={250}
                onConfirm={handleCancel}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  );
};

const CreateTeamModal = ({
  isOpen,
  onClose,
  team,
  maxPlayers,
  tournamentId,
  onSuccess,
  isBracket,
  maxTeams,
}) => {
  // 0: overview
  // 1: members
  // 2: Cancel
  const [page, setPage] = useState(1);
  const [algoliaId, setAlgoliaId] = useState(null);
  const [algoliaKey, setAlgoliaKey] = useState(null);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamCode, setTeamCode] = useState('');
  const [jersey, setJersey] = useState('');
  const [seed, setSeed] = useState(0);

  const [selectedMember, setSelectedMember] = useState(null);
  const [revPercent, setRevPercent] = useState(0);
  const [revMin, setRevMin] = useState(0);
  const [shares, setShares] = useState([]);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tag, setTag] = useState(null);
  const [tags, setTags] = useState([]);

  const [toPromote, setToPromote] = useState(null);
  const [bench, setBench] = useState([]);

  const getBench = () => {
    if (!team) {
      return;
    }
    client
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/stakes/bench?event_team_id=${team.id}`
      )
      .then(({ data }) => {
        setBench(data);
      });
  };
  useEffect(() => {
    getBench();
  }, [team]);

  const debouncedVal = useDebounce(value);

  const getTitle = () => {
    if (page === 0) {
      return team ? team.name : 'Create Team';
    }
    if (page === 1) {
      return 'Pickup Players';
    }
    if (page === 4 && selectedMember) {
      return `Edit ${selectedMember.user_profile.username} Revenue Share`;
    }
    return '';
  };

  useEffect(() => {
    const getAlgolia = () =>
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/config`)
        .then(({ data }) => {
          setAlgoliaId(data.algolia_app_id);
          setAlgoliaKey(data.algolia_search_key);
        });
    const interval = setInterval(getAlgolia, ONE_HOUR);
    getAlgolia();

    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/events/tags?event_id=${tournamentId}`
      )
      .then(({ data }) => {
        setTags(data);
      });

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    console.log({ debouncedVal, algoliaId });
    if (algoliaId && algoliaKey) {
      const algolia = algoliasearch(algoliaId, algoliaKey);
      const index = algolia.initIndex('User');
      index
        .search(debouncedVal, {
          length: 24,
          offset: 0,
        })
        .then(({ hits }) => {
          setResults(hits);
        })
        .catch(() => setResults([]));
    }
  }, [algoliaId, algoliaKey, debouncedVal]);

  useEffect(() => {
    if (team) {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/rev_share?event_team_id=${team.id}`
        )
        .then(({ data }) => setShares(data || []));
    }
  }, [team]);

  useEffect(() => {
    if (isOpen) {
      setPage(0);
      setSelectedUsers([]);
      setError('');
      setIsLoading(false);
      setTeamName(team?.name || '');
      setTeamCode(team?.code || '');
      setJersey(team?.jersey_color || '');
      setTag(team?.tag || null);
      setSeed(team?.seed || 0);
      setRevMin(0);
      setRevPercent(0);
      setToPromote(null);
    }
  }, [isOpen]);

  const addOrRemoveUser = (user) => {
    setSelectedUsers(
      selectedUsers.some((u) => u.id === user.id)
        ? selectedUsers.filter((u) => u.id !== user.id)
        : [...selectedUsers, user]
    );
  };

  const handleChangeName = async () => {
    try {
      setError('');
      setIsLoading(true);
      await client.patch(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/teams/${team.id}/change_name`,
        {
          name: teamName,
        }
      );
      onSuccess();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeCode = async () => {
    try {
      setError('');
      setIsLoading(true);
      await client.patch(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/teams/${team.id}/change_code`,
        {
          code: teamCode,
        }
      );
      onSuccess();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeSeed = async () => {
    try {
      setError('');
      setIsLoading(true);
      await client.patch(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/teams/${team.id}/change_seed`,
        {
          seed,
        }
      );
      onSuccess();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setIsLoading(true);
      if (team) {
        await client.delete(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/teams/${team.id}`
        );
        onSuccess();
        onClose();
      } else {
        setSelectedUsers([]);
      }
    } catch (e) {
      setPage(0);
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromote = async (id) => {
    if (team.tournament_players.length + 1 >= maxPlayers) {
      setToPromote(id);
    } else {
      try {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/partners/event_promote`,
          {
            event_team_id: team.id,
            bench_user_id: id,
            lineup_user_id: 353,
          }
        );
        onSuccess();
        onClose();
      } catch (e) {
        setError(e.response?.data?.message || 'Something went wrong');
      }
    }
  };

  const handleAddToBench = async (id) => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/event_pickup`,
        {
          event_team_id: team.id,
          bench_user_id: id,
        }
      );
      getBench();
      setPage(0);
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  const handleSaveRevShare = async () => {
    setError('');
    if (Number(revPercent) < 0 || Number(revPercent) > 100) {
      setError('Invalid Percentage');
      return;
    }
    try {
      setIsLoading(true);
      const body = {
        event_player_id: selectedMember.id,
        min_amount: revMin,
        percentage: Number(revPercent),
      };

      await client.put(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/rev_share`,
        body
      );

      setShares(
        shares.map((s) => (s.event_player_id === selectedMember.id ? body : s))
      );
      setPage(0);
      setRevMin(0);
      setRevPercent(0);
      setSelectedMember(null);
      onSuccess();
    } catch (e) {
      setPage(0);
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTag = async (t) => {
    try {
      const { data } = await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/events/tags`,
        { ...t, event_team_id: team.id }
      );
      setTag(data);
      onSuccess();
    } catch (e) {
      // noop
    }
  };
  return (
    <BaseModal
      header={getTitle()}
      large
      modalIsOpen={isOpen}
      closeModal={onClose}
    >
      {page === 0 && (
        <div className="sm:px-8 px-4 flex flex-col justify-between h-full pt-3">
          {team && (
            <SwapPlayerModal
              isOpen={!!toPromote}
              onClose={() => setToPromote(null)}
              onSuccess={() => setToPromote(null)}
              toPromoteId={toPromote}
              variant="default"
              bench={bench.map((b) => b.user_profile)}
              lineup={team.tournament_players
                .filter((b) => b.type !== 'leader')
                .map((b) => b.user_profile)}
              teamId={team.id}
            />
          )}
          {team ? (
            <div>
              <div className="flex gap-4 mb-8">
                <div className="flex-1">
                  <p className="text-white font-bold mb-2 text-sm">Team Name</p>
                  <input
                    type="text"
                    className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full mb-2"
                    name="team_name"
                    onChange={(e) => setTeamName(e.target.value)}
                    value={teamName}
                    placeholder="Team Name"
                  />
                  {teamName.trim() && team?.name !== teamName && (
                    <Button
                      text="Update Name"
                      variant="primary"
                      onClick={handleChangeName}
                      disabled={isLoading}
                    />
                  )}
                  <p className="text-white font-bold mb-2 text-sm">Team Code</p>
                  <input
                    type="text"
                    className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full mb-2"
                    name="team_code"
                    onChange={(e) =>
                      setTeamCode(e.target.value.toUpperCase().slice(0, 3))
                    }
                    value={teamCode}
                    placeholder="Team Code"
                  />
                  {teamCode.trim() && team?.code !== teamCode && (
                    <Button
                      text="Update Code"
                      variant="primary"
                      onClick={handleChangeCode}
                      disabled={isLoading}
                    />
                  )}
                  <div>
                    {/* <Jersey color={jersey || 'white'} onChange={setJersey} />
                    <br /> */}
                    {tag && (
                      <div>
                        <Tag tag={tag} />
                      </div>
                    )}
                    <div className="inline-flex gap-4 items-center py-2 relative">
                      <Popover>
                        <Popover.Button className={classNames('')}>
                          <span className="px-4 py-2 text-center font-bold flex justify-center items-center text-sm transition-all ease-in-out hover:opacity-75 btn-primary  rounded-md">
                            Select Tag
                          </span>
                        </Popover.Button>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform translate-y-2 opacity-0"
                          enterTo="transform translate-y-0 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform translate-y-0 opacity-100"
                          leaveTo="transform translate-y-2 opacity-0"
                        >
                          <Popover.Panel
                            className={classNames(
                              'absolute z-40 shadow-lg rounded-lg bg-black w-[400px]'
                            )}
                          >
                            {({ close }) => (
                              <div
                                className="absolute -mt-2 bg-grey-4 rounded w-[140px] py-2"
                                onClick={close}
                              >
                                {tags.map((t) => (
                                  <button
                                    type="button"
                                    className="px-4 w-full flex items-center"
                                    onClick={() => handleTag(t)}
                                  >
                                    <Tag tag={t} />
                                  </button>
                                ))}
                              </div>
                            )}
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                      {/* <Button
                        text="Create Tag"
                        variant="tertiary"
                        onClick={() => setTagModalOpen(true)}
                      /> */}
                    </div>
                  </div>
                </div>
                <CreateTagModal
                  isOpen={tagModalOpen}
                  onClose={() => setTagModalOpen(false)}
                  teamId={team.id}
                  onSave={(data) => {
                    setTag(data);
                    onSuccess();
                  }}
                  tag={tag}
                  tags={tags}
                />
                {isBracket && (
                  <div className="flex-1">
                    <p className="text-white font-bold mb-2 text-sm">
                      Seed (Optional)
                    </p>
                    <input
                      type="number"
                      min={1}
                      max={maxTeams}
                      className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full mb-2"
                      name="team_name"
                      onChange={(e) =>
                        setSeed(Math.max(Number(e.target.value), 1))
                      }
                      value={seed}
                      placeholder="Seed"
                    />
                    {team?.seed !== seed && (
                      <Button
                        text="Update Seed"
                        variant="primary"
                        onClick={handleChangeSeed}
                        disabled={isLoading}
                      />
                    )}
                  </div>
                )}
              </div>
              <h3 className="text-2xl font-extrabold text-white mb-4">
                Lineup
              </h3>
              {team?.tournament_players.map((p) => {
                const share = shares.find((s) => s.event_player_id === p.id);
                return (
                  <div
                    key={`user_${p.user_profile.id}`}
                    className="flex items-center justify-between mb-4"
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={p.user_profile.profile_image_url}
                        alt={p.user_profile.username}
                        height={36}
                        width={36}
                        className="object-cover rounded-full"
                        onError={(e) => {
                          e.target.src =
                            'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                        }}
                      />
                      <p className="text-white font-extrabold leading-snug">
                        {p.user_profile.username}
                        <small className="block text-grey-2 font-normal">
                          {p.type === 'leader' ? 'Team Leader' : 'Team Member'}
                        </small>
                        {share && (
                          <small className="block text-[10px] text-grey-2 font-normal">
                            Min Rev Share:{' '}
                            <span className="font-bold text-white">
                              {convertToDollarAmount(share?.min_amount)}
                            </span>{' '}
                            • Rev Share Percent:{' '}
                            <span className="font-bold text-white">
                              {share?.percentage}%
                            </span>
                          </small>
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <Button
                        text="Edit Rev Share"
                        variant="tertiary"
                        onClick={() => {
                          setSelectedMember(p);
                          setPage(4);
                          if (share) {
                            setRevMin(share.min_amount);
                            setRevPercent(share.percentage);
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {maxPlayers !== 1 && (
                <>
                  <h3 className="text-2xl pt-6 font-extrabold text-white mb-4 flex items-center justify-between gap-4">
                    Bench
                    <Button
                      variant="primary"
                      small
                      text="Pickup Player"
                      onClick={() => setPage(1)}
                      rounded
                      className="!py-2 !px-3"
                      textClass="!text-xs"
                    />
                  </h3>
                  {bench.length === 0 ? (
                    <div className="mb-8">Nothing to display</div>
                  ) : (
                    <div className=" mb-12">
                      {bench.map((l) => (
                        <PlayerCard
                          key={`bench_${l.user_profile.id}`}
                          username={l.user_profile.username}
                          avatar={l.user_profile.profile_image_url}
                          onPromote={() => handlePromote(l.user_profile.id)}
                          // onDrop={() => handleDrop(l.user_profile.id)}
                          // isLoading={swapState.isLoading}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div />
          )}
          {error && <p className="p-4 text-center text-warning">{error}</p>}
          <div className="flex gap-4">
            <Button
              text={team ? 'Remove Team' : 'Cancel'}
              variant="tertiary-danger"
              rounded
              large
              className="!flex-1"
              onClick={team ? () => setPage(2) : onClose}
            />
          </div>
        </div>
      )}
      {page === 1 && (
        <div className="sm:px-8 px-4 h-full pt-3">
          <label className="block relative pb-8" htmlFor="#search">
            <div className="absolute top-0 left-0 ml-8 pt-3">
              <img
                src={SearchIcon}
                height={24}
                width={24}
                className="object-contain"
                alt="search"
              />
            </div>
            <input
              className="flex-1 py-3 pr-8 pl-20 bg-grey-4 rounded-full w-full block !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue text-white mt-1"
              placeholder="Search for players"
              id="search"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </label>

          <div className="pb-8">
            {selectedUsers.map((r) => (
              <div
                key={`user_${r.id}`}
                className="flex items-center justify-between mb-4"
              >
                <div className="flex items-center gap-4">
                  <img
                    src={r.profile_image_url}
                    alt={r.username}
                    height={48}
                    width={48}
                    className="object-cover rounded-full"
                    onError={(e) => {
                      e.target.src =
                        'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                    }}
                  />
                  <p className="text-white font-extrabold">{r.username}</p>
                </div>
                <Button
                  text="Remove"
                  variant="tertiary-danger"
                  rounded
                  onClick={() => addOrRemoveUser(r)}
                />
              </div>
            ))}
            {results
              .filter((r) => !bench.some((u) => u.user_profile.id === r.id))
              .map((r) => (
                <div
                  key={`user_${r.id}`}
                  className={classNames(
                    'flex items-center justify-between mb-4'
                  )}
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={r.profile_image_url}
                      alt={r.username}
                      height={48}
                      width={48}
                      className="object-cover rounded-full"
                      onError={(e) => {
                        e.target.src =
                          'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                      }}
                    />
                    <p className="text-white font-extrabold">{r.username}</p>
                  </div>
                  <Button
                    text="Select"
                    variant="primary"
                    rounded
                    onClick={() => handleAddToBench(r.id)}
                  />
                </div>
              ))}
          </div>
          <div className="sticky -bottom-7 w-full bg-black py-4">
            <div className="flex gap-4">
              <Button
                text="Cancel"
                disabled={isLoading}
                variant="tertiary-danger"
                rounded
                large
                className="!flex-1"
                onClick={() => {
                  setPage(0);
                  setSelectedUsers([]);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {page === 2 && team && (
        <div className="sm:px-8 pt-12 h-full flex flex-col items-center justify-center">
          <AvatarGroup
            users={team.tournament_players.map(
              (p) => p.user_profile.profile_image_url
            )}
            large
            limit={3}
          />
          <h3 className="font-extrabold text-white text-3xl pt-4 mb-2">
            Confirm Remove {team.name}
          </h3>
          <p className="text-grey-2 flex-1">
            Confirm you want to remove {team.name} from the event.
          </p>
          <div className="w-full">
            <p className="text-grey-2 text-sm mb-2 text-center">
              THIS CANNOT BE UNDONE AND WILL REFUND ANY STAKERS
            </p>
            <div className="">
              <HoldButton
                variant="warning"
                text="Hold To Confirm"
                timeout={250}
                onConfirm={handleCancel}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      )}

      {page === 4 && (
        <div className="sm:px-8 px-4 flex flex-col justify-between h-full pt-3">
          <div className="flex-1">
            <p className="text-white font-bold mb-2 text-sm">Minimum Amount</p>
            <CurrencyInput value={revMin} simple onValueChange={setRevMin} />
            <p className="text-white font-bold mb-2 text-sm">
              Percentage (0-100)
            </p>
            <input
              type="number"
              className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full mb-2"
              onChange={(e) => setRevPercent(e.target.value)}
              value={revPercent}
              placeholder="0 - 100"
            />
          </div>
          {error && <p className="p-4 text-center text-warning">{error}</p>}
          <div className="flex gap-4">
            <Button
              text="Cancel"
              disabled={isLoading}
              variant="tertiary-danger"
              rounded
              large
              className="!flex-1"
              onClick={() => {
                setRevMin(0);
                setRevPercent(0);
                setSelectedMember(null);
                setPage(0);
              }}
            />
            <Button
              text="Confirm"
              variant="primary"
              rounded
              large
              className="!flex-1"
              onClick={handleSaveRevShare}
              disabled={isLoading}
            />
          </div>
        </div>
      )}
    </BaseModal>
  );
};

export const Jersey = ({ color, onChange }) => (
  <div className="flex justify-end flex-row-reverse gap-4 pt-4 px-4">
    <TeamColorPicker color={color} onChange={onChange} />
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-3 scale-[2]"
    >
      <path
        d="M12.3357 1.57802L15.7168 5.87337L12.5788 7.52817L12.4455 7.59847L12.4454 7.74917L12.4424 13.0626H3.47643L3.47344 7.70234L3.47336 7.55147L3.33984 7.48123L0.283281 5.87332L3.66159 1.58145C3.66756 1.57632 3.6758 1.56898 3.68432 1.56047L3.69784 1.54695L3.70914 1.53153C3.96815 1.17833 4.42495 0.9375 4.95612 0.9375H5.72032C6.00319 1.93099 6.917 2.65768 8 2.65768C9.083 2.65768 9.99681 1.93099 10.2797 0.9375H11.0189C11.5289 0.9375 11.9432 1.15713 12.1898 1.48425L12.2481 1.5616L12.3357 1.57802Z"
        fill={color}
        stroke="#979797"
        strokeWidth="0.5"
      />
    </svg>
  </div>
);

const TeamColorPicker = ({ onChange, color, title }) => {
  const [search, setSearch] = useState('');
  const [showSwatches, setShowSwatches] = useState('');
  const filtered = useMemo(
    () =>
      Object.keys(colors).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: colors[curr].filter((t) =>
            t.name.toLowerCase().includes(search.toLowerCase())
          ),
        }),
        {}
      ),
    [search]
  );
  return (
    <div className="relative">
      <Popover>
        <Popover.Button
          className={classNames(
            'px-4 py-2 text-center flex justify-center items-center text-sm transition-all ease-in-out hover:opacity-75 bg-white font-bold rounded-lg'
          )}
        >
          {title || 'Select Color'}
        </Popover.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform translate-y-2 opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform translate-y-2 opacity-0"
        >
          <Popover.Panel
            className={classNames(
              'absolute z-40 shadow-lg rounded-lg bg-black w-[400px]'
            )}
          >
            {({ close }) => (
              <div className="bg-[#050505] px-4 rounded-2xl absolute -top-2 h-[250px] overflow-auto">
                <div className="sticky top-0 -mx-4 -mt-4 pt-4 px-4 bg-[#050505] mb-4 flex items-center justify-between gap-4 pb-4">
                  <input
                    type="text"
                    className="py-2 flex-1 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full  sticky top-0"
                    name="team_name"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    type="button"
                    className="w-8 h-8 rounded"
                    style={{
                      backgroundColor: color,
                    }}
                    onClick={() => setShowSwatches(true)}
                  >
                    &nbsp;
                  </button>
                </div>
                {showSwatches && (
                  <div
                    className="sticky top-12 right-0 flex justify-end"
                    onClick={close}
                  >
                    <SwatchesPicker
                      onChange={({ hex }) => {
                        onChange(hex);
                        setShowSwatches(false);
                      }}
                      height={160}
                    />
                  </div>
                )}

                {Object.keys(filtered).map((key) =>
                  filtered[key].length === 0 ? null : (
                    <div className="text-white uppercase mb-4  border-grey-3 bg-grey-4 p-4 rounded-2xl shadow-lg shadow-black/50">
                      <p className="font-extrabold text-lg pb-1 mb-1">{key}</p>{' '}
                      {filtered[key].map((team) => (
                        <div className="flex items-center gap-2 mb-2">
                          <span className="capitalize text-base">
                            {team.name}
                          </span>
                          {team.colors.map((c) => (
                            <button
                              type="button"
                              onClick={() => {
                                onChange(c);
                                close();
                              }}
                              style={{
                                backgroundColor: c,
                              }}
                              className={classNames(
                                'h-6 w-6 rounded inline-block',
                                {
                                  'border-2': color === c,
                                }
                              )}
                            >
                              &nbsp;
                            </button>
                          ))}
                          <button
                            type="button"
                            onClick={() => {
                              onChange('#ffffff');
                              close();
                            }}
                            style={{
                              backgroundColor: '#ffffff',
                            }}
                            className={classNames(
                              'h-6 w-6 rounded inline-block',
                              {
                                'border-2': color === '#ffffff',
                              }
                            )}
                          >
                            &nbsp;
                          </button>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export const CreateTagModal = ({ teamId, isOpen, onClose, onSave, tag }) => {
  const [backgroundColor, setBackgroundColor] = useState(
    tag?.background_color || '#fff'
  );
  const [color, setColor] = useState(tag?.text_color || '#000');
  const [name, setName] = useState(tag?.title || 'Tag Name');
  const [imageUrl, setImageUrl] = useState(tag?.image_url || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/events/tags`,
        {
          event_team_id: Number(teamId),
          title: name,
          image_url: imageUrl || null,
          background_color: backgroundColor,
          text_color: color,
        }
      );
      onSave(data);
      onClose();
    } catch (e) {
      // noop
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal
      header="Create Tag"
      closeModal={onClose}
      modalIsOpen={isOpen}
      paginateData={() => {}}
      large
      height="600px"
    >
      <div className="flex flex-col gap-6 sm:px-8 px-4 h-full">
        <div>
          <span className="text-white text-xs font-bold block">
            Tag Preview
          </span>
          <span
            style={{
              backgroundColor,
              color,
            }}
            className="text-[12px] bg-grey-3 flex-1 font-bold px-3 py-[4px] rounded-full mt-2 inline-flex items-center gap-1 overflow-x-hidden"
          >
            {imageUrl && (
              <img
                src={imageUrl}
                alt={name}
                className="w-[16px] object-contain"
              />
            )}
            <span className="truncate">{name}</span>
          </span>
        </div>
        <label>
          <span className="text-white text-xs font-bold block mb-2">
            Tag Name
          </span>
          <input
            type="text"
            className="py-2 flex-1 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full  sticky top-0"
            name="tag_name"
            placeholder="Tag name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          <span className="text-white text-xs font-bold block mb-2">
            Tag Image
          </span>
          <input
            type="text"
            className="py-2 flex-1 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full  sticky top-0"
            name="image_url"
            placeholder="Image Url"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </label>
        <span className="text-white text-xs font-bold block -mb-4">Colors</span>
        <div className="flex gap-4 relative">
          <TeamColorPicker
            teamId={teamId}
            onChange={setColor}
            title="Set Text Color"
            color={color}
          />
          <TeamColorPicker
            teamId={teamId}
            onChange={setBackgroundColor}
            title="Set Background Color"
            color={backgroundColor}
          />
        </div>
        <div className="flex-1 flex items-end justify-end">
          <Button
            variant="primary"
            text="Save Tag"
            rounded
            large
            onClick={handleSave}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

const Tag = ({ tag }) => (
  <span
    style={{
      backgroundColor: tag.background_color,
      color: tag.text_color,
    }}
    className="text-[12px] bg-grey-3 flex-1 font-bold px-3 py-[4px] rounded-full mt-2 inline-flex items-center gap-1 overflow-x-hidden"
  >
    {tag.image_url && (
      <img
        src={tag.image_url}
        alt={tag.title}
        className="w-[16px] object-contain"
      />
    )}
    <span className="truncate">{tag.title}</span>
  </span>
);

export default UpdateTournament;
