import classNames from 'classnames';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import SearchIcon from '../../assets/Icons/search-icon.svg';
import BaseModal from '../../components/BaseModal/BaseModal';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';

const IPOverlapModal = ({ user, isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [toCompare, setToCompare] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [overlaps, setOverlaps] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [debouncedInput] = useDebounce(searchInput);

  const handleSelect = (u) => {
    setToCompare(u);
    setSearchInput('');
  };

  useEffect(() => {
    setOverlaps([]);
    if (toCompare) {
      setLoading(true);
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/users/${user.user.id}/overlap?user_id=${toCompare.id}`
        )
        .then(({ data }) => setOverlaps(data))
        .finally(() => setLoading(false));
    }
  }, [toCompare]);

  useEffect(() => {
    if (isOpen) {
      setToCompare(null);
      setSearchResults([]);
      setOverlaps([]);
      setSearchInput('');
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (debouncedInput) {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/users?page=1&page_size=7&filter=${debouncedInput}`
        )
        .then(({ data }) => setSearchResults(data));
    } else {
      setSearchResults([]);
    }
  }, [debouncedInput]);

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header="IP Overlap"
      height="auto"
      large
    >
      <div className="text-grey-2 pb-4 sm:px-8 px-4">
        <div className="mb-4 relative">
          <input
            className={classNames(
              'py-3 px-4 text-sm text-white bg-grey-4 border border-grey-3 focus:outline-none w-full rounded-full'
            )}
            placeholder="Search Users..."
            onChange={(e) => setSearchInput(e.target.value)}
            onClick={() => setSearchInput('')}
            value={searchInput}
          />
          {searchResults.length > 0 && (
            <div className="absolute top-9 bg-black w-full border border-grey-3 rounded-2xl max-h-[160px] overflow-auto shadow shadow-black/30">
              {searchResults.map((u) => (
                <button
                  type="button"
                  onClick={() => handleSelect(u.user)}
                  href={`/godmode/users/${u.user.id}`}
                  key={u.user.id}
                  className="flex justify-between items-center hover:bg-grey-4 px-4 py-2 w-full"
                >
                  <div className="flex items-center">
                    <img
                      src={u.user.profile_image_url}
                      alt="profile"
                      className="rounded-full w-6 h-6 object-cover"
                    />
                    <p className="text-white text-lg ml-3">{u.user.username}</p>
                  </div>
                  <p
                    className={classNames('uppercase text-xs', {
                      'text-primary': u.user.status === 'disabled',
                    })}
                  >
                    {u.user.status}
                  </p>
                </button>
              ))}
            </div>
          )}
        </div>

        {!toCompare && (
          <p className="border-2 border-dashed border-grey-3 rounded-lg py-8 sm:py-14 px-4 text-center">
            Select a user to compare
          </p>
        )}

        {toCompare && (
          <div className="bg-black rounded-2xl">
            <div className="grid grid-cols-3 gap-4 font-extrabold text-white border-b border-grey-3 p-4">
              <p className="truncate">{user.user.username}</p>
              <div />
              <p className="text-right truncate">{toCompare.username}</p>
            </div>
            {!loading && overlaps.length === 0 && (
              <p className="rounded-lg py-8 px-4 text-center">
                No Overlaps To Display
              </p>
            )}
            {loading && (
              <div className="py-8">
                <Loader />
              </div>
            )}
            <div className="flex flex-col">
              {overlaps.map((o, i) => (
                <div
                  className={`grid grid-cols-3 gap-4 font-extrabold text-white p-4 ${
                    i % 2 === 0 ? 'bg-white/5' : ''
                  }`}
                >
                  <p className="!leading-tight">
                    <span className="font-normal text-grey-2">Check Ins:</span>{' '}
                    {o.user_1_quantity_of_access}
                    <span className="text-[10px] block text-grey-2 font-normal">
                      Last Access:{' '}
                      {format(
                        new Date(o.user_1_last_access),
                        'MMM dd, y h:mmaa'
                      )}
                    </span>
                  </p>
                  <p className="font-normal text-center !leading-tight">
                    {o.ip_address}
                    <span className="text-[10px] text-grey-2 block">
                      {o.city}, {o.state}, {o.country}
                    </span>
                  </p>
                  <p className="text-right !leading-tight">
                    <span className="font-normal text-grey-2">Check Ins:</span>{' '}
                    {o.user_2_quantity_of_access}
                    <span className="text-[10px] block text-grey-2 font-normal">
                      Last Access:{' '}
                      {format(
                        new Date(o.user_2_last_access),
                        'MMM dd, y h:mmaa'
                      )}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default IPOverlapModal;
