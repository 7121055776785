import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/client';

const usersContext = createContext({
  users: [],
  user: {},
});

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [checkIns, setCheckIns] = useState([]);
  const [stakeSlips, setStakeSlips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isMore, setIsMore] = useState(true);

  const getUsers = async (search) => {
    if (search) {
      const { data } = await client.get(
        `${process.env.REACT_APP_API_URL}/admin/v1/users?page=1&page_size=7&filter=${search}`
      );

      setUsers(data);
    } else {
      setUsers([]);
    }
  };

  const getSingleUser = async (userId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/users?page=1&page_size=1&sort=desc&filter=${userId}`
    );

    const userRes = data[0] ? data[0] : {};

    setUser(userRes);
  };

  const getCheckIns = async (userId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/check_in?page=1&page_size=20`
    );

    setCheckIns(data);
  };

  const paginateCheckIns = async (userId) => {
    if (isMore) {
      setLoading(true);
      const { data } = await client.get(
        `${
          process.env.REACT_APP_API_URL
        }/admin/v1/users/${userId}/check_in?page=${page + 1}&page_size=20`
      );

      if (data.length === 0 || data.length < 20) {
        setIsMore(false);
      }

      setPage(page + 1);
      setCheckIns([...checkIns, ...data]);
    }
    setLoading(false);
  };

  const getStakeSlips = async (userId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/stakes/slips?user_id=${userId}&page=1&page_size=20`
    );

    setStakeSlips(data);
  };

  const banUser = async (userId, expiration, type, reason) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/disable`,
      {
        type,
        reason,
        expires_at: expiration,
      }
    );

    getSingleUser(userId);
  };

  const unBanUser = async (userId) => {
    const reason = prompt('Reason?');
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/enable`,
      { reason }
    );

    getSingleUser(userId);
  };

  const freezeUser = async (userId, reason) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/freeze`,
      { reason }
    );

    getSingleUser(userId);
  };

  const unFreezeUser = async (userId) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/unfreeze`
    );

    getSingleUser(userId);
  };

  const silenceUser = async (userId, expiration, reason) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/silence?time=${expiration}`
    );
    await client.post(`${process.env.REACT_APP_API_URL}/admin/v1/notes`, {
      user_id: userId,
      note: `Silenced: ${reason}`,
    });

    getSingleUser(userId);
  };

  const unSilenceUser = async (userId) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/silence`,
      {
        time: null,
      }
    );

    getSingleUser(userId);
  };

  const disconnectUserAccount = async (userId, idSystem, reason) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/disconnect_gamer_id`,
      {
        id_system_id: idSystem,
        reason,
      }
    );

    getSingleUser(userId);
  };

  const disconnectUserTwitch = async (userId, reason) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/disconnect_twitch`,
      {
        reason,
      }
    );

    getSingleUser(userId);
  };

  const disconnectUserPayPal = async (userId, reason) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/disconnect_paypal`,
      {
        reason,
      }
    );

    getSingleUser(userId);
  };

  const disconnectUserCredit = async (userId, id, reason) => {
    await client.delete(
      `${process.env.REACT_APP_API_URL}/admin/v1/payment_sources/${id}?reason=${reason}`
    );

    getSingleUser(userId);
  };

  const disconnectUserVenmo = async (userId, reason) => {
    await client.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/disconnect_venmo`,
      {
        reason,
      }
    );

    getSingleUser(userId);
  };

  return {
    loading,
    users,
    user,
    checkIns,
    stakeSlips,
    getUsers,
    getSingleUser,
    getCheckIns,
    paginateCheckIns,
    getStakeSlips,
    banUser,
    unBanUser,
    freezeUser,
    unFreezeUser,
    silenceUser,
    unSilenceUser,
    disconnectUserAccount,
    disconnectUserTwitch,
    disconnectUserPayPal,
    disconnectUserVenmo,
    disconnectUserCredit,
  };
};

export function UsersProvider({ children }) {
  const users = useUsers();

  return (
    <usersContext.Provider value={users}>{children}</usersContext.Provider>
  );
}

export default function UsersConsumer() {
  return React.useContext(usersContext);
}

UsersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
