/* eslint-disable no-nested-ternary */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const buttonStyles =
  'px-4 py-2 text-center flex justify-center items-center text-sm transition-all ease-in-out hover:opacity-75';

const loader = (
  <svg
    className="animate-spin -ml-1 mr-3 h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);
const Button = ({
  onClick,
  className,
  imageClass,
  text,
  variant,
  image,
  disabled = false,
  rounded,
  isLoading,
  large,
}) => (
  <button
    className={cn(buttonStyles, className, {
      'btn-primary': variant === 'primary',
      'btn-secondary': variant === 'secondary',
      'btn-tertiary hover:bg-grey-3': variant === 'tertiary',
      'btn-tertiary text-warning hover:bg-grey-4':
        variant === 'tertiary-danger',
      'btn-warning': variant === 'warning',
      'btn-info': variant === 'info',
      'rounded-xl': image,
      'rounded-full': rounded,
      'rounded-md': !rounded,
      'opacity-50 hover:cursor-default': disabled,
      'btn-secondary bg-grey-4 text-white': variant === 'grey',
      ' !py-3 !px-12': !!large,
    })}
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    {isLoading ? (
      <span>{loader}</span>
    ) : image ? (
      <img
        src={image}
        alt="button-type"
        className={cn(text ? 'mr-3' : '', imageClass)}
      />
    ) : null}
    {!isLoading && <span className="font-bold">{text}</span>}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  image: PropTypes.string,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  imageClass: PropTypes.string,
  isLoading: PropTypes.bool,
  large: PropTypes.bool,
};

export default Button;
