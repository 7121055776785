// @eslint-disable
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { FC } from 'react';

const AvatarGroup: FC<{
  users:
    | { id: number; activity_status?: string; profile_image_url: string }[]
    | string[];
  limit: number;
  large?: boolean;
  medium?: boolean;
}> = ({ users, limit, large, medium }) => (
  <div className="flex items-center">
    {users.slice(0, limit === users.length ? limit : limit - 1).map(
      (
        user:
          | string
          | {
              id: number;
              activity_status?: string;
              profile_image_url: string;
            },
        i
      ) => (
        <div
          key={typeof user === 'string' ? user : user.id}
          className={classNames('relative flex items-center', {
            '-ml-[8px]': i > 0,
          })}
          style={{ zIndex: users.length - i }}
        >
          <img
            height={large ? 80 : medium ? 40 : 24}
            width={large ? 80 : medium ? 40 : 24}
            src={typeof user === 'string' ? user : user.profile_image_url}
            className="rounded-full object-cover"
            alt="avatar"
            onError={(e) => {
              // @ts-ignore
              e.target.src =
                'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
            }}
          />
          {typeof user !== 'string' && user.activity_status === 'Online' && (
            <div className="bg-success h-2 w-2 absolute bottom-0 right-0 rounded full border border-black" />
          )}
        </div>
      )
    )}
    {users.length > limit && (
      <div
        className={classNames(
          'bg-neutral-800 rounded-full -ml-[8px] flex items-center justify-center text-neutral-400',
          {
            'h-[24px] w-[24px]': !large && !medium,
            'h-[80px] w-[80px] text-5xl': large,
            'h-[40px] w-[40px] text-3xl': medium,
          }
        )}
      >
        +
      </div>
    )}
  </div>
);
export default AvatarGroup;
