/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import AutoLinkText from 'react-autolink-text2';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Card from '../../components/Card/Card';
import Modal from '../../components/BaseModal/BaseModal';
import client from '../../services/client';
import useUsers from '../../contexts/UserContext/UserContext';
import Button from '../../components/Buttons/Button/Button';
import MoreIcon from '../../assets/Icons/more.svg';
import Loader from '../../components/Loader/Loader';

const Notes = ({ userIdOverride, small }) => {
  const [tags, setTags] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [isMore, setIsMore] = useState(true);
  const [selectedNote, setSelectedNote] = useState(null);
  const [noteInput, setNoteInput] = useState('');
  const [page, setPage] = useState(1);
  const { user } = useUsers();
  const textAreaRef = useRef(null);
  const containerRef = useRef(null);

  const userId = userIdOverride || user?.user?.id;

  useEffect(() => {
    textAreaRef.current.style.height = '52px';
    textAreaRef.current.style.height = `${Math.max(
      52,
      textAreaRef.current.scrollHeight
    )}px`;
  }, [noteInput]);

  const getTags = async () => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/notes/tags`
    );

    setTags(data.map((t) => (typeof t === 'string' ? t : t.title)));
  };

  const getNotes = async (id, filter) => {
    setLoading(true);

    const { data } = await client.get(
      `${
        process.env.REACT_APP_API_URL
      }/admin/v1/notes?user_id=${id}&page_size=20&page=1${
        filter ? `&tag=${filter}` : ''
      }`
    );

    setNotes(data.reverse());
    setLoading(false);
    setTimeout(() => {
      containerRef.current.scrollIntoView();
    }, 1000);
  };

  useEffect(() => {
    if (userId) {
      getTags(selectedFilter);
      getNotes(userId, selectedFilter);
    }
  }, [userId, selectedFilter]);

  useEffect(() => {
    if (selectedNote) {
      setNoteInput(notes.find((n) => n.id === selectedNote)?.note || '');
      setSelectedTag(notes.find((n) => n.id === selectedNote)?.tag || '');
    } else {
      setNoteInput('');
    }
  }, [selectedNote]);

  const paginateData = async () => {
    if (isMore && userId) {
      setLoading(true);
      const { data } = await client.get(
        `${
          process.env.REACT_APP_API_URL
        }/admin/v1/notes?user_id=${userId}&page_size=20&page=${page + 1}${
          selectedFilter ? `&tag=${selectedFilter}` : ''
        }`
      );

      if (data.length === 0 || data.length < 7) {
        setIsMore(false);
      }

      setPage(page + 1);
      setNotes([...data, ...notes]);
    }
    setLoading(false);
  };

  const saveNote = async () => {
    if (userId) {
      try {
        await client.post(`${process.env.REACT_APP_API_URL}/admin/v1/notes`, {
          user_id: userId,
          note: noteInput,
          tag: selectedTag,
        });
        setSelectedTag('');
        setNoteInput('');
        getNotes(userId);
        setAddOpen(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const editNote = async () => {
    if (userId && selectedNote) {
      try {
        await client.patch(
          `${process.env.REACT_APP_API_URL}/admin/v1/notes/${selectedNote}`,
          { note: noteInput, tag: selectedTag }
        );
        setNotes(
          notes.map((n) =>
            n.id === selectedNote
              ? { ...n, note: noteInput, tag: selectedTag }
              : n
          )
        );
        setSelectedTag('');
        setNoteInput('');
        setSelectedNote(null);
        setAddOpen(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const deleteNote = async (id) => {
    if (userId) {
      try {
        await client.delete(
          `${process.env.REACT_APP_API_URL}/admin/v1/notes/${id}`
        );
        setNotes(notes.filter((n) => n.id !== id));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handlePublish = () => {
    if (selectedNote) {
      editNote();
    } else {
      saveNote();
    }
  };

  return (
    <div className=" border-l border-grey-4 h-full flex flex-col bg-black">
      <div className="flex-1">
        <h2
          className={`text-white text-2xl font-extrabold sticky top-0 w-full bg-black pb-4 px-4 flex items-center justify-between ${
            !userIdOverride ? '' : 'pt-4 pr-20'
          } gap-4 z-10`}
        >
          Notes
          <select
            aria-label="filter"
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            className="py-2 text-sm text-white bg-grey-4 focus:outline-none px-4 rounded-full min-w-[200px] font-normal capitalize"
          >
            <option value="">Filter</option>
            {tags.map((t) => (
              <option key={`filter_${t}`} value={t}>
                {t}
              </option>
            ))}
          </select>
        </h2>
        {loading ? (
          <Loader />
        ) : notes.length ? (
          <>
            {isMore && (
              <div className="flex items-center justify-center p-2">
                <Button
                  text="Load More"
                  variant="tertiary"
                  onClick={paginateData}
                  rounded
                />
              </div>
            )}
            {notes.map((note) => (
              <Note
                note={note}
                key={`note_full_${note.id}`}
                onEdit={() => setSelectedNote(note.id)}
                onDelete={() => deleteNote(note.id)}
                small={small}
              />
            ))}
          </>
        ) : (
          <div className="px-4">
            <Card bordered>
              <p className="text-zinc-500 text-center py-8">
                No notes available
              </p>
            </Card>
          </div>
        )}
        <div ref={containerRef} />
      </div>
      <div className="sticky bottom-0 w-full bg-black border-t border-l border-r border-grey-4 rounded-t-lg p-4">
        <select
          aria-label="tag"
          value={selectedTag}
          onChange={(e) => setSelectedTag(e.target.value)}
          className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full mb-2 rounded-full capitalize"
        >
          <option value="">Select Tag</option>
          {tags.map((t) => (
            <option key={`tag_${t}`} value={t} className="capitalize">
              {t}
            </option>
          ))}
        </select>
        <textarea
          ref={textAreaRef}
          className="bg-grey-4 text-white h-44 w-full px-4 py-3 resize-none rounded-3xl max-h-[128px]"
          value={noteInput}
          placeholder="Note"
          onKeyDown={(e) => {
            if (
              (e.keyCode === 10 || e.keyCode === 13) &&
              (e.ctrlKey || e.metaKey) &&
              noteInput
            ) {
              handlePublish();
            }
          }}
          onChange={(e) => setNoteInput(e.target.value)}
        />
        <button
          type="button"
          onClick={handlePublish}
          disabled={!noteInput}
          className="bg-blue h-[44px] w-[44px] rounded-full absolute bottom-[26px] right-[22px] flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none"
        >
          <img src="https://1v1me.com/Icons/send-message.svg" alt="send" />
        </button>
      </div>

      <Modal
        modalIsOpen={addOpen || !!selectedNote}
        closeModal={() => {
          setAddOpen(false);
          setSelectedNote(null);
        }}
        header={selectedNote ? 'Edit Note' : 'Add Note'}
        height="300px"
      >
        <div className="bg-grey-4">
          <textarea
            className="bg-grey-3 text-white rounded-md h-44 w-full pl-3 pt-3 resize-none mb-2"
            placeholder="Note (required)"
            value={noteInput}
            onChange={(e) => setNoteInput(e.target.value)}
          />
          <select
            aria-label="tag"
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            className="py-3 text-sm text-white bg-grey-3 focus:outline-none px-4 w-full mb-4 rounded-full capitalize"
          >
            <option value="">Select Tag</option>
            {tags.map((t) => (
              <option key={`tag__${t}`} value={t} className="capitalize">
                {t}
              </option>
            ))}
          </select>
          <Button
            variant="primary"
            text="Publish"
            large
            rounded
            className="!w-full"
            disabled={!noteInput}
            onClick={handlePublish}
          />
        </div>
      </Modal>
    </div>
  );
};

const Note = ({ note, truncate, onEdit, onDelete, small }) => (
  <div className="flex items-start gap-4 mb-7 px-4">
    <img
      src={note.auditor.profile_image_url}
      className={classNames('rounded-full w-12 h-12 object-cover', {
        '!h-8 !w-8': small,
      })}
      alt="Auditor"
    />
    <div className="flex-1">
      <div
        className={classNames(
          'flex items-center justify-between text-xs gap-4 mb-2',
          { '!mb-0': small }
        )}
      >
        <p className="text-grey-2">{note.auditor.username}</p>
        <div className="flex items-center justify-end gap-2">
          <p
            className={classNames('text-grey-2 uppercase', {
              'text-[10px]': small,
            })}
          >
            {format(
              new Date(note.created_at),
              small ? 'MM/dd/yy hh:mm aa' : 'MMMM do, yyyy - hh:mm aa'
            )}
          </p>
          {!truncate && (
            <Popover className="relative">
              <Popover.Button className="py-1">
                <img
                  src={MoreIcon}
                  alt="Show more"
                  className={classNames('ml-3', { '!ml-2': small })}
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div className="absolute right-1 top-4  w-64 bg-black rounded overflow-hidden">
                  {onEdit && (
                    <Button
                      variant="secondary"
                      text="Edit Note"
                      className="w-full justify-start text-left uppercase z-20 relative"
                      onClick={() => onEdit()}
                    />
                  )}
                  {onDelete && (
                    <Button
                      variant="secondary"
                      text="Delete Note"
                      className="w-full justify-start text-left uppercase !text-warning z-20 relative"
                      onClick={() => onDelete()}
                    />
                  )}
                </div>
              </Transition>
            </Popover>
          )}
        </div>
      </div>

      <p
        className={` text-white mb-2 ${truncate ? '' : 'whitespace-pre-wrap'} ${
          small ? 'text-xs' : 'text-base'
        }`}
      >
        <AutoLinkText
          text={note.note}
          maxLength={truncate ? 140 : undefined}
          disableUrlStripping
          linkProps={{
            target: '_blank',
            style: {
              textDecoration: 'underline',
            },
          }}
        />
      </p>
      {note.tag && (
        <p className="capitalize inline-block px-4 py-2 text-grey-2 border border-grey-2 text-xs rounded-full">
          {note.tag}
        </p>
      )}
    </div>
  </div>
);

Note.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  truncate: PropTypes.bool,
  note: PropTypes.objectOf({
    note: PropTypes.string,
    created_at: PropTypes.string,
    auditor: {
      profile_image_url: PropTypes.string,
      username: PropTypes.string,
    },
  }),
};

export default Notes;
