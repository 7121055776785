/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import { Popover, Transition } from '@headlessui/react';
import React, { useMemo, useState } from 'react';
import MoreIcon from '../../assets/Icons/more.svg';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';

const ScoreOptions = ({
  event,
  options,
  matchId,
  onSave,
  round,
  scores,
  team1UUID,
  team2UUID,
}) => {
  const [confirming, setConfirming] = useState(null);

  const allWins = useMemo(
    () =>
      (scores || []).reduce(
        (prev, curr) => {
          if (curr.team_uuid === team1UUID) {
            return {
              ...prev,
              team1Id: curr.team_id,
              team1AllWins:
                curr.won && curr.completed
                  ? prev.team1AllWins + 1
                  : prev.team1AllWins,
            };
          }
          if (curr.team_uuid === team2UUID) {
            return {
              ...prev,
              team2Id: curr.team_id,
              team2AllWins:
                curr.won && curr.completed
                  ? prev.team2AllWins + 1
                  : prev.team2AllWins,
            };
          }
          return prev;
        },
        { team1AllWins: 0, team1Id: null, team2AllWins: 0, team2Id: null }
      ),
    [scores, team1UUID, team2UUID]
  );

  const handleScore = async (s, userId, teamId, win) => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/play_by_plays`,
        {
          ...s,
          team_id: teamId,
          user_id: userId,
          round_number: round,
        }
      );

      const payload = {
        round_number: round,
      };

      const team1Wins =
        teamId === allWins.team1Id
          ? allWins.team1AllWins + 1
          : allWins.team1AllWins;
      const team2Wins =
        teamId === allWins.team2Id
          ? allWins.team2AllWins + 1
          : allWins.team2AllWins;

      const isFinal =
        team1Wins > event.play_info.num_of_rounds / 2 ||
        team2Wins > event.play_info.num_of_rounds / 2;

      if (win) {
        if (isFinal) {
          payload.final = true;
        }
        await client.post(
          `${process.env.REACT_APP_API_URL}//admin/v1/matches/${matchId}/play_by_plays/finalize_round`,
          payload
        );
        setConfirming(null);
      }
      onSave();
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  const { primary, secondary } = useMemo(
    () =>
      (options || []).reduce(
        (prev, curr) => {
          if (curr.primary_option) {
            return {
              ...prev,
              primary: [...prev.primary, curr],
            };
          }
          return {
            ...prev,
            secondary: [...prev.secondary, curr],
          };
        },
        { primary: [], secondary: [] }
      ),
    [options]
  );

  return (
    <div>
      {event.teams.map((team, i) => {
        const color = i === 0 ? '#EE2629' : i === 1 ? '#2676EE' : undefined;
        return (
          <div
            key={`team_options_${team.roster.team_id}`}
            className="text-white mb-8 relative"
            style={{ zIndex: event.teams.length - i }}
          >
            <h3 className="bg-grey-4 px-4 py-2 text-2xl font-extrabold text-center mb-4">
              {team.roster.team_name}
            </h3>
            {(team.roster.players.length > 1
              ? [
                  ...team.roster.players,
                  {
                    user_profile: {
                      id: null,
                      username: 'UnknownPlayer',
                    },
                  },
                ]
              : team.roster.players
            ).map((p, idx, arr) => (
              <div
                key={`options_${p.user_profile.username}`}
                className="flex items-start gap-4 mb-4"
              >
                <div className="h-[72px] flex items-center w-1/5">
                  <p className="font-extrabold truncate ">
                    {p.user_profile.username}
                  </p>
                </div>
                <div className="grid grid-cols-3 gap-2 flex-1">
                  {!primary.length ? (
                    <p className="h-[72px] flex items-center justify-center col-span-2 text-grey-2">
                      No options to display, use manual entry
                    </p>
                  ) : (
                    primary.map((option) => (
                      <button
                        key={`primary_option_${p.user_profile.id}_${option.id}`}
                        className="min-h-[72px] py-4 px-4 w-full bg-grey-4 flex-1 rounded-xl leading-tight hover:opacity-75  transition-opacity ease-in-out !ring-0 !outline-none focus-visible:!outline-blue"
                        style={{
                          backgroundColor: p.user_profile.id
                            ? color
                            : undefined,
                          color: p.user_profile.id ? undefined : color,
                        }}
                        type="button"
                        onClick={() =>
                          option.win_option
                            ? setConfirming({
                                userId: p.user_profile.id,
                                teamId: team.roster.team_id,
                                data: option,
                              })
                            : handleScore(
                                option,
                                p.user_profile.id,
                                team.roster.team_id
                              )
                        }
                      >
                        <p className="truncate">{p.user_profile.username}</p>
                        <b>{option.button_text}</b>
                      </button>
                    ))
                  )}
                  {secondary.length > 0 && (
                    <Popover
                      className="relative z-20"
                      style={{ zIndex: arr.length - idx }}
                    >
                      <Popover.Button className="h-full relative py-4 px-4 min-h-[72px] w-full bg-grey-4 flex-1 flex items-center justify-center rounded-xl leading-tight hover:opacity-75  transition-opacity ease-in-out !ring-0 !outline-none focus-visible:!outline-blue">
                        <img src={MoreIcon} alt="expand" className="w-8" />
                      </Popover.Button>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform translate-y-2 opacity-0"
                        enterTo="transform translate-y-0 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform translate-y-0 opacity-100"
                        leaveTo="transform translate-y-2 opacity-0"
                      >
                        <Popover.Panel
                          className={`absolute  shadow-black/50 max-h-[240px] overflow-y-auto -top-4  z-50 shadow-lg rounded-lg bg-grey-4 overflow-x-hidden  w-[300px] ${
                            primary.length % 3 === 0 ? 'left-0' : 'right-0'
                          }`}
                        >
                          {({ close }) => (
                            <div onClick={() => close()}>
                              <p className="truncate py-2 px-4 font-bold">
                                {p.user_profile.username}
                              </p>
                              {secondary.map((option) => (
                                <button
                                  key={`secondary_option_${p.user_profile.id}_${option.id}`}
                                  className="py-3 px-4 w-full bg-grey-4 flex-1 leading-tight border-t border-grey-3 hover:opacity-75  transition-opacity ease-in-out !ring-0 !outline-none"
                                  style={{
                                    backgroundColor: p.user_profile.id
                                      ? color
                                      : undefined,
                                    color: p.user_profile.id
                                      ? undefined
                                      : color,
                                  }}
                                  type="button"
                                  onClick={() =>
                                    option.win_option
                                      ? setConfirming({
                                          userId: p.user_profile.id,
                                          teamId: team.roster.team_id,
                                          data: option,
                                        })
                                      : handleScore(
                                          option,
                                          p.user_profile.id,
                                          team.roster.team_id
                                        )
                                  }
                                >
                                  <b>{option.button_text}</b>
                                </button>
                              ))}
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}
                </div>
              </div>
            ))}
          </div>
        );
      })}
      <ConfirmModal
        isOpen={!!confirming}
        onClose={() => setConfirming(null)}
        data={confirming}
        event={event}
        onComplete={() =>
          handleScore(
            confirming?.data,
            confirming?.userId,
            confirming?.teamId,
            true
          )
        }
      />
    </div>
  );
};

const ConfirmModal = ({ isOpen, onClose, event, data, onComplete }) => {
  const team = event.teams.find((t) => t.roster.team_id === data?.teamId);

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header=""
      fullWidth={false}
      large
    >
      {team && (
        <div className="sm:px-12 pt-12 h-full flex flex-col items-center justify-center ">
          <img
            src={
              team.roster.players.find(
                (p) =>
                  p.type === 'leader' || p.type === 'TournamentPlayer::Leader'
              )?.user_profile.profile_image_url ||
              team.roster.players[0].user_profile.profile_image_url
            }
            alt="player"
            className="w-[64px]  h-[64px]  object-cover rounded-full"
          />
          <h3 className="font-extrabold text-white text-3xl pt-4 mb-2 flex-1 text-center">
            Confirm {team.roster.team_name} Won <br /> By{' '}
            {data?.data.button_text}
          </h3>
          <div className="max-w-lg mx-auto w-full">
            <div className="">
              <Button
                variant="primary"
                text="Confirm"
                rounded
                large
                className="!w-full"
                onClick={onComplete}
              />
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  );
};

export default ScoreOptions;
