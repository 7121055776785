/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ScrollContainer from 'react-indiana-drag-scroll';

import Pill from '../Pill/Pill';

import convertToDollarAmount from '../../utils/convertToDollar';

const Information = ({
  setVideo,
  clipInfo,
  sortedGroups,
  video,
  liveMatch,
  live,
}) => {
  const defaultImage =
    'https://mobile-assets.1v1me.com/users/v1/default_profile.png';

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  return (
    <div className="">
      <div className="my-6 lg:mx-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {sortedGroups[0].map((user) => (
              <div
                className="flex flex-shrink-0 items-center mr-3"
                key={user.hls_master_file_location}
              >
                <div
                  onClick={() => setVideo(user)}
                  role="button"
                  key={user.user_profile.id}
                  className=""
                >
                  <img
                    src={user.user_profile.profile_image_url}
                    alt="selected"
                    className={cn('rounded-full w-10 h-10 md:w-12 md:h-12', {
                      'border-2 border-warning':
                        video.hls_master_file_location ===
                        user.hls_master_file_location,
                    })}
                    onError={handleImageError}
                  />
                </div>
              </div>
            ))}
            {sortedGroups.length > 1 && (
              <p className="italic text-grey-2 font-bold ml-2 mr-5">VS</p>
            )}
            {sortedGroups.length > 1 &&
              sortedGroups[1].map((user) => (
                <div
                  className="flex flex-shrink-0 items-center mr-3"
                  key={user.hls_master_file_location}
                >
                  <div
                    onClick={() => setVideo(user)}
                    role="button"
                    key={user.user_profile.id}
                    className=""
                  >
                    <img
                      src={user.user_profile.profile_image_url}
                      alt="selected"
                      className={cn('rounded-full w-10 h-10 md:w-12 md:h-12', {
                        'border-2 border-warning':
                          video.hls_master_file_location ===
                          user.hls_master_file_location,
                      })}
                      onError={handleImageError}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="">
          {live && (
            <div className="flex mt-3 items-center">
              {liveMatch.type === 'tournament_team' && (
                <div className="flex mt-3 mr-3">
                  <p className="uppercase">{liveMatch.top_text}</p>
                </div>
              )}
              {sortedGroups.length === 2 && (
                <div className="flex mt-3 mr-3">
                  <p className="uppercase">
                    {liveMatch.top_text} vs {liveMatch.bottom_text}
                  </p>
                </div>
              )}
              <div className="bg-warning text-white font-bold rounded-sm text-center w-10 mt-3 text-sm">
                LIVE
              </div>
            </div>
          )}
          <div className="mt-3 flex justify-between">
            <h2 className="text-white text-4xl font-bold">
              WATCHING @{video.user_profile?.username}
            </h2>
            <h2 className="text-white text-4xl font-extrabold">
              {live
                ? convertToDollarAmount(liveMatch.pot_size)
                : convertToDollarAmount(clipInfo.pot_size)}
            </h2>
          </div>
          <div className="flex justify-between mt-3">
            <ScrollContainer className="w-4/5 lg:w-3/4">
              <div className="flex items-center">
                {clipInfo.tags.map(({ title, image_url }) => (
                  <Pill
                    className="flex items-center mr-3 bg-grey-4 flex-shrink-0"
                    key={title}
                  >
                    {image_url && (
                      <img src={image_url} alt="icon" className="w-4 mr-2" />
                    )}
                    <p className="text-sm text-grey-2">{title}</p>
                  </Pill>
                ))}
              </div>
            </ScrollContainer>
            <div className="">
              <p className="text-warning text-xl text-right font-extrabold uppercase">
                POT
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Information.propTypes = {
  setVideo: PropTypes.func,
  clipInfo: PropTypes.shape({
    clipper: PropTypes.shape({
      username: PropTypes.string,
      profile_image_url: PropTypes.string,
    }),
    pot_size: PropTypes.number,
    num_of_comments: PropTypes.number,
    num_of_likes: PropTypes.number,
    num_of_shares: PropTypes.number,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        image_url: PropTypes.string,
      })
    ),
    share_url: PropTypes.string,
  }),
  liveMatch: PropTypes.shape({
    clipper: PropTypes.shape({
      username: PropTypes.string,
      profile_image_url: PropTypes.string,
    }),
    pot_size: PropTypes.number,
    num_of_comments: PropTypes.number,
    num_of_likes: PropTypes.number,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        image_url: PropTypes.string,
      })
    ),
    share_url: PropTypes.string,
    type: PropTypes.string,
    top_text: PropTypes.string,
    bottom_text: PropTypes.string,
  }),
  live: PropTypes.bool,
  sortedGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        clip: PropTypes.string,
        user_profile: PropTypes.shape({
          id: PropTypes.number,
        }),
      })
    )
  ),
  video: PropTypes.shape({
    hls_master_file_location: PropTypes.string,
    user_profile: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
    }),
  }),
};

export default Information;
