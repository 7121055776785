const colors = {
  epl: [
    {
      name: 'AFC Bournemouth',
      league: 'epl',
      colors: ['#E62333', '#000000'],
    },
    {
      name: 'Arsenal',
      league: 'epl',
      colors: ['#EF0107', '#023474', '#9C824A'],
    },
    {
      name: 'Brighton & Hove Albion',
      league: 'epl',
      colors: ['#0055a9', '#F8BC1B'],
    },
    {
      name: 'Burnley',
      league: 'epl',
      colors: ['#8CCCE5', '#53162F', '#F9EC34'],
    },
    {
      name: 'Chelsea',
      league: 'epl',
      colors: ['#034694', '#DBA111', '#ED1C24'],
    },
    {
      name: 'Crystal Palace',
      league: 'epl',
      colors: ['#1B458F', '#C4122E', '#A7A5A6'],
    },
    {
      name: 'Everton',
      league: 'epl',
      colors: ['#274488'],
    },
    {
      name: 'Huddersfield Town',
      league: 'epl',
      colors: ['#0073d2'],
    },
    {
      name: 'Leicester City',
      league: 'epl',
      colors: ['#FDBE11', '#0053A0'],
    },
    {
      name: 'Liverpool',
      league: 'epl',
      colors: ['#00A398', '#D00027', '#FEF667'],
    },
    {
      name: 'Manchester City',
      league: 'epl',
      colors: ['#98c5e9', '#00285e', '#f4bc46'],
    },
    {
      name: 'Manchester United',
      league: 'epl',
      colors: ['#DA020E', '#FFE500', '#000000'],
    },
    {
      name: 'Newcastle United',
      league: 'epl',
      colors: ['#241f20', '#00B8F4', '#c3a572'],
    },
    {
      name: 'Southampton',
      league: 'epl',
      colors: ['#ED1A3B', '#211E1F', '#FFC20E'],
    },
    {
      name: 'Stoke City',
      league: 'epl',
      colors: ['#E03A3E', '#1B449C'],
    },
    {
      name: 'Swansea City',
      league: 'epl',
      colors: ['#000000'],
    },
    {
      name: 'Tottenham Hotspur',
      league: 'epl',
      colors: ['#001C58'],
    },
    {
      name: 'Watford',
      league: 'epl',
      colors: ['#FBEE23', '#ED2127', '#000000'],
    },
    {
      name: 'West Bromwich Albion',
      league: 'epl',
      colors: ['#091453'],
    },
    {
      name: 'West Ham United',
      league: 'epl',
      colors: ['#60223B', '#F7C240', '#5299C6'],
    },
  ],
  mlb: [
    {
      name: 'Arizona Diamondbacks',
      league: 'mlb',
      colors: ['#A71930', '#000000', '#E3D4AD'],
    },
    {
      name: 'Atlanta Braves',
      league: 'mlb',
      colors: ['#CE1141', '#13274F'],
    },
    {
      name: 'Baltimore Orioles',
      league: 'mlb',
      colors: ['#DF4601', '#000000'],
    },
    {
      name: 'Boston Red Sox',
      league: 'mlb',
      colors: ['#BD3039', '#0D2B56'],
    },
    {
      name: 'Chicago Cubs',
      league: 'mlb',
      colors: ['#CC3433', '#0E3386'],
    },
    {
      name: 'Chicago White Sox',
      league: 'mlb',
      colors: ['#000000', '#C4CED4'],
    },
    {
      name: 'Cincinnati Reds',
      league: 'mlb',
      colors: ['#C6011F', '#000000'],
    },
    {
      name: 'Colorado Rockies',
      league: 'mlb',
      colors: ['#333366', '#231F20', '#C4CED4'],
    },
    {
      name: 'Cleveland Indians',
      league: 'mlb',
      colors: ['#E31937', '#002B5C'],
    },
    {
      name: 'Detroit Tigers',
      league: 'mlb',
      colors: ['#0C2C56'],
    },
    {
      name: 'Houston Astros',
      league: 'mlb',
      colors: ['#002D62', '#EB6E1F'],
    },
    {
      name: 'Kansas City Royals',
      league: 'mlb',
      colors: ['#004687', '#C09A5B'],
    },
    {
      name: 'Los Angeles Angels of Anaheim',
      league: 'mlb',
      colors: ['#BA0021', '#003263'],
    },
    {
      name: 'Los Angeles Dodgers',
      league: 'mlb',
      colors: ['#EF3E42', '#005A9C'],
    },
    {
      name: 'Miami Marlins',
      league: 'mlb',
      colors: ['#FF6600', '#0077C8', '#FFD100', '#000000'],
    },
    {
      name: 'Milwaukee Brewers',
      league: 'mlb',
      colors: ['#0A2351', '#B6922E'],
    },
    {
      name: 'Minnesota Twins',
      league: 'mlb',
      colors: ['#002B5C', '#D31145'],
    },
    {
      name: 'New York Mets',
      league: 'mlb',
      colors: ['#FF5910', '#002D72'],
    },
    {
      name: 'New York Yankees',
      league: 'mlb',
      colors: ['#E4002B', '#003087'],
    },
    {
      name: 'Oakland Athletics',
      league: 'mlb',
      colors: ['#003831', '#EFB21E'],
    },
    {
      name: 'Philadelphia Phillies',
      league: 'mlb',
      colors: ['#284898', '#E81828'],
    },
    {
      name: 'Pittsburgh Pirates',
      league: 'mlb',
      colors: ['#FDB827', '#000000'],
    },
    {
      name: 'San Diego Padres',
      league: 'mlb',
      colors: ['#002D62', '#FEC325', '#7F411C', '#A0AAB2'],
    },
    {
      name: 'San Francisco Giants',
      league: 'mlb',
      colors: ['#FD5A1E', '#000000', '#8B6F4E'],
    },
    {
      name: 'Seattle Mariners',
      league: 'mlb',
      colors: ['#0C2C56', '#005C5C', '#C4CED4'],
    },
    {
      name: 'St Louis Cardinals',
      league: 'mlb',
      colors: ['#C41E3A', '#000066', '#FEDB00'],
    },
    {
      name: 'Tampa Bay Rays',
      league: 'mlb',
      colors: ['#092C5C', '#8FBCE6', '#F5D130'],
    },
    {
      name: 'Texas Rangers',
      league: 'mlb',
      colors: ['#C0111F', '#003278'],
    },
    {
      name: 'Toronto Blue Jays',
      league: 'mlb',
      colors: ['#134A8E', '#1D2D5C', '#E8291C'],
    },
    {
      name: 'Washington Nationals',
      league: 'mlb',
      colors: ['#AB0003', '#11225B'],
    },
  ],
  mls: [
    {
      name: 'Atlanta United FC',
      league: 'mls',
      colors: ['#A29061', '#80000B', '#000000'],
    },
    {
      name: 'Chicago Fire',
      league: 'mls',
      colors: ['#AF2626', '#0A174A', '#8A8D8F'],
    },
    {
      name: 'Colorado Rapids',
      league: 'mls',
      colors: ['#91022D', '#85B7EA', '#8A8D8F', '#313F49'],
    },
    {
      name: 'Columbus Crew',
      league: 'mls',
      colors: ['#000000', '#FFDB00', '#8A8D8F'],
    },
    {
      name: 'DC United',
      league: 'mls',
      colors: ['#000000', '#DD0000'],
    },
    {
      name: 'FC Dallas',
      league: 'mls',
      colors: ['#CF0032', '#07175C', '#8A8D8F'],
    },
    {
      name: 'Houston Dynamo',
      league: 'mls',
      colors: ['#F36600', '#2E2926', '#85B7EA'],
    },
    {
      name: 'LA Galaxy',
      league: 'mls',
      colors: ['#00245D', '#004689', '#F1AA00', '#FFD200'],
    },
    {
      name: 'Minnesota United FC',
      league: 'mls',
      colors: ['#CFD4D8', '#6CADDF', '#000000'],
    },
    {
      name: 'Montreal Impact',
      league: 'mls',
      colors: ['#122089', '#000000', '#7A878F'],
    },
    {
      name: 'New England Revolution',
      league: 'mls',
      colors: ['#0A2141', '#D80016', '#8A8D8F'],
    },
    {
      name: 'New York Red Bulls',
      league: 'mls',
      colors: ['#D50031', '#012055', '#FFC800', '#8A8D8F'],
    },
    {
      name: 'New York City FC',
      league: 'mls',
      colors: ['#6CADDF', '#00285E', '#FD4F00'],
    },
    {
      name: 'Orlando City SC',
      league: 'mls',
      colors: ['#633492', '#FDE192'],
    },
    {
      name: 'Philadelphia Union',
      league: 'mls',
      colors: ['#002D55', '#5090CD', '#B38707', '#B49759', '#F4F4F4'],
    },
    {
      name: 'Portland Timbers',
      league: 'mls',
      colors: ['#004812', '#EBE72B'],
    },
    {
      name: 'Real Salt Lake',
      league: 'mls',
      colors: ['#A50531', '#013474', '#F2D11A'],
    },
    {
      name: 'San Jose Earthquakes',
      league: 'mls',
      colors: ['#0051BA', '#000000', '#B1B4B2'],
    },
    {
      name: 'Seattle Sounders FC',
      league: 'mls',
      colors: ['#4F8A10', '#11568C', '#212930'],
    },
    {
      name: 'Sporting Kansas City',
      league: 'mls',
      colors: ['#91b0d5', '#002b5c', '#9fa1a4'],
    },
    {
      name: 'Toronto FC',
      league: 'mls',
      colors: ['#D80016', '#313F49', '#A1AAAD'],
    },
    {
      name: 'Vancouver Whitecaps FC',
      league: 'mls',
      colors: ['#12264C', '#85B7EA', '#838383'],
    },
  ],
  nba: [
    {
      name: 'Atlanta Hawks',
      league: 'nba',
      colors: ['#e13a3e', '#c4d600', '#061922'],
    },
    {
      name: 'Boston Celtics',
      league: 'nba',
      colors: ['#008348', '#bb9753', '#a73832', '#fab383', '#061922'],
    },
    {
      name: 'Brooklyn Nets',
      league: 'nba',
      colors: ['#061922'],
    },
    {
      name: 'Charlotte Hornets',
      league: 'nba',
      colors: ['#1d1160', '#008ca8', '#a1a1a4'],
    },
    {
      name: 'Chicago Bulls',
      league: 'nba',
      colors: ['#ce1141', '#061922'],
    },
    {
      name: 'Cleveland Cavaliers',
      league: 'nba',
      colors: ['#860038', '#fdbb30', '#002d62'],
    },
    {
      name: 'Dallas Mavericks',
      league: 'nba',
      colors: ['#007dc5', '#c4ced3', '#061922', '#20385b'],
    },
    {
      name: 'Denver Nuggets',
      league: 'nba',
      colors: ['#4d90cd', '#fdb927', '#0f586c'],
    },
    {
      name: 'Detroit Pistons',
      league: 'nba',
      colors: ['#ed174c', '#006bb6', '#0f586c'],
    },
    {
      name: 'Golden State Warriors',
      league: 'nba',
      colors: ['#fdb927', '#006bb6', '#26282a'],
    },
    {
      name: 'Houston Rockets',
      league: 'nba',
      colors: ['#ce1141', '#c4ced3', '#061922', '#fdb927'],
    },
    {
      name: 'Indiana Pacers',
      league: 'nba',
      colors: ['#ffc633', '#00275d', '#bec0c2'],
    },
    {
      name: 'Los Angeles Clippers',
      league: 'nba',
      colors: ['#ed174c', '#006bb6', '#061922', '#bec0c2'],
    },
    {
      name: 'Los Angeles Lakers',
      league: 'nba',
      colors: ['#fdb927', '#552582', '#061922', '#8177b7'],
    },
    {
      name: 'Memphis Grizzlies',
      league: 'nba',
      colors: ['#0f586c', '#7399c6', '#bed4e9', '#fdb927'],
    },
    {
      name: 'Miami Heat',
      league: 'nba',
      colors: ['#98002e', '#f9a01b', '#061922', '#bcbec0'],
    },
    {
      name: 'Milwaukee Bucks',
      league: 'nba',
      colors: ['#00471b', '#f0ebd2', '#061922', '#007dc5'],
    },
    {
      name: 'Minnesota Timberwolves',
      league: 'nba',
      colors: [
        '#005083',
        '#00a94f',
        '#c4ced3',
        '#ffe600',
        '#e03a3f',
        '#061922',
      ],
    },
    {
      name: 'New Orleans Pelicans',
      league: 'nba',
      colors: ['#002b5c', '#e31837', '#b4975a'],
    },
    {
      name: 'New York Knicks',
      league: 'nba',
      colors: ['#006bb6', '#f58426', '#bec0c2', '#231f20'],
    },
    {
      name: 'Oklahoma City Thunder',
      league: 'nba',
      colors: ['#007dc3', '#f05133', '#fdbb30', '#002d62'],
    },
    {
      name: 'Orlando Magic',
      league: 'nba',
      colors: ['#007dc5', '#c4ced3', '#061922'],
    },
    {
      name: 'Philadelphia 76ers',
      league: 'nba',
      colors: ['#ed174c', '#006bb6', '#002b5c', '#c4ced3'],
    },
    {
      name: 'Phoenix Suns',
      league: 'nba',
      colors: [
        '#e56020',
        '#1d1160',
        '#63717a',
        '#f9a01b',
        '#b95915',
        '#bec0c2',
        '#061922',
      ],
    },
    {
      name: 'Portland Trail Blazers',
      league: 'nba',
      colors: ['#e03a3e', '#bac3c9', '#061922'],
    },
    {
      name: 'Sacramento Kings',
      league: 'nba',
      colors: ['#724c9f', '#8e9090', '#061922'],
    },
    {
      name: 'San Antonio Spurs',
      league: 'nba',
      colors: ['#bac3c9', '#061922'],
    },
    {
      name: 'Toronto Raptors',
      league: 'nba',
      colors: ['#ce1141', '#061922', '#a1a1a4', '#b4975a'],
    },
    {
      name: 'Utah Jazz',
      league: 'nba',
      colors: ['#002b5c', '#f9a01b', '#00471b', '#bec0c2'],
    },
    {
      name: 'Washington Wizards',
      league: 'nba',
      colors: ['#002b5c', '#e31837', '#c4ced4'],
    },
  ],
  nfl: [
    {
      name: 'Arizona Cardinals',
      league: 'nfl',
      colors: ['#97233F', '#000000', '#FFB612', '#A5ACAF'],
    },
    {
      name: 'Atlanta Falcons',
      league: 'nfl',
      colors: ['#A71930', '#000000', '#A5ACAF'],
    },
    {
      name: 'Baltimore Ravens',
      league: 'nfl',
      colors: ['#241773', '#000000', '#9E7C0C', '#C60C30'],
    },
    {
      name: 'Buffalo Bills',
      league: 'nfl',
      colors: ['#00338D', '#C60C30'],
    },
    {
      name: 'Carolina Panthers',
      league: 'nfl',
      colors: ['#0085CA', '#000000', '#BFC0BF'],
    },
    {
      name: 'Cincinnati Bengals',
      league: 'nfl',
      colors: ['#000000', '#FB4F14'],
    },
    {
      name: 'Cleveland Browns',
      league: 'nfl',
      colors: ['#FB4F14', '#22150C', '#A5ACAF'],
    },
    {
      name: 'Dallas Cowboys',
      league: 'nfl',
      colors: [
        '#002244',
        '#B0B7BC',
        '#ACC0C6',
        '#A5ACAF',
        '#00338D',
        '#000000',
      ],
    },
    {
      name: 'Chicago Bears',
      league: 'nfl',
      colors: ['#0B162A', '#C83803'],
    },
    {
      name: 'Denver Broncos',
      league: 'nfl',
      colors: ['#002244', '#FB4F14'],
    },
    {
      name: 'Detroit Lions',
      league: 'nfl',
      colors: ['#005A8B', '#B0B7BC', '#000000'],
    },
    {
      name: 'Green Bay Packers',
      league: 'nfl',
      colors: ['#203731', '#FFB612'],
    },
    {
      name: 'Houston Texans',
      league: 'nfl',
      colors: ['#03202F', '#A71930'],
    },
    {
      name: 'Indianapolis Colts',
      league: 'nfl',
      colors: ['#002C5F', '#A5ACAF'],
    },
    {
      name: 'Jacksonville Jaguars',
      league: 'nfl',
      colors: ['#000000', '#006778', '#9F792C', '#D7A22A'],
    },
    {
      name: 'Kansas City Chiefs',
      league: 'nfl',
      colors: ['#E31837', '#FFB612', '#000000'],
    },
    {
      name: 'Los Angeles Rams',
      league: 'nfl',
      colors: ['#002244', '#B3995D'],
    },
    {
      name: 'Miami Dolphins',
      league: 'nfl',
      colors: ['#008E97', '#F58220', '#005778'],
    },
    {
      name: 'Minnesota Vikings',
      league: 'nfl',
      colors: ['#4F2683', '#FFC62F', '#E9BF9B', '#000000'],
    },
    {
      name: 'New England Patriots',
      league: 'nfl',
      colors: ['#002244', '#C60C30', '#B0B7BC'],
    },
    {
      name: 'New Orleans Saints',
      league: 'nfl',
      colors: ['#9F8958', '#000000'],
    },
    {
      name: 'New York Giants',
      league: 'nfl',
      colors: ['#0B2265', '#A71930', '#A5ACAF'],
    },
    {
      name: 'New York Jets',
      league: 'nfl',
      colors: ['#203731'],
    },
    {
      name: 'Oakland Raiders',
      league: 'nfl',
      colors: ['#A5ACAF', '#000000'],
    },
    {
      name: 'Philadelphia Eagles',
      league: 'nfl',
      colors: ['#004953', '#A5ACAF', '#ACC0C6', '#000000', '#565A5C'],
    },
    {
      name: 'Pittsburgh Steelers',
      league: 'nfl',
      colors: ['#000000', '#FFB612', '#C60C30', '#00539B', '#A5ACAF'],
    },
    {
      name: 'Los Angeles Chargers',
      league: 'nfl',
      colors: ['#002244', '#0073CF', '#FFB612'],
    },
    {
      name: 'San Francisco 49ers',
      league: 'nfl',
      colors: ['#AA0000', '#B3995D', '#000000', '#A5ACAF'],
    },
    {
      name: 'Seattle Seahawks',
      league: 'nfl',
      colors: ['#002244', '#69BE28', '#A5ACAF'],
    },
    {
      name: 'Tampa Bay Buccaneers',
      league: 'nfl',
      colors: ['#D50A0A', '#34302B', '#000000', '#FF7900', '#B1BABF'],
    },
    {
      name: 'Tennessee Titans',
      league: 'nfl',
      colors: ['#002244', '#4B92DB', '#C60C30', '#A5ACAF'],
    },
    {
      name: 'Washington Redskins',
      league: 'nfl',
      colors: ['#773141', '#FFB612', '#000000', '#5B2B2F'],
    },
  ],
  nhl: [
    {
      name: 'Anaheim Ducks',
      league: 'nhl',
      colors: ['#010101', '#a2aaad', '#fc4c02', '#85714d'],
    },
    {
      name: 'Arizona Coyotes',
      league: 'nhl',
      colors: ['#010101', '#862633', '#ddcba4'],
    },
    {
      name: 'Boston Bruins',
      league: 'nhl',
      colors: ['#010101', '#ffb81c'],
    },
    {
      name: 'Buffalo Sabres',
      league: 'nhl',
      colors: ['#041e42', '#a2aaad', '#ffb81c', '#c8102e'],
    },
    {
      name: 'Calgary Flames',
      league: 'nhl',
      colors: ['#010101', '#f1be48', '#c8102e'],
    },
    {
      name: 'Carolina Hurricanes',
      league: 'nhl',
      colors: ['#010101', '#a2aaad', '#c8102e'],
    },
    {
      name: 'Chicago Blackhawks',
      league: 'nhl',
      colors: [
        '#010101',
        '#ff671f',
        '#ffd100',
        '#001871',
        '#c8102e',
        '#00843d',
        '#cc8a00',
      ],
    },
    {
      name: 'Colorado Avalanche',
      league: 'nhl',
      colors: ['#010101', '#236192', '#a4a9ad', '#6f263d'],
    },
    {
      name: 'Columbus Blue Jackets',
      league: 'nhl',
      colors: ['#041e42', '#a4a9ad', '#c8102e'],
    },
    {
      name: 'Dallas Stars',
      league: 'nhl',
      colors: ['#010101', '#006341', '#8a8d8f'],
    },
    {
      name: 'Detroit Red Wings',
      league: 'nhl',
      colors: ['#c8102e'],
    },
    {
      name: 'Edmonton Oilers',
      league: 'nhl',
      colors: ['#00205b', '#cf4520'],
    },
    {
      name: 'Florida Panthers',
      league: 'nhl',
      colors: ['#041e42', '#b9975b', '#c8102e'],
    },
    {
      name: 'Los Angeles Kings',
      league: 'nhl',
      colors: ['#010101', '#a2aaad'],
    },
    {
      name: 'Minnesota Wild',
      league: 'nhl',
      colors: ['#154734', '#ddcba4', '#eaaa00', '#a6192e'],
    },
    {
      name: 'Montreal Canadiens',
      league: 'nhl',
      colors: ['#001e62', '#a6192e'],
    },
    {
      name: 'Nashville Predators',
      league: 'nhl',
      colors: ['#041e42', '#ffb81c'],
    },
    {
      name: 'New Jersey Devils',
      league: 'nhl',
      colors: ['#010101', '#c8102e'],
    },
    {
      name: 'New York Islanders',
      league: 'nhl',
      colors: ['#003087', '#fc4c02'],
    },
    {
      name: 'New York Rangers',
      league: 'nhl',
      colors: ['#0033a0', '#c8102e'],
    },
    {
      name: 'Ottawa Senators',
      league: 'nhl',
      colors: ['#010101', '#c8102e', '#c69214'],
    },
    {
      name: 'Philadelphia Flyers',
      league: 'nhl',
      colors: ['#010101', '#fa4616'],
    },
    {
      name: 'Pittsburgh Penguins',
      league: 'nhl',
      colors: ['#010101', '#ffb81c'],
    },
    {
      name: 'San Jose Sharks',
      league: 'nhl',
      colors: ['#010101', '#e57200', '#006272'],
    },
    {
      name: 'St Louis Blues',
      league: 'nhl',
      colors: ['#041e42', '#ffb81c', '#003087'],
    },
    {
      name: 'Tampa Bay Lightning',
      league: 'nhl',
      colors: ['#00205b'],
    },
    {
      name: 'Toronto Maple Leafs',
      league: 'nhl',
      colors: ['#00205b'],
    },
    {
      name: 'Vancouver Canucks',
      league: 'nhl',
      colors: ['#00205b', '#97999b', '#041c2c'],
    },
    {
      name: 'Vegas Golden Knights',
      league: 'nhl',
      colors: ['#010101', '#b4975a', '#333f42'],
    },
    {
      name: 'Washington Capitals',
      league: 'nhl',
      colors: ['#041e42', '#a2aaad', '#782f40', '#a6192e', '#53565a'],
    },
    {
      name: 'Winnipeg Jets',
      league: 'nhl',
      colors: ['#041e42', '#c8102e'],
    },
  ],
};

export default colors;
