import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Switch } from '@headlessui/react';
import Button from '../../../components/Buttons/Button/Button';
import Modal from '../../../components/BaseModal/BaseModal';

import UserIcon from '../../../components/Icons/user-icon';

const InviteList = ({
  inviteOnly,
  setInviteOnly,
  tournamentInviteLists,
  handleAddInviteList,
  createTournamentList,
  onConfirm,
}) => {
  // Modals
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [inviteListName, setInviteListName] = useState('');

  const handleCreateTournamentList = () => {
    createTournamentList(inviteListName);
    setOpenInviteModal(false);
  };

  return (
    <div className="overflow-y-auto no-scrollbar transition-all duration-300 ease-in-out translate-x-0 fade-in-fast">
      <h3 className="text-white font-bold text-2xl mb-6">Invite Lists</h3>
      <div className="w-full lg:w-5/12 m-auto border border-grey-3 rounded-2xl p-6">
        <div className="mt-8 relative">
          <p className="text-white font-bold mb-3">
            Is this Tournament Invite-Only?
          </p>
          <div className="flex items-center gap-2">
            <Switch
              checked={inviteOnly}
              onChange={() => setInviteOnly(!inviteOnly)}
              className={`${
                inviteOnly ? 'bg-blue' : 'bg-grey-3'
              } relative inline-flex h-8 w-16 items-center rounded-full`}
            >
              <span
                className={`${
                  inviteOnly ? 'translate-x-9' : 'translate-x-1'
                } inline-block h-6 w-6 transform rounded-full bg-white transition`}
              />
            </Switch>
            <span className="text-white text-xs uppercase">
              {inviteOnly ? 'Yes' : 'No'}
            </span>
          </div>
        </div>
        {!inviteOnly && (
          <div className="flex justify-center pt-12">
            <Button
              rounded
              text="Confirm"
              variant="primary"
              className="!w-full"
              onClick={() => onConfirm()}
            />
          </div>
        )}
        {inviteOnly && (
          <>
            <div className="flex justify-between items-center mt-8">
              <h3 className="text-white font-bold text-2xl">
                Select an Invite List
              </h3>
              <Button
                rounded
                text="Create Invite List"
                variant="primary"
                onClick={() => setOpenInviteModal(true)}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-6">
              {tournamentInviteLists.map(({ id, name, num_of_invitees }) => (
                <div
                  className={cn(
                    'hover:bg-grey-4 pt-3 pb-4 bg-grey-3 h-20 rounded-xl'
                  )}
                  onClick={() => handleAddInviteList(id, name)}
                  role="button"
                  key={id}
                >
                  <div className="flex px-3">
                    <div className="relative w-12">
                      <div className="bg-white rounded-full p-3 z-20 absolute">
                        <UserIcon color="black" className="w-4" />
                      </div>
                      <div className="bg-grey-2 rounded-full p-3 z-10 absolute top-3 left-4">
                        <UserIcon color="black" className="w-4" />
                      </div>
                    </div>
                    <div className="ml-6">
                      <p className="text-white font-bold text-lg">{name}</p>
                      <p className="text-grey-2">{num_of_invitees} Members</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Modal
        height="150px"
        modalIsOpen={openInviteModal}
        closeModal={() => {
          setOpenInviteModal(false);
        }}
        header="Create Invite Lists"
      >
        <div className="relative">
          <input
            onChange={(e) => setInviteListName(e.target.value)}
            name="inviteListName"
            value={inviteListName}
            type="Text"
            placeholder="Invite List Name"
            className="w-full rounded-full bg-zinc-700 text-white py-2 px-3 mt-2"
          />

          <Button
            className="w-full mt-12"
            rounded
            text="Create"
            variant="primary"
            onClick={handleCreateTournamentList}
            disabled={inviteListName === ''}
          />
        </div>
      </Modal>
    </div>
  );
};

InviteList.propTypes = {
  inviteOnly: PropTypes.bool,
  setInviteOnly: PropTypes.func,
  tournamentInviteLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      num_of_invitees: PropTypes.number,
    })
  ),
  handleAddInviteList: PropTypes.func,
  createTournamentList: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default InviteList;
