/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import groupBy from 'lodash/groupBy';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import classNames from 'classnames';
import {
  differenceInMinutes,
  format,
  isBefore,
  isThisWeek,
  isToday,
  isTomorrow,
} from 'date-fns';
import { Switch } from '@headlessui/react';
import algoliasearch from 'algoliasearch';
import UserIcon from '../../../assets/Icons/user-icon.svg';
import ActiveIcon from '../../../assets/Icons/active.svg';
import RecruitIcon from '../../../assets/Icons/recruit.svg';
import ClockIcon from '../../../assets/Icons/clock-wide.svg';
import CalendarIcon from '../../../assets/Icons/calendar.svg';
import CompletedIcon from '../../../assets/Icons/completed.svg';
import RulesWhiteImage from '../../../assets/Icons/rules-white.svg';
import useTournament from '../../../contexts/TournamentsContext/TournamentsContext';

import { formattedDate } from '../../../utils/index';

import Button from '../../../components/Buttons/Button/Button';
import client from '../../../services/client';
import MatchCard from '../../../features/Matches/MatchCard.tsx';
import Loader from '../../../components/Loader/Loader';
import BaseModal from '../../../components/BaseModal/BaseModal';
import TabGroup from '../../../components/TabGroup.tsx';
import Team2 from '../../../components/Team2';
import PartnerAvailability from '../../PartnerManagement/PartnerAvailability';
import PlayEventCard from './PlayEventCard';
import Recruitment from './Recruitment';
// eslint-disable-next-line import/no-cycle
import ReplaceEvents from '../../PartnerManagement/ReplaceEvents';
import ScheduledEvent from './ScheduledEvent';
import { ONE_HOUR } from '../../../utils/time';
import useDebounce from '../../../utils/useDebounce';

const TournameList = ({ tournaments, handleNextStep, onRefetch }) => {
  const [drafts, setDrafts] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generateSuccess, setGenerateSuccess] = useState(false);
  const [approvalSuccess, setApprovalSuccess] = useState(false);

  const fetchDrafts = () => {
    setError('');
    setIsLoading(true);
    client
      .get(`${process.env.REACT_APP_API_URL}/admin/v1/events/drafts`)
      .catch((e) => setError('There was an issue fetching the events'))
      .then(({ data }) => setDrafts(data))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchDrafts();
  }, []);

  const [search, setSearch] = useSearchParams();
  const tab = String(search.get('tab') || 'active');

  const handleApproveAll = () => {
    const ok = window.confirm('Are you sure?');
    if (ok) {
      setError('');
      setIsLoading(true);
      setApprovalSuccess(false);
      client
        .post(`${process.env.REACT_APP_API_URL}/admin/v1/events/drafts/approve`)
        .catch(() => setError('There was an issue approving the events'))
        .then(() => {
          setApprovalSuccess(true);
          setDrafts([]);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleDelete = (id) => {
    const ok = window.confirm('Are you sure?');
    if (ok) {
      client
        .delete(`${process.env.REACT_APP_API_URL}/admin/v1/events/drafts/${id}`)
        .catch((e) => setError('There was an issue deleting the event'))
        .then(() => setDrafts((d) => d.filter((draft) => draft.id !== id)));
    }
  };

  const handleGenerate = () => {
    const ok = window.confirm('Are you sure?');
    if (ok) {
      setGenerateSuccess(false);
      setError('');
      client
        .post(
          `${process.env.REACT_APP_API_URL}/admin/v1/events/drafts/generate`
        )
        .catch(() => setError('There was an issue generating the events'))
        .then(() => setGenerateSuccess(true));
    }
  };

  const byMode = groupBy(
    drafts,
    (d) =>
      `${d.event.play_info.game_name}: ${d.event.play_info.game_mode_title}`
  );
  const getAvgScore = (events) =>
    events.reduce((prev, curr) => prev + curr.score, 0) / events.length;

  // const stats = (drafts || []).reduce(
  //   (prev, curr) => {
  //     const mode = curr.event.play_info.game_mode_title;
  //     return {
  //       total: (prev?.total || 0) + 1,
  //       modes: {
  //         ...prev.modes,
  //         [mode]: {
  //           ...prev.modes[mode],
  //           total: (prev[mode]?.total || 0) + 1,
  //         },
  //       },
  //     };
  //   },
  //   { total: 0, avg: 0, modes: {} }
  // );

  const renderData = () => {
    if (isLoading) {
      return (
        <div className="py-12">
          <Loader />
        </div>
      );
    }

    const grouped = (drafts || []).reduce((prev, curr, i) => {
      const d = new Date(curr.event.stakes_close_at);
      if (differenceInMinutes(d, new Date()) < 0) {
        const formatted = format(d, 'eeee, MMMM do');
        return {
          ...prev,
          [`${0}::${formatted}`]: [...(prev[`${0}::${formatted}`] || []), curr],
        };
      }
      if (differenceInMinutes(d, new Date()) <= 20) {
        return {
          ...prev,
          [`${0}::Starting Soon`]: [
            ...(prev[`${0}::Starting Soon`] || []),
            curr,
          ],
        };
      }

      if (i === 0) {
        return {
          ...prev,
          [`${0}::Up Next`]: [...(prev[`${0}::Up Next`] || []), curr],
        };
      }

      if (isToday(d)) {
        return {
          ...prev,
          [`${1}::Later Today`]: [...(prev[`${1}::Later Today`] || []), curr],
        };
      }

      if (isTomorrow(d)) {
        return {
          ...prev,
          [`${2}::Tomorrow`]: [...(prev[`${2}::Tomorrow`] || []), curr],
        };
      }

      if (isThisWeek(d)) {
        const formatted = format(d, 'eeee');
        return {
          ...prev,
          [`${d.getDay() + 10}::${formatted}`]: [
            ...(prev[`${d.getDay() + 10}::${formatted}`] || []),
            curr,
          ],
        };
      }

      const formatted = format(d, 'eeee, MMMM do');
      return {
        ...prev,
        [`${100}::${formatted}`]: [
          ...(prev[`${100}::${formatted}`] || []),
          curr,
        ],
      };
    }, {});

    if (drafts.length > 0) {
      return (
        <div className="grid grid-cols-1 gap-4 text-grey-2">
          {Object.keys(grouped)
            .sort((a, b) => Number(a.split('::')[0]) - Number(b.split('::')[0]))
            // .filter((key) =>
            //   completed ? true : key.split('::')[1] === activeTab
            // )
            .map((key, i) => (
              <div className="mb-8" key={key}>
                <div className="flex items-center gap-4 mb-6">
                  <h2 className="font-extrabold text-white text-xl sm:text-3xl flex-1">
                    {key.split('::')[1]}
                  </h2>
                </div>
                {grouped[key].map((draft) => (
                  <ScheduledEvent
                    key={`draft_${draft.id}`}
                    game={draft.event.play_info.game_name}
                    title={draft.event.play_info.game_mode_title}
                    color={draft.event.play_info.game_mode_color}
                    icon={draft.event.play_info.game_icon}
                    subtitle={draft.event.play_info.subtitle}
                    startDate={draft.event.start_date}
                    rollover_available={false}
                    teams={draft.event.teams}
                    onDelete={() => handleDelete(draft.id)}
                    delta={draft.score}
                  />
                ))}
              </div>
            ))}
        </div>
      );
    }
    return (
      <p className="border-2 border-dashed text-center border-grey-4 p-8 text-lg">
        There are currently no events to display
      </p>
    );
  };

  return (
    <div className="flex gap-8 h-[calc(100vh-80px)]">
      <div className="w-[300px] pr-4 border-r border-grey-4 flex flex-col">
        <div className="flex-1">
          <button
            type="button"
            className={classNames(
              'flex justify-start gap-4 px-4 items-center btn-secondary hover:opacity-75 w-full mt-3 mb-6 rounded-lg h-14 transition-opacity text-lg font-bold',
              { '!bg-grey-4': tab === 'active' }
            )}
            onClick={() => setSearch({ tab: 'active' })}
          >
            <img src={ActiveIcon} alt="profile" className="w-6" />
            Active Events
          </button>
          <button
            type="button"
            className={classNames(
              'flex justify-start gap-4 px-4 items-center btn-secondary hover:opacity-75 w-full mt-3 mb-6 rounded-lg h-14 transition-opacity text-lg font-bold',
              { '!bg-grey-4': tab === 'replace' }
            )}
            onClick={() => setSearch({ tab: 'replace' })}
          >
            <img src={CalendarIcon} alt="profile" className="w-6" />
            Replace Events
          </button>
          <button
            type="button"
            className={classNames(
              'flex justify-start gap-4 px-4 items-center btn-secondary hover:opacity-75 w-full mt-3 mb-6 rounded-lg h-14 transition-opacity text-lg font-bold',
              { '!bg-grey-4': tab === 'approval' }
            )}
            onClick={() => setSearch({ tab: 'approval' })}
          >
            <img src={ClockIcon} alt="profile" className="w-6" />
            Pending Events
          </button>
          <button
            type="button"
            className={classNames(
              'flex justify-start gap-4 px-4 items-center btn-secondary hover:opacity-75 w-full mt-3 mb-6 rounded-lg h-14 transition-opacity text-lg font-bold',
              { '!bg-grey-4': tab === 'completed' }
            )}
            onClick={() => setSearch({ tab: 'completed' })}
          >
            <img src={CompletedIcon} alt="profile" className="w-6" />
            Completed Events
          </button>
          <button
            type="button"
            className={classNames(
              'flex justify-start gap-4 px-4 items-center btn-secondary hover:opacity-75 w-full mt-3 mb-6 rounded-lg h-14 transition-opacity text-lg font-bold',
              { '!bg-grey-4': tab === 'partners' }
            )}
            onClick={() => setSearch({ tab: 'partners' })}
          >
            <img src={UserIcon} alt="profile" className="w-5" />
            Partners
          </button>
          <button
            type="button"
            className={classNames(
              'flex justify-start gap-4 px-4 items-center btn-secondary hover:opacity-75 w-full mt-3 mb-6 rounded-lg h-14 transition-opacity text-lg font-bold',
              { '!bg-grey-4': tab === 'recruit' }
            )}
            onClick={() => setSearch({ tab: 'recruit' })}
          >
            <img src={RecruitIcon} alt="recruit" className="w-5" />
            Recruitment
          </button>
        </div>
        <Link
          to="/invite-management"
          className="flex justify-start gap-4 px-4 items-center text-sm btn-secondary hover:opacity-75 w-full mt-3 mb-6 rounded-lg h-14"
        >
          <div className="flex items-center py-3">
            <p className="text-white text-lg ml-3 font-bold">Invite Lists</p>
          </div>
        </Link>
      </div>
      <div
        className={classNames('flex-1', {
          'overflow-hidden': tab === 'replace',
        })}
      >
        {tab === 'approval' && (
          <div className="flex gap-8 border-t border-grey-4">
            <div className="flex-1 border-r border-grey-4 h-[calc(100vh-80px)] overflow-y-auto">
              <div className="flex flex-col gap-4 pt-6 pb-12 max-w-7xl px-4 mx-auto text-grey-2">
                <div className="flex justify-end gap-4">
                  <Button
                    text="Refresh Events"
                    variant="tertiary"
                    onClick={fetchDrafts}
                  />
                  <Button
                    text="Generate More Events"
                    variant="primary"
                    onClick={handleGenerate}
                  />
                </div>
                {generateSuccess && (
                  <p className="text-center border border-success rounded-2xl text-white font-extrabold text-lg p-4">
                    Request Success:{' '}
                    <span className="font-normal">
                      Stephen will message you when done
                    </span>
                  </p>
                )}
                {approvalSuccess && (
                  <p className="text-center border border-success rounded-2xl text-white font-extrabold text-lg p-4">
                    Request Success:{' '}
                    <span className="font-normal">
                      Ops will receive a message when completed
                    </span>
                  </p>
                )}
                <p className="text-center text-warning">{error}</p>
                {renderData()}
              </div>
            </div>
            <div className="w-[300px] flex flex-col gap-4 pt-8 justify-start pb-4 h-[calc(100vh-80px)] overflow-y-auto">
              <h3 className=" text-2xl text-white font-extrabold">Stats</h3>
              <div className="flex-1">
                <Stat
                  label="All Games"
                  numOfGames={drafts.length}
                  avgScore={getAvgScore(drafts)}
                />
                {Object.keys(byMode).map((m) => (
                  <Stat
                    key={`mode_stat_${m}`}
                    label={m}
                    numOfGames={byMode[m].length}
                    avgScore={getAvgScore(byMode[m])}
                  />
                ))}
              </div>
              <div className="sticky bottom-0 flex flex-col gap-4 bg-black">
                <Button
                  className="!w-full"
                  rounded
                  text="Manually Add Event"
                  variant="tertiary"
                  large
                  onClick={() => handleNextStep()}
                />
                <Button
                  className="!w-full"
                  rounded
                  text="Approve Schedule"
                  variant="primary"
                  large
                  onClick={handleApproveAll}
                />
              </div>
            </div>
          </div>
        )}

        {tab === 'active' && (
          <div className="flex gap-8 border-t border-grey-4">
            <div className="flex-1 border-r border-grey-4 h-[calc(100vh-80px)] overflow-y-auto">
              <div className="p-4 max-w-7xl mx-auto">
                <Events endpoint="/admin/v1/events/active" completed={false} />
              </div>
            </div>
            <div className="w-[300px] flex flex-col gap-4 pt-8">
              <Button
                rounded
                text="Create Event"
                variant="primary"
                onClick={() => handleNextStep()}
              />
            </div>
          </div>
        )}

        {tab === 'completed' && (
          <div className="flex gap-8 border-t border-grey-4">
            <div className="flex-1 border-r border-grey-4 h-[calc(100vh-80px)] overflow-y-auto">
              <div className="p-4 max-w-7xl mx-auto">
                <Events endpoint="/admin/v1/events/completed" completed />
              </div>
            </div>
            <div className="w-[300px] flex flex-col gap-4 pt-8" />
          </div>
        )}
        {tab === 'partners' && <PartnerAvailability />}
        {tab === 'recruit' && <Recruitment />}
        {tab === 'replace' && <ReplaceEvents />}
      </div>
    </div>
  );
};

const Stat = ({ label, numOfGames, avgScore }) => (
  <div className="mb-4">
    <h4 className=" text-sm text-white font-extrabold mb-2">{label}</h4>
    <div className="border border-grey-4 rounded-2xl text-white">
      <p className="flex items-center justify-between text-xs py-2 px-4 border-b border-grey-4">
        <span>Number of Matches</span>
        <span className="font-extrabold text-sm">{numOfGames}</span>
      </p>
      <p className="flex items-center justify-between text-xs py-2 px-4">
        <span>Average Match Quality</span>
        <span
          className={`font-extrabold text-sm ${
            avgScore > 50 ? 'text-warning' : 'text-success'
          }`}
        >
          {numOfGames === 0 ? 0 : Math.round(avgScore)}
        </span>
      </p>
    </div>
  </div>
);

const Events = ({ endpoint, completed }) => {
  const [games, setGames] = useState([]);
  const [modes, setModes] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);

  const [events, setEvents] = useState([]);
  const [doneFetching, setDoneFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [playEvents, setPlayEvents] = useState([]);
  const [isLoadingPlay, setIsLoadingPlay] = useState(false);
  const [tab, setTab] = useState('Stakeable');
  const [doneFetchingPlay, setDoneFetchingPlay] = useState(false);
  const [playPage, setPlayPage] = useState(1);

  // const [search, setSearch] = useSearchParams();
  // const activeTab = String(search.get('activeTab') || '');

  const [algoliaId, setAlgoliaId] = useState(null);
  const [algoliaKey, setAlgoliaKey] = useState(null);

  const [results, setResults] = useState([]);
  const [value, setValue] = useState('');
  const debouncedVal = useDebounce(value);

  useEffect(() => {
    if (!debouncedVal) {
      return setResults([]);
    }
    if (algoliaId && algoliaKey) {
      const algolia = algoliasearch(algoliaId, algoliaKey);
      const index = algolia.initIndex('User');
      index
        .search(debouncedVal, {
          length: 24,
          offset: 0,
        })
        .then(({ hits }) => {
          setResults(hits);
        })
        .catch(() => setResults([]));
    }
  }, [algoliaId, algoliaKey, debouncedVal]);

  useEffect(() => {
    const getAlgolia = () =>
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/config`)
        .then(({ data }) => {
          setAlgoliaId(data.algolia_app_id);
          setAlgoliaKey(data.algolia_search_key);
        });
    const interval = setInterval(getAlgolia, ONE_HOUR);
    getAlgolia();
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/games/`)
      .then(({ data }) => {
        setGames(data.sort((a, b) => a.name.localeCompare(b.name)));
      });
  }, []);

  useEffect(() => {
    setModes([]);
    setPage(1);
    setSelectedMode(null);

    if (selectedGame) {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/game_modes?game_id=${selectedGame}`
        )
        .then(({ data }) => {
          setModes(data.sort((a, b) => a.title.localeCompare(b.title)));
        });
    }
  }, [selectedGame]);

  useEffect(() => {
    setDoneFetching(false);
    setIsLoading(true);

    let url = userId
      ? `${process.env.REACT_APP_API_URL}/admin/v1/events/active_by_user?user_id=${userId}&page_size=20&page=${page}`
      : `${process.env.REACT_APP_API_URL}${endpoint}?page_size=20&page=${page}`;
    if (selectedGame) {
      url += `&game_id=${selectedGame}`;
    }
    if (selectedMode) {
      url += `&game_mode_id=${selectedMode}`;
    }
    client
      .get(url)
      .then(({ data }) => {
        if (data.length < 20) {
          setDoneFetching(true);
        }
        if (page === 1) {
          setEvents(data);
        } else {
          setEvents((e) => [...e, ...data]);
        }
      })
      .catch((e) => {})
      .finally(() => setIsLoading(false));
  }, [page, selectedGame, selectedMode, userId]);

  useEffect(() => {
    setIsLoadingPlay(true);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/play_events?page_size=10&page=${playPage}`
      )
      .then(({ data }) => {
        if (data.length < 10) {
          setDoneFetchingPlay(true);
        }
        if (playPage === 1) {
          setPlayEvents(data);
        } else {
          setPlayEvents((e) => [...e, ...data]);
        }
      })
      .catch((e) => {})
      .finally(() => setIsLoadingPlay(false));
  }, [playPage]);

  const groupedEvents = useMemo(() => {
    if (!events.length) return {};
    const data = events.reduce((prev, curr, i) => {
      const d = completed
        ? new Date(curr.end_date)
        : new Date(curr.stakes_close_at);
      if (completed || differenceInMinutes(d, new Date()) < 0) {
        const formatted = completed ? format(d, 'eeee, MMMM do') : 'Live';
        return {
          ...prev,
          [`${completed ? i : 0}::${formatted}`]: [
            ...(prev[`${completed ? i : 0}::${formatted}`] || []),
            curr,
          ],
        };
      }
      if (differenceInMinutes(d, new Date()) <= 20) {
        return {
          ...prev,
          [`${0}::Starting Soon`]: [
            ...(prev[`${0}::Starting Soon`] || []),
            curr,
          ],
        };
      }

      if (i === 0) {
        return {
          ...prev,
          [`${0}::Up Next`]: [...(prev[`${0}::Up Next`] || []), curr],
        };
      }

      if (isToday(d)) {
        return {
          ...prev,
          [`${1}::Later Today`]: [...(prev[`${1}::Later Today`] || []), curr],
        };
      }

      if (isTomorrow(d)) {
        return {
          ...prev,
          [`${2}::Tomorrow`]: [...(prev[`${2}::Tomorrow`] || []), curr],
        };
      }

      if (isThisWeek(d)) {
        const formatted = format(d, 'eeee');
        return {
          ...prev,
          [`${d.getDay() + 10}::${formatted}`]: [
            ...(prev[`${d.getDay() + 10}::${formatted}`] || []),
            curr,
          ],
        };
      }

      const formatted = format(d, 'eeee, MMMM do');
      return {
        ...prev,
        [`${100}::${formatted}`]: [
          ...(prev[`${100}::${formatted}`] || []),
          curr,
        ],
      };
    }, {});

    return data;
  }, [events, completed]);

  const groupedPlayEvents = useMemo(() => {
    if (!playEvents.length) return {};
    const data = playEvents.reduce((prev, curr, i) => {
      const d = new Date(curr.start_date);
      if (completed || differenceInMinutes(d, new Date()) < 0) {
        const formatted = completed ? format(d, 'eeee, MMMM do') : 'Live';
        return {
          ...prev,
          [`${completed ? i : 0}::${formatted}`]: [
            ...(prev[`${completed ? i : 0}::${formatted}`] || []),
            curr,
          ],
        };
      }
      if (differenceInMinutes(d, new Date()) <= 20) {
        return {
          ...prev,
          [`${0}::Starting Soon`]: [
            ...(prev[`${0}::Starting Soon`] || []),
            curr,
          ],
        };
      }

      if (i === 0) {
        return {
          ...prev,
          [`${0}::Up Next`]: [...(prev[`${0}::Up Next`] || []), curr],
        };
      }

      if (isToday(d)) {
        return {
          ...prev,
          [`${1}::Later Today`]: [...(prev[`${1}::Later Today`] || []), curr],
        };
      }

      if (isTomorrow(d)) {
        return {
          ...prev,
          [`${2}::Tomorrow`]: [...(prev[`${2}::Tomorrow`] || []), curr],
        };
      }

      if (isThisWeek(d)) {
        const formatted = format(d, 'eeee');
        return {
          ...prev,
          [`${d.getDay() + 10}::${formatted}`]: [
            ...(prev[`${d.getDay() + 10}::${formatted}`] || []),
            curr,
          ],
        };
      }

      const formatted = format(d, 'eeee, MMMM do');
      return {
        ...prev,
        [`${100}::${formatted}`]: [
          ...(prev[`${100}::${formatted}`] || []),
          curr,
        ],
      };
    }, {});

    return data;
  }, [playEvents]);

  // useEffect(() => {
  //   if (!completed) {
  //     setSearch({
  //       tab: 'active',
  //       activeTab: Object.keys(groupedEvents)[0]?.split('::')[1],
  //     });
  //   }
  // }, [groupedEvents, completed]);

  return (
    <div>
      <div>
        {!completed && (
          <div className="overflow-auto mb-4 short-scroll">
            <TabGroup
              activeTab={tab}
              tabs={['Stakeable', 'Not Stakeable']}
              onChange={setTab}
            />
          </div>
        )}
      </div>
      {tab === 'Not Stakeable' ? (
        <>
          <div className="grid grid-cols-1 gap-4 mt-6 mb-12 text-grey-2">
            {Object.keys(groupedPlayEvents)
              .sort(
                (a, b) => Number(a.split('::')[0]) - Number(b.split('::')[0])
              )
              // .filter((key) =>
              //   completed ? true : key.split('::')[1] === activeTab
              // )
              .map((key, i) => (
                <div className="mb-16" key={key}>
                  <div className="flex items-center gap-4 mb-6">
                    <h2 className="font-extrabold text-white text-xl sm:text-3xl flex-1">
                      {key.split('::')[1]}
                    </h2>
                  </div>
                  {groupedPlayEvents[key].map((event) => (
                    <PlayEventCard
                      event={event}
                      key={`play_${event.id}`}
                      hasButtons
                    />
                  ))}
                </div>
              ))}
          </div>
          {!doneFetching && events.length > 0 && (
            <div className="flex items-center justify-center pb-8 mt-8">
              <Button
                variant="secondary"
                isLoading={isLoadingPlay}
                disabled={isLoadingPlay}
                text="Load More Events"
                onClick={() => setPlayPage(playPage + 1)}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="grid grid-cols-1 gap-4 mt-6 mb-12 text-grey-2">
            <div className="flex justify-end gap-2 items-center flex-wrap">
              <div className="relative">
                {userId ? (
                  <button
                    type="button"
                    className="bg-grey-3 px-4 py-1 rounded-full text-white text-sm "
                    onClick={() => setUserId(null)}
                  >
                    Filtering by User ID {userId}
                  </button>
                ) : (
                  <input
                    type="text"
                    placeholder="Filter By User"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className="py-2 text-sm  text-white bg-grey-4 focus:outline-none px-4 rounded-full min-w-[200px] font-normal"
                  />
                )}
                <div className="max-h-[180px] w-[300px] overflow-auto bg-black absolute right-0 top-full rounded-2xl">
                  {results.map((r) => (
                    <button
                      type="button"
                      onClick={() => {
                        setResults([]);
                        setValue('');
                        setUserId(r.id);
                      }}
                      key={`user_${r.id}`}
                      className={classNames(
                        'flex items-center justify-between px-4 py-3 w-full hover:bg-grey-4'
                      )}
                    >
                      <div className="flex items-center gap-4">
                        <img
                          src={r.profile_image_url}
                          alt={r.username}
                          height={24}
                          width={24}
                          className="object-cover rounded-full"
                          onError={(e) => {
                            // @ts-ignore
                            e.target.src =
                              'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                          }}
                        />
                        <p className="text-white font-extrabold text-sm">
                          {r.username}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
              <div>
                <select
                  aria-label="filter"
                  value={selectedGame}
                  onChange={(e) => setSelectedGame(e.target.value)}
                  className="py-2  text-sm text-white bg-grey-4 focus:outline-none px-4 rounded-full min-w-[200px] font-normal capitalize"
                >
                  <option value="">Filter By Game</option>
                  {games.map((g) => (
                    <option key={`game_${g.id}`} value={g.id}>
                      {g.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <select
                  aria-label="filter"
                  disabled={modes.length === 0}
                  value={selectedMode}
                  onChange={(e) => setSelectedMode(e.target.value)}
                  className="py-2  text-sm disabled:opacity-50 text-white bg-grey-4 focus:outline-none px-4 rounded-full min-w-[200px] font-normal capitalize"
                >
                  <option value="">Filter By Mode</option>
                  {modes.map((m) => (
                    <option key={`mode_${m.id}`} value={m.id}>
                      {m.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {Object.keys(groupedEvents).length === 0 && doneFetching && (
              <p className="border-2 border-dashed rounded-2xl border-grey-3 p-4 text-center">
                Please Refine Your Search
              </p>
            )}
            {Object.keys(groupedEvents)
              .sort(
                (a, b) => Number(a.split('::')[0]) - Number(b.split('::')[0])
              )
              // .filter((key) =>
              //   completed ? true : key.split('::')[1] === activeTab
              // )
              .map((key, i) => (
                <div className="mb-16" key={key}>
                  <div className="flex items-center gap-4 mb-6">
                    <h2 className="font-extrabold text-white text-xl sm:text-3xl flex-1">
                      {key.split('::')[1]}
                    </h2>
                  </div>
                  {groupedEvents[key].map((event) =>
                    completed ? (
                      <CompletedEvent
                        key={`active_${event.id}`}
                        event={event}
                      />
                    ) : (
                      <ActiveEvent key={`active_${event.id}`} event={event} />
                    )
                  )}
                </div>
              ))}
          </div>
          {!doneFetching && events.length > 0 && (
            <div className="flex items-center justify-center pb-8 mt-8">
              <Button
                variant="secondary"
                isLoading={isLoading}
                disabled={isLoading}
                text="Load More Events"
                onClick={() => setPage(page + 1)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const MatchCardWrap = ({ event, match, teams }) => {
  if (!teams) {
    return <Loader />;
  }
  return (
    <MatchCard
      tournament={event}
      match={match}
      teams={(teams || [])
        .map((t) => {
          const found = match.participants.find((p) => {
            const player = t.tournament_players.find(
              (tp) => tp.user_profile.id === p.user_id
            );
            return player;
          });

          return found
            ? {
                ...t,
                team_uuid: found.team_uuid,
              }
            : null;
        })
        .filter(Boolean)}
      forceScores
    />
  );
};

/**
 *
 * @param {event: Object} props
 * @returns
 */
const ActiveEvent = ({ event }) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(event.active || false);
  }, [event.active]);

  const handleToggle = async () => {
    if (confirm('Are You Sure?')) {
      const prev = checked;
      try {
        setChecked(!prev);
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${event.id}/${
            prev ? 'hide' : 'unhide'
          }`
        );
      } catch (e) {
        alert(e.response?.data?.message || 'Something went wrong');
        setChecked(prev);
      }
    }
  };

  return (
    <div className="bg-grey-4 rounded-3xl mb-8">
      <div
        className={classNames(
          'rounded-t-3xl overflow-hidden !ring-0 !outline-none   border-grey-3 block group border-b'
        )}
      >
        <div
          className={classNames('border-l-[16px] flex gap-4')}
          style={{ borderColor: event.play_info.game_mode_color }}
        >
          <div className="flex flex-1 shrink-0 gap-2 sm:gap-4 items-start p-4 pr-0 ">
            <img
              src={event.play_info.game_icon}
              alt="player"
              className="w-[32px] h-[32px] sm:h-[48px] sm:w-[48px] object-cover rounded-full mt-1 sm:mt-0 sm:inline-block hidden"
            />
            <div className="sm:w-auto w-full">
              <p className="uppercase text-grey-2 text-xs font-bold flex items-end gap-1 ">
                <img
                  src={event.play_info.game_icon}
                  alt="player"
                  className="w-[16px] h-[16px] object-cover  rounded-full mt-1 sm:hidden"
                />
                {event.play_info.game_name}
              </p>
              <h3 className="text-white font-extrabold uppercase  sm:text-lg flex items-center justify-between gap-4">
                <span className="flex-1">
                  {event.play_info.game_mode_title}
                </span>
                <img
                  src="/Icons/arrow-right.svg"
                  alt="icon"
                  className="h-[20px] w-[20px] rounded-full mr-4 group-hover:mr-3 transition-all ease-in-out sm:hidden"
                />
              </h3>
              <p className="uppercase text-grey-2 font-bold text-[10px] sm:text-xs">
                {event.play_info.subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="border-l border-r border-b border-grey-4 rounded-b-3xl pt-4">
        <div className="px-4 flex items-center justify-between pb-2">
          <p className="text-xs uppercase">
            {format(new Date(event.start_date), 'hh:mm aa · MMMM dd')}
          </p>
        </div>
        {event.teams.map((t) => (
          <Team2 key={t.roster.id} team={t} noStake />
        ))}
        <div className="flex items-center justify-between p-4 border-t mt-4 border-grey-3">
          {typeof event.active === 'boolean' && (
            <div className="flex flex-col  gap-1">
              <p className="text-xs">{checked ? 'Displayed' : 'Hidden'}</p>
              <Switch
                onClick={handleToggle}
                checked={checked}
                className={`${
                  checked ? 'bg-blue' : 'bg-grey-3'
                } relative inline-flex h-6 w-12 items-center rounded-full`}
              >
                <span
                  className={`${
                    checked ? 'translate-x-7' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          )}

          <div className="flex items-center gap-4">
            <Button
              variant="tertiary"
              text="Edit"
              rounded
              className="!flex-1 !bg-grey-3 !w-48"
              onClick={() =>
                window.open(`/godmode/tournament-management/${event.id}/manage`)
              }
            />
            <Button
              variant="primary"
              text="Overview"
              className="!flex-1"
              rounded
              onClick={() =>
                window.open(`/godmode/tournament-management/${event.id}`)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {event: Object} props
 * @returns
 */
const CompletedEvent = ({ event }) => {
  const [matches, setMatches] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [rulesOpen, setRulesOpen] = useState(false);

  const { rules, getRulesSet, gameMode, getGameMode } = useTournament();

  useEffect(() => {
    if (!modalOpen) {
      return;
    }

    client
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/tournaments/${event.id}/teams`
      )
      .catch((e) => {})
      .then(({ data }) => setTeams(data));
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${event.id}/completed_matches`
      )
      .then(({ data }) => {
        setMatches(data);
      })
      .catch((e) => {});
  }, [modalOpen]);

  useEffect(() => {
    if (!rulesOpen) {
      return;
    }

    if (!event.play_info.element_set_id) {
      return;
    }
    getRulesSet(event.play_info.element_set_id);
    if (rules !== undefined) {
      getGameMode(rules.game_mode_id);
    }
  }, [rules?.game_mode_id, rulesOpen]);

  return (
    <div className="bg-grey-4 rounded-3xl mb-8">
      <div
        className={classNames(
          'rounded-t-3xl overflow-hidden !ring-0 !outline-none   border-grey-3 block group border-b'
        )}
      >
        <div
          className={classNames('border-l-[16px] flex gap-4')}
          style={{ borderColor: event.play_info.game_mode_color }}
        >
          <div className="flex flex-1 shrink-0 gap-2 sm:gap-4 items-start p-4 pr-0 ">
            <img
              src={event.play_info.game_icon}
              alt="player"
              className="w-[32px] h-[32px] sm:h-[48px] sm:w-[48px] object-cover rounded-full mt-1 sm:mt-0 sm:inline-block hidden"
            />
            <div className="sm:w-auto w-full">
              <p className="uppercase text-grey-2 text-xs font-bold flex items-end gap-1 ">
                <img
                  src={event.play_info.game_icon}
                  alt="player"
                  className="w-[16px] h-[16px] object-cover  rounded-full mt-1 sm:hidden"
                />
                {event.play_info.game_name}
              </p>
              <h3 className="text-white font-extrabold uppercase  sm:text-lg flex items-center justify-between gap-4">
                <span className="flex-1">
                  {event.play_info.game_mode_title}
                </span>
                <img
                  src="/Icons/arrow-right.svg"
                  alt="icon"
                  className="h-[20px] w-[20px] rounded-full mr-4 group-hover:mr-3 transition-all ease-in-out sm:hidden"
                />
              </h3>
              <p className="uppercase text-grey-2 font-bold text-[10px] sm:text-xs">
                {event.play_info.subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="border-l border-r border-b border-grey-4 rounded-b-3xl pt-4">
        <div className="px-4 flex items-center justify-between pb-2">
          <p className="text-xs uppercase">
            {format(new Date(event.start_date), 'hh:mm aa · MMMM dd')}
          </p>
        </div>
        {event.teams.map((t) => (
          <Team2 key={t.roster.id} team={t} noStake showScores />
        ))}
        <div className="flex items-center justify-end p-4">
          <div className="flex items-center gap-4">
            <Button
              variant="grey"
              className="!bg-grey-3"
              text="Event Rules"
              rounded
              onClick={() => setRulesOpen(true)}
            />
            <Button
              variant="primary"
              text="Update Scores"
              rounded
              onClick={() => setModalOpen(true)}
            />
          </div>
        </div>
      </div>

      <BaseModal
        modalIsOpen={rulesOpen}
        closeModal={() => setRulesOpen(false)}
        header={event.play_info.game_mode_title}
        large
      >
        <div className="pb-8 px-4 sm:px-8 h-full overflow-auto sm:max-h-[80dvh]">
          {rules && gameMode ? (
            <div>
              <p className="text-grey-2 text-sm mt-1">{gameMode.description}</p>
              {/* {extendedRules.data?.length > 0 && (
                <button
                  onClick={() => setShowExtendedRules(true)}
                  className="flex items-center mt-3 border-grey-4 border rounded-xl p-4 !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-gold w-full text-left"
                >
                  <img
                    src="/Icons/rules-white.svg"
                    alt="rules-white"
                    className="w-8"
                  />
                  <div className="ml-3">
                    <h3 className="text-grey-2 text-xs">Extended Rules</h3>
                    <p className="text-white font-bold text-sm">
                      Click For Details
                    </p>
                  </div>
                </button>
              )} */}

              <div className="flex items-center mt-3 border-grey-4 border rounded-xl p-4">
                <img
                  src={rules.match_format.icon_url}
                  alt={rules.match_format.title}
                  className="w-8"
                />
                <div className="ml-3">
                  <h3 className="text-grey-2 text-xs">
                    {rules.match_format.title}
                  </h3>
                  <p className="text-white font-bold text-sm">
                    {rules.match_format.description}
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-3 border-grey-4 border rounded-xl p-4">
                <img
                  src={gameMode.icon_url}
                  alt={gameMode.name}
                  className="w-8"
                />
                <div className="ml-3">
                  <h3 className="text-grey-2 text-xs">Game Mode</h3>
                  <p className="text-white font-bold text-sm">
                    {gameMode.name}
                  </p>
                </div>
              </div>
              {rules.game_maps?.length > 0 && (
                <div className="flex items-start mt-3 border-grey-4 border rounded-xl p-4">
                  <img
                    src={rules.game_maps[0].icon_url}
                    alt={gameMode.name}
                    className="w-8"
                  />
                  <div className="ml-3">
                    <h3 className="text-grey-2 text-xs">
                      Round Map{rules.game_maps.length > 1 && 's'}
                    </h3>
                    <div>
                      {rules.game_maps.map((map, i) => (
                        <p
                          className="text-white font-bold text-sm"
                          // eslint-disable-next-line react/no-array-index-key
                          key={`map_${map.id}_${i}`}
                        >
                          #{i + 1} {map.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {rules.rule_types.map((r) => (
                <div
                  key={r.id}
                  className="flex items-center mt-3 border-grey-4 border rounded-xl p-4"
                >
                  <img src={r.icon_url} alt={r.title} className="w-8" />
                  <div className="ml-3">
                    <h3 className="text-grey-2 text-xs">{r.title}</h3>
                    <p className="text-white font-bold text-sm">
                      {r.rule_option.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Loader white />
          )}
        </div>
      </BaseModal>
      <BaseModal
        modalIsOpen={modalOpen}
        large
        closeModal={() => setModalOpen(false)}
        header={`${event.play_info.game_mode_title} Matches`}
        height="75vh"
      >
        <div className="grid grid-cols-1 gap-4 py-4 sm:px-8 px-4">
          {matches.map((m) => (
            <MatchCardWrap event={event} match={m} teams={teams} />
          ))}
        </div>
      </BaseModal>
    </div>
  );
};

export default TournameList;
