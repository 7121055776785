/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import Header from './Header/Header';
import SlideOutNav from './SlideOutNav/SlideOutNav';

const Layout = ({ children, showHeaderElements, userId, className }) => {
  const [searchIsOpen, setIsSearchOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  const { tournamentId } = useParams();

  const tournamentManagmentRoute = location.pathname.replace(
    `/${tournamentId}`,
    ''
  );

  return (
    <main className="flex">
      <div className="flex flex-col w-full">
        <Header
          setIsNavOpen={setIsNavOpen}
          showHeaderElements={showHeaderElements}
          setIsSearchOpen={setIsSearchOpen}
          isNavOpen={isNavOpen}
          searchIsOpen={searchIsOpen}
          tournamentManagmentRoute={tournamentManagmentRoute}
          userId={userId}
        />
        <section
          className={cn(
            'px-4 md:px-6 w-full pt-20 z-0 h-screen overflow-auto',
            className
          )}
        >
          <SlideOutNav
            tournamentManagmentRoute={tournamentManagmentRoute}
            userId={userId}
            isNavOpen={isNavOpen}
            closeNav={() => setIsNavOpen(false)}
          />
          {children}
        </section>
      </div>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showHeaderElements: PropTypes.bool,
  userId: PropTypes.number,
};

export default Layout;
