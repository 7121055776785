/* eslint-disable no-alert */
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/client';

const walletContext = createContext({
  transactions: [],
  wallet: {},
  loading: false,
});

const useWallet = () => {
  const [transactions, setTransactions] = useState([]);
  const [wallet, setWallet] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isMore, setIsMore] = useState(true);
  const [isPayPalLoading, setIsPayPalLoading] = useState(false);
  const [isRefundLoading, setIsRefundLoading] = useState(false);
  const [PayPalLink, setPayPalLink] = useState('');
  const [refundError, setRefundError] = useState(false);
  const [refundErrorMessage, setRefundErrorMessage] = useState('');

  const getTransactions = async (userId, types, pageFromClient) => {
    setLoading(true);

    const { data } = await client.get(
      `${
        process.env.REACT_APP_API_URL
      }/admin/v1/users/${userId}/transactions?page=${
        pageFromClient === undefined ? page : pageFromClient
      }&page_size=20&${types || ''}`
    );

    if (data.length === 0 || data.length < 20) {
      setIsMore(false);
    }

    setTransactions(data);
    setLoading(false);
  };

  const paginateTransactions = async (userId, types) => {
    if (isMore) {
      setLoading(true);
      const { data } = await client.get(
        `${
          process.env.REACT_APP_API_URL
        }/admin/v1/users/${userId}/transactions?page=${page + 1}&page_size=20&${
          types || ''
        }`
      );

      if (data.length === 0 || data.length < 20) {
        setIsMore(false);
      }

      setPage(page + 1);
      setTransactions([...transactions, ...data]);
    }
    setLoading(false);
  };

  const getWallet = async (userId) => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/wallet`
    );

    setWallet(data);
    setLoading(false);
  };

  const getPaypalURL = async (transactionId) => {
    try {
      setIsPayPalLoading(true);
      const { data } = await client.get(
        `${process.env.REACT_APP_API_URL}/admin/v1/transactions/${transactionId}/paypal_url`
      );

      setPayPalLink(data);
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsPayPalLoading(false);
    }
  };

  const refundStake = async (transactionId) => {
    setIsRefundLoading(true);

    try {
      const { data } = await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/transactions/${transactionId}/refund_stake`
      );

      const transactionIndex = transactions.findIndex(
        (transaction) => transaction.id === data.id
      );

      if (transactionIndex !== -1) {
        transactions[transactionIndex] = data;
        setTransactions(transactions);
        setIsRefundLoading(false);
      }
    } catch (error) {
      setRefundError(true);
      setRefundErrorMessage(error.response.data.message);
    }
  };

  const resetRefundError = () => {
    setRefundError(false);
    setRefundErrorMessage('');
  };

  return {
    loading,
    transactions,
    wallet,
    getTransactions,
    getWallet,
    paginateTransactions,
    refundStake,
    setPage,
    PayPalLink,
    getPaypalURL,
    isPayPalLoading,
    isRefundLoading,
    refundError,
    refundErrorMessage,
    resetRefundError,
    setTransactions,
  };
};

export function WalletProvider({ children }) {
  const wallet = useWallet();

  return (
    <walletContext.Provider value={wallet}>{children}</walletContext.Provider>
  );
}

export default function WalletConsumer() {
  return React.useContext(walletContext);
}

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
