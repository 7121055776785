import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Modal from '../../components/BaseModal/BaseModal';
import InboxLayout from './InboxLayout.tsx';

const OpenInbox = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { userId } = useParams();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        onClick={openModal}
        role="button"
        className="flex justify-between items-center bg-zinc-900 rounded-md text-white text-lg font-extrabold p-4 cursor-pointer"
      >
        <p>Preview Inbox</p>
      </div>
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        header=""
        backgroundColor="bg-black"
        xl
        height="90vh"
      >
        <InboxLayout userId={userId} />
      </Modal>
    </>
  );
};

export default OpenInbox;
