/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';

import Logo from '../../../assets/Images/god-mode-logo.svg';
import SearchIcon from '../../../assets/Icons/search-icon.svg';
import Button from '../../Buttons/Button/Button';

import UserIcon from '../../../assets/Icons/user-icon.svg';
import TournamentIcon from '../../../assets/Icons/tournamnet-icon.svg';
import DisputeIcon from '../../../assets/Icons/dispute-icon.svg';
import MarketingIcon from '../../../assets/Icons/marketing-icon.svg';

import useAuth from '../../../contexts/AuthContext/AuthContext';
import useUserSearch from '../../../contexts/UserContext/UserContext';

const Header = ({
  showHeaderElements,
  searchIsOpen,
  setIsSearchOpen,
  userId,
  tournamentManagmentRoute,
  setIsNavOpen,
  isNavOpen,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { god } = useAuth();

  const { logout } = useAuth();
  const { getUsers, users } = useUserSearch();

  const debouncedUserSearch = useDebouncedCallback(
    (value) => {
      if (value) {
        getUsers(value);
        setIsSearchOpen(true);
      } else {
        setIsSearchOpen(false);
      }
    },
    // delay in ms
    1000
  );

  const handleSearchChange = (e) => {
    const {
      target: { value },
    } = e;
    setSearchValue(value);
    debouncedUserSearch(value);
  };

  const defaultImage =
    'https://mobile-assets.1v1me.com/users/v1/default_profile.png';

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  return (
    <div className={cn('fixed w-full bg-black z-10')}>
      <div className="flex justify-between pr-3 sm:px-6 mt-1">
        <div className="flex items-center w-full lg:w-9/12 xl:w-5/12">
          <Link
            to={`/godmode/users/${god?.id}`}
            className="flex header pt-3 mr-6"
          >
            <img src={Logo} alt="google icon" className="hidden lg:block" />
            <h1 className="text-white italic font-bold text-4xl ml-3 leading-none">
              GODMODE
              <span className="block text-sm -mt-1">
                {window.location.hostname === 'support-portal.1v1me.com'
                  ? 'Production'
                  : 'Staging'}
              </span>
            </h1>
          </Link>
          {showHeaderElements && (
            <div className="flex items-center justify-center grow">
              <div className="relative text-gray-600 focus-within:text-gray-400 w-full">
                <div className="z-50 inset-x-0" style={{ top: '-20px' }}>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button
                      type="submit"
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <img src={SearchIcon} alt="search icon" className="w-5" />
                    </button>
                  </span>
                  <input
                    className={cn(
                      'py-3 text-sm text-white bg-grey-4 focus:outline-none text-center w-full',
                      { 'rounded-full': searchIsOpen === false },
                      {
                        'rounded-t-md':
                          users.length > 0 && searchIsOpen === true,
                      }
                    )}
                    placeholder="Search"
                    onChange={handleSearchChange}
                    onClick={() => setSearchValue('')}
                    value={searchValue}
                  />
                </div>
                {users.length > 0 && searchIsOpen === true ? (
                  <>
                    <div
                      className="z-50 block absolute w-full bg-zinc-800 rounded-b-lg border-t border-zinc-900 max-h-[80vh] overflow-auto"
                      style={{ top: '45px' }}
                    >
                      {users.map(
                        ({
                          user: { profile_image_url, username, status, id },
                        }) => (
                          <a
                            onClick={() => debouncedUserSearch('')}
                            href={`/godmode/users/${id}`}
                            key={id}
                            className="flex justify-between items-center hover:bg-zinc-700 px-3 py-4"
                          >
                            <div className="flex items-center mt-3">
                              <img
                                src={profile_image_url}
                                alt="profile"
                                className="rounded-full w-8"
                                onError={handleImageError}
                              />
                              <p className="text-white text-lg ml-3">
                                {username}
                              </p>
                            </div>
                            <p
                              className={cn('uppercase text-xs mt-3', {
                                'text-primary': status === 'disabled',
                              })}
                            >
                              {status}
                            </p>
                          </a>
                        )
                      )}
                    </div>
                    <div
                      role="button"
                      onClick={() => setIsSearchOpen(false)}
                      className="ReactModal__Overlay ReactModal__Overlay--after-open modal-overlay"
                    />
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
        {showHeaderElements && (
          <>
            <div className="hidden xl:flex">
              <NavLink
                to="/godmode/moderation"
                className={(navData) =>
                  navData.isActive ? 'block bg-grey-3' : 'block hover:bg-grey-3'
                }
              >
                <span className="flex items-center pt-4 px-4 text-base font-normal">
                  <img src={UserIcon} alt="user-icon" className="w-5 mr-3" />
                  <p className="text-white text-sm uppercase">Moderation</p>
                </span>
              </NavLink>
              <NavLink
                to="/godmode/tournaments?tab=active"
                className={(navData) =>
                  navData.isActive ||
                  tournamentManagmentRoute ===
                    '/godmode/tournament-management' ||
                  tournamentManagmentRoute === '/invite-management'
                    ? 'block bg-grey-3'
                    : 'block hover:bg-grey-3'
                }
              >
                <span className="flex items-center pt-4 px-4 text-base font-normal">
                  <img
                    src={TournamentIcon}
                    alt="user-icon"
                    className="w-5 mr-3"
                  />
                  <p className="text-white uppercase text-sm">Events</p>
                </span>
              </NavLink>
              <a
                href="/godmode/disputes"
                className={
                  tournamentManagmentRoute === '/godmode/disputes'
                    ? 'block bg-grey-3 mr-3'
                    : 'block hover:bg-grey-3 mr-3'
                }
              >
                <span className="flex items-center pt-4 px-4 text-base font-normal">
                  <img src={DisputeIcon} alt="user-icon" className="w-5 mr-3" />
                  <p className="text-white uppercase text-sm">Disputes</p>
                </span>
              </a>
              <NavLink
                to="/marketing"
                className={(navData) =>
                  navData.isActive || tournamentManagmentRoute === '/marketing'
                    ? 'block bg-grey-3 mr-3'
                    : 'block hover:bg-grey-3 mr-3'
                }
              >
                <span className="flex items-center pt-4 px-4 text-base font-normal">
                  <img
                    src={MarketingIcon}
                    alt="user-icon"
                    className="w-5 mr-3"
                  />
                  <p className="text-white uppercase text-sm">Marketing</p>
                </span>
              </NavLink>
              <div className="ml-6 md:ml-0 mt-2">
                <Button
                  variant="tertiary"
                  text="Sign Out"
                  onClick={logout}
                  rounded
                />
              </div>
            </div>
            <div className="w-8 mt-2 xl:hidden ml-3">
              {isNavOpen ? (
                <div
                  className="w-8"
                  onClick={() => setIsNavOpen(false)}
                  role="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke=" #FFF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  className=""
                  onClick={() => setIsNavOpen(true)}
                  role="button"
                >
                  <div className="space-y-2">
                    <div className="w-8 h-0.5 bg-white" />
                    <div className="w-8 h-0.5 bg-white" />
                    <div className="w-8 h-0.5 bg-white" />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  showHeaderElements: PropTypes.bool.isRequired,
  searchIsOpen: PropTypes.bool,
  setIsSearchOpen: PropTypes.func,
  userId: PropTypes.number,
  tournamentManagmentRoute: PropTypes.string,
  setIsNavOpen: PropTypes.func,
  isNavOpen: PropTypes.bool,
};

export default Header;
