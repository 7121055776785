/* eslint-disable no-alert */
/* eslint-disable consistent-return */
import React, { Fragment, useEffect, useState } from 'react';
import format from 'date-fns/format';
import classNames from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import PaypalIcon from '../../assets/Icons/Paypal-Icon.svg';
import convertToDollarAmount from '../../utils/convertToDollar';

import Button from '../../components/Buttons/Button/Button';

import MoreIcon from '../../assets/Icons/more.svg';
import client from '../../services/client';
import BaseModal from '../../components/BaseModal/BaseModal';
import Loader from '../../components/Loader/Loader';
import HoldButton from '../../components/HoldButton';

const TransactionCard = ({
  id,
  state,
  date,
  image,
  title,
  amount,
  type,
  directionType,
  purseContext,
  handleGetPayPalURL,
  setRefundTransactionModalOpen,
  setTransactionToRefund,
  onRefund,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [transactionData, setTransactionData] = useState(null);

  useEffect(() => {
    if (id && (type === 'withdrawal' || type === 'deposit') && showModal) {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/transactions/${id}/info`
        )
        .then(({ data }) => setTransactionData(data))
        .catch(() => {});
    }
  }, [id, type, showModal]);
  const getSubtitle = () => {
    if (state === 'return') return 'Returned';
    if (state === 'cancelled') return 'Cancelled';
    if (state === 'failed') return 'Failed';
    if (state === 'pending') return 'Pending';
  };

  const handleRefund = async () => {
    try {
      setLoadingRefund(true);
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/transactions/${id}/refund_deposit`
      );
      setShowRefundModal(false);
      onRefund(id);
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoadingRefund(false);
    }
  };

  return (
    <div className="flex items-center justify-between mb-4 md:mb-8">
      <div className="flex items-center w-9/12">
        <div className="h-10 md:h-14 w-10 md:w-14 flex items-center justify-center rounded-full bg-grey-4 mr-4">
          <img
            src={image}
            alt="deposit"
            className="h-10 md:h-14 w-10 md:w-14"
          />
        </div>
        <p className="md:text-2xl text-white">
          <span className="flex items-center">
            {title}
            {(type === 'withdrawal' || type === 'deposit') && (
              <Button
                variant="secondary"
                text=""
                image={PaypalIcon}
                imageClass="w-6"
                className=" mr-2"
                onClick={() => setShowModal(true)}
              />
            )}
          </span>
          <span className="block text-xs md:text-base text-grey-2">
            {format(new Date(date), 'MMMM do, yyyy - hh:mm aa')}
          </span>
        </p>
      </div>
      <div className="flex">
        <div>
          <p
            className={classNames('md:text-2xl text-white text-right', {
              'text-grey-2': state === 'pending',
            })}
          >
            {directionType === 'debit' ? '-' : '+'}
            {convertToDollarAmount(amount, true)}
          </p>
          <p
            className={classNames('text-base uppercase text-right', {
              'text-warning':
                state === 'return' ||
                state === 'failed' ||
                state === 'cancelled',
              'text-grey-2': state === 'pending',
            })}
          >
            {getSubtitle()}
          </p>
        </div>
        <Popover className="relative">
          {(purseContext === 'stake' &&
            state !== 'return' &&
            type !== 'pursepayout') ||
          type === 'withdrawal' ||
          type === 'deposit' ? (
            <Popover.Button>
              <img src={MoreIcon} alt="Show more" className="ml-3" />
            </Popover.Button>
          ) : (
            <div className="w-6 ml-1" />
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-1 top-4 z-10 w-64">
              {title === 'Deposit' && (
                <>
                  <Button
                    variant="tertiary"
                    text="View On Paypal"
                    className="w-full justify-start text-left uppercase"
                    onClick={() => handleGetPayPalURL(id)}
                  />

                  <Button
                    variant="tertiary"
                    text="Refund Transaction"
                    className="w-full justify-start text-left uppercase"
                    onClick={() => setShowRefundModal(true)}
                  />
                </>
              )}
              {title === 'Withdrawal' && state !== 'failed' && (
                <Button
                  variant="tertiary"
                  text="View On Paypal"
                  className="w-full justify-start text-left uppercase"
                  onClick={() => handleGetPayPalURL(id)}
                />
              )}
              {purseContext === 'stake' && (
                <Button
                  variant="tertiary"
                  text="Refund Transaction"
                  className="w-full justify-start text-left uppercase"
                  onClick={() => {
                    setRefundTransactionModalOpen(true);
                    setTransactionToRefund(id);
                  }}
                />
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
        <BaseModal
          header="Refund Transaction"
          modalIsOpen={showRefundModal}
          closeModal={() => setShowRefundModal(false)}
          height="auto"
        >
          <p className="text-grey-2 mb-8">
            Are you sure you want to refund this transaction of{' '}
            <b className="text-white">{convertToDollarAmount(amount, true)}</b>
          </p>
          <div className="mb-8">
            <HoldButton
              text="Hold To Confirm Refund"
              timeout={200}
              variant="primary"
              onConfirm={handleRefund}
              disabled={loadingRefund}
            />
          </div>
        </BaseModal>
        <BaseModal
          header="Transaction Data"
          modalIsOpen={showModal}
          large
          closeModal={() => setShowModal(false)}
        >
          <div className="px-8 pt-2">
            {transactionData ? (
              <>
                <h3 className="text-white font-extrabold text-xl">
                  {transactionData.title}
                </h3>
                <p className="mb-4 text-grey-2">
                  {transactionData.description}
                </p>
              </>
            ) : (
              ''
            )}
            {transactionData ? (
              <pre className="rounded-2xl bg-grey-4/50 text-white p-4 overflow-auto text-sm border border-grey-3">
                {JSON.stringify(
                  transactionData.payload || 'Loading...',
                  null,
                  2
                )}
              </pre>
            ) : (
              <Loader />
            )}
          </div>
        </BaseModal>
      </div>
    </div>
  );
};

export default TransactionCard;
