/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Switch } from '@headlessui/react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Button from '../../../components/Buttons/Button/Button';
import TabGroup from '../../../components/TabGroup.tsx';
import Loader from '../../../components/Loader/Loader';
import client from '../../../services/client';
import { CreateMatchRules, GameMode, FAQType } from '../../../types';
import RulesImage from '../../../assets/Icons/rules-white.svg';
import PrizesImage from '../../../assets/Icons/prizes.svg';
import Carousel from '../../../components/Carousel.tsx';

const ModeConfirm: FC<{
  editing?: boolean;
  onConfirm?: () => void;
  onUpdate: (format: CreateMatchRules) => void;
  modeId: number;
  rules: CreateMatchRules;
}> = ({ onConfirm, onUpdate, rules, modeId, editing }) => {
  const [gameMode, setMode] = useState<GameMode | null>(null);
  const [errorMode, setErrorMode] = useState(false);
  const [loadingMode, setLoadingMode] = useState(false);

  const [faqs, setfaqs] = useState<FAQType[]>([]);
  const [errorFaqs, setErrorFaqs] = useState(false);
  const [loadingFaqs, setLoadingFaqs] = useState(false);

  const [isEditingMap, setIsEditingMap] = useState(false);
  const [isEditingFormat, setIsEditingFormat] = useState(false);
  const [isEditingRule, setIsEditingRule] = useState(false);
  const [ruleToEdit, setRuleToEdit] = useState<number | null>(null);

  const [showRules, setShowRules] = useState(false);

  useEffect(() => {
    const fetchMode = async () => {
      try {
        setLoadingMode(true);
        setErrorMode(false);
        const { data } = await client.get(
          `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${modeId}`
        );
        const f =
          data && data.match_formats.length ? data.match_formats[0] : null;
        const defaults = {
          maps:
            data && data.game_maps.length
              ? f
                ? new Array(f.num_of_maps).fill(data.game_maps[0].id)
                : [data.game_maps[0].id]
              : [],
          format: f?.id || null,
          ruleIds:
            data && data.rule_types.length
              ? data.rule_types.reduce((prev, curr) => {
                  const val =
                    curr.default_rule_option_id ||
                    curr.rule_options[0]?.id ||
                    null;
                  if (val) {
                    return {
                      ...prev,
                      [curr.id]:
                        curr.default_rule_option_id ||
                        curr.rule_options[0]?.id ||
                        null,
                    };
                  }
                  return { ...prev };
                }, {})
              : [],
        };
        setMode(data);
        if (!editing) {
          onUpdate(defaults);
        }
      } catch (e) {
        setErrorMode(true);
      } finally {
        setLoadingMode(false);
      }
    };
    if (modeId) {
      fetchMode();
    }
  }, [modeId, onUpdate]);

  useEffect(() => {
    const getExtended = async () => {
      if (gameMode && gameMode.wager_extended_rules_id) {
        try {
          setLoadingFaqs(true);
          const { data } = await client.get(
            `${process.env.REACT_APP_API_URL}/api/v1/faq/${gameMode.wager_extended_rules_id}`
          );
          setfaqs(data);
        } catch (e) {
          setErrorFaqs(true);
        } finally {
          setLoadingFaqs(false);
        }
      } else {
        setfaqs([]);
      }
    };
    getExtended();
  }, [gameMode]);

  useEffect(() => {
    setIsEditingMap(false);
  }, [rules.maps]);

  useEffect(() => {
    setIsEditingFormat(false);
  }, [rules.format]);

  useEffect(() => {
    setIsEditingRule(false);
    setTimeout(() => {
      setRuleToEdit(null);
    }, 300);
  }, [rules.ruleIds]);

  const format = useMemo(() => {
    if (!rules.format || !gameMode) return null;
    return gameMode.match_formats.find((f) => f.id === rules.format);
  }, [gameMode, rules.format]);

  const mapNames = useMemo(() => {
    if (!gameMode || !rules.maps?.length) return '';
    return rules.maps
      .map((m, i, arr) => {
        const name = gameMode.game_maps.find((map) => map.id === m)?.name;
        return `${arr.length > 1 ? `#${i + 1} ` : ''}${name}`;
      })
      .join('\n');
  }, [gameMode, rules.maps]);

  const handleSubmit = () => {
    onConfirm?.();
  };

  return (
    <div className="overflow-y-auto no-scrollbar transition-all duration-300 ease-in-out translate-x-0 fade-in-fast pb-16">
      {errorMode && (
        <p className="text-warning text-center">
          There was an issue fecthing the game data
        </p>
      )}
      {loadingMode && (
        <div className="py-12">
          <Loader />
        </div>
      )}
      {gameMode && !loadingMode && (
        <div className="flex gap-16">
          {/* {numOfRounds > 1 && (
            <div className="w-[300px]">
              <h3 className="text-3xl font-bold text-white mb-8">
                Select Rules
              </h3>
              <div className="flex items-center justify-between gap-2">
                <span className="text-white text-lg font-bold">
                  Apply To All Rounds
                </span>
                <Switch
                  checked
                  onChange={console.log}
                  className={`${
                    true ? 'bg-blue' : 'bg-grey-3'
                  } relative inline-flex h-8 w-16 items-center rounded-full`}
                >
                  <span
                    className={`${
                      true ? 'translate-x-9' : 'translate-x-1'
                    } inline-block h-6 w-6 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
            </div>
          )} */}
          <div className="flex-1">
            <h3 className="text-2xl font-bold text-white mb-4">
              {gameMode.title}
            </h3>
            <p className="mb-8 text-grey-2">{gameMode.description}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-16">
              {format && (
                <RuleCard
                  image={format.icon_url}
                  title={format.title}
                  subtitle={`${format.num_of_winning_rounds} of ${format.num_of_rounds}`}
                  onEdit={() => setIsEditingFormat(true)}
                />
              )}
              {format && gameMode?.game_maps.length > 0 && (
                <RuleCard
                  image={gameMode?.game_maps[0].icon_url}
                  title={`Map${format.num_of_maps > 1 ? 's' : ''}`}
                  subtitle={mapNames}
                  onEdit={!onUpdate ? undefined : () => setIsEditingMap(true)}
                />
              )}
              {gameMode.rule_types.map((t) => (
                <RuleCard
                  key={`rule_${t.id}`}
                  image={t.icon_url}
                  title={t.title}
                  subtitle={
                    t.rule_options.find((o) => o.id === rules.ruleIds[t.id])
                      ?.name || String(rules.ruleIds[t.id])
                  }
                  onEdit={
                    t.rule_options.length > 1
                      ? () => {
                          setRuleToEdit(t.id);
                          setIsEditingRule(true);
                        }
                      : undefined
                  }
                />
              ))}
              {gameMode?.wager_extended_rules_id && faqs.length > 0 && (
                <RuleCard
                  image={RulesImage}
                  title="Extended Rules"
                  subtitle="Click For Details"
                  onClick={() => setShowRules(true)}
                />
              )}
            </div>
            {onConfirm && (
              <div className="flex justify-center">
                <Button
                  rounded
                  text="Accept Rules"
                  variant="primary"
                  className="!w-full max-w-lg"
                  onClick={handleSubmit}
                />
              </div>
            )}
            <BaseModal
              modalIsOpen={showRules}
              large
              closeModal={() => setShowRules(false)}
              header="Extended Rules"
              fullWidth={false}
            >
              <div className=" px-4 sm:px-8 pb-12">
                <p className="mb-8 text-grey-2">
                  These platform rules apply to all 1v1Me matches
                </p>
                {faqs.length ? (
                  <div>
                    {faqs.map((section) => (
                      <div key={`rule_section_${section.id}`} className="mb-8">
                        <p className="uppercase text-grey-2">{section.title}</p>
                        <Accordion allowZeroExpanded>
                          {section.items.map((item) => (
                            <AccordionItem
                              key={`rule_${item.id}`}
                              className="border border-grey-3 rounded-md px-4 py-4 mt-3"
                            >
                              <AccordionItemHeading>
                                <AccordionItemButton className="text-white font-bold relative">
                                  <p>{item.title}</p>
                                  <div
                                    className="accordion__arrow"
                                    role="presentation"
                                  />
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel className="mt-2 text-grey-2 tournament-rule-description">
                                {item.description}
                              </AccordionItemPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </BaseModal>

            <EditMap
              maps={gameMode?.game_maps || []}
              mapAmount={format?.num_of_maps || 1}
              selectedMaps={rules.maps}
              isOpen={isEditingMap}
              onClose={() => setIsEditingMap(false)}
              onSubmit={(maps) => onUpdate({ ...rules, maps })}
            />

            <BaseModal
              modalIsOpen={isEditingFormat}
              large
              closeModal={() => setIsEditingFormat(false)}
              header="Specify Rounds"
              fullWidth={false}
            >
              <div className=" px-4 sm:px-8 pb-12">
                <p className="mb-8">Select how many rounds you want to play</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {gameMode.match_formats.map((f) => (
                    <Button
                      key={`format_${f.id}`}
                      text={`${f.title} ${f.num_of_winning_rounds} of ${f.num_of_rounds}`}
                      variant={f.id === rules.format ? 'primary' : 'grey'}
                      className="!text-left !justify-start"
                      onClick={() => onUpdate({ ...rules, format: f.id })}
                    />
                  ))}
                </div>
              </div>
            </BaseModal>

            <BaseModal
              modalIsOpen={isEditingRule}
              large
              closeModal={() => {
                setIsEditingRule(false);
                setTimeout(() => {
                  setRuleToEdit(null);
                }, 300);
              }}
              header={
                gameMode.rule_types.find((t) => t.id === ruleToEdit)?.title
              }
              fullWidth={false}
            >
              <div className=" px-4 sm:px-8 pt-4 pb-12">
                <div className="flex flex-col gap-4 flex-wrap">
                  {gameMode.rule_types
                    .find((t) => t.id === ruleToEdit)
                    ?.rule_options.map((rule) => (
                      <Button
                        key={`rule_${rule.id}`}
                        text={rule.name}
                        variant={
                          ruleToEdit && rules.ruleIds[ruleToEdit] === rule.id
                            ? 'primary'
                            : 'grey'
                        }
                        className="!text-left !justify-start !truncate"
                        onClick={() =>
                          ruleToEdit
                            ? onUpdate({
                                ...rules,
                                ruleIds: {
                                  ...rules.ruleIds,
                                  [ruleToEdit]: rule.id,
                                },
                              })
                            : () => {}
                        }
                      />
                    ))}
                </div>
              </div>
            </BaseModal>
          </div>
        </div>
      )}
    </div>
  );
};

export const RuleCard: FC<{
  image?: string;
  title: string;
  subtitle: string;
  onEdit?: () => void;
  onClick?: () => void;
}> = ({ image, title, subtitle, onEdit, onClick }) => (
  <div
    role="button"
    className={classNames(
      'flex justify-between gap-2 border border-grey-4 rounded-xl overflow-hidden',
      { 'cursor-pointer': !!onClick, 'cursor-default': !onClick }
    )}
    onClick={() => onClick?.()}
  >
    <div className="flex items-center gap-4 p-4 flex-1">
      {image && (
        <img
          src={image}
          height={48}
          width={48}
          className="object-contain saturate-0"
          alt={title}
        />
      )}
      <div>
        <p className="uppercase text-sm text-grey-2">{title}</p>
        <p className="font-semibold text-white text-lg">
          <pre className="font-sans">{subtitle}</pre>
        </p>
      </div>
    </div>

    {onEdit && (
      <Button
        variant="secondary"
        className="!text-blue"
        onClick={onEdit}
        text="Edit"
      />
    )}
  </div>
);

const EditMap: FC<{
  onClose: () => void;
  isOpen: boolean;
  mapAmount: number;
  maps: GameMode['game_maps'];
  selectedMaps: number[];
  onSubmit: (mapIds: number[]) => void;
}> = ({ onClose, isOpen, mapAmount, maps, selectedMaps: sMaps, onSubmit }) => {
  const [selectedMaps, setSelectedMaps] = useState<number[]>(sMaps);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setSelectedMaps(sMaps);
    setPage(0);
  }, [sMaps]);

  const handleToggleMap = (mapId: number, index: number) => {
    const newMaps = [...selectedMaps];
    newMaps[index] = mapId;
    setSelectedMaps(newMaps);
  };

  const pages = useMemo(
    () => new Array(mapAmount).fill(null).map((p, i) => `Round ${i + 1}`),
    [mapAmount]
  );

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header={`Select Map${mapAmount > 1 ? 's' : ''}`}
      large
      fullWidth={false}
    >
      <div className=" px-4 sm:px-8 pb-12">
        <p className="mb-8">
          Select the map{mapAmount > 1 ? 's' : ''} you want to play on
        </p>
        {mapAmount > 1 && (
          <div className="mb-4">
            <TabGroup
              tabs={pages.map((p) => p)}
              onChange={(name) => setPage(pages.indexOf(name))}
              activeTab={pages[page]}
            />
          </div>
        )}

        <Carousel
          unknownHeight
          currentPage={page}
          items={pages.map((p, i) => (
            <div
              key={`map_page_${p}`}
              className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8"
            >
              {maps.map((map) => (
                <Button
                  key={`map_${map.id}`}
                  text={map.name}
                  variant={selectedMaps[i] === map.id ? 'primary' : 'grey'}
                  className="!text-left !justify-start"
                  large
                  onClick={() => handleToggleMap(map.id, i)}
                />
              ))}
            </div>
          ))}
        />

        <Button
          variant="primary"
          text="Confirm"
          className="!w-full"
          rounded
          disabled={selectedMaps.length !== mapAmount}
          onClick={() => {
            onSubmit(selectedMaps);
            onClose();
          }}
        />
      </div>
    </BaseModal>
  );
};

export default ModeConfirm;
