/* eslint-disable no-unused-vars */
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import branch from 'branch-sdk';

import client from '../../services/client';

// branch.init('key_live_0cd68c7483e32c3aad3ca21d225d11b9', (err, data) => {
//   console.log(err, data);
// });

const marketingContext = createContext({});

// curl -XGET 'https://api2.branch.io/v1/url?url=https://example.app.link/WgiqvsepqF&branch_key=key_live_kaFuWw8WvY7yn1d9yYiP8gokwqjV0Swt'

const useMarketing = () => {
  const getClipById = async (clipId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/clips/${clipId}`
    );

    return data;
  };

  const getBranch = async (link) => {
    const { data } = await axios.get(
      `https://api2.branch.io/v1/url?url=${link}&branch_key=${process.env.REACT_APP_BRANCH_KEY}`
    );
    return data;
  };

  return {
    getClipById,
    getBranch,
  };
};

export function MarketingProvider({ children }) {
  const users = useMarketing();

  return (
    <marketingContext.Provider value={users}>
      {children}
    </marketingContext.Provider>
  );
}

export default function UsersConsumer() {
  return React.useContext(marketingContext);
}

MarketingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
