import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import Button from '../../../components/Buttons/Button/Button';
import Team2 from '../../../components/Team2';

/**
 *
 * @param {{
 *   color: string,
 *   icon: string,
 *   game: string,
 *   title: string,
 *   subtitle: string,
 *   startDate: string,
 *   rollover_available: boolean,
 *   teams: Object[],
 *   onDelete: () => void,
 *   onReplace: () => void,
 *   delta: number
 * }} props
 * @returns
 */

const ScheduledEvent = ({
  color,
  icon,
  game,
  title,
  startDate,
  subtitle,
  rollover_available,
  teams,
  onDelete,
  onApprove,
  delta,
  isLoading,
}) => (
  <div className="bg-grey-4 rounded-3xl mb-8">
    <div
      className={classNames(
        'rounded-t-3xl overflow-hidden !ring-0 !outline-none   border-grey-3 block group border-b'
      )}
    >
      <div
        className={classNames('border-l-[16px] flex gap-4')}
        style={{ borderColor: color }}
      >
        <div className="flex flex-1 shrink-0 gap-2 sm:gap-4 items-start p-4 pr-0 ">
          <img
            src={icon}
            alt="player"
            className="w-[32px] h-[32px] sm:h-[48px] sm:w-[48px] object-cover rounded-full mt-1 sm:mt-0 sm:inline-block hidden"
          />
          <div className="sm:w-auto w-full">
            <p className="uppercase text-grey-2 text-xs font-bold flex items-end gap-1 ">
              <img
                src={icon}
                alt="player"
                className="w-[16px] h-[16px] object-cover  rounded-full mt-1 sm:hidden"
              />
              {game}
            </p>
            <h3 className="text-white font-extrabold uppercase  sm:text-lg flex items-center justify-between gap-4">
              <span className="flex-1">{title}</span>
              <img
                src="/Icons/arrow-right.svg"
                alt="icon"
                className="h-[20px] w-[20px] rounded-full mr-4 group-hover:mr-3 transition-all ease-in-out sm:hidden"
              />
            </h3>
            <p className="uppercase text-grey-2 font-bold text-[10px] sm:text-xs">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="border-l border-r border-b border-grey-4 rounded-b-3xl pt-4">
      <div className="px-4 flex items-center justify-between pb-2">
        <p className="text-xs uppercase">
          {format(new Date(startDate), 'hh:mm aa · MMMM dd')}
        </p>
      </div>
      {teams.map((t) => (
        <Team2 key={t.roster.id} team={t} noStake />
      ))}

      <div className="flex items-center justify-between p-4">
        <div className="flex items-center gap-2">
          <p className="font-bold text-white text-xs">
            RANK DELTA:{' '}
            <span className={delta > 50 ? 'text-warning' : 'text-success'}>
              {delta}
            </span>
          </p>
          <p className="font-normal text-grey-2 text-xs" style={{ marginLeft: '5px' }}>
            {rollover_available === true ? 'Roll Over Available' : ''}
          </p>
        </div>
        <div className="flex items-center gap-4">
          {onApprove && (
            <Button
              variant="primary"
              text="Approve Event"
              isLoading={isLoading}
              disabled={isLoading}
              rounded
              onClick={onApprove}
            />
          )}
          {onDelete && (
            <Button
              variant="warning"
              text="Delete Event"
              isLoading={isLoading}
              disabled={isLoading}
              rounded
              onClick={onDelete}
            />
          )}
        </div>
      </div>
    </div>
  </div>
    );

    export default ScheduledEvent;
