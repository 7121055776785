/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import Button from '../../Buttons/Button/Button';
import UserIcon from '../../../assets/Icons/user-icon.svg';
import TournamentIcon from '../../../assets/Icons/tournamnet-icon.svg';
import DisputeIcon from '../../../assets/Icons/dispute-icon.svg';
import CloseIcon from '../../../assets/Icons/close-icon.svg';
import MarketingIcon from '../../../assets/Icons/marketing-icon.svg';

import useAuth from '../../../contexts/AuthContext/AuthContext';

const SlidingMenu = ({
  isNavOpen,
  closeNav,
  className,
  tournamentManagmentRoute,
  userId,
}) => {
  const { logout } = useAuth();
  return (
    <div
      className={cn('menu-right bg-black w-full w-6/12 md:w-4/12', className, {
        'menu-right active': isNavOpen,
      })}
    >
      <div className="flex justify-end pt-3 pr-3">
        <button onClick={closeNav} type="button" className="z-50">
          <img src={CloseIcon} alt="close" />
        </button>
      </div>
      <div className="mt-6">
        <NavLink
          to="/godmode/moderation"
          className={(navData) =>
            navData.isActive ? 'block bg-grey-3' : 'block hover:bg-grey-3'
          }
        >
          <span className="flex items-center py-4 px-6 text-base font-normal">
            <img src={UserIcon} alt="user-icon" className="w-5 mr-3" />
            <p className="text-white text-sm uppercase">Moderation</p>
          </span>
        </NavLink>
        <NavLink
          to="/godmode/tournaments?tab=active"
          className={(navData) =>
            navData.isActive ||
            tournamentManagmentRoute === '/godmode/tournament-management'
              ? 'block bg-grey-3'
              : 'block hover:bg-grey-3'
          }
        >
          <span className="flex items-center py-4 px-6 text-base font-normal">
            <img src={TournamentIcon} alt="user-icon" className="w-5 mr-3" />
            <p className="text-white uppercase text-sm">Events</p>
          </span>
        </NavLink>
        <a
          href="/godmode/disputes"
          className={
            tournamentManagmentRoute === '/godmode/disputes'
              ? 'block bg-grey-3 mr-3'
              : 'block hover:bg-grey-3 mr-3'
          }
        >
          <span className="flex items-center py-4 px-6 text-base font-normal">
            <img src={DisputeIcon} alt="user-icon" className="w-5 mr-3" />
            <p className="text-white uppercase text-sm">Disputes</p>
          </span>
        </a>
        <NavLink
          to="/marketing"
          className={(navData) =>
            navData.isActive || tournamentManagmentRoute === '/marketing'
              ? 'block bg-grey-3'
              : 'block hover:bg-grey-3'
          }
        >
          <span className="flex items-center py-4 px-6 text-base font-normal">
            <img src={MarketingIcon} alt="user-icon" className="w-5 mr-3" />
            <p className="text-white uppercase text-sm">Marketing</p>
          </span>
        </NavLink>
      </div>
      <div className="mt-2 absolute bottom-4 w-full">
        <Button
          variant="tertiary"
          text="Sign Out"
          onClick={logout}
          className="w-11/12"
        />
      </div>
    </div>
  );
};

SlidingMenu.propTypes = {
  isNavOpen: PropTypes.bool.isRequired,
  closeNav: PropTypes.func,
  className: PropTypes.string,
  userId: PropTypes.number,
  tournamentManagmentRoute: PropTypes.string,
};

export default SlidingMenu;
