import { Switch } from '@headlessui/react';
import React, { FC, useEffect, useState } from 'react';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import { WithCentsCurrencyInput } from '../../components/CurrencyInput';
import HoldButton from '../../components/HoldButton.tsx';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';

const DonateModal: FC<{
  userId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ userId, isOpen, onClose, onSuccess }) => {
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [wagerOnly, setWagerOnly] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDonate = async () => {
    try {
      setIsLoading(true);
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/donate`,
        {
          wager_only: wagerOnly,
          description,
          amount,
        }
      );
      onSuccess();
      onClose();
      setAmount(0);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setDescription('');
      setWagerOnly(false);
      setAmount(0);
      setIsLoading(false);
      setIsError(false);
    }
  }, [isOpen]);

  return (
    <BaseModal
      fullWidth={false}
      modalIsOpen={isOpen}
      closeModal={onClose}
      header="Donate"
      height="500px"
      backgroundColor="black"
    >
      {isLoading && (
        <div className="h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col justify-between h-full">
          <div className="">
            <WithCentsCurrencyInput
              promo={false}
              error={false}
              onValueChange={setAmount}
              onEnter={() => {}}
              value={amount}
              small={false}
            />
            <textarea
              className="bg-grey-4 text-grey-2 border border-grey-3 rounded-md h-36 w-full pl-3 pt-3 resize-none mb-4 mt-4"
              placeholder="Description (required)"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="relative mb-12">
              <p className="text-white font-bold mb-3">Wager Only?</p>
              <div className="flex items-center gap-2">
                <Switch
                  checked={wagerOnly}
                  onChange={() => setWagerOnly(!wagerOnly)}
                  className={`${
                    wagerOnly ? 'bg-blue' : 'bg-grey-3'
                  } relative inline-flex h-8 w-16 items-center rounded-full`}
                >
                  <span
                    className={`${
                      wagerOnly ? 'translate-x-9' : 'translate-x-1'
                    } inline-block h-6 w-6 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <span className="text-white text-xs uppercase">
                  {wagerOnly ? 'Yes' : 'No'}
                </span>
              </div>
            </div>

            {isError && (
              <p className="text-warning text-center pb-4">
                There was an issue donating
              </p>
            )}
          </div>

          <div className="pb-4">
            <HoldButton
              variant="primary"
              text="Hold To Confirm Donation"
              timeout={250}
              onConfirm={handleDonate}
              disabled={amount === 0 || !description}
            />
          </div>
        </div>
      )}
    </BaseModal>
  );
};

export default DonateModal;
