import { GoogleAuthProvider } from 'firebase/auth';
import { signInWithGoogle } from '../services/firebase';
import authenticate from '../api/auth/auth';
import { setSessionCookie } from '../utils/cookie';

const useAuthWithGoogle = async () => {
  const result = await signInWithGoogle();
  const { idToken } = GoogleAuthProvider.credentialFromResult(result);

  setSessionCookie('googleToken', idToken);
  return authenticate(idToken);
};

export default useAuthWithGoogle;
