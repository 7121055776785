import React, { useState, useEffect } from 'react';
import PropTypes, { number } from 'prop-types';
import cn from 'classnames';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import Button from '../../../components/Buttons/Button/Button';
import Pill from '../../../components/Pill/Pill';

import { formattedDate, formatTime } from '../../../utils/index';
import convertToDollarAmount from '../../../utils/convertToDollar';

import ControllerIcon from '../../../assets/Icons/controller.svg';
import StakeableIcon from '../../../assets/Icons/stakeable-icon.svg';
import TrophyIcon from '../../../components/Icons/TrophyIcon';
import UserIcon from '../../../components/Icons/user-icon';
import client from '../../../services/client';

const Review = ({
  title,
  game,
  landscapeImage,
  isStakeable,
  displayDate,
  amount,
  mode,
  console,
  inviteListName,
  handleCreateTournament,
  cmsFaqId,
  matchType,
  bracketSize,
  bracketType,
  disabled,
  error,
  entryFee,
}) => {
  const [isToday, setIsToday] = useState(false);
  const [modeTitle, setModeTitle] = useState('');
  const [consoleTitle, setConsoleTitle] = useState('');
  const [dollars] = convertToDollarAmount(amount, true).split('.');

  useEffect(() => {
    // Create date from input value
    const inputDate = new Date(displayDate);

    // Get today's date
    const todaysDate = new Date();

    // call setHours to take the time out of the comparison
    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      // Date equals today's date
      setIsToday(true);
    }
  }, []);

  useEffect(() => {
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/game_modes/${mode}`)
      .then(({ data }) => setModeTitle(data.title))
      .catch(() => {});
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/consoles`)
      .then(({ data }) =>
        setConsoleTitle(data.find((c) => c.id === console)?.name || '')
      )
      .catch(() => {});
  }, [mode, console]);

  return (
    <div className=" transition-all duration-300 ease-in-out translate-x-0 fade-in-fast">
      <h3 className="text-white font-bold text-2xl mb-6">Review</h3>
      <div className="flex flex-wrap lg:flex-nowrap gap-12 justify-between lg:w-10/12 xl:w-8/12 m-auto border border-grey-3 rounded-2xl p-6">
        <div className="w-full lg:w-7/12 xl:w-5/12">
          <div className="">
            <LazyLoadImage
              alt="tournamentPackage"
              src={landscapeImage}
              effect="opacity"
              className="rounded-md"
            />
          </div>
          <p className="text-grey-2 text-sm mt-3">
            {isToday
              ? `TODAY AT ${formatTime(displayDate)}`
              : formattedDate(displayDate)}
          </p>
          <h3 className="text-white text-xl font-bold uppercase mt-1">
            {title}
          </h3>
          <p className="text-sm text-grey-2 mt-1">
            <span className="text-white">Event Type:</span>{' '}
            {matchType.replace('Tournament::', '')}
          </p>
          {matchType.toLowerCase().includes('bracket') && (
            <p className="text-sm text-grey-2 mt-1 capitalize">
              <span className="text-white">Bracket Type:</span> {bracketType}{' '}
              Elimination
            </p>
          )}
          {matchType.toLowerCase().includes('bracket') && (
            <p className="text-sm text-grey-2 mt-1">
              <span className="text-white">Bracket Size:</span> {bracketSize}
            </p>
          )}
          {cmsFaqId && (
            <p className="text-sm text-grey-2 mt-1">
              <span className="text-white">CMS ID:</span> {cmsFaqId}
            </p>
          )}
          <div className="flex gap-3 mt-3">
            <Pill className="bg-grey-4">
              <p className="text-xs text-grey-2">{game}</p>
            </Pill>
            <Pill className="bg-grey-4">
              <p className="text-xs text-grey-2"> {modeTitle}</p>
            </Pill>
            <Pill className="bg-grey-4">
              <p className="text-xs text-grey-2"> {consoleTitle}</p>
            </Pill>
          </div>
        </div>
        <div className="w-full lg:flex-1">
          {!isStakeable && (
            <>
              <p className="text-grey-2 text-center text-base uppercase">
                Entry Fee
              </p>
              <h2
                className={cn(
                  'font-black bg-transparent w-full text-white text-center py-3',
                  {
                    'text-7xl sm:text-8xl': dollars.length <= 6,
                    'text-6xl sm:text-7xl': dollars.length === 7,
                    'text-5xl sm:text-6xl': dollars.length === 8,
                    'text-4xl sm:text-5xl': dollars.length === 9,
                    'text-3xl sm:text-4xl': dollars.length >= 10,
                  }
                )}
              >
                {convertToDollarAmount(entryFee)}
              </h2>
            </>
          )}

          <div className="flex gap-6 mt-10">
            <div className="border border-grey-3 rounded-2xl p-3 w-full">
              <div className="flex items-center h-full">
                <div className="bg-white rounded-full w-12 h-12 pt-3 px-2">
                  <img src={ControllerIcon} alt="controller" className="" />
                </div>
                <div className="ml-6">
                  <p className="text-grey-2 font-normal uppercase text-xs">
                    Game
                  </p>
                  <p className="text-white font-bold text-base">{game}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-6 mt-4">
            {isStakeable && (
              <div className="border border-grey-3 rounded-2xl p-3 w-full">
                <div className="flex items-center">
                  <div className="">
                    <img src={StakeableIcon} alt="stake" className="" />
                  </div>
                  <div className="ml-6">
                    <p className="text-grey-2 font-normal uppercase text-xs">
                      STAKEABLE
                    </p>
                    <p className="text-white font-bold uppercase">Yes</p>
                  </div>
                </div>
              </div>
            )}

            {inviteListName && (
              <div className="border border-grey-3 rounded-2xl p-3 w-full">
                <div className="flex items-center">
                  <div className="relative w-12 h-12">
                    <div className="bg-white rounded-full p-3 z-20 absolute">
                      <UserIcon color="black" className="w-4" />
                    </div>
                    <div className="bg-grey-2 rounded-full p-3 z-10 absolute top-3 left-4">
                      <UserIcon color="black" className="w-4" />
                    </div>
                  </div>
                  <div className="ml-6">
                    <p className="text-grey-2 font-normal uppercase text-xs">
                      REGISTRATION
                    </p>
                    <p className="text-white font-bold text-base">
                      {inviteListName}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <p className="mt-4 text-center text-warning">{error}</p>
          <Button
            className={cn('w-full mt-2', { '!mt-6': !error })}
            rounded
            text="Confirm & Publish"
            variant="primary"
            onClick={() => (disabled ? () => {} : handleCreateTournament())}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

Review.propTypes = {
  title: PropTypes.string,
  game: PropTypes.string,
  landscapeImage: PropTypes.string,
  isStakeable: PropTypes.bool,
  displayDate: PropTypes.string,
  amount: PropTypes.number,
  mode: PropTypes.string,
  console: PropTypes.string,
  inviteListName: PropTypes.string,
  cmsFaqId: PropTypes.string,
  matchType: PropTypes.string,
  bracketType: PropTypes.string,
  bracketSize: PropTypes.string,
  handleCreateTournament: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Review;
