import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import EmptyState from '../../components/EmptyState';
import TransactionCard from './TransactionCard';

const TransactionsList = ({
  transactions,
  setTransactionToRefund,
  handleGetPayPalURL,
  setRefundTransactionModalOpen,
  onRefund,
}) => {
  if (transactions.length === 0) {
    return (
      <EmptyState text="You have no recent activity. Click Deposit to get started" />
    );
  }

  return (
    <div>
      {transactions.length > 0 &&
        transactions.map((transaction) => (
          <TransactionCard
            key={uuidv4()}
            id={transaction.id}
            title={transaction.title}
            type={transaction.type}
            directionType={transaction.direction_type}
            date={transaction.created_at}
            amount={transaction.amount}
            image={transaction.icon_url}
            state={transaction.state}
            purseContext={transaction.purse_context}
            handleGetPayPalURL={handleGetPayPalURL}
            setRefundTransactionModalOpen={setRefundTransactionModalOpen}
            setTransactionToRefund={setTransactionToRefund}
            onRefund={onRefund}
          />
        ))}
    </div>
  );
};

export default TransactionsList;
