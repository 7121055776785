/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-restricted-globals */
import classNames from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Link, useNavigate } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import convertToDollarAmount from '../../utils/convertToDollar';

import { getNumberWithOrdinal } from '../../utils/numbers';
import { Game, GameMode, Match, Scores } from '../../types';
import client from '../../services/client';
import Star from '../../assets/Icons/rounded-star.svg';
import Controller from '../../assets/Icons/controller.svg';
import MoreIcon from '../../assets/Icons/more.svg';
import GlobeIcon from '../../assets/Icons/globe.svg';
import Loader from '../../components/Loader/Loader';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import TrashCanIcon from '../../assets/Icons/trash.svg';
// @ts-ignore
import HoldButton from '../../components/HoldButton.tsx';
// @ts-ignore
import AvatarGroup from '../../components/AvatarGroup.tsx';

const MatchCard: FC<{
  match: Match;
  userId: number;
  isLink?: boolean;
  tournament?: boolean;
  onUpdateMatch?: (newMatch: Match) => void;
  teams?: any[];
  forceScores?: boolean;
}> = ({
  match,
  userId,
  isLink,
  onUpdateMatch,
  tournament,
  teams,
  forceScores,
}) => {
  const [game, setGame] = useState<Game | null>(null);
  const [gameMode, setGameMode] = useState<GameMode | null>(null);
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [settingScores, setSettingScores] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cacheGame = sessionStorage.getItem(`game_${match.game_id}`);
    if (cacheGame) {
      setGame(JSON.parse(cacheGame) as Game);
    } else {
      client
        .get<Game>(
          `${process.env.REACT_APP_API_URL}/api/v1/games/${match.game_id}`
        )
        .then(({ data }) => {
          sessionStorage.setItem(`game_${match.game_id}`, JSON.stringify(data));
          setGame(data);
        });
    }

    const cacheMode = sessionStorage.getItem(`game_mode_${match.game_mode_id}`);
    if (cacheMode) {
      setGameMode(JSON.parse(cacheMode) as GameMode);
    } else {
      client
        .get<GameMode>(
          `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${match.game_mode_id}`
        )
        .then(({ data }) => {
          sessionStorage.setItem(
            `game_mode_${match.game_mode_id}`,
            JSON.stringify(data)
          );
          setGameMode(data);
        });
    }
  }, [match]);

  const sortedParticipants = useMemo(
    () =>
      [...match.participants].sort((a, b) =>
        a.user_id === userId ? -1 : a.team_uuid === b.team_uuid ? 0 : 1
      ),
    [match]
  );

  const userParticipant = useMemo(
    () =>
      userId
        ? match.participants.find((p) => p.user_id === userId)
        : match.participants[0],
    [match]
  );

  // TODO: Figure out a better way to do this
  const [topSection, bottomSection] = useMemo(() => {
    const userPlacement = match.placements.find(
      (p) => p.team_uuid === userParticipant?.team_uuid
    );
    const otherPlacement = match.placements.find(
      (p) => p.team_uuid !== userParticipant?.team_uuid
    );
    const userTeam = sortedParticipants.filter(
      (p) => p.team_uuid === userParticipant?.team_uuid
    );
    const userTeamName = teams?.find((t) =>
      t.tournament_players.find(
        (p) => p.user_profile.id === userTeam[0].user_id
      )
    )?.name;

    const otherTeam = sortedParticipants.filter(
      (p) => p.team_uuid !== userParticipant?.team_uuid
    );
    const otherTeamName = teams?.find((t) =>
      t.tournament_players.find(
        (p) => p.user_profile.id === otherTeam[0].user_id
      )
    )?.name;
    return [
      {
        team: userTeam,
        placement: userPlacement,
        name: userTeamName,
      },
      {
        team: otherTeam,
        placement: otherPlacement,
        name: otherTeamName,
      },
    ];
  }, [match, teams]);

  const matchFormat = useMemo(
    () => gameMode?.match_formats.find((f) => f.id === match.match_format_id),
    [match, gameMode]
  );

  const topLabel = useMemo(() => {
    if (tournament) {
      return '';
    }

    if (
      match.state !== 'closed' ||
      match.cancel_reason ||
      typeof topSection?.placement?.placement !== 'number'
    ) {
      return '--';
    }

    if (gameMode?.label === 'FFA' && topSection.placement?.placement) {
      return getNumberWithOrdinal(topSection.placement?.placement);
    }

    if (topSection.placement?.placement === 1) {
      return 'Won';
    }

    return 'Lost';
  }, [match, gameMode, tournament, topSection]);

  const bottomLabel = useMemo(() => {
    if (tournament) {
      return '';
    }

    if (
      match.state !== 'closed' ||
      match.cancel_reason ||
      typeof bottomSection?.placement?.placement !== 'number'
    ) {
      return '--';
    }

    if (gameMode?.label === 'FFA') {
      return null;
    }

    if (bottomSection.placement?.placement === 1) {
      return 'Won';
    }

    return 'Lost';
  }, [match, gameMode, tournament, bottomSection]);

  const handleReplay = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        const { data } = await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${match.id}/replay`
        );
        onUpdateMatch?.(data);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };
  const handleDispute = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        const { data } = await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${match.id}/reverse`
        );
        onUpdateMatch?.(data);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };
  const handleDisputeLive = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        const { data } = await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${match.id}/dispute`
        );
        onUpdateMatch?.(data);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  const handleWinner = async (id, skipConfirm?: boolean) => {
    try {
      setError('');
      let res = skipConfirm || false;
      if (!skipConfirm) {
        res = window.confirm('Are you sure!?');
      }
      if (res) {
        const { data } = await client.post<Match>(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/submit`,
          {
            team_uuid: id,
            placement: 1,
          }
        );
        onUpdateMatch?.(data);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  if (!game || !gameMode) return <Loader />;

  return (
    <div
      className={classNames(
        'rounded-lg  py-2 px-4 mb-4 text-grey-2 overflow-hidden',
        {
          'bg-grey-4 hover:opacity-75 transition-opacity ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue':
            isLink,
          'border border-grey-3': !isLink,
          'bg-grey-4 !border-none !py-4 !rounded-2xl': tournament,
        }
      )}
    >
      <h3
        className={classNames(
          'flex items-center text-[10px] justify-between text-white font-bold leading-none',
          {
            'border-l-[16px] -mx-4 -mt-4 px-4 py-2 border-b border-grey-3 mb-4':
              tournament,
          }
        )}
        style={{ borderLeftColor: tournament ? gameMode.color : 'transparent' }}
      >
        <span
          className={classNames('text-xl sm:text-2xl', {
            '!text-xl': tournament,
          })}
        >
          <p className="uppercase text-grey-2 text-xs flex items-end gap-1 sm:mb-0">
            <img
              src={game.logo_url}
              alt="player"
              className="w-[16px] h-[16px] object-cover rounded-full mt-1 mr-1"
            />
            {game.name}
          </p>
          {gameMode.title}{' '}
          {tournament && (
            <small className="text-grey-2 text-xs block">
              ID: <b className="text-white">{match.id}</b>
            </small>
          )}
          <small className="text-grey-2 text-xs block">
            Host:{' '}
            <b className="text-white">
              {match.participants.find((p) => p.type === 'host')?.username}
            </b>
          </small>
          {!tournament && (
            <span
              className={classNames(
                'text-xs uppercase block font-bold mb-1 text-warning',
                {
                  '!text-grey-2':
                    match.state === 'closed' || match.state === 'open',
                }
              )}
            >
              {match.state}
            </span>
          )}
        </span>
        {!tournament && (
          <span className="text-3xl sm:text-4xl">
            {typeof match.amount === 'number'
              ? convertToDollarAmount(match.amount)
              : `${convertToDollarAmount(match.amount_range.min)}${
                  match.amount_range.max !== match.amount_range.min
                    ? `-${convertToDollarAmount(match.amount_range.max)}`
                    : ''
                }`}
          </span>
        )}
      </h3>
      {!tournament && (
        <div className="mb-4 flex items-center gap-2 sm:text-base text-sm">
          <p className="text-white bg-grey-4 rounded-full w-5 h-5 text-[10px] flex items-center justify-center font-extrabold">
            ID
          </p>
          <p>{match.id}</p>{' '}
          <img
            height={16}
            width={16}
            src={GlobeIcon}
            className="rounded-full"
            alt={game.name}
          />
          <p>{match.region}</p>
          <img
            height={16}
            width={16}
            src={game.logo_url}
            className="rounded-full"
            alt={game.name}
          />
          <p>{match.game_name}</p>
          <img src={Star} height={16} width={16} alt="star" />
          <p>{matchFormat?.description}</p>
          <div className="flex items-center justify-center bg-grey-4 rounded-full h-[16px] w-[16px]">
            <img
              className="brightness-200"
              src={Controller}
              height={10}
              width={10}
              alt="star"
            />
          </div>
          <p>{match.console_name}</p>
        </div>
      )}

      {tournament ? (
        teams?.map((t) => (
          <div
            key={`team_${t.id}`}
            className={classNames(
              'w-full flex items-center justify-between text-base mb-2 cursor-pointer'
            )}
            onClick={() => setModalOpen(true)}
          >
            <div className="flex items-center gap-4">
              <AvatarGroup
                medium
                users={(t.tournament_players || []).map((p) => p.user_profile)}
                limit={3}
              />
              <div>
                <p
                  className={classNames({
                    'font-extrabold text-white text-lg': !!tournament,
                  })}
                >
                  {t.name}
                </p>
                {t.tournament_players.length > 0 && (
                  <p className="text-xs text-grey-2 truncate">
                    {t.tournament_players
                      .map((p) => p.user_profile.username)
                      .join(' · ')}
                  </p>
                )}
              </div>
            </div>
            {match.state !== 'live' && match.state !== 'confirmed' && (
              <span>
                {gameMode?.label === 'FFA'
                  ? getNumberWithOrdinal(t.placement)
                  : t.placement === 1
                  ? 'Won'
                  : 'Lost'}
              </span>
            )}
          </div>
        ))
      ) : (
        <>
          <div
            className={classNames(
              'w-full flex items-center justify-between text-base mb-2 cursor-pointer',
              {
                'font-bold text-white':
                  match.state === 'closed' && topLabel !== 'Lost',
              }
            )}
            onClick={() => setModalOpen(true)}
          >
            <div className="flex items-center gap-4">
              <AvatarGroup
                medium={tournament && match.state === 'live'}
                users={topSection.team}
                limit={3}
              />
              <div>
                <p>
                  {tournament ? topSection.name : topSection.team[0]?.username}
                </p>
                {topSection.team.length > 1 && (
                  <p className="text-xs text-grey-2 truncate">
                    {topSection.team
                      .map((p) => p.user_profile.username)
                      .join(' · ')}
                  </p>
                )}
              </div>
            </div>
            <span>{topLabel}</span>
          </div>
          <div
            className={classNames(
              'w-full flex items-center justify-between text-base mb-2 cursor-pointer',
              {
                'font-bold text-white':
                  match.state === 'closed' && bottomLabel === 'Won',
              }
            )}
            onClick={() => setModalOpen(true)}
          >
            <div className="flex items-center gap-4">
              <AvatarGroup
                medium={tournament && match.state === 'live'}
                users={bottomSection.team}
                limit={3}
              />
              <div>
                <p>
                  {tournament
                    ? bottomSection.name
                    : bottomSection.team[0]?.username}
                </p>
                {bottomSection.team.length > 1 && (
                  <p className="text-xs text-grey-2 truncate">
                    {bottomSection.team
                      .map((p) => p.user_profile.username)
                      .join(' · ')}
                  </p>
                )}
              </div>
            </div>
            <span>{bottomLabel}</span>
          </div>
        </>
      )}

      <div className="flex items-center justify-between">
        {!tournament && (
          <p className="text-xs pt-4">
            {tournament
              ? format(new Date(match.created_at), 'eeee, MMMM do')
              : format(
                  new Date(match.created_at),
                  'eeee, MMMM do, y @ h:mm aa'
                )}
          </p>
        )}
        <div className="flex items-center justify-between gap-4 flex-1 mt-4">
          {forceScores ? (
            <div className="grid gap-4 pt-4 w-full">
              <Button
                variant="primary"
                rounded
                text="Set Scores"
                className="!w-full"
                large
                onClick={() => setSettingScores(true)}
              />
            </div>
          ) : match.state === 'disputed' ||
            match.state === 'canceled' ||
            match.state === 'closed' ||
            match.state === 'live' ||
            match.state === 'confirmed' ? (
            <Popover className="relative z-10 flex-1">
              <Popover.Button className="bg-grey-4 w-full rounded-2xl flex items-center hover:opacity-75  transition-opacity ease-in-out !ring-0 !outline-none">
                <Button
                  variant="primary"
                  rounded
                  text="Options"
                  className="!w-full"
                  large
                  onClick={() => {}}
                />
              </Popover.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform translate-y-2 opacity-0"
                enterTo="transform translate-y-0 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform translate-y-0 opacity-100"
                leaveTo="transform translate-y-2 opacity-0"
              >
                <Popover.Panel className="absolute -bottom-2 right-0 z-10 shadow-lg border border-grey-3 rounded-lg bg-black w-[300px]">
                  {({ close }) => (
                    <div onClick={() => close()}>
                      {match.state === 'disputed' && (
                        <button
                          type="button"
                          onClick={handleReplay}
                          className="py-2 w-full text-left px-4 text-white hover:bg-grey-4 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                        >
                          Force Replay Match
                        </button>
                      )}

                      {match.state === 'closed' && (
                        <button
                          type="button"
                          onClick={handleDispute}
                          className="py-2 w-full text-left px-4 text-white hover:bg-grey-4 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                        >
                          Dispute Match
                        </button>
                      )}
                      {(match.state === 'canceled' ||
                        match.state === 'closed') && (
                        <button
                          type="button"
                          onClick={handleDispute}
                          className="py-2 w-full text-left px-4 text-white hover:bg-grey-4 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                        >
                          Redispute Match
                        </button>
                      )}
                      {match.state === 'live' &&
                        tournament &&
                        teams?.map((t) => (
                          <button
                            type="button"
                            onClick={() => handleWinner(t.team_uuid)}
                            className="py-2 w-full text-left px-4 text-white hover:bg-grey-4 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                          >
                            {t.name} Won
                          </button>
                        ))}
                      {match.state === 'disputed' && !tournament && (
                        <button
                          type="button"
                          onClick={() =>
                            navigate({
                              pathname: '/godmode/disputes',
                              search: `?selectedMatch=${match.id}`,
                            })
                          }
                          className="py-2 w-full text-left px-4 text-white hover:bg-grey-4 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                        >
                          Open Dispute Portal
                        </button>
                      )}
                      {(match.state === 'live' ||
                        match.state === 'confirmed' ||
                        match.state === 'starting') && (
                        <button
                          type="button"
                          onClick={handleDisputeLive}
                          className="py-2 w-full text-left px-4 text-warning hover:bg-grey-4 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                        >
                          Force Dispute Match
                        </button>
                      )}
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </Popover>
          ) : null}
        </div>
      </div>

      {error && (
        <p className="pt-2 text-warning text-center text-sm">{error}</p>
      )}
      <BaseModal
        header="Players"
        modalIsOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        fullWidth={false}
      >
        <div className="pt-4">
          {topSection.team.map((p) => (
            <a
              href={`/godmode/users/${p.user_id}`}
              key={`top_${p.user_id}`}
              className="flex gap-2 items-center mb-4"
            >
              <img
                src={p.profile_image_url}
                alt={p.username}
                className="rounded-full object-cover w-8 h-8"
                onError={(e) => {
                  // @ts-ignore
                  e.target.src =
                    'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                }}
              />
              <p className="text-white">
                {p.username}
                <small className="text-grey-2 block">
                  {p.id} • {p.gamer_id}
                </small>
              </p>
            </a>
          ))}
          {bottomSection.team.map((p) => (
            <a
              href={`/godmode/users/${p.user_id}`}
              className="flex gap-2 items-center mb-4"
              key={`bottom_${p.user_id}`}
            >
              <img
                src={p.profile_image_url}
                alt={p.username}
                className="rounded-full object-cover w-8 h-8"
                onError={(e) => {
                  // @ts-ignore
                  e.target.src =
                    'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                }}
              />
              <p className="text-white">
                {p.username}
                <small className="text-grey-2 block">
                  {p.id} • {p.gamer_id}
                </small>
              </p>
            </a>
          ))}
        </div>
      </BaseModal>
      {teams &&
        matchFormat &&
        tournament &&
        (match.state === 'live' || forceScores) && (
          <ScoresModal
            isOpen={settingScores}
            onClose={() => setSettingScores(false)}
            scoreType={gameMode.round_score_type}
            numRounds={matchFormat.num_of_rounds}
            matchId={match.id}
            teams={teams}
            error={error}
            onWinner={(id) => handleWinner(id, true)}
            hideFinalize={forceScores}
          />
        )}
    </div>
  );
};

export const ScoresModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  scoreType: GameMode['round_score_type'];
  numRounds: number;
  matchId: number;
  teams: any[];
  error: string;
  onWinner: (id: string) => void;
  hideFinalize?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  currRound?: any;
}> = ({
  isOpen,
  onClose,
  scoreType,
  numRounds,
  matchId,
  teams,
  error,
  onWinner,
  hideFinalize,
  onSave,
  onDelete,
  currRound,
}) => {
  const [scores, setScores] = useState<{
    /* eslint-disable no-undef */
    [round: number]: {
      [team: string]: number;
    };
  }>({});
  const [saving, setSaving] = useState(false);
  const [e, setError] = useState('');
  const [showConfirm, setShowConfirm] = useState(true);

  const handleSave = (
    round: number,
    s: {
      [team: string]: number;
    }
  ) => {
    setError('');
    setSaving(true);
    const payload = {
      round_number: round,
      scores: Object.keys(s).map((t) => ({
        team_uuid: t,
        score: s[t],
      })),
    };
    client
      .put<Scores>(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/scores`,
        payload
      )
      .then(() => setScores((sc) => ({ ...sc, [round]: s })))
      .catch((err) =>
        setError(err.response?.data?.message || 'Something went wrong')
      )
      .finally(() => {
        setSaving(false);
        onSave?.();
      });
  };

  const handleDelete = (round: number) => {
    const res = confirm('Are you sure?');
    if (res) {
      setError('');
      setSaving(true);
      client
        .delete<Scores>(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/scores?round_number=${round}`
        )
        .then(() => {
          setScores((sc) => {
            const clone = { ...sc };
            delete clone[round];
            return clone;
          });

          onDelete?.();
        })
        .catch((err) =>
          setError(err.response?.data?.message || 'Something went wrong')
        )
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    client
      .get<Scores>(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/scores`
      )
      .then(({ data }) => {
        const obj: typeof scores = {};
        data.forEach((r) => {
          if (!obj[r.round_number]) {
            obj[r.round_number] = {};
          }
          obj[r.round_number][r.team_uuid] = r.score;
        });
        setScores(obj);
      });
  }, [matchId, isOpen]);

  const handleUpdateScore = (teamId: string, round: number, score: number) => {
    setScores((s) => ({
      ...s,
      [round]: {
        ...s[round],
        [teamId]: score,
      },
    }));
  };

  const handleToggle = (teamId: string, round: number) => {
    const newObj = { ...scores };
    if (!newObj[round]) {
      newObj[round] = {};
    }
    teams.forEach((t) => {
      newObj[round][t.team_uuid] = 0;
    });
    newObj[round][teamId] = 1;
    setScores(newObj);
  };

  const handleSubmit = async () => {
    setShowConfirm(true);
  };

  const getDisabled = () => {
    if (teams.length > 2) {
      return !scores[1] || Object.keys(scores[1]).length === 0;
    }
    const results = teams.reduce((prev, curr) => {
      const wins: string[] = [];
      Object.keys(scores).forEach((r) => {
        const winner = Object.keys(scores[r]).reduce((a, b) =>
          scores[r][a] > scores[r][b] ? a : b
        );
        if (winner === curr.team_uuid) {
          wins.push(winner);
        }
      });
      return [...prev, wins];
    }, []);

    return !results.some((r) => r.length > numRounds / 2);
  };

  return (
    <BaseModal
      header="Set Scores"
      modalIsOpen={isOpen}
      closeModal={onClose}
      fullWidth={false}
      paginateData={console.log}
      large
    >
      {teams.length > 2 ? (
        <div className="sm:px-8 px-4">
          <div className="grid grid-cols-2 py-2 gap-4">
            {teams.map((t, i) => (
              <>
                <p
                  className="flex items-center gap-2 font-bold text-white"
                  key={`score_${t.team_uuid}`}
                >
                  <AvatarGroup
                    small
                    users={t.tournament_players.map((p) => p.user_profile)}
                    limit={3}
                  />
                  {t.name}
                </p>
                {scoreType === 'points' ? (
                  <input
                    placeholder="Score"
                    key={`score_${t.id}`}
                    className="py-2 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full fade-in-fast"
                    onChange={(event) =>
                      handleUpdateScore(
                        t.team_uuid,
                        1,
                        Number(event.target.value)
                      )
                    }
                    value={scores[1]?.[t.team_uuid] ?? ''}
                  />
                ) : (
                  <Button
                    rounded
                    text={t.name}
                    large
                    className="fade-in-fast"
                    onClick={() => handleToggle(t.team_uuid, 1)}
                    variant={scores[1]?.[t.team_uuid] ? 'primary' : 'tertiary'}
                  />
                )}
              </>
            ))}
          </div>
          {(error || e) && (
            <p className="text-warning text-center text-sm pt-4">
              {e || error}
            </p>
          )}
          {!hideFinalize && (
            <div className="flex items-center justify-end pt-8">
              <Button
                rounded
                text="Finalize Scores"
                large
                variant="primary"
                disabled={getDisabled()}
                onClick={handleSubmit}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="sm:px-8 px-4">
          <div className="grid grid-cols-4 py-2 gap-4">
            <div />
            {teams.map((t) => (
              <p
                className="flex items-center gap-2 font-bold text-white"
                key={`score_${t.id}`}
              >
                <AvatarGroup
                  small
                  users={t.tournament_players.map((p) => p.user_profile)}
                  limit={3}
                />
                {t.name}
              </p>
            ))}
          </div>
          {Array.from(Array(numRounds).keys()).map((round) => {
            const r = Number(round) + 1;
            const display =
              (scores[round] && Object.keys(scores[round])?.length > 0) ||
              (scores[r] && Object.keys(scores[r])?.length > 0);

            return (
              <RoundRow
                key={`round_${r}`}
                round={r}
                roundScores={scores[r]}
                isLoading={saving}
                onSave={handleSave}
                teams={teams}
                scoreType={scoreType}
                display={display}
                onDelete={handleDelete}
                currRound={currRound}
              />
            );
          })}

          {(error || e) && (
            <p className="text-warning text-center text-sm pt-4">
              {e || error}
            </p>
          )}
          {!hideFinalize && (
            <div className="flex items-center justify-end pt-8">
              <Button
                rounded
                text="Finalize Scores"
                large
                variant="primary"
                disabled={getDisabled()}
                onClick={handleSubmit}
              />
            </div>
          )}
        </div>
      )}
      <ConfirmModal
        teams={teams}
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        scores={scores}
        error={error}
        onWinner={onWinner}
      />
    </BaseModal>
  );
};

const RoundRow: FC<{
  currRound?: number;
  round: number;
  roundScores: {
    [team: string]: number;
  };
  teams: any[];
  scoreType: GameMode['round_score_type'];
  onSave: (
    round: number,
    newScores: {
      [team: string]: number;
    }
  ) => void;
  isLoading: boolean;
  display: boolean;
  onDelete: (round: number) => void;
}> = ({
  round,
  roundScores,
  teams,
  scoreType,
  isLoading,
  onSave,
  display,
  onDelete,
  currRound,
}) => {
  const [scores, setScores] = useState(roundScores || {});

  useEffect(() => {
    setScores(roundScores || {});
  }, [roundScores]);

  const handleUpdateScore = (teamId: string, score: number) => {
    setScores((s) => ({
      ...s,
      [teamId]: score,
    }));
  };

  const handleToggle = (teamId: string) => {
    const newObj = { ...scores };

    teams.forEach((t) => {
      newObj[t.team_uuid] = 0;
    });
    newObj[teamId] = 1;
    setScores(newObj);
  };

  return (
    <div className="grid grid-cols-4 py-2 gap-4 items-center hover:bg-white/5">
      <p className="font-extrabold uppercase text-white">Round {round}</p>
      {(round === 1 || display) && (
        <>
          {teams.map((t) =>
            scoreType === 'points' ? (
              <input
                placeholder="Score"
                className="py-2 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full fade-in-fast"
                onChange={(event) =>
                  handleUpdateScore(t.team_uuid, Number(event.target.value))
                }
                value={scores?.[t.team_uuid] ?? ''}
              />
            ) : (
              <Button
                rounded
                text={t.name}
                large
                className="fade-in-fast"
                onClick={() => handleToggle(t.team_uuid)}
                variant={scores?.[t.team_uuid] ? 'primary' : 'tertiary'}
              />
            )
          )}
          {Object.keys(scores).length > 1 && (
            <div className="grid grid-cols-3 gap-2">
              {isEqual(scores, roundScores) &&
                (!currRound || currRound === round) && (
                  <Button
                    text=""
                    variant="warning"
                    disabled={isLoading}
                    isLoading={isLoading}
                    onClick={() => onDelete(round)}
                    image={TrashCanIcon}
                    imageClass="w-4 saturate-0 brightness-[100]"
                    className="!p-2 !rounded"
                  />
                )}
              <Button
                text={isEqual(scores, roundScores) ? 'Saved' : 'Save'}
                variant="primary"
                disabled={isLoading || isEqual(scores, roundScores)}
                isLoading={isLoading}
                onClick={() => onSave(round, scores)}
                className={classNames({
                  '!col-span-2': isEqual(scores, roundScores),
                  '!col-span-3': !isEqual(scores, roundScores),
                })}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const ConfirmModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  teams: any[];
  scores: {
    /* eslint-disable no-undef */
    [round: number]: {
      [team: string]: number;
    };
  };
  error: string;
  onWinner: (id: string) => void;
}> = ({ isOpen, onClose, scores, teams, error, onWinner }) => {
  const [isLoading, setIsLoading] = useState(false);
  const team = useMemo(() => {
    const results = teams.reduce((prev, curr) => {
      const wins = {};
      Object.keys(scores).forEach((r) => {
        const winner = Object.keys(scores[r]).reduce((a, b) =>
          scores[r][a] > scores[r][b] ? a : b
        );
        if (winner === curr.team_uuid) {
          wins[curr.team_uuid] = wins[curr.team_uuid]
            ? wins[curr.team_uuid] + 1
            : 1;
        }
      });
      return { ...prev, ...wins };
    }, {});

    const uuid = Object.keys(results).reduce(
      (a, b) => (results[a] > results[b] ? a : b),
      ''
    );
    return teams.find((t) => t.team_uuid === uuid);
  }, [scores, teams]);

  useEffect(() => {
    setIsLoading(false);
  }, [error]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (team) {
      onWinner(team.team_uuid);
    }
  };

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header=""
      fullWidth={false}
      large
    >
      <div className="sm:px-12 pt-12 h-full flex flex-col items-center justify-center ">
        <AvatarGroup
          users={(team?.tournament_players || []).map(
            (p) => p.user_profile.profile_image_url
          )}
          large
          limit={3}
        />
        <h3 className="font-extrabold text-white text-3xl pt-4 mb-2 flex-1 text-center">
          Confirm Team {team?.name} Won
        </h3>
        <div className="max-w-lg mx-auto">
          {error && (
            <p className="py-2 text-warning text-center text-sm">{error}</p>
          )}
          <p className="text-grey-2 text-sm mb-4 text-center">
            THIS CANNOT BE UNDONE AND WILL PAYOUT ANY STAKERS
          </p>
          <div className="">
            <HoldButton
              variant="warning"
              text="Hold To Confirm"
              timeout={250}
              onConfirm={handleSubmit}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default MatchCard;
