/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useWallet from '../../contexts/WalletContext/WalletContext';
import useUsers from '../../contexts/UserContext/UserContext';

import Card from '../../components/Card/Card';

import { formattedDate, convertToDollarAmount } from '../../utils';

const Wallet = ({ globalUser }) => {
  const { transactions, getTransactions } = useWallet();
  const { user } = useUsers();

  useEffect(() => {
    if (user.user) {
      getTransactions(user.user.id);
    }
  }, [user]);

  const getSubtitle = (state) => {
    if (state === 'return') return 'Returned';
    if (state === 'cancelled') return 'Cancelled';
    if (state === 'failed') return 'Failed';
    if (state === 'pending') return 'Pending';
  };

  return (
    <>
      <div className="mt-8 flex justify-between items-center">
        <h2 className="text-white text-2xl">
          <span className="font-extrabold mr-2">Wallet</span>
          <span className="text-grey-2">
            {convertToDollarAmount(globalUser.wallet.balance)}
          </span>
        </h2>
        <Link
          to={`/users/${user.user.id}/wallet/`}
          className="text-secondary cursor-pointer text-sm"
        >
          See All
        </Link>
      </div>
      {transactions.length ? (
        transactions
          .slice(0, 3)
          .map(
            ({
              icon_url,
              title,
              created_at,
              amount,
              type,
              state,
              id,
              direction_type,
            }) => (
              <div className="" key={id}>
                <div className="flex items-center mt-6">
                  <div className="rounded-full bg-zinc-900 w-16">
                    <img src={icon_url} alt="profile" />
                  </div>
                  <div className="ml-3 w-full flex justify-between">
                    <div className="">
                      <h2 className="text-white text-xl capitalize">{title}</h2>
                      <h2 className="text-white text-sm text-grey-2 mt-2">
                        {formattedDate(created_at)}
                      </h2>
                    </div>
                    <div className="text-right">
                      <h2 className="text-white text-xl capitalize">
                        {direction_type === 'debit' ? '-' : '+'}
                        {convertToDollarAmount(amount)}
                      </h2>
                      <p
                        className={classNames(
                          'text-base uppercase text-right',
                          {
                            'text-warning':
                              state === 'return' ||
                              state === 'failed' ||
                              state === 'cancelled',
                            'text-grey-2': state === 'pending',
                          }
                        )}
                      >
                        {getSubtitle(state)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )
      ) : (
        <Card bordered>
          <p className="text-grey-2 text-center py-8">
            No transactions available
          </p>
        </Card>
      )}
    </>
  );
};

Wallet.propTypes = {
  globalUser: PropTypes.shape({
    wallet: PropTypes.shape({
      balance: PropTypes.number,
    }),
  }),
};

export default Wallet;
