import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Accordion from '../../components/Accordian/Accordian';

const DisputeList = ({
  disputes,
  selectedDispute,
  handleGetGameMode,
  handleSetDispute,
  setSelectedDispute,
  closeVideoPlayer,
}) => {
  const sideInfoRef = useRef();

  return (
    <div className="absolute top-0 bottom-0 flex flex-col w-full">
      <div className="section">
        <div ref={sideInfoRef} className="scrollable-content pb-3">
          <div className="">
            {disputes.length > 0 ? (
              disputes.map(
                ({
                  id,
                  participants,
                  amount,
                  element_set_id,
                  game_mode_id,
                  game_name,
                  game_mode_title,
                  console_name,
                  region,
                  ...dispute
                }) => {
                  const liveGroups = groupBy(participants, 'team_uuid');

                  const sortedGroups = Object.keys(liveGroups).map(
                    (key) => liveGroups[key]
                  );

                  return (
                    <Accordion
                      key={uuidv4()}
                      isOpen={selectedDispute.id === id}
                      matchId={id}
                      participants={sortedGroups}
                      amount={amount}
                      console_name={console_name}
                      gameModeId={game_mode_id}
                      game_name={game_name}
                      game_mode_title={game_mode_title}
                      elementSetId={element_set_id}
                      handleGetGameMode={handleGetGameMode}
                      handleSetDispute={handleSetDispute}
                      setSelectedDispute={setSelectedDispute}
                      closeVideoPlayer={closeVideoPlayer}
                      categories={[dispute.disputes[0]?.category]}
                      disputedAt={dispute.disputed_at}
                      region={region}
                    />
                  );
                }
              )
            ) : (
              <div className="p-6 rounded-md border-dashed border-2 border-grey-2 mx-3">
                <p className="text-center text-grey-2">
                  All Disputes Resolved 🎉
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DisputeList.propTypes = {
  disputes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      // eslint-disable-next-line react/forbid-prop-types
      participants: PropTypes.array,
      amount: PropTypes.number,
      element_set_id: PropTypes.number,
      extended_rules_id: PropTypes.number,
      game_mode_id: PropTypes.number,
      game_name: PropTypes.string,
      game_mode_title: PropTypes.string,
    })
  ),
  handleGetGameMode: PropTypes.func,
  handleSetDispute: PropTypes.func,
  setSelectedDispute: PropTypes.func,
  closeVideoPlayer: PropTypes.func,
  selectedDispute: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default DisputeList;
