/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Layout from '../../components/Layout/Layout';
import UserProfile from '../../features/UserProfile/UserProfile';
import UserActions from '../../features/UserActions/UserActions';
import UserAccounts from '../../features/UserAccounts/UserAccounts';
import Wallet from '../../features/Wallet/Wallet';
import AuditLog from '../../features/AuditLog/AuditLog';
import PotentialDuplicates from '../../features/PotentialDuplicates/PotentialDuplicates';
import RecentReports from '../../features/RecentReports/RecentReports';
import Stakes from '../../features/Stakes';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/BaseModal/BaseModal';
import CheckMarkIcon from '../../assets/Icons/plus-icon.svg';

import useUsers from '../../contexts/UserContext/UserContext';
import useAuth from '../../contexts/AuthContext/AuthContext';

import { formattedDate } from '../../utils';
import Notes from '../../features/Notes';
import MatchHistory from '../../features/Matches/MatchHistory';
import Input from '../../components/FormElements/Input';
import HoldButton from '../../components/HoldButton.tsx';
import client from '../../services/client';
import Button from '../../components/Buttons/Button/Button';
import OpenInbox from '../../features/Inbox/OpenInbox';
import IPOverlapModal from './IPOverlapModa';
import SessionsModal from './SessionsModal';

const User = () => {
  const { userId } = useParams();
  const {
    loading,
    user,
    checkIns,
    paginateCheckIns,
    getSingleUser,
    getCheckIns,
    getStakeSlips,
  } = useUsers();
  const { god } = useAuth();

  const [isPartner, setIsPartner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingTwitch, setIsEditingTwitch] = useState(false);
  const [showIds, setShowIds] = useState(false);
  const [gamerIdToEdit, setGamerIdToEdit] = useState('');
  const [idSystems, setIdSytems] = useState([]);
  const [identifications, setId] = useState(null);
  const [isIpOverlapShowing, setIsIpOverlapShowing] = useState(false);
  const [isSessionsModalShowing, setIsSessionsModalShowing] = useState(false);

  const globalUser = Object.keys(user).length ? user : god;

  useEffect(() => {
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/id_systems`)
      .then(({ data }) => setIdSytems(data));

    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/registered?user_id=${userId}`
      )
      .then(({ data }) => setIsPartner(data.registered));

    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/wallets/identity?user_id=${userId}`
      )
      .then(({ data }) => setId(data))
      .catch(() => {});
  }, []);

  useEffect(() => {
    getSingleUser(userId);
    getCheckIns(userId);
    getStakeSlips(userId);
  }, [userId]);

  const paginateData = () => {
    paginateCheckIns(userId);
  };

  if (Object.keys(globalUser).length === 0 || Object.keys(user).length === 0) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  return (
    <Layout showHeaderElements userId={globalUser.user.id}>
      <div className="md:grid md:grid-cols-6 gap-8 md:h-[calc(100vh-5rem)] overflow-hidden">
        <div className="overflow-auto no-scrollbar pb-8 md:grid md:grid-cols-2 col-span-4 gap-8">
          <div>
            <UserProfile
              globalUser={globalUser}
              openModal={() => setIsModalOpen(true)}
              openUsername={() => setIsEditingProfile(true)}
              isPartner={isPartner}
            />
            <UserActions user={globalUser} />
            <UserAccounts
              user={globalUser}
              onTwitch={() => setIsEditingTwitch(true)}
              onGamerId={(id) => setGamerIdToEdit(id)}
            />
          </div>
          <div>
            <div className="grid grid-cols-2 gap-2">
              <PotentialDuplicates />
              <MatchHistory username={globalUser.user.username} />
              <Stakes />
              <OpenInbox />
            </div>
            {globalUser.wallet.state === 'verification_needed' && (
              <p className="p-4 text-center rounded-xl bg-grey-4 text-grey-2 mt-2 font-bold">
                ID Verification Needed
              </p>
            )}
            {globalUser.wallet.state === 'verifying' && (
              <Link
                to={`/godmode/moderation?user=${globalUser.user.id}`}
                className="p-4 text-center rounded-xl bg-warning text-white mt-2 font-bold block w-full"
              >
                ID Awaiting Verification
              </Link>
            )}
            {globalUser.wallet.state === 'verified' && !identifications && (
              <p className="p-4 text-center rounded-xl border border-grey-4 text-white mt-2 font-bold flex items-center justify-center gap-2">
                ID Verified
                <small>(Legacy)</small>{' '}
                <span className="text-black bg-success rounded-full w-5 h-5 flex items-center justify-center">
                  ✓
                </span>{' '}
              </p>
            )}
            {globalUser.wallet.state === 'verified' && !!identifications && (
              <button
                type="button"
                onClick={() => setShowIds(true)}
                className="p-4 w-full bg-grey-4 text-center rounded-xl border border-grey-4 text-white mt-2 font-bold flex items-center justify-center gap-2"
              >
                ID Verified{' '}
                <span className="text-black bg-success rounded-full w-5 h-5 flex items-center justify-center">
                  ✓
                </span>{' '}
              </button>
            )}
            <div className="pt-4 grid grid-cols-2 gap-2">
              <Button
                text="IP Overlaps"
                variant="primary"
                className="!w-full"
                onClick={() => setIsIpOverlapShowing(true)}
              />
              <Button
                text="App Sessions"
                variant="primary"
                className="!w-full"
                onClick={() => setIsSessionsModalShowing(true)}
              />
            </div>

            <Wallet globalUser={globalUser} />
            <br />
            <AuditLog />
            <RecentReports />
          </div>
        </div>

        <div className="overflow-auto no-scrollbar col-span-2 max-h-screen">
          <Notes />
        </div>
      </div>

      <GamerIdModal
        isOpen={!!gamerIdToEdit}
        onSuccess={() => getSingleUser(userId)}
        onClose={() => setGamerIdToEdit('')}
        userId={user.user.id}
        gamerId={idSystems.find((s) => s.type === gamerIdToEdit)}
      />

      <TwitchModal
        isOpen={isEditingTwitch}
        onSuccess={() => getSingleUser(userId)}
        onClose={() => setIsEditingTwitch(false)}
        defaultValue=""
        userId={user.user.id}
      />

      <ProfileModal
        isOpen={isEditingProfile}
        onSuccess={() => getSingleUser(userId)}
        onClose={() => setIsEditingProfile(false)}
        defaultValue={user.user.username}
        profileImage={user.user.profile_image_url}
        userId={user.user.id}
      />

      <IPOverlapModal
        isOpen={isIpOverlapShowing}
        onClose={() => setIsIpOverlapShowing(false)}
        user={user}
      />

      <SessionsModal
        isOpen={isSessionsModalShowing}
        onClose={() => setIsSessionsModalShowing(false)}
        user={user}
      />

      <Modal
        modalIsOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        header="Check ins"
        paginateData={paginateData}
        loading={loading}
      >
        {checkIns.map(({ city, country, ip_address, state, created_at }) => (
          <div className="mt-3 w-full" key={`checkin_${created_at}`}>
            <div className="flex justify-between w-full">
              <h2 className="text-xs text-zinc-500 uppercase">
                IP: {ip_address}
              </h2>
              <h2 className="text-xs text-zinc-500 uppercase">
                {formattedDate(created_at)}
              </h2>
            </div>
            <h2 className="text-white text-xl">
              {city}, {state}, {country}
            </h2>
          </div>
        ))}
      </Modal>
      {identifications && (
        <IdModal
          selfie={identifications.selfie_image_url}
          id={identifications.id_image_url}
          isOpen={showIds}
          onClose={() => setShowIds(false)}
        />
      )}
    </Layout>
  );
};

const IdModal = ({ isOpen, onClose, selfie, id }) => {
  const idRef = useRef();
  const selfieRef = useRef();
  const [idDimensions, setIdDimensions] = useState({ width: 0, height: 0 });
  const [selfieDimensions, setSelfieDimensions] = useState({
    width: 0,
    height: 0,
  });

  const uiElements = [
    {
      name: 'custom-rotate-button',
      ariaLabel: 'Rotate',
      order: 9,
      isButton: true,
      html: {
        isCustomSVG: true,
        inner:
          '<path d="M13.887 6.078C14.258 6.234 14.5 6.598 14.5 7V8.517C18.332 8.657 21.258 10.055 23.15 12.367 24.519 14.041 25.289 16.13 25.496 18.409A1 1 0 0123.504 18.591C23.327 16.645 22.68 14.952 21.601 13.633 20.156 11.867 17.831 10.653 14.5 10.517V12A1.002 1.002 0 0112.779 12.693L10.304 10.121A1.002 1.002 0 0110.324 8.713L12.8 6.286A1 1 0 0113.887 6.078ZM7.5 16A1.5 1.5 0 006 17.5V24.5A1.5 1.5 0 007.5 26H17.5A1.5 1.5 0 0019 24.5V17.5A1.5 1.5 0 0017.5 16H7.5Z" id="pswp__icn-rotate"/>',
        outlineID: 'pswp__icn-rotate',
      },
      appendTo: 'bar',
      onClick: (e, el, pswpInstance) => {
        if (!pswpInstance.currSlide?.content.element) {
          return;
        }

        const item = pswpInstance.currSlide.content.element;

        const prevRotateAngle = Number(item.dataset.rotateAngel) || 0;
        const rotateAngle = prevRotateAngle === 270 ? 0 : prevRotateAngle + 90;

        // add slide rotation
        item.style.transform = `${item.style.transform.replace(
          `rotate(-${prevRotateAngle}deg)`,
          ''
        )} rotate(-${rotateAngle}deg)`;
        item.dataset.rotateAngel = String(rotateAngle);
      },
      onInit: (el, pswpInstance) => {
        // remove applied rotation on slide change
        // https://photoswipe.com/events/#slide-content-events
        pswpInstance.on('contentRemove', () => {
          if (!pswpInstance.currSlide?.content.element) {
            return;
          }

          const item = pswpInstance.currSlide.content.element;
          item.style.transform = `${item.style.transform.replace(
            `rotate(-${item.dataset.rotateAngel}deg)`,
            ''
          )}`;
          delete item.dataset.rotateAngel;
        });
      },
    },
  ];

  return (
    <Modal modalIsOpen={isOpen} closeModal={onClose} header="ID Images">
      <div>
        <h3 className="pt-4 pb-2 font-extrabold text-white text-2xl">ID</h3>
        <Gallery uiElements={uiElements} withCaption>
          {idDimensions.width && (
            <Item
              original={id}
              thumbnail={id}
              width={String(idDimensions.width)}
              height={String(idDimensions.height)}
              caption="ID"
            >
              {({ ref, open }) => (
                <div className="rounded-2xl border border-grey-4 w-full">
                  <img
                    ref={ref}
                    onClick={open}
                    className="cursor-pointer"
                    src={id}
                  />
                </div>
              )}
            </Item>
          )}
          <h3 className="pt-4 pb-2 font-extrabold text-white text-2xl">
            Selfie
          </h3>
          {selfieDimensions.width && (
            <Item
              original={selfie}
              thumbnail={selfie}
              width={String(selfieDimensions.width)}
              height={String(selfieDimensions.height)}
              caption="Selfie & ID"
            >
              {({ ref, open }) => (
                <div className="rounded-2xl border border-grey-4 w-full">
                  <img
                    ref={ref}
                    onClick={open}
                    className="cursor-pointer"
                    src={selfie}
                  />
                </div>
              )}
            </Item>
          )}
        </Gallery>
      </div>
      <div className="hidden">
        <img
          ref={idRef}
          alt="id"
          className="cursor-pointer"
          src={id}
          onLoad={({ target }) => {
            setIdDimensions({
              width: target.width,
              height: target.height,
            });
          }}
        />
        <img
          ref={selfieRef}
          alt="selfie"
          className="cursor-pointer"
          src={selfie}
          onLoad={({ target }) => {
            setSelfieDimensions({
              width: target.width,
              height: target.height,
            });
          }}
        />
      </div>
    </Modal>
  );
};

const ProfileModal = ({
  isOpen,
  onClose,
  defaultValue,
  onSuccess,
  userId,
  profileImage,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(profileImage);
  const [file, setFile] = useState(null);
  const hiddenFileInput = useRef(null);

  const handleUpdateUsername = async () => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/username`,
        { username: value.trim() }
      );
    } catch (e) {
      throw (
        e.response?.data?.message ||
        'Something went wrong updating the username'
      );
    }
  };

  const handleUpdateImage = async () => {
    if (newImage === profileImage) {
      return;
    }
    try {
      const { data } = await client.get(
        `${process.env.REACT_APP_API_URL}/api/v1/media/upload_url?type=profile`
      );
      if (file) {
        await axios.put(data.url, file, {
          headers: { 'Content-Type': file.type },
        });
      }

      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/profile_image`,
        { profile_image_url: newImage ? data.url : null }
      );
    } catch (e) {
      throw (
        e.response?.data?.message || 'Something went wrong updating the avatar'
      );
    }
  };

  const handleSubmit = async () => {
    setError('');
    setLoading(true);
    try {
      await handleUpdateUsername();
      await handleUpdateImage();

      onSuccess();
      onClose();
    } catch (e) {
      setError(String(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
      setError('');
      setValue(defaultValue);
      setNewImage(profileImage);
      setFile(null);
    }
  }, [isOpen]);

  const handleImage = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setFile(fileUploaded);
      const reader = new FileReader();
      // eslint-disable-next-line func-names
      reader.onload = function (event) {
        setNewImage(event.target.result);
      };

      reader.readAsDataURL(fileUploaded);
    } else {
      setFile(null);
      setNewImage('');
    }
  };

  const handlePhotoClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <Modal modalIsOpen={isOpen} closeModal={onClose} header="Update Profile">
      {loading && (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      )}
      {!loading && (
        <div className="flex flex-col items-stretch justify-between h-full pt-8">
          <div>
            <div className="flex mb-8 border-b border-grey-4 pb-8 gap-12">
              <img
                src={
                  newImage ||
                  'https://mobile-assets.1v1me.com/users/v1/default_profile.png'
                }
                alt="profile"
                className="w-24 h-24 rounded-full object-cover mx-auto mb-4"
              />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleImage}
                style={{ display: 'none' }}
                accept="image/*"
              />
              <div className="flex items-center justify-center gap-4 flex-col flex-1">
                <Button
                  text="Edit Image"
                  variant="tertiary"
                  rounded
                  className="!w-full"
                  onClick={handlePhotoClick}
                />
                <Button
                  text="Remove Image"
                  variant="tertiary-danger"
                  className="!w-full"
                  rounded
                  onClick={() => setNewImage('')}
                />
              </div>
            </div>

            <div>
              <p className="text-white font-extrabold text-sm mb-2">
                Edit Username
              </p>
              <Input
                placeholder={defaultValue || 'Username'}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              {error && (
                <p className="text-center text-warning py-2">{error}</p>
              )}
            </div>
          </div>

          <HoldButton
            timeout={250}
            text="Hold To Confirm Change"
            onConfirm={handleSubmit}
            variant="primary"
            disabled={!value.trim()}
          />
        </div>
      )}
    </Modal>
  );
};

const TwitchModal = ({ isOpen, onClose, defaultValue, onSuccess, userId }) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUpdateTwitch = async () => {
    try {
      setError('');
      setLoading(true);
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/connect_twitch`,
        { username: value }
      );
      onSuccess();
      onClose();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header="Connect Twitch"
      height="180px"
    >
      {loading && (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && (
        <div className="flex flex-col items-stretch justify-between h-full">
          <div>
            <Input
              placeholder={defaultValue || 'Twitch Username'}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {error && <p className="text-center text-warning py-2">{error}</p>}
          </div>
          <HoldButton
            timeout={250}
            text="Hold To Confirm Change"
            onConfirm={handleUpdateTwitch}
            variant="primary"
            disabled={!value}
          />
        </div>
      )}
    </Modal>
  );
};

const GamerIdModal = ({ isOpen, onClose, onSuccess, userId, gamerId }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setValue('');
    }
  }, [isOpen]);

  const handleUpdateTwitch = async () => {
    try {
      setError('');
      setLoading(true);
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/connect_gamer_id`,
        { name: value, id_system_id: gamerId.id }
      );
      onSuccess();
      onClose();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header={`Connect ${gamerId?.name || 'Account'}`}
      height="180px"
      large
    >
      {loading && (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && (
        <div className="flex flex-col items-stretch justify-between h-full sm:px-8 px-4 pt-4">
          <div>
            <Input
              placeholder={gamerId?.placeholder || 'Username'}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {error && <p className="text-center text-warning py-2">{error}</p>}
          </div>
          <HoldButton
            timeout={250}
            text="Hold To Confirm Change"
            onConfirm={handleUpdateTwitch}
            variant="primary"
            disabled={!value}
          />
        </div>
      )}
    </Modal>
  );
};

export default User;
