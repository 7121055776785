/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable indent */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Jersey } from '../../pages/TournamentManagement/ScoreCard';

const TeamsList = ({ completed, teams, handleOpenTeamDQModal }) => {
  const elimatedTeams = teams.filter((team) => team.placement > 3);

  return (
    <>
      <h2 className="text-white text-2xl font-extrabold mt-6 px-6 lg:px-0">
        Teams
      </h2>
      {teams.length ? (
        <div className="mt-3">
          {/* Completed Tournament */}
          {completed &&
            teams
              .filter((team) => team.placement > 0)
              .sort((teamA, teamB) => teamA.placement - teamB.placement)
              .map((team) => (
                <div
                  className="hover:bg-grey-4 pt-3 pb-4 px-4"
                  role="button"
                  key={uuidv4()}
                  onClick={() => handleOpenTeamDQModal(team)}
                >
                  <div
                    className="flex justify-between"
                    style={{ flexBasis: '1 1 0px' }}
                  >
                    <div className="relative lg:mt-2">
                      {team.tournament_players.map((player, i) => (
                        <div
                          key={uuidv4()}
                          className={cn(
                            'rounded-full w-8 h-8 bg-cover bg-center absolute z-50',
                            { 'left-4': i === 1 },
                            { 'left-8': i === 2 },
                            { 'left-12': i === 3 }
                          )}
                          style={{
                            backgroundImage: `url(${player.user_profile.profile_image_url})`,
                          }}
                        />
                      ))}
                    </div>
                    <div className="flex justify-between w-9/12 lg:w-10/12 items-center">
                      <div className="">
                        <h3 className="text-white font-bold text-sm lg:text-lg">
                          {team.display_team_name}
                        </h3>
                        <div className="flex">
                          {team.tournament_players.map((player, index) => (
                            <p
                              key={uuidv4()}
                              className="text-grey-2 text-xs mr-1"
                            >
                              {index ? <span className="mr-1">.</span> : null}
                              {player.user_profile.username}
                            </p>
                          ))}
                        </div>
                      </div>
                      {team.placement === 1 && (
                        <div className="text-sm bg-grey-3 uppercase text-yellow font-bold rounded-sm text-center px-2 mt-3">
                          Champion
                        </div>
                      )}
                      {team.placement === 2 && (
                        <div className="text-sm bg-grey-3 uppercase text-white font-bold rounded-sm text-center px-2 mt-3">
                          Winner
                        </div>
                      )}
                      {team.placement === 3 && (
                        <div className="text-sm bg-grey-3 uppercase text-warning font-bold rounded-sm text-center px-2 mt-3">
                          Winner
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          {teams
            .sort((teamA, teamB) => teamA.placement - teamB.placement)
            .map((team) => (
              <div key={uuidv4()}>
                {team.placement !== null &&
                  team.placement <= 3 &&
                  team.placement >= 0 &&
                  !completed && (
                    <div
                      className="hover:bg-grey-4 pt-3 pb-4 px-2 lg:px-0"
                      role="button"
                      onClick={() => handleOpenTeamDQModal(team)}
                    >
                      <div
                        className="flex justify-between"
                        style={{ flexBasis: '1 1 0px' }}
                      >
                        <div className="relative lg:mt-2">
                          {team.tournament_players.map((player, i) => (
                            <div
                              key={uuidv4()}
                              className={cn(
                                'rounded-full w-8 h-8 bg-cover bg-center absolute z-50',
                                { 'left-4': i === 1 },
                                { 'left-8': i === 2 },
                                { 'left-12': i === 3 }
                              )}
                              style={{
                                backgroundImage: `url(${player.user_profile.profile_image_url})`,
                              }}
                            />
                          ))}
                        </div>
                        <div className="flex justify-between w-9/12 lg:w-10/12 items-center">
                          <div className="">
                            <h3 className="text-white font-bold text-sm lg:text-lg">
                              Team {team.display_team_name}
                            </h3>
                            <div className="flex">
                              {team.tournament_players.map((player, index) => (
                                <p
                                  key={uuidv4()}
                                  className="text-grey-2 text-xs mr-1"
                                >
                                  {index ? (
                                    <span className="mr-1">.</span>
                                  ) : null}
                                  {player.user_profile.username}
                                </p>
                              ))}
                            </div>
                          </div>
                          {team.placement === 1 && (
                            <div className="text-sm bg-grey-3 uppercase text-yellow font-bold rounded-sm text-center px-2 mt-3">
                              Champion
                            </div>
                          )}
                          {team.placement === 2 && (
                            <div className="text-sm bg-grey-3 uppercase text-white font-bold rounded-sm text-center px-2 mt-3">
                              Winner
                            </div>
                          )}
                          {team.placement === 3 && (
                            <div className="text-sm bg-grey-3 uppercase text-warning font-bold rounded-sm text-center px-2 mt-3">
                              Winner
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            ))}
          {teams.map((team) => (
            <div
              key={uuidv4()}
              // eslint-disable-next-line no-confusing-arrow
              onClick={() => handleOpenTeamDQModal(team)}
              role="button"
            >
              {team.placement === null && !completed && (
                <div className="hover:bg-grey-4 pt-3 pb-4 px-2 lg:px-0">
                  <div
                    className="flex justify-between"
                    style={{ flexBasis: '1 1 0px' }}
                  >
                    <div className="relative lg:mt-2">
                      {team.tournament_players.map((player, i) => (
                        <div
                          key={uuidv4()}
                          className={cn(
                            'rounded-full w-8 h-8 bg-cover bg-center absolute z-50',
                            { 'left-4': i === 1 },
                            { 'left-8': i === 2 },
                            { 'left-12': i === 3 }
                          )}
                          style={{
                            backgroundImage: `url(${player.user_profile.profile_image_url})`,
                          }}
                        />
                      ))}
                    </div>
                    <div className="flex justify-between w-9/12 lg:w-10/12 items-center">
                      <div className="">
                        <h3 className="text-white font-bold text-sm lg:text-lg">
                          {team.display_team_name}
                        </h3>
                        <div className="flex">
                          {team.tournament_players.map((player, index) => (
                            <p
                              key={uuidv4()}
                              className="text-grey-2 text-xs mr-1"
                            >
                              {index ? <span className="mr-1">.</span> : null}
                              {player.user_profile.username}
                            </p>
                          ))}
                        </div>
                      </div>

                      <div className="text-sm bg-grey-4 uppercase text-white font-bold rounded-sm text-center px-4">
                        {team.state}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 flex items-center gap-2 pl-1">
                    {team.jersey_color && (
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="scale-[1.2]"
                      >
                        <path
                          d="M12.3357 1.57802L15.7168 5.87337L12.5788 7.52817L12.4455 7.59847L12.4454 7.74917L12.4424 13.0626H3.47643L3.47344 7.70234L3.47336 7.55147L3.33984 7.48123L0.283281 5.87332L3.66159 1.58145C3.66756 1.57632 3.6758 1.56898 3.68432 1.56047L3.69784 1.54695L3.70914 1.53153C3.96815 1.17833 4.42495 0.9375 4.95612 0.9375H5.72032C6.00319 1.93099 6.917 2.65768 8 2.65768C9.083 2.65768 9.99681 1.93099 10.2797 0.9375H11.0189C11.5289 0.9375 11.9432 1.15713 12.1898 1.48425L12.2481 1.5616L12.3357 1.57802Z"
                          fill={team.jersey_color}
                          stroke="#979797"
                          strokeWidth="0.5"
                        />
                      </svg>
                    )}
                    {team.tag && (
                      <span
                        style={{
                          backgroundColor: team.tag.background_color,
                          color: team.tag.text_color,
                        }}
                        className="text-xs bg-grey-3 px-2 py-[3px] rounded-full inline-flex items-center gap-1"
                      >
                        {team.tag.image_url && (
                          <img
                            src={team.tag.image_url}
                            alt={team.tag.title}
                            className="w-[16px] object-contain"
                          />
                        )}
                        {team.tag.title}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
          {elimatedTeams.length > 0 && !completed && (
            <>
              <h2 className="text-white text-2xl font-extrabold px-4">
                Eliminated
              </h2>
              {teams.map((team) => (
                <div key={uuidv4()}>
                  {team.placement > 3 && (
                    <div
                      className="hover:bg-grey-4 pt-3 pb-4 px-2 opacity-50"
                      role="button"
                    >
                      <div
                        className="flex justify-between"
                        style={{ flexBasis: '1 1 0px' }}
                      >
                        <div className="relative lg:mt-2">
                          {team.tournament_players.map((player, i) => (
                            <div
                              key={uuidv4()}
                              className={cn(
                                'rounded-full w-8 h-8 bg-cover bg-center absolute z-50',
                                { 'left-4': i === 1 },
                                { 'left-8': i === 2 },
                                { 'left-12': i === 3 }
                              )}
                              style={{
                                backgroundImage: `url(${player.user_profile.profile_image_url})`,
                              }}
                            />
                          ))}
                        </div>
                        <div className="flex justify-between w-9/12 lg:w-10/12 items-center">
                          <div className="">
                            <h3 className="text-white font-bold text-sm lg:text-lg">
                              Team {team.display_team_name}
                            </h3>
                            <div className="flex">
                              {team.tournament_players.map((player, index) => (
                                <p
                                  key={uuidv4()}
                                  className="text-grey-2 text-xs mr-1"
                                >
                                  {index ? (
                                    <span className="mr-1">.</span>
                                  ) : null}
                                  {player.user_profile.username}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      ) : (
        <div className="flex mt-3">
          <div className="p-6 rounded-md border-dashed border-2 border-grey-2 w-full">
            <p className="text-center text-grey-2">
              No teams singed up for for this tournament yet
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export const Team = ({ team, bg, onClick }) => {
  const image = useMemo(() => {
    const defaultImage =
      'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
    const byLeader = team.roster.players.find(
      (p) => p.type === 'leader' || p.type === 'TournamentPlayer::Leader'
    );

    if (byLeader && byLeader.user_profile.profile_image_url !== defaultImage) {
      return byLeader.user_profile.profile_image_url;
    }
    const byName = team.roster.players.find((p) => {
      if (team.roster.team_name) {
        return team.roster.team_name
          .toLowerCase()
          .includes(p.user_profile.username.toLowerCase());
      }
      return false;
    });
    if (byName && byName.user_profile.profile_image_url !== defaultImage) {
      return byName.user_profile.profile_image_url;
    }

    return defaultImage;
  }, [team.roster.team_name, team.roster.players]);
  return (
    <button
      type="button"
      className="text-white flex items-center gap-2 mb-4 p-4 rounded-lg bg-grey-3 w-full text-left"
      style={{ backgroundColor: bg }}
      onClick={() => onClick()}
    >
      <img
        src={image}
        onError={(e) => {
          // @ts-ignore
          e.target.src =
            'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
        }}
        alt={team.roster.team_name}
        className="rounded-full object-cover self-start w-[36px] h-[36px] "
      />
      <div className="flex-1">
        <h3 className="font-extrabold text-white leading-none  flex items-center gap-2">
          {team.roster.team_name}
        </h3>
        {team.roster.players.length > 1 ? (
          <p className="text-xs mt-1">
            {team.roster.players
              .map((p) => p.user_profile.username)
              .join(' · ')}
          </p>
        ) : team.tag ? (
          <span
            style={{
              backgroundColor: team.tag.background_color,
              color: team.tag.text_color,
            }}
            className="text-xs bg-grey-3 px-2 py-[3px] rounded-full mt-1 inline-flex items-center gap-1"
          >
            {team.tag.image_url && (
              <img
                src={team.tag.image_url}
                alt={team.tag.title}
                className="w-[16px] object-contain"
              />
            )}
            {team.tag.title}
          </span>
        ) : null}
      </div>
      <p className="text-xs leading-none flex items-center flex-col -mt-2">
        {team.jersey_color && <Jersey color={team.jersey_color} />} Edit Jersey
      </p>
    </button>
  );
};

TeamsList.propTypes = {
  completed: PropTypes.bool,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      active_television_channel_id: PropTypes.number,
      state: PropTypes.string,
      display_team_name: PropTypes.string,
      tournament_players: PropTypes.arrayOf(
        PropTypes.shape({
          user_profile: PropTypes.shape({
            profile_image_url: PropTypes.string,
            username: PropTypes.string,
          }),
        })
      ),
    })
  ),
  handleOpenTeamDQModal: PropTypes.func,
};

export default TeamsList;
