import React from 'react';

const UserIcon = ({ color = 'white', className = '' }) => (
  <svg
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.1912 11.6647C14.1501 11.6647 16.5699 9.07397 16.5699 5.77315C16.5699 2.56438 14.1238 0 11.1912 0C8.25863 0 5.79945 2.59068 5.8126 5.7863C5.8126 9.07397 8.23233 11.6647 11.1912 11.6647ZM2.56438 24H19.8181C21.4225 24 22.3825 23.2241 22.3825 21.9353C22.3825 18.5688 18.0033 13.966 11.1912 13.966C4.36603 13.966 0 18.5688 0 21.9353C0 23.2241 0.96 24 2.56438 24Z"
      fill={color}
    />
  </svg>
);

export default UserIcon;
