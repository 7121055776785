/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import Unlink from '../../assets/Icons/unlink-icon.svg';

const CreditCard = ({
  image,
  last4,
  expMonth,
  expYear,
  cardName,
  holder,
  onUnlink,
  disabled,
  type,
}) => {
  const date = new Date(expYear, expMonth - 1, 1);
  return (
    <div
      type="button"
      className={classNames(
        'p-4 pl-6 rounded-2xl text-grey-2 w-full text-left flex gap-4 ',
        {
          'border border-grey-3': !!disabled,
          'bg-grey-4': !disabled,
        }
      )}
    >
      <div className="shrink-0 rounded-full p-2 border flex items-center justify-center h-[48px] w-[48px] bg-white">
        <img
          src={image}
          alt={cardName}
          className="w-full object-contain shadow shadow-black/30"
        />
      </div>
      <div className="flex-1">
        <h3 className="font-extrabold text-white">
          {cardName || 'Unknown Brand'}{' '}
          <small className="text-xs font-normal">
            {type === 'PaymentSource::DotsCreditCard' ? '(Dots)' : '(PayPal)'}
          </small>
        </h3>
        <p className="text-sm">····{last4}</p>
        <p
          className={classNames('text-sm', {
            'text-warning': !isAfter(date, new Date()),
          })}
        >
          Expires: {String(expMonth).padStart(2, '0')}/{expYear}
        </p>
        <p className="text-sm">{holder}</p>
      </div>
      <div className="self-center">
        {onUnlink && (
          <button type="button" onClick={onUnlink}>
            <img src={Unlink} alt="unlink" className="hover:cursor-pointer" />
          </button>
        )}
      </div>
    </div>
  );
};

export default CreditCard;
