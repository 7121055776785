/* eslint-disable */
import classNames from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Button from '../../components/Buttons/Button/Button';
import AvatarGroup from '../../components/AvatarGroup.tsx';
import { Console, Game, GameMode, Match } from '../../types';
import convertToDollarAmount from '../../utils/convertToDollar';
import { getNumberWithOrdinal } from '../../utils/numbers';
import client from '../../services/client';
import Star from '../../assets/Icons/rounded-star.svg';

interface Props {
  match: Match;
  userId: number;
  senderId: number;
}

const MatchCard: FC<Props> = ({ match, userId, senderId }) => {
  const [gameMode, setGameMode] = useState<null | GameMode>(null);
  const [game, setGame] = useState<null | Game>(null);
  const [consoles, setConsoles] = useState<Console[]>([]);

  useEffect(() => {
    const cacheMode = sessionStorage.getItem(`game_mode_${match.game_mode_id}`);
    if (cacheMode) {
      setGameMode(JSON.parse(cacheMode) as GameMode);
    } else {
      client
        .get<GameMode>(
          `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${match.game_mode_id}`
        )
        .then(({ data }) => {
          sessionStorage.setItem(
            `game_mode_${match.game_mode_id}`,
            JSON.stringify(data)
          );
          setGameMode(data);
        });
    }

    const cacheGame = sessionStorage.getItem(`game_${match.game_id}`);
    if (cacheGame) {
      setGame(JSON.parse(cacheGame) as Game);
    } else {
      client
        .get<Game>(
          `${process.env.REACT_APP_API_URL}/api/v1/games/${match.game_id}`
        )
        .then(({ data }) => {
          sessionStorage.setItem(`game_${match.game_id}`, JSON.stringify(data));
          setGame(data);
        });
    }

    const cacheConsoles = sessionStorage.getItem('consoles');
    if (cacheConsoles) {
      setConsoles(JSON.parse(cacheConsoles));
    } else {
      client
        .get(`${process.env.REACT_APP_API_URL}/api/v1/consoles/`)
        .then(({ data }) => {
          sessionStorage.setItem('consoles', JSON.stringify(data));
          setConsoles(data);
        });
    }
  }, []);

  const sortedParticipants = useMemo(
    () =>
      [...match.participants].sort((a, b) =>
        a.user_id === userId ? -1 : a.team_uuid === b.team_uuid ? 0 : 1
      ),
    [match]
  );

  const userParticipant = useMemo(
    () => match.participants.find((p) => p.user_id === userId),
    [match]
  );

  const selectedConsole = useMemo(
    () =>
      consoles.length
        ? consoles.find((c) => c.id === match.console_id)
        : undefined,
    [consoles, match.console_id]
  );

  // TODO: Figure out a better way to do this
  const [topSection, bottomSection] = useMemo(() => {
    const userPlacement = match.placements.find(
      (p) => p.team_uuid === userParticipant?.team_uuid
    );
    const otherPlacement = match.placements.find(
      (p) => p.team_uuid !== userParticipant?.team_uuid
    );
    const userTeam = sortedParticipants.filter(
      (p) => p.team_uuid === userParticipant?.team_uuid
    );
    const otherTeam = sortedParticipants.filter(
      (p) => p.team_uuid !== userParticipant?.team_uuid
    );
    return [
      { team: userTeam, placement: userPlacement },
      { team: otherTeam, placement: otherPlacement },
    ];
  }, [match]);

  const matchFormat = useMemo(
    () => gameMode?.match_formats.find((f) => f.id === match.match_format_id),
    [match, gameMode]
  );

  const topLabel = useMemo(() => {
    if (match.state !== 'closed') return '--';
    if (gameMode?.label === 'FFA' && topSection.placement?.placement) {
      return getNumberWithOrdinal(topSection.placement?.placement);
    }
    if (topSection.placement?.placement === 1) {
      return 'Won';
    }
    return 'Lost';
  }, [match, gameMode]);

  const bottomLabel = useMemo(() => {
    if (match.state !== 'closed' || match.cancel_reason) return '--';
    if (gameMode?.label === 'FFA') return null;
    if (bottomSection.placement?.placement === 1) return 'Won';
    return 'Lost';
  }, [match, gameMode]);

  const cellType: 'reviewable' | 'live' | 'locked' | 'closed' = useMemo(() => {
    if (!gameMode) {
      return 'closed';
    }

    if (match.state === 'disputed' || match.state === 'closed') {
      return 'closed';
    }

    if (
      match.state === 'open' ||
      (match.state === 'locked' &&
        match.participants.some((p) => p.user_id === userId))
    ) {
      return 'reviewable';
    }
    if (
      match.state === 'live' ||
      match.state === 'starting' ||
      match.state === 'confirmed' ||
      (userParticipant && userParticipant?.state !== 'pending')
    ) {
      return 'live';
    }
    if (senderId === userId) {
      return 'closed';
    }
    if (match.state === 'locked') {
      if (
        !userParticipant &&
        match.participants.length <
          gameMode.num_players_per_team * gameMode.min_num_of_teams
      ) {
        return 'reviewable';
      }

      return 'locked';
    }
    return 'closed';
  }, [
    gameMode,
    match.participants,
    match.state,
    senderId,
    userId,
    userParticipant,
  ]);

  if (!game || !gameMode || !selectedConsole) return null;

  const isTeamGame = gameMode.num_players_per_team !== 1;

  if (cellType === 'live') {
    return <p>"Live"</p>;
    // return (
    //   <WatchCard
    //     topTeam={
    //       userParticipant ? (!isTeamGame ? "You" : "Your Team") : "Host Team"
    //     }
    //     bottomTeam={
    //       !isTeamGame
    //         ? bottomSection.team.length
    //           ? "Other Player"
    //           : "Open Slot"
    //         : bottomSection.team.length
    //         ? "Other Team"
    //         : "Pending Team"
    //     }
    //     amount={match.amount}
    //     link={match.state === "live" ? `/live/${match.id}` : undefined}
    //     image={gameMode.hero_url}
    //     tags={[
    //       { title: game.name, image_url: game.logo_url },
    //       { title: gameMode.title, image_url: gameMode.icon_url },
    //       { title: matchFormat?.description, image_url: matchFormat?.icon_url },
    //     ]}
    //     label={match.state === "locked" ? "open" : match.state}
    //   />
    // );
  }
  if (cellType === 'reviewable') {
    return (
      <div className="bg-grey-4  rounded-lg overflow-hidden inline-block min-w-[300px] lg:min-w-[400px]">
        <div className="h-40 relative -mx-4 -mt-3">
          <img
            src={gameMode.hero_url}
            alt={'bg'}
            className="object-cover w-full h-full absolute top-0 left-0"
          />
        </div>
        <div className="p-4 flex items-center justify-between gap-2 flex-col lg:flex-row">
          <img
            height={48}
            width={48}
            src={game.logo_url}
            className="rounded-full"
            alt={game.name}
          />
          <div className="mr-4 flex-1">
            <h3 className="text-white text-xl font-bold mb-1">
              Play {gameMode.title}
            </h3>
            <div className="flex items-center gap-2 text-xs flex-nowrap overflow-x-auto short-scroll">
              <div className="shrink-0">
                <img
                  height={12}
                  width={12}
                  src={selectedConsole.icon_url}
                  className="rounded-full object-contain"
                  alt={selectedConsole.name}
                />
              </div>
              <p className="text-grey-2 whitespace-nowrap">
                {selectedConsole.name}
              </p>
              <div className="shrink-0">
                <img src={Star} height={18} width={18} alt="star" />
              </div>
              <p className="text-grey-2 pr-2 -ml-1 whitespace-nowrap">
                {matchFormat?.description}
              </p>
            </div>
          </div>
          <p className="text-2xl font-bold">
            {typeof match.amount === 'number'
              ? convertToDollarAmount(match.amount)
              : `${convertToDollarAmount(match.amount_range.min)}${
                  match.amount_range.max !== match.amount_range.min
                    ? `-${convertToDollarAmount(match.amount_range.max)}`
                    : ''
                }`}
            <span className="block text-grey-2 font-normal uppercase text-sm text-center lg:text-right">
              Buy-In
            </span>
          </p>
        </div>
        <div className="px-4 pb-4 flex items-center justify-center">
          <Button
            variant="primary"
            text="View Challenge"
            rounded
            onClick={() => {}}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="relative ">
      <div
        className={classNames(
          'rounded-lg  py-3 px-4 overflow-hidden bg-black border border-grey-3  min-w-[400px]'
        )}
      >
        <div className="flex items-start justify-between  leading-none gap-24">
          <div>
            <h3 className="text-white text-xl font-bold mb-1">
              Play {gameMode.title}
            </h3>
            <div className="mb-4 flex items-center gap-2 text-xs">
              <img
                height={16}
                width={16}
                src={game.logo_url}
                className="rounded-full"
                alt={game.name}
              />
              <p className="text-grey-2">{match.game_name}</p>
              <img src={Star} height={16} width={16} alt="star" />
              <p className="text-grey-2">{matchFormat?.description}</p>
            </div>
          </div>
          <span className="text-2xl font-bold">
            {typeof match.amount === 'number'
              ? convertToDollarAmount(match.amount)
              : `${convertToDollarAmount(match.amount_range.min)}${
                  match.amount_range.max !== match.amount_range.min
                    ? `-${convertToDollarAmount(match.amount_range.max)}`
                    : ''
                }`}
            <span className="block text-warning font-normal uppercase text-sm text-right">
              Pot
            </span>
          </span>
        </div>

        <div
          className={classNames(
            'w-full flex items-center justify-between text-base mb-2',
            {
              'font-bold text-white': topLabel !== 'Lost',
              'text-grey-2': topLabel === 'Lost',
            }
          )}
        >
          <div className="flex items-center gap-8">
            <AvatarGroup users={topSection.team} limit={3} />
            <p>
              {topSection.team[0]?.username}{' '}
              {topSection.team.length > 1 && `+${topSection.team.length - 1}`}
            </p>
          </div>
          <span>{topLabel}</span>
        </div>
        <div
          className={classNames(
            'w-full flex items-center justify-between text-base',
            {
              'font-bold text-white': bottomLabel === 'Won',
              'text-grey-2': bottomLabel === 'Lost',
              'opacity-0': !bottomSection.team[0],
            }
          )}
        >
          <div className="flex items-center gap-8">
            <AvatarGroup users={bottomSection.team} limit={3} />
            <p>
              {bottomSection.team[0]?.username}{' '}
              {bottomSection.team.length > 1 &&
                `+${bottomSection.team.length - 1}`}
            </p>
          </div>
          <span>{bottomLabel}</span>
        </div>
      </div>
      {['live', 'starting', 'open', 'locked'].includes(match.state) && (
        <div className="absolute bottom-0 left-0 w-full flex items-center justify-center">
          <p className="inline-block bg-black uppercase text-xs text-grey-2 px-2 translate-y-1">
            {match.state === 'locked' ? 'Open' : match.state}
          </p>
        </div>
      )}
    </div>
  );
};

export default MatchCard;
