import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import usepotentialDuplicates from '../../contexts/PotentialDuplicatesContext/PotentialDuplicatesContext';
import useUsers from '../../contexts/UserContext/UserContext';
import handleImageError from '../../utils/imageError';

import Modal from '../../components/BaseModal/BaseModal';

const PotentialDuplicates = () => {
  const { duplicates, getDuplicates } = usepotentialDuplicates();
  const { user } = useUsers();

  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (user.user) {
      getDuplicates(user.user.id);
    }
  }, [user]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        onClick={openModal}
        role="button"
        className="flex justify-between items-center bg-zinc-900 rounded-md text-white text-lg font-extrabold p-4 cursor-pointer"
      >
        <p className="truncate">Potential Duplicates</p>
        {duplicates.length > 0 && (
          <p className="text-white btn-warning text-xs rounded-full w-6 h-6 text-center flex items-center justify-center">
            {duplicates.length}
          </p>
        )}
      </div>
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        header="Potential Duplicates"
      >
        <div className="mt-3">
          {duplicates.map(({ user: duplicateUser }) => (
            <Link
              to={`/godmode/users/${duplicateUser.id}`}
              className="flex justify-between mb-4"
              key={duplicateUser.id}
              onClick={closeModal}
            >
              <div className="flex items-center">
                <img
                  src={duplicateUser.profile_image_url}
                  alt="profile"
                  className="rounded-full w-10"
                  onError={handleImageError}
                />
                <p className="text-white text-l ml-3">
                  {duplicateUser.username}
                </p>
              </div>
              <p
                className={cn('uppercase text-xs mt-3', {
                  'text-primary':
                    duplicateUser.status === 'disabled' ||
                    duplicateUser.status === 'banned',
                  'text-zinc-500':
                    duplicateUser.status === 'enabled' ||
                    duplicateUser.status === 'unverified',
                })}
              >
                {duplicateUser.status}
              </p>
            </Link>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default PotentialDuplicates;
