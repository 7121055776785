/* eslint-disable no-alert */

import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { TwitchPlayer } from 'react-twitch-embed';

import { useSearchParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import SlideOut from '../../components/SlideOutContent/SlideOutContent';
import DisputesList from '../../features/DisputesList/DisputesList';
import MatchInformations from '../../components/MatchInformation/MatchInformation';
import AssignDispute from '../../features/AssignDispute/AssignDispute';

import useDisputes from '../../contexts/DisputesContext/DisputesContext';
import useAuth from '../../contexts/AuthContext/AuthContext';
import Modal from '../../components/Modal/Modal';
import client from '../../services/client';
import Button from '../../components/Buttons/Button/Button';

const User = () => {
  const [search, setSearch] = useSearchParams();

  const { god } = useAuth();
  const {
    gameMode,
    rules,
    disputes,
    disputeClip,
    getDisputes,
    getRulesSet,
    getGameMode,
    getDisputeClip,
    submitMatchPlacement,
    cancelMatch,
    fetchedAll,
  } = useDisputes();

  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [selectedDispute, setSelectedDispute] = useState({});
  const [video, setVideo] = useState({});
  const [disputeModal, setDisputeModal] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getDisputes(page);
  }, [page]);

  useEffect(() => {
    if (disputes.length && search.get('selectedMatch')) {
      const found = disputes.find(
        (d) => d.id === Number(search.get('selectedMatch'))
      );
      if (found) {
        setSelectedDispute(found);
      }
      setSearch(
        {},
        {
          replace: true,
        }
      );
    }
  }, [disputes]);

  useEffect(() => {
    if (Object.keys(disputeClip).length > 0) {
      const initialClip = disputeClip.television_channel_participants[0];

      setVideo(initialClip);
    }
  }, [disputeClip]);

  const handleGetGameMode = (gameModeId, elementSetId, matchId) => {
    setIsRulesOpen(true);
    getGameMode(gameModeId);
    getRulesSet(elementSetId, matchId);
  };

  const handleSetDispute = (matchId) => {
    const dispute = disputes.filter((element) => element.id === matchId);
    setSelectedDispute(dispute[0]);
    setVideo({});
    getDisputeClip(dispute[0].id);
  };

  const findNextMatch = () => {
    if (disputes.length > 0) {
      setSelectedDispute(disputes[0]);
    }
  };

  const handleMatchDispute = (cat) => {
    if (!disputeModal) {
      return;
    }
    submitMatchPlacement(
      disputeModal.disputeId,
      disputeModal.winnerUUID,
      disputeModal.loserUUID,
      cat
    );
    setVideo({});
    setSelectedDispute({});
    setDisputeModal(null);
  };

  const handleCancelMatch = (disputeId) => {
    const res = window.confirm('Are you sure!?');
    if (res) {
      cancelMatch(disputeId);
      setVideo({});
      findNextMatch();
    }
  };

  const handleProgress = (state) => {
    video.duration = state.playedSeconds;

    setVideo(video);
  };

  const handlePerspectiveChange = (clippedVideo) => {
    setVideo(clippedVideo);
  };

  const handleReplay = async (id) => {
    try {
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${id}/replay`
        );
        getDisputes();
        setSelectedDispute({});
      }
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  let disputedSortedGroups = [];

  if (selectedDispute.participants?.length > 0) {
    const selectedDisputesParticipants = groupBy(
      selectedDispute.participants,
      'team_uuid'
    );

    disputedSortedGroups = Object.keys(selectedDisputesParticipants).map(
      (key) => selectedDisputesParticipants[key]
    );
  }

  const matchGroups = groupBy(
    disputeClip.television_channel_participants,
    'group_identifier'
  );

  const sortedGroup = Object.keys(matchGroups).map((key) => matchGroups[key]);

  if (Object.keys(god).length === 0) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  return (
    <Layout showHeaderElements userId={god.id} className="md:px-0">
      <SlideOut
        isOpen={isRulesOpen}
        handleOpenSlider={() => setIsRulesOpen(false)}
        setSlideOutWidth={() => {}}
        setSlideOutHeight={() => {}}
      >
        {Object.keys(gameMode).length > 0 && Object.keys(rules).length > 0 && (
          <div className="mt-3 overflow-auto pb-8 h-[calc(100vh-60px)]">
            <h3 className="text-white font-bold text-2xl">{gameMode.title}</h3>
            <p className="text-grey-2 text-sm mt-1">{gameMode.description}</p>
            <div className="flex items-center mt-3 border-grey-3 border rounded-xl p-2">
              <img
                src={rules.match_format.icon_url}
                alt={rules.match_format.title}
                className="w-8"
              />
              <div className="ml-3">
                <h3 className="text-grey-2 text-xs">
                  {rules.match_format.title}
                </h3>
                <p className="text-white font-bold text-sm">
                  {rules.match_format.description}
                </p>
              </div>
            </div>
            <div className="flex items-center mt-3 border-grey-3 border rounded-xl p-2">
              <img src={gameMode.icon_url} alt={gameMode.id} className="w-8" />
              <div className="ml-3">
                <h3 className="text-grey-2 text-xs">Game Mode</h3>
                <p className="text-white font-bold text-sm">{gameMode.name}</p>
              </div>
            </div>
            {rules.rule_types.map((rule) => (
              <div
                key={rule.id}
                className="flex items-center mt-3 border-grey-3 border rounded-xl p-2"
              >
                <img src={rule.icon_url} alt={rule.id} className="w-8" />
                <div className="ml-3">
                  <h3 className="text-grey-2 text-xs">{rule.title}</h3>
                  <p className="text-white font-bold text-sm">
                    {rule.rule_option.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </SlideOut>
      <div className="flex">
        <div
          className="w-4/12 lg:w-3/12 relative border-r border-grey-3"
          style={{ height: 'calc(100vh - 80px)' }}
        >
          <DisputesList
            disputes={disputes}
            handleGetGameMode={handleGetGameMode}
            handleSetDispute={handleSetDispute}
            setSelectedDispute={setSelectedDispute}
            closeVideoPlayer={() => setVideo({})}
            selectedDispute={selectedDispute}
          />
        </div>

        <div className="mx-3 lg:mx-0 w-8/12 lg:w-8/12">
          {Object.keys(selectedDispute).length > 0 &&
            Object.keys(disputeClip).length === 0 && (
              <h3 className="text-white text-xl font-bold w-1/2 m-auto text-center my-12">
                No Streams Available
              </h3>
            )}
          {Object.keys(video).length > 0 && video.twitch_vod_id ? (
            <div>
              <div className="clips-player-wrapper">
                <TwitchPlayer
                  video={video.twitch_vod_id}
                  parent={[
                    'localhost',
                    'onevoneme-staging.herokuapp.com',
                    'onevoneme.herokuapp.com',
                    '1v1me.com',
                  ]}
                  autoplay
                  className="react-player"
                  onProgress={handleProgress}
                  playsInline
                  controls
                  time={video.start_time}
                />
              </div>
            </div>
          ) : (
            <h3 className="text-white text-xl font-bold w-1/2 m-auto text-center my-36 lg:my-60">
              No Streams Available
            </h3>
          )}
          {Object.keys(disputeClip).length > 0 && (
            <MatchInformations
              setVideo={handlePerspectiveChange}
              clipInfo={disputeClip}
              sortedGroups={sortedGroup}
              video={video}
            />
          )}
          <div className="lg:hidden mt-20">
            <AssignDispute
              disputedSortedGroups={disputedSortedGroups}
              selectedDispute={selectedDispute}
              handleMatchDispute={(disputeId, winnerUUID, loserUUID) =>
                setDisputeModal({ disputeId, winnerUUID, loserUUID })
              }
              cancelMatch={cancelMatch}
              replayMatch={handleReplay}
            />
          </div>
        </div>
        <div className="hidden lg:block w-3/12 mx-3 pl-3 border-l border-grey-3">
          <AssignDispute
            disputedSortedGroups={disputedSortedGroups}
            selectedDispute={selectedDispute}
            handleMatchDispute={(disputeId, winnerUUID, loserUUID) =>
              setDisputeModal({ disputeId, winnerUUID, loserUUID })
            }
            cancelMatch={handleCancelMatch}
            replayMatch={handleReplay}
          />
        </div>
      </div>
      <DisputeModal
        baseCategory={selectedDispute.category || ''}
        isOpen={!!disputeModal}
        onClose={() => setDisputeModal(null)}
        onSubmit={handleMatchDispute}
        teamName={
          selectedDispute?.participants?.find(
            (p) => p.team_uuid === disputeModal?.winnerUUID
          )?.username || ''
        }
      />
    </Layout>
  );
};

const DisputeModal = ({
  isOpen,
  onClose,
  onSubmit,
  baseCategory,
  teamName,
}) => {
  const [category, setCategory] = useState(baseCategory || '');
  const [catagories, setCategories] = useState([]);
  useEffect(() => {
    setCategory(baseCategory || '');
  }, [baseCategory]);
  useEffect(() => {
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/faq/support_disputes`)
      .then(({ data }) => setCategories(data));
  }, []);
  const handleSubmit = () => {
    onSubmit(category);
  };

  const getCategoryColor = (c) => {
    if (c === 'No Show') {
      return '#00AD1C';
    }
    if (c === 'Broken Rule') {
      return '#DE12FF';
    }
    if (c === 'Disconnect') {
      return '#FFD912';
    }
    if (c === 'Cheating') {
      return '#FF124F';
    }
    if (c === 'Mutual Cancel') {
      return '#1263FF';
    }
    return '#979797';
  };

  return (
    <Modal
      header="Are you sure" // "What type of dispute was this?"
      closeModal={onClose}
      modalIsOpen={isOpen}
      height="500px"
    >
      <div className="px-4 h-full flex flex-col justify-between">
        <div>
          {catagories.map((c) => (
            <button
              type="button"
              key={`category_${c.id}`}
              title={c.title}
              disabled={category === c.category}
              className="block bg-grey-4 disabled:bg-white transition-colors w-full rounded mb-3 py-3 px-8 text-left font-bold"
              style={{ color: getCategoryColor(c.category || 'Other') }}
              onClick={() => setCategory(c.category)}
            >
              {c.category}
            </button>
          ))}
        </div>
        <div className="flex items-center justify-end">
          <Button
            variant="primary"
            // disabled={!category}
            text={`Assign ${teamName} Win`}
            onClick={handleSubmit}
            className="!w-full"
            rounded
            large
          />
        </div>
      </div>
    </Modal>
  );
};

export default User;
