import classNames from 'classnames';
import { isDate } from 'date-fns';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import SearchIcon from '../../assets/Icons/search-icon.svg';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';

const SessionsModal = ({ user, isOpen, onClose }) => {
  const [isLoadingInitial, setLoadingInitial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doneFetching, setDoneFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {}, [isOpen]);

  useEffect(() => {
    if (!user?.user?.id) {
      return;
    }
    if (!isOpen) {
      setSessions([]);
    }
    if (page === 1) {
      setLoadingInitial(true);
    } else {
      setLoading(true);
    }
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${user.user.id}/sessions?page=${page}&page_size=10`
      )
      .then(({ data }) => {
        if (data.length < 10) {
          setDoneFetching(true);
        }
        if (page === 1) {
          setSessions(data);
        } else {
          setSessions((s) => [...s, ...data]);
        }
      })
      .finally(() => {
        setLoadingInitial(false);
        setLoading(false);
      });
  }, [page, user, isOpen]);

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header="App Sessions"
      height="auto"
      backgroundColor="black"
      large
    >
      <div className="text-grey-2 pb-4 sm:px-8 px-4">
        {isLoadingInitial && <Loader />}
        <div>
          {sessions.map((s) => (
            <div
              className="mb-4 p-4 border border-grey-4 rounded-2xl"
              key={`session_${s.created_at}`}
            >
              {Object.keys(s).map((key) => (
                <p className="text-white text-xs">
                  <b className="text-xs">{key}:</b>{' '}
                  {['created_at', 'updated_at'].includes(key)
                    ? format(new Date(s[key]), 'MMMM do, y @ h:mm aa')
                    : s[key]}
                </p>
              ))}
            </div>
          ))}
        </div>
        {!doneFetching && sessions.length > 0 && (
          <div className="flex items-center justify-center pb-8 mt-8">
            <Button
              variant="secondary"
              text="Load More"
              onClick={() => setPage(page + 1)}
              disabled={loading}
              isLoading={loading}
            />
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default SessionsModal;
