import React from 'react';
import { Link } from 'react-router-dom';
import ImageIcon from '../../assets/Icons/image.svg';

import useAuth from '../../contexts/AuthContext/AuthContext';

import Layout from '../../components/Layout/Layout';

import ClipIcon from '../../assets/Icons/clip-icon.svg';

const ClipDownloader = () => {
  const { god } = useAuth();

  return (
    <Layout Layout showHeaderElements userId={god.id}>
      <div className="flex gap-4 items-center">
        <Link
          to="/marketing/clip-downloader"
          className="flex justify-center items-center text-sm btn-tertiary hover:bg-grey-3 w-64 mt-3 mb-6 rounded-lg h-12"
        >
          <div className="flex items-center py-3">
            <img src={ClipIcon} alt="profile" className="w-6" />
            <p className="text-white text-lg ml-3 font-bold">Clip Downloader</p>
          </div>
        </Link>
        <Link
          to="/marketing/image-uploader"
          className="flex justify-center items-center text-sm btn-tertiary hover:bg-grey-3 w-64 mt-3 mb-6 rounded-lg h-12"
        >
          <div className="flex items-center py-3">
            <img src={ImageIcon} alt="profile" className="w-6" />
            <p className="text-white text-lg ml-3 font-bold">Image Uploader</p>
          </div>
        </Link>
        <Link
          to="/marketing/referrals"
          className="flex justify-center items-center text-sm btn-tertiary hover:bg-grey-3 w-64 mt-3 mb-6 rounded-lg h-12"
        >
          <div className="flex items-center py-3">
            <img
              src="https://www.1v1me.com/Icons/boost-circle.svg"
              alt="profile"
              className="w-6"
            />
            <p className="text-white text-lg ml-3 font-bold">Referral Codes</p>
          </div>
        </Link>
        <Link
          to="/marketing/email-builder"
          className="flex justify-center items-center text-sm btn-tertiary hover:bg-grey-3 w-64 mt-3 mb-6 rounded-lg h-12"
        >
          <div className="flex items-center py-3">
            <p className="text-white text-lg font-bold">📫 Email Builder</p>
          </div>
        </Link>
      </div>
    </Layout>
  );
};

export default ClipDownloader;
