/* eslint-disable no-unused-expressions */

const formatBrackets = (bracket, teams) => {
  const winners = [];
  const losers = [];

  bracket[0]?.rounds.map((round) =>
    round.matches.forEach((match) => {
      let participants;
      let matchState = 'SCHEDULED';

      const team1Id = match.tournament_team_1_id || match.tournament_team1?.id;
      const team2Id = match.tournament_team_2_id || match.tournament_team2?.id;

      if (team1Id && team2Id) {
        const team1 = teams.find((element) => element.id === team1Id);
        const team2 = teams.find((element) => element.id === team2Id);

        participants = [team1, team2];
      } else if (team1Id && !team2Id) {
        const team1 = teams.find((element) => element.id === team1Id);

        participants = [team1, { name: '--' }];
      } else if (!team1Id && team2Id) {
        const team2 = teams.find((element) => element.id === team2Id);
        participants = [{ name: '--' }, team2];
      } else if (!team1Id && !team2Id && match.round_name !== 'Grand Finals') {
        participants = [
          { name: match.top_label },
          { name: match.bottom_label },
        ];
      } else if (!team1Id && !team2Id && match.round_name === 'Grand Finals') {
        participants = [{ name: 'If Necessary' }, { name: 'If Necessary' }];
      } else if (!team1Id && !team2Id) {
        participants = [
          { name: match.top_label },
          { name: match.bottom_label },
        ];
      }

      participants = (participants || [])?.map((participant) => {
        if (
          participant !== undefined &&
          participant.id === match.round_winner_id
        ) {
          matchState = 'DONE';
          return { ...participant, isWinner: true };
        }
        return { ...participant, isWinner: false };
      });

      winners.push({
        id: match.id,
        roundName: match.round_name,
        name: match.cell_label,
        nextMatchId: match.winner_round_match_id,
        nextLooserMatchId: match.loser_round_match_id,
        state: matchState,
        participants,
        topLabel: match.top_label,
        bottomLabel: match.bottom_label,
        television_channel_id: match.television_channel_id,
        television_channel_thumbnail: match.television_channel_thumbnail,
        roundWinnerId: match.round_winner_id,
      });
    })
  );

  bracket[1]?.rounds.map((round) =>
    round.matches.forEach((match) => {
      let participants;
      let matchState = 'SCHEDULED';

      const team1Id = match.tournament_team_1_id || match.tournament_team1?.id;
      const team2Id = match.tournament_team_2_id || match.tournament_team2?.id;

      if (team1Id && team2Id) {
        const team1 = teams.find((element) => element.id === team1Id);
        const team2 = teams.find((element) => element.id === team2Id);
        participants = [team1, team2];
      } else if (team1Id && !team2Id) {
        const team1 = teams.find((element) => element.id === team1Id);

        participants = [team1, { name: '--' }];
      } else if (!team1Id && team2Id) {
        const team2 = teams.find((element) => element.id === team2Id);
        participants = [{ name: '--' }, team2];
      } else if (!team1Id && !team2Id && match.round_name !== 'Grand Finals') {
        participants = [
          { name: match.top_label },
          { name: match.bottom_label },
        ];
      } else if (!team1Id && !team2Id && match.round_name === 'Grand Finals') {
        participants = [{ name: 'If Necessary' }, { name: 'If Necessary' }];
      } else if (!team1Id && !team2Id) {
        participants = [
          { name: match.top_label },
          { name: match.bottom_label },
        ];
      }

      participants = participants?.map((participant) => {
        if (
          participant !== undefined &&
          participant.id === match.round_winner_id
        ) {
          matchState = 'DONE';
          return { ...participant, isWinner: true };
        }
        return { ...participant, isWinner: false };
      });

      losers.push({
        id: match.id,
        roundName: match.round_name,
        name: match.cell_label,
        nextMatchId: match.winner_round_match_id,
        nextLooserMatchId: match.loser_round_match_id,
        state: matchState,
        participants,
        topLabel: match.top_label,
        bottomLabel: match.bottom_label,
        television_channel_id: match.television_channel_id,
        television_channel_thumbnail: match.television_channel_thumbnail,
        roundWinnerId: match.round_winner_id,
      });
    })
  );

  return {
    upper: winners,
    lower: losers,
    isSingle: !bracket[1] || bracket[1].name === 'No Losers',
  };
};

const formatRound = (round) => {
  let participants;
  let matchState = 'SCHEDULED';

  if (round.tournament_team1 && round.tournament_team2) {
    participants = [round.tournament_team1, round.tournament_team2];
  } else if (round.tournament_team1 && round.tournament_team2 === null) {
    participants = [round.tournament_team1, { name: '--' }];
  } else if (round.tournament_team1 === null && round.tournament_team2) {
    participants = [{ name: '--' }, round.tournament_team2];
  } else if (
    round.tournament_team1 === null &&
    round.tournament_team2 === null &&
    round.round_name !== 'Grand Finals'
  ) {
    participants = [{ name: round.top_label }, { name: round.bottom_label }];
  } else if (
    round.tournament_team1 === null &&
    round.tournament_team2 === null &&
    round.round_name === 'Grand Finals'
  ) {
    participants = [{ name: 'If Necessary' }, { name: 'If Necessary' }];
  } else if (
    round.tournament_team1 === null &&
    round.tournament_team2 === null
  ) {
    participants = [{ name: round.top_label }, { name: round.bottom_label }];
  }

  participants = (participants || [])?.map((participant) => {
    if (participant.id === round.round_winner_id) {
      matchState = 'DONE';
      return { ...participant, isWinner: true };
    }
    return { ...participant, isWinner: false };
  });

  const formattedRound = {
    id: round.id,
    roundName: round.round_name,
    name: round.cell_label,
    nextMatchId: round.winner_round_match_id,
    nextLooserMatchId: round.loser_round_match_id,
    state: matchState,
    participants,
    topLabel: round.top_label,
    bottomLabel: round.bottom_label,
    television_channel_id: round.television_channel_id,
    television_channel_thumbnail: round.television_channel_thumbnail,
    roundWinnerId: round.round_winner_id,
  };

  return formattedRound;
};

export { formatBrackets, formatRound };
