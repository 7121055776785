import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

const TabGroup: FC<{
  tabs: string[];
  onChange: (tab: string) => void;
  activeTab: string;
  activeTabs?: string[];
  multi?: boolean;
}> = ({ tabs, activeTab, activeTabs, onChange, multi }) => (
  <div className="inline-flex items-center relative gap-4 flex-nowrap overflow-x-auto short-scroll">
    {tabs.map((tab) => (
      <button
        key={tab}
        // style={{ width: tab === activeTab ? "auto" : 100 }}
        type="button"
        onClick={() => onChange(tab)}
        className={classNames(
          'px-4 py-2 shrink-0 text-center transition-all duration-300 ease-in-out flex justify-center items-center z-10 rounded-full truncate text-grey-2 hover:opacity-70  !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue',
          {
            '!text-black font-bold pointer-events-none !px-8 bg-white':
              tab === activeTab,
            '!text-black font-bold !px-8 bg-white':
              activeTabs?.includes(tab) && multi,
          }
        )}
      >
        {tab}
      </button>
    ))}
  </div>
);

export default TabGroup;
