/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import StakeSlip from '../StakeSlip';

const StakeCard = ({ stake }) => {
  const getWinnings = (place, slip) => {
    if (!slip) return 0;
    const placement = slip.potential_prizes.find((p) => p.placement === place);
    return placement
      ? placement.value * (slip.paid_amount + (slip.promotional_amount || 0))
      : 0;
  };

  const stakedTeams = useMemo(
    () =>
      stake.event
        ? stake.slips
            .map((s) => {
              const team = stake.event.teams.find(
                (t) => t.roster.id === s.event_team_id
              );

              return {
                teamId: s.tournament_team_id,
                name: team.roster.team_name,
                players: team.roster.players.map(
                  (p) => p.user_profile.username
                ),
                avatar: team.roster.players.find((p) => p.type === 'leader')
                  ?.user_profile.profile_image_url,
                staked: s.paid_amount,
                toWin:
                  s.potential_prizes.find((p) => p.placement === 1)?.payout ||
                  0,
                promoAmount: s.promotional_amount,
                returned: s.awarded_amount,
                placement:
                  typeof s.awarded_amount === 'number'
                    ? team.placement
                    : undefined,
              };
            })
            .sort((a, b) => b.placement - a.placement)
        : null,
    [stake.slips, stake.event]
  );

  const actualWinnings = useMemo(() => {
    if (!stake.slips.some((s) => typeof s.awarded_amount === 'number')) {
      return null;
    }
    return stake.slips.reduce(
      // eslint-disable-next-line no-return-assign, no-param-reassign
      (prev, curr) => (prev += curr.awarded_amount),
      0
    );
  }, [stake.slips]);

  const maxWinnings = useMemo(() => {
    if (!stake.slips.length) return 0;

    let firstPlace = stake.slips[0];
    let secondPlace = null;
    let thirdPlace = null;

    // Find first
    stake.slips.forEach((data) => {
      if (getWinnings(1, data.slip) > getWinnings(1, firstPlace.slip)) {
        firstPlace = data;
      }
    });

    // Find Second
    stake.slips.forEach((data) => {
      if (
        getWinnings(2, data.slip) > getWinnings(2, secondPlace?.slip) &&
        data.team.id !== firstPlace.team.id
      ) {
        secondPlace = data;
      }
    });

    // Find Third
    stake.slips.forEach((data) => {
      if (
        getWinnings(3, data.slip) > getWinnings(3, thirdPlace?.slip) &&
        data.team.id !== firstPlace.team.id &&
        data.team.id !== secondPlace.team.id
      ) {
        thirdPlace = data;
      }
    });

    return Math.floor(
      getWinnings(1, firstPlace.slip) +
        getWinnings(2, secondPlace?.slip) +
        getWinnings(3, thirdPlace?.slip)
    );
  }, [stake.slips]);

  return stakedTeams ? (
    <div className="mb-4">
      <StakeSlip
        event={stake.event}
        stakes={{ teams: stakedTeams, maxWinnings, actualWinnings }}
      />
    </div>
  ) : null;
};

export default StakeCard;
