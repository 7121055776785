import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/client';

const potentialDuplicatesContext = createContext({
  wallet: [],
});

const usepotentialDuplicates = () => {
  const [duplicates, setDuplicates] = useState([]);

  const getDuplicates = async (userId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/sketchy`
    );

    setDuplicates(data);
  };

  return {
    duplicates,
    getDuplicates,
  };
};

export function PotentialDuplicatesProvider({ children }) {
  const duplicates = usepotentialDuplicates();

  return (
    <potentialDuplicatesContext.Provider value={duplicates}>
      {children}
    </potentialDuplicatesContext.Provider>
  );
}

export default function PotentialDuplicatesConsumer() {
  return React.useContext(potentialDuplicatesContext);
}

PotentialDuplicatesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
