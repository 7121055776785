import React, { FC, useEffect, useState } from 'react';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import CurrencyInput from '../../components/CurrencyInput/WithCents.tsx';
import HoldButton from '../../components/HoldButton.tsx';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';
import { PaymentSource } from '../../types';
import convertToDollarAmount from '../../utils/convertToDollar';

const WithdrawModal: FC<{
  userId: number;
  isOpen: boolean;
  onClose: () => void;
  maxAmount: number;
  onSuccess: () => void;
  sources: PaymentSource[];
}> = ({ userId, isOpen, onClose, maxAmount, onSuccess, sources }) => {
  const [amount, setAmount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState<null | number>(null);

  const handleWithdraw = async () => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: userId,
        amount,
        payment_source_id: selectedSource,
      };
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/wallets/withdraw`,
        payload
      );
      onSuccess();
      onClose();
      setAmount(0);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setAmount(0);
      setIsLoading(false);
      setIsError(false);
    }
  }, [isOpen]);

  return (
    <BaseModal
      fullWidth={false}
      modalIsOpen={isOpen}
      closeModal={onClose}
      header="Force Withdraw"
    >
      {isLoading && (
        <div className="h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col justify-between h-full">
          <div className="">
            <CurrencyInput onValueChange={setAmount} value={amount} />
            <div className="flex items-center justify-center mb-4">
              <Button
                text="Max Amount"
                variant="tertiary"
                rounded
                onClick={() => setAmount(maxAmount)}
              />
            </div>
            {isError && (
              <p className="text-warning text-center pb-4">
                There was an issue withdrawing
              </p>
            )}
            {amount > maxAmount && (
              <p className="text-warning text-center pb-4">
                The most you can withdraw is{' '}
                {convertToDollarAmount(maxAmount, true)}
              </p>
            )}
          </div>
          <div className="flex-1">
            <p className="text-white font-bold mb-1">Payment Source</p>
            {sources.map((s) => (
              <label
                htmlFor={`source_${s.id}`}
                className="text-grey-2 flex items-center gap-3 mb-1"
                key={`source_${s.id}`}
              >
                <input
                  type="radio"
                  id={`source_${s.id}`}
                  onChange={() => setSelectedSource(s.id)}
                  checked={selectedSource === s.id}
                  className="accent-blue w-4 h-4"
                />
                <span>{s.type.replace('PaymentSource::', '')}</span>
              </label>
            ))}
          </div>

          <div className="">
            <HoldButton
              variant="warning"
              text="Hold To Confirm Withdrawal"
              timeout={250}
              onConfirm={handleWithdraw}
              disabled={amount === 0 || amount > maxAmount || !selectedSource}
            />
          </div>
        </div>
      )}
    </BaseModal>
  );
};

export default WithdrawModal;
