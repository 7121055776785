/* eslint-disable */
import React, {
  FC,
  UIEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Subscription } from '@rails/actioncable';
import { Popover, Transition } from '@headlessui/react';
import { Gif } from '@giphy/react-components';
import groupBy from 'lodash/groupBy';
import { GiphyFetch } from '@giphy/js-fetch-api';
import classNames from 'classnames';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';
import {
  Clip,
  ConvoMessage,
  Match,
  TournamentTeamType,
  UserProfile,
} from '../../types';
import {
  differenceInDays,
  differenceInMilliseconds,
  format,
  isToday,
  isYesterday,
} from 'date-fns';
import MatchCard from './MatchCard.tsx';
import { anonymizeMatch } from '../../utils/match.ts';
import BountyCard from './BountyCard.tsx';
import WatchCard from './WatchCard.tsx';
import { getSessionCookie } from '../../utils/cookie';
import MoreIcon from '../../assets/Icons/more.svg';
import BaseModal from '../../components/BaseModal/BaseModal';

const ChatWrapper: FC<{
  title?: string;
  showLoader?: boolean;
  isFetching?: boolean;
  doneFetching?: boolean;
  data: any[];
  onLoadMore: () => void;
  typingUsers?: string[];
  renderOptions?: () => React.ReactElement;
  grey?: boolean;
  onRef: (ref: React.MutableRefObject<HTMLDivElement>) => void;
  userId: number;
  onDelete: (id: number) => void;
}> = ({
  title,
  showLoader,
  doneFetching,
  isFetching,
  data,
  onLoadMore,
  typingUsers,
  grey,
  onRef,
  userId,
  onDelete,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrolled, setScrolled] = useState(false);
  const endRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    // @ts-ignore
    onRef(containerRef);
  }, [containerRef, onRef]);

  useEffect(() => {
    // Clear timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const scrollToNew = (smooth?: boolean) => {
    setTimeout(() => {
      endRef.current?.scrollIntoView?.({
        behavior: 'auto',
      });
    }, 300);
  };

  useEffect(() => {
    if (!scrolled) {
      scrollToNew(true);
    }
  }, [scrolled, data]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    setScrolled(
      // @ts-ignore
      Math.floor(e.target.scrollHeight - e.target.scrollTop) !==
        // @ts-ignore
        Math.floor(e.target.clientHeight)
    );
  };

  const renderTyping = () => {
    if (!typingUsers || !typingUsers.length) {
      return null;
    }
    let text = '';
    if (typingUsers.length === 1) {
      text = `${typingUsers[0]} is typing...`;
    }
    if (typingUsers.length === 2) {
      text = `${typingUsers.join(' and ')} are typing...`;
    }
    if (typingUsers.length > 2) {
      text = `Multiple people are typing...`;
    }
    return (
      <p className="px-4 py-3 text-xs italic absolute bottom-full left-0 w-full bg-black mb-[1px] text-grey-2">
        {text}
      </p>
    );
  };

  return (
    <div className="relative overflow-hidden h-full flex flex-col">
      {title && (
        <h3
          className={classNames(
            'text-white font-bold text-xl 3xl:text-2xl sticky top-0 px-4 py-4 transition-colors flex items-center gap-4',
            {
              'bg-black': !grey,
              'bg-grey-4': grey,
            }
          )}
        >
          {title}
        </h3>
      )}
      {showLoader && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black z-10">
          <Loader />
        </div>
      )}
      <div
        className="overflow-y-auto pb-8 relative pt-2 px-4 flex-1"
        onScroll={handleScroll}
        ref={containerRef}
      >
        {!doneFetching && data.length >= 20 && !isFetching && (
          <div className="flex justify-center">
            <Button
              text={'Load More Messages'}
              onClick={onLoadMore}
              variant="grey"
              isLoading={isFetching}
              rounded
            />
          </div>
        )}
        <div ref={endRef} />
        <Messages messages={data} userId={userId} onDelete={onDelete} />
        <div ref={endRef} />
      </div>
      {typingUsers && typingUsers.length > 0 && (
        <div className="bg-black border-t border-grey-3 px-4 pt-2 pb-4 relative">
          {renderTyping()}
        </div>
      )}
    </div>
  );
};

export const ConversationChat: FC<{
  convo: any;
  typingUsers?: string[];
  userId: number;
}> = ({ convo, typingUsers, userId }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [doneFetching, setDoneFetching] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(true);

  let subscription = useRef<Subscription>(null);

  const [comments, setComments] = useState<ConvoMessage[]>([]);

  useEffect(() => {
    import('@rails/actioncable').then(({ createConsumer }) => {
      const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

      const updateSubscription = cable.subscriptions.create(
        {
          channel: 'MessagesChannel',
          conversation_id: convo.id,
          token: getSessionCookie('token'),
        },
        {
          received(data) {
            const message =
              // @ts-ignore
              data[Object.keys(data).find((k) => k.startsWith('message/'))];
            if (message) {
              setComments((c) => [message, ...c]);
            }
          },
        }
      );

      // @ts-ignore
      subscription.current = updateSubscription;
    });

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [userId, convo.id]);

  useEffect(() => {
    setDoneFetching(false);
  }, [convo.id]);

  useEffect(() => {
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/messages/?conversation_id=${convo.id}&page=${page}&page_size=20`
      )
      .then(({ data }) => {
        if (data.length < 20) {
          setDoneFetching(true);
        }
        // preserve scroll position
        if (containerRef.current) {
          const { scrollHeight } = containerRef.current;
          const pastScroll = scrollHeight;
          setComments((c) => (page === 1 ? data : [...c, ...data]));
          const currentScroll = containerRef.current.scrollHeight - pastScroll;
          containerRef.current.scrollTo(0, currentScroll);
        } else {
          setComments((c) => (page === 1 ? data : [...c, ...data]));
        }
      });
  }, [convo.id, page]);

  useEffect(() => {
    setComments([]);
    setPage(1);
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1200);
    return () => clearTimeout(timeout);
  }, [convo.id]);

  const handleLoadMore = () => {
    setPage((p) => p + 1);
  };

  const allMessages = useMemo(() => {
    return [...comments].reverse().map(convoToMessage);
  }, [comments]);

  return (
    <>
      <ChatWrapper
        userId={userId}
        showLoader={loading}
        doneFetching={doneFetching}
        onLoadMore={handleLoadMore}
        data={allMessages}
        typingUsers={typingUsers}
        // @ts-ignore
        onRef={(ref) => (containerRef.current = ref.current)}
        onDelete={(id) => setComments(comments.filter((c) => c.data.id !== id))}
      />
    </>
  );
};

export interface TextMessage {
  id: number;
  type: 'text' | 'mentions' | 'status';
  user_profile: Pick<
    UserProfile,
    'id' | 'username' | 'profile_image_url' | 'is_verified'
  >;
  data: {
    created_at: string;
    updated_at: string;
    text: string;
  };
}

export interface GifMessage {
  id: number;
  type: 'giphy';
  user_profile: Pick<
    UserProfile,
    'id' | 'username' | 'profile_image_url' | 'is_verified'
  >;
  data: {
    created_at: string;
    updated_at: string;
    giphy_id: string;
  };
}

export interface MatchMessage {
  id: number;
  type: 'match';
  user_profile: Pick<
    UserProfile,
    'id' | 'username' | 'profile_image_url' | 'is_verified'
  >;
  data: {
    created_at: string;
    updated_at: string;
    match: Match;
  };
}

export interface BountyMessage {
  id: number;
  type: 'bounty';
  user_profile: Pick<
    UserProfile,
    'id' | 'username' | 'profile_image_url' | 'is_verified'
  >;
  data: {
    created_at: string;
    updated_at: string;
    team: TournamentTeamType;
  };
}

export interface ClipMessage {
  id: number;
  type: 'clip';
  user_profile: Pick<
    UserProfile,
    'id' | 'username' | 'profile_image_url' | 'is_verified'
  >;
  data: {
    created_at: string;
    updated_at: string;
    clip: Clip;
  };
}

export type Message =
  | TextMessage
  | GifMessage
  | MatchMessage
  | BountyMessage
  | ClipMessage;

const THIRTY_MIN = 30 * 60 * 1000;
const Messages: FC<{
  messages: Message[];
  userId: number;
  onDelete: (id: number) => void;
}> = ({ messages, userId, onDelete }) => {
  const [toSilence, setToSilence] = useState<null | Pick<
    UserProfile,
    'id' | 'username' | 'profile_image_url' | 'is_verified'
  >>(null);
  // const [silenceReason, setSilencReason] = useState('');

  const [buttonFocus, setButtonFocus] = useState<{ [key: string]: boolean }>({
    day1: true,
  });
  const getTommorrow = () => {
    const today = new Date();

    today.setDate(today.getDate() + 1);

    return today.toISOString();
  };

  const [expiration, setExpirattion] = useState<string | null>(getTommorrow());

  const handleExpirations = (date, button) => {
    if (button === 'forever') {
      setButtonFocus({ [button]: true });
      setExpirattion(null);
    } else {
      const today = new Date();
      const tomorrow = new Date(today);
      if (date === '1h') {
        tomorrow.setHours(tomorrow.getHours() + 1);
      } else {
        tomorrow.setDate(tomorrow.getDate() + date);
      }
      setButtonFocus({ [button]: true });
      setExpirattion(tomorrow.toISOString());
    }
  };

  const silenceUser = async (expiration) => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${toSilence?.id}/silence?time=${expiration}`
      );
      setToSilence(null);
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  const handleSilenceUser = () => {
    silenceUser(expiration === null ? '2099-12-01T00:00:00Z' : expiration);
  };

  const handleDelete = async (id: number) => {
    await client.delete(
      `${process.env.REACT_APP_API_URL}/admin/v1/messages/${id}`
    );
    onDelete(id);
  };
  /**
   * Parse out mentions from text, could prob be done better, but it works
   */
  const parseMessage = (comment: Message, isUser?: boolean) => {
    if (comment.type === 'text' || comment.type === 'status')
      return comment.data.text;
    if (comment.type === 'mentions') {
      let str = '';
      comment.data.text.split('¡').forEach((t) => {
        if (/\{(.*?)\}/.test(t))
          str += `<a href="/profile/${
            JSON.parse(t).username
          }" style="font-weight: 700; color: ${
            isUser ? 'white' : '#00B3EF'
          };">@${JSON.parse(t).username}</a>`;
        else str += t;
      });
      return (
        <span
          className="whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: str }}
        />
      );
    }
    if (comment.type === 'giphy') {
      return <GifImage comment={comment} isUser={isUser} />;
    }
    if (comment.type === 'match') {
      return (
        <MatchCard
          userId={userId}
          match={anonymizeMatch(comment.data.match, userId)}
          senderId={comment.user_profile.id}
        />
      );
    }
    if (comment.type === 'bounty') {
      return (
        <BountyCard
          userId={userId}
          team={comment.data.team}
          senderId={comment.user_profile.id}
        />
      );
    }
    if (comment.type === 'clip') {
      const clipGroups = groupBy(
        comment.data.clip.clip_media,
        'group_identifier'
      );
      const groups = Object.keys(clipGroups).map((key) => clipGroups[key]);
      const topText = `${groups[0][0].user_profile.username}${
        groups[0].length > 1 ? ` +${groups[0].length - 1}` : ''
      }`;
      const bottomText = groups[1]?.length
        ? `${groups[1][0].user_profile.username}${
            groups[1].length > 1 ? ` +${groups[1].length - 1}` : ''
          }`
        : '';
      return (
        <WatchCard
          topTeam={topText}
          bottomTeam={bottomText}
          amount={comment.data.clip.pot_size}
          link={`/clips/${comment.data.clip.id}`}
          image={comment.data.clip.thumbnail_image_url}
          tags={comment.data.clip.tags}
          label={'Clip'}
          amountLabel={
            comment.data.clip.type === 'tournamentteamclip' ? 'pool' : 'pot'
          }
        />
      );
    }
    return null;
  };

  const getTimestamp = (d: Date) => {
    const date = new Date(d);
    const today = isToday(date);
    const yesterday = isYesterday(date);
    const thisWeek = differenceInDays(new Date(), date) < 7;
    const time = format(date, 'h:mm aa');

    if (today) return time;
    if (yesterday) return `Yesterday at ${time}`;
    if (thisWeek) return `${format(date, 'EEEE')} at ${time}`;
    return `${format(date, 'MMMM dd')} at ${time}`;
  };

  return (
    <>
      {!messages.length && (
        <p className="text-center text-grey-2 py-4">
          No messages to display yet
        </p>
      )}
      {messages
        .filter(
          (m) =>
            m &&
            [
              'text',
              'mentions',
              'giphy',
              'status',
              'match',
              'bounty',
              'clip',
            ].includes(m.type) &&
            (m.type === 'match' ? m.data.match.state !== 'canceled' : true)
        )
        .map((comment, i, arr) => {
          const isUser = userId === comment.user_profile.id;
          const isFirstFromUser =
            arr[i - 1]?.user_profile.id !== comment.user_profile.id;
          const isLastFromUser =
            arr[i + 1]?.user_profile.id !== comment.user_profile.id;
          const date = new Date(comment.data.created_at);
          const showDate = arr[i - 1]
            ? differenceInMilliseconds(
                date,
                new Date(arr[i - 1].data.created_at)
              ) > THIRTY_MIN
            : true;
          const content = parseMessage(comment, isUser);

          return (
            <React.Fragment key={`message_${comment.id}_${i}`}>
              {showDate && (
                <p
                  tabIndex={0}
                  className="text-xs mt-4 mb-4 text-center !ring-0 !outline-none text-grey-2"
                >
                  {getTimestamp(date)}
                </p>
              )}
              {comment.type === 'status' ? (
                <p
                  tabIndex={0}
                  className="text-xs mt-4 mb-4 text-center !ring-0 !outline-none text-grey-2 relative"
                >
                  {content}
                </p>
              ) : (
                <Popover>
                  <div className="group relative">
                    {!isUser && (isFirstFromUser || showDate) && (
                      <div
                        tabIndex={0}
                        className={classNames(
                          'text-xs mt-2 mb-1 flex items-center gap-2 !ring-0 !outline-none text-grey-2',
                          {
                            'justify-end': isUser,
                          }
                        )}
                      >
                        <span
                          className="inline-block"
                          style={{ height: 24, width: 24 }}
                        />
                        {comment.user_profile.username}{' '}
                        {comment.user_profile.is_verified && (
                          <div className="flex items-center">
                            <img
                              src="https://mobile-assets.1v1me.com/users/v1/verified-icon.svg"
                              height={12}
                              width={12}
                              alt="Verified"
                              className="inline-block"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      tabIndex={0}
                      key={`comment_${comment.id}`}
                      className={classNames(
                        'flex shrink-0 items-end gap-2 overflow-x-hidden overflow-y-auto !ring-0 !outline-none group',
                        {
                          'justify-end': isUser,
                        }
                      )}
                    >
                      {!isUser &&
                        (isLastFromUser ? (
                          <a
                            className="mb-2 relative z-50"
                            href={`/godmode/users/${comment.user_profile.id}`}
                            style={{ height: 24, width: 24, minWidth: 24 }}
                          >
                            <img
                              src={comment.user_profile.profile_image_url}
                              height={24}
                              width={24}
                              alt={comment.user_profile.username}
                              className="rounded-full object-cover"
                            />
                          </a>
                        ) : (
                          <span
                            className="inline-block"
                            style={{ height: 24, width: 24, minWidth: 24 }}
                          />
                        ))}

                      <Popover.Button
                        as="div"
                        style={{
                          maxWidth: 'calc(min(100%, 500px) - 32px)',
                          hyphens: 'auto',
                        }}
                        className={classNames(
                          'rounded-xl text-white inline-block break-words relative',
                          {
                            'pb-2 mb-1':
                              comment.type !== 'bounty' &&
                              comment.type !== 'match' &&
                              comment.type !== 'clip' &&
                              comment.type !== 'giphy',
                            'px-5 pt-2':
                              comment.type !== 'giphy' &&
                              comment.type !== 'match' &&
                              comment.type !== 'bounty' &&
                              comment.type !== 'clip',
                            'bg-blue':
                              isUser &&
                              comment.type !== 'match' &&
                              comment.type !== 'bounty' &&
                              comment.type !== 'clip',
                            'rounded-br-sm': isUser,
                            'rounded-bl-sm': !isUser,
                            'bg-grey-4':
                              !isUser &&
                              comment.type !== 'match' &&
                              comment.type !== 'bounty',
                            'opacity-50': !content,
                          }
                        )}
                      >
                        {content || (
                          <p className="text-xs">Unsupported Message Type</p>
                        )}
                      </Popover.Button>
                    </div>
                    <p
                      className={classNames(
                        'text-xs text-grey-2 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0 transition-all duration-300 ease-in-out -translate-y-2 pb-1',
                        {
                          'text-right': isUser,
                          'ml-8': !isUser,
                        }
                      )}
                    >
                      {getTimestamp(new Date(comment.data.created_at))}
                    </p>
                    {['text', 'mentions', 'giphy', 'clip'].includes(
                      comment.type
                    ) && (
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform translate-y-2 opacity-0"
                        enterTo="transform translate-y-0 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform translate-y-0 opacity-100"
                        leaveTo="transform translate-y-2 opacity-0"
                      >
                        <Popover.Panel
                          className={classNames(
                            'absolute bottom-2 z-40 shadow-lg border border-grey-3 rounded-lg bg-black w-[300px]',
                            {
                              'right-8': isUser,
                              'left-8': !isUser,
                            }
                          )}
                        >
                          {({ close }) => (
                            <div onClick={() => close()}>
                              <button
                                type="button"
                                onClick={() =>
                                  setToSilence(comment.user_profile)
                                }
                                className="py-2 w-full text-left px-4  bg-grey-4 text-white hover:bg-grey-3 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                              >
                                Silence {comment.user_profile.username}
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDelete(comment.id)}
                                className="py-2 w-full text-left px-4 text-warning hover:bg-grey-4 transition-colors ease-in-out !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue"
                              >
                                Delete Message
                              </button>
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    )}
                  </div>
                </Popover>
              )}
            </React.Fragment>
          );
        })}
      {/* SILENCE MODAL */}
      <BaseModal
        height="auto"
        paginateData={() => {}}
        modalIsOpen={!!toSilence}
        closeModal={() => setToSilence(null)}
        header={`Silence ${toSilence?.username}`}
        className={undefined}
        fullWidth={undefined}
      >
        <div className="relative mx-3">
          <div className="mb-6 mt-2 border border-zinc-700 flex justify-between w-full rounded-full">
            <button
              onClick={() => handleExpirations('1h', 'hour1')}
              type="button"
              className={classNames('w-32 text-zinc-500 py-2 px-3')}
              style={
                buttonFocus.hour1
                  ? {
                      backgroundColor: 'white',
                      borderRadius: '9999px',
                      color: 'black',
                    }
                  : {}
              }
            >
              1H
            </button>
            <button
              onClick={() => handleExpirations(1, 'day1')}
              type="button"
              className={classNames('w-32 text-zinc-500 py-2 px-3')}
              style={
                buttonFocus.day1
                  ? {
                      backgroundColor: 'white',
                      borderRadius: '9999px',
                      color: 'black',
                    }
                  : {}
              }
            >
              24H
            </button>
            <button
              onClick={() => handleExpirations(2, 'day2')}
              type="button"
              className={classNames('w-32 text-zinc-500 py-2 px-3')}
              style={
                buttonFocus.day2
                  ? {
                      backgroundColor: 'white',
                      borderRadius: '9999px',
                      color: 'black',
                    }
                  : {}
              }
            >
              48H
            </button>
            <button
              onClick={() => handleExpirations(3, 'day3')}
              type="button"
              className={classNames('w-32 text-zinc-500 py-2 px-3')}
              style={
                buttonFocus.day3
                  ? {
                      backgroundColor: 'white',
                      borderRadius: '9999px',
                      color: 'black',
                    }
                  : {}
              }
            >
              72H
            </button>
            <button
              onClick={() => handleExpirations(7, 'week')}
              type="button"
              className={classNames('w-32 text-zinc-500 py-2 px-3')}
              style={
                buttonFocus.week
                  ? {
                      backgroundColor: 'white',
                      borderRadius: '9999px',
                      color: 'black',
                    }
                  : {}
              }
            >
              1W
            </button>
            <button
              onClick={() => handleExpirations(30, 'month')}
              type="button"
              className={classNames('w-32 text-zinc-500 py-2 px-3')}
              style={
                buttonFocus.month
                  ? {
                      backgroundColor: 'white',
                      borderRadius: '9999px',
                      color: 'black',
                    }
                  : {}
              }
            >
              1M
            </button>
            <button
              onClick={() => handleExpirations(10000, 'forever')}
              type="button"
              className={classNames('w-32 text-zinc-500 py-2 px-3')}
              style={
                buttonFocus.forever
                  ? {
                      backgroundColor: 'white',
                      borderRadius: '9999px',
                      color: 'black',
                    }
                  : {}
              }
            >
              Forever
            </button>
          </div>
          <div className="w-full">
            <Button
              className={classNames('w-full', {
                // 'opacity-50 hover:cursor-default': silenceReason.length === 0,
              })}
              variant="primary"
              text={`Silence ${toSilence?.username}`}
              onClick={handleSilenceUser}
              // disabled={silenceReason.length === 0}
            />
          </div>
        </div>
      </BaseModal>
    </>
  );
};

const GifImage: FC<{
  comment: GifMessage;
  isUser?: boolean;
}> = ({ comment, isUser }) => {
  const [gif, setGif] = useState<null | any>(null);
  useEffect(() => {
    const getGif = async () => {
      const gf = new GiphyFetch('NSTWy5rstKfFrDWnieQCM53IWBBlaseI');
      const res = await gf.gif(comment.data.giphy_id);
      setGif(res.data);
    };
    getGif();
  }, [comment.data.giphy_id]);
  return (
    <span className={classNames('')}>
      {gif ? (
        <Gif noLink hideAttribution width={260} gif={gif} />
      ) : (
        <span className="p-2">Loading...</span>
      )}
    </span>
  );
};

export const convoToMessage = (message: ConvoMessage) => {
  if (
    message.type === 'Message::TextMessage' ||
    message.type === 'Message::StatusMessage' ||
    message.type === 'Message::MentionMessage'
  ) {
    let type: Message['type'] = 'text';
    if (message.type === 'Message::StatusMessage') {
      type = 'status';
    }
    if (message.type === 'Message::MentionMessage') {
      type = 'mentions';
    }
    return {
      id: message.data.id,
      type,
      user_profile: message.participant.user_profile,
      data: {
        created_at: message.data.created_at,
        updated_at: message.data.updated_at,
        text: message.data.text,
      },
    } as TextMessage;
  }
  if (message.type === 'Message::GiphyMessage') {
    return {
      id: message.data.id,
      type: 'giphy',
      user_profile: message.participant.user_profile,
      data: {
        created_at: message.data.created_at,
        updated_at: message.data.updated_at,
        giphy_id: message.data.giphy_id,
      },
    } as GifMessage;
  }
  if (message.type === 'Message::MatchMessage') {
    return {
      id: message.data.id,
      type: 'match',
      user_profile: message.participant.user_profile,
      data: {
        created_at: message.data.created_at,
        updated_at: message.data.updated_at,
        match: message.data.match,
      },
    } as MatchMessage;
  }
  if (message.type === 'Message::BountyInviteMessage') {
    return {
      id: message.data.id,
      type: 'bounty',
      user_profile: message.participant.user_profile,
      data: {
        created_at: message.data.created_at,
        updated_at: message.data.updated_at,
        team: message.data.team,
      },
    } as BountyMessage;
  }
  if (message.type === 'Message::ClipMessage') {
    return {
      id: message.data.id,
      type: 'clip',
      user_profile: message.participant.user_profile,
      data: {
        created_at: message.data.created_at,
        updated_at: message.data.updated_at,
        clip: message.data.clip,
      },
    } as ClipMessage;
  }
};

export default ConversationChat;
