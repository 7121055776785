/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import useReports from '../../contexts/RecentReportsContext/RecentReportsContext';
import useUsers from '../../contexts/UserContext/UserContext';

import Modal from '../../components/BaseModal/BaseModal';
import Card from '../../components/Card/Card';
import BlockIcon from '../../assets/Icons/block-icon.svg';

import { formattedDate } from '../../utils';

const RecentReports = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const { loading, reports, getReports, paginateReports } = useReports();
  const { user } = useUsers();

  useEffect(() => {
    if (user.user) {
      getReports(user.user.id);
    }
  }, [user]);

  const paginateData = () => {
    paginateReports(user.user.id);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const removeReporty = (title) => title.replace(/ .*/, '');

  return (
    <div className="mt-12">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-white text-2xl font-extrabold">Recent Reports</h2>
        {reports.length > 0 && (
          <div
            className="text-secondary cursor-pointer text-sm"
            onClick={openModal}
            role="button"
          >
            See All
          </div>
        )}
      </div>

      {reports.length ? (
        reports.slice(0, 3).map(({ title, reason, created_at }) => (
          <div className="flex mt-4" key={created_at}>
            <div className="rounded-full w-12 h-12">
              <img src={BlockIcon} alt="profile" />
            </div>
            <div className="ml-3 w-full">
              <div className="flex justify-between w-full">
                <h2 className="text-white text-xs  uppercase">
                  {removeReporty(title)}
                </h2>
                <h2 className="ml-3 text-white text-xs text-zinc-500 uppercase">
                  {formattedDate(created_at)}
                </h2>
              </div>
              {reason ? (
                <h2 className="text-white text-xl">{reason}</h2>
              ) : (
                <h2 className="text-l italic text-zinc-500">No Reason</h2>
              )}
            </div>
          </div>
        ))
      ) : (
        <Card bordered>
          <p className="text-zinc-500 text-center py-8">No reports available</p>
        </Card>
      )}

      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        header="Recent Reports"
        paginateData={paginateData}
        loading={loading}
      >
        <div className="">
          {reports.map(({ title, reason, created_at }, index) => (
            <div
              className="flex items-center mt-4"
              key={`report-created-${created_at}-${index}`}
            >
              <div className="rounded-full w-12 h-12">
                <img src={BlockIcon} alt="profile" />
              </div>
              <div className="ml-3 w-full">
                <div className="flex justify-between w-full">
                  <h2 className="text-white text-xs uppercase">
                    {removeReporty(title)}
                  </h2>
                  <h2 className="ml-3 text-white text-xs text-zinc-500 uppercase">
                    {formattedDate(created_at)}
                  </h2>
                </div>
                {reason ? (
                  <h2 className="text-white text-xl">{reason}</h2>
                ) : (
                  <h2 className="text-l italic text-zinc-500">No Reason</h2>
                )}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default RecentReports;
