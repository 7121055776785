import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import convertToDollarAmount from '../../utils/convertToDollar';

import PlusIcon from '../../assets/Icons/checkmark.svg';
import CheckMarkIcon from '../../assets/Icons/plus-icon.svg';
import TrashCanIcon from '../../assets/Icons/trash.svg';

const UserInvite = ({
  inviteList = false,
  user: {
    id,
    profile_image_url,
    username,
    user_stats: { total_earnings },
    inviteId,
  },
  addUserToInviteList,
  removeUserFromInviteList,
  removeUserByInviteId,
  className,
  index,
  selectedIndex,
  selectedUser,
  userInviteError = {},
  usersInvited = [],
  usersResult = [],
}) => {
  const [userInvited, setUserInvited] = useState(false);

  useEffect(() => {
    if (selectedUser !== undefined && selectedUser === id) {
      setUserInvited(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userInviteError.user_id === id) {
      setUserInvited(false);
    }
  }, [userInviteError]);

  useEffect(() => {
    const usersAlreadyInvited = usersInvited.filter(
      ({ user_profile }) => user_profile.id === id
    );

    if (usersAlreadyInvited.length > 0) {
      setUserInvited(true);
    } else {
      setUserInvited(false);
    }
  }, [usersResult, usersInvited]);

  const defaultImage =
    'https://mobile-assets.1v1me.com/users/v1/default_profile.png';

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  const handleUserInvite = (userId) => {
    setUserInvited(true);
    addUserToInviteList(userId);
  };

  const handleRemoveUserById = (userId) => {
    const usersAlreadyInvited = usersInvited.filter(
      ({ user_profile }) => user_profile.id === userId
    );

    if (usersAlreadyInvited.length > 0) {
      setUserInvited(false);
      removeUserByInviteId(userId);
    }
  };

  return (
    <div
      className={cn('block w-full hover:bg-grey-3', className, {
        'bg-grey-3': index === selectedIndex,
      })}
    >
      <div
        role="button"
        key={id}
        className="flex justify-between items-center px-4 py-3"
      >
        <Link to={`/godmode/users/${id}`} className="flex items-center w-full">
          <img
            src={profile_image_url}
            alt="profile"
            className="rounded-full w-8"
            onError={handleImageError}
          />
          <div>
            <p className="text-white text-lg ml-3">{username}</p>
            <p className="text-sm ml-3 text-grey-2">
              {convertToDollarAmount(total_earnings)} EARNED
            </p>
            {Object.keys(userInviteError).length > 0 &&
              userInviteError.user_id === id && (
                <p className="text-sm ml-3 text-warning">
                  {userInviteError.errorMessage}
                </p>
              )}
          </div>
        </Link>
        {inviteList ? (
          <div>
            <div
              role="button"
              onClick={() => removeUserFromInviteList(inviteId, username)}
            >
              <img src={TrashCanIcon} alt="Plus" className="" />
            </div>
          </div>
        ) : (
          <div>
            {userInvited || selectedUser === id ? (
              <div role="button" onClick={() => handleRemoveUserById(id)}>
                <img
                  src={CheckMarkIcon}
                  alt="Plus"
                  className="rounded-full bg-grey-3 p-3"
                />
              </div>
            ) : (
              <div role="button" onClick={() => handleUserInvite(id)}>
                <img
                  src={PlusIcon}
                  alt="Plus"
                  className="rounded-full bg-grey-3 p-3"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

UserInvite.propTypes = {
  inviteList: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
    profile_image_url: PropTypes.string,
    username: PropTypes.string,
    user_stats: PropTypes.shape({ total_earnings: PropTypes.number }),
    inviteId: PropTypes.number,
  }),
  addUserToInviteList: PropTypes.func,
  removeUserFromInviteList: PropTypes.func,
  className: PropTypes.string,
  index: PropTypes.number,
  selectedIndex: PropTypes.number,
  selectedUser: PropTypes.number,
  userInviteError: PropTypes.shape({
    errorMessage: PropTypes.string,
    user_id: PropTypes.number,
  }),
  removeUserByInviteId: PropTypes.func,
};

export default UserInvite;
