import Cookies from 'js-cookie';

export const setSessionCookie = (sessionName, session) => {
  Cookies.remove(sessionName);
  Cookies.set(sessionName, session, { expires: 365 });
};

export const getSessionCookie = (sessionName) => {
  const sessionCookie = Cookies.get(sessionName);

  if (sessionCookie === undefined) {
    return {};
  }
  return sessionCookie;
};
