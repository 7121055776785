/* eslint-disable no-nested-ternary */
import {
  nextFriday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
} from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import useAuth from '../../contexts/AuthContext/AuthContext';
import client from '../../services/client';
import { Card } from './PartnerAvailability';
import Schedule from './Schedule';
import { getDates } from './util';

const PartnerSchedule = () => {
  const params = useParams();
  const [search, setSearch] = useSearchParams();
  const { god } = useAuth();

  const dates = useMemo(getDates, []);
  const selectedDate = Number(search.get('dateOffset') || 0);
  const selectedPartner = Number(params.partnerId);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avail, setAvail] = useState([]);
  const [schedule, setSchedule] = useState(null);

  const daysOfWeek = useMemo(() => {
    const monday = schedule
      ? new Date(dates[selectedDate].split(' - ')[0])
      : new Date();
    const tuesday = nextTuesday(monday);
    const wednesday = nextWednesday(tuesday);
    const thursday = nextThursday(wednesday);
    const friday = nextFriday(thursday);
    const saturday = nextSaturday(friday);
    const sunday = nextSunday(saturday);

    return {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    };
  }, [schedule, dates]);

  const fetchAvail = useCallback(() => {
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/availabilities?week_offset=${selectedDate}`
      )
      .catch(() => {})
      .then(({ data }) => setAvail(data))
      .finally(() => setLoading(false));
  }, [selectedDate]);

  useEffect(() => {
    fetchAvail();
  }, [fetchAvail]);

  useEffect(() => {
    setLoading(true);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/${selectedPartner}/availability?week_offset=${selectedDate}`
      )
      .catch(() => setError(true))
      .then(({ data }) => setSchedule(data))
      .finally(() => setLoading(false));
  }, [selectedDate, selectedPartner]);

  const handleUpdate = (leagueId, newSlots) => {
    fetchAvail();
    setSchedule((s) => ({
      ...s,
      leagues: s.leagues.map((l) =>
        l.league_id === leagueId ? { ...l, timeslots: newSlots } : l
      ),
    }));
  };
  const available = useMemo(() => avail.filter((p) => p.available), [avail]);
  const unavailable = useMemo(() => avail.filter((p) => !p.available), [avail]);

  if (Object.keys(god).length === 0) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }
  const user = avail.find((p) => p.partner_id === selectedPartner);
  return (
    <Layout showHeaderElements userId={god.id} className="md:px-0 text-grey-2">
      <div className="flex gap-8 h-[calc(100vh-80px)] overflow-hidden">
        <div className="w-[340px] pr-4 border-r border-grey-4 flex flex-col overflow-auto">
          <div className="flex-1 p-4">
            <h2 className="font-extrabold text-white text-xl flex-1 flex items-center justify-between gap-4 mb-2">
              Partner Availablity
            </h2>
            <select
              aria-label="Date"
              className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 min-w-[300px] rounded-full mb-8"
              value={selectedDate}
              onChange={(e) =>
                setSearch({ dateOffset: Number(e.target.value) })
              }
            >
              {Object.keys(dates)
                .sort((a, b) => a - b)
                .map((d) => (
                  <option key={`date_${d}`} value={d}>
                    {(+d === 1 || +d === 0) && '🖊️ '}
                    {dates[d]}
                  </option>
                ))}
            </select>
            {available.length >= 1 && (
              <>
                <h3 className="font-extrabold text-white text-lg mb-4">
                  {available.length} Partners Available
                </h3>
                <div className="grid grid-cols-1  mb-8 gap-4">
                  {available.map((p) => (
                    <Card
                      dark
                      isActive={p.partner_id === selectedPartner}
                      partner={p}
                      key={`partner_${p.partner_id}`}
                      selectedDate={selectedDate}
                    />
                  ))}
                </div>
              </>
            )}
            {unavailable.length >= 1 && (
              <>
                <h3 className="font-extrabold text-white text-lg mb-4">
                  {unavailable.length} Partners Haven’t Filed
                </h3>
                <div className="grid grid-cols-1  mb-8 gap-4">
                  {unavailable.map((p) => (
                    <Card
                      dark
                      isActive={p.partner_id === selectedPartner}
                      partner={p}
                      key={`partner_${p.partner_id}`}
                      selectedDate={selectedDate}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <div className="flex gap-8 border-t border-grey-4">
            <div className="flex-1 border-r border-grey-4 h-[calc(100vh-80px)] overflow-y-auto">
              <div className="p-4 max-w-7xl mx-auto">
                {loading ? (
                  <Loader />
                ) : error ? (
                  <p className="text-center py-12 px-4 text-warning">
                    Something went wrong
                  </p>
                ) : user ? (
                  schedule?.leagues.map((l) => (
                    <Schedule
                      key={`league_${l.league_id}`}
                      dates={daysOfWeek}
                      game={l.play_info.game_name}
                      title={l.play_info.game_mode_title}
                      color={l.play_info.game_mode_color}
                      slots={l.timeslots}
                      offset={selectedDate}
                      leagueId={l.league_id}
                      teammates={l.teammates}
                      icon={l.play_info.game_icon}
                      playersPerTeam={l.num_players_per_team}
                      user={avail.find((p) => p.partner_id === selectedPartner)}
                      onUpdate={handleUpdate}
                    />
                  ))
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PartnerSchedule;
