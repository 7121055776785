/* eslint-disable indent */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../contexts/AuthContext/AuthContext';

const RequireAuth = ({ children }) => {
  const { checkAuth, god } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  // TODO: add loading logic authenticatiion
  return Object.keys(god).length ? children : null;
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;
