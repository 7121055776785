/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import convertToDollarAmount from '../utils/convertToDollar';

/**
 * @param {{
 *  team: Object;
 *  onSelect?: () => void;
 *  onRoster?: () => void;
 *  small?: boolean;
 *  cantStake?: boolean;
 *  showScores?: boolean;
 *  isLast?: boolean;
 *   noStake?: boolean;
 * }} props
 * @returns
 */
const Team2 = (props) => {
  const [buttonHovered, setButtonHovered] = useState(false);

  const buttonVariant = useMemo(() => {
    if (!props.team.stake || props.team.placement) {
      return 'grey-border';
    }
    const canStake =
      !props.small &&
      !props.team.stake?.sold_out &&
      props.team.stake?.max_purchase_amount >= 100;

    if (props.team.stake?.promotion) {
      return canStake ? 'gold-pulse' : 'gold-disabled';
    }
    if (props.team.stake?.slip?.paid_amount > 0) {
      if (canStake) {
        return props.team.stake?.slip?.promotional_amount > 0
          ? 'gold-filled'
          : 'green-filled';
      }
      return props.team.stake?.slip?.promotional_amount > 0
        ? 'gold-disabled'
        : 'green-disabled';
    }
    if (!canStake) {
      return 'grey-border';
    }
    return props.team.stake?.slip?.promotional_amount > 0
      ? 'gold-border'
      : 'green-border';
  }, [props.team.stake, props.team.placement, props.small]);

  const image = useMemo(() => {
    const defaultImage =
      'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
    const byLeader = props.team.roster.players.find(
      (p) => p.type === 'leader' || p.type === 'TournamentPlayer::Leader'
    );

    if (byLeader && byLeader.user_profile.profile_image_url !== defaultImage) {
      return byLeader.user_profile.profile_image_url;
    }
    const byName = props.team.roster.players.find((p) => {
      if (props.team.roster.team_name) {
        return props.team.roster.team_name
          .toLowerCase()
          .includes(p.user_profile.username.toLowerCase());
      }
      return false;
    });
    if (byName && byName.user_profile.profile_image_url !== defaultImage) {
      return byName.user_profile.profile_image_url;
    }

    return defaultImage;
  }, [props.team.roster.team_name, props.team.roster.players]);

  const isDisabled =
    buttonVariant === 'grey-border' ||
    !props.team.stake ||
    props.cantStake ||
    ((props.team.stake?.sold_out ||
      props.team.stake?.max_purchase_amount < 100) &&
      !props.small);

  return (
    <div
      className={classNames(' border-grey-4 text-left w-full  px-4 relative ')}
    >
      {props.team.stake?.highlight && (
        <div className="flex items-center gap-2 ml-12 mb-2">
          <img
            src={props.team.stake?.highlight.icon_image_url}
            className="rounded-full object-cover h-[12px] w-[12px]"
            alt={props.team.stake?.highlight.description}
          />
          <p className="text-xs leading-none">
            {props.team.stake?.highlight.description}
          </p>
        </div>
      )}
      <div
        role="button"
        className={classNames(
          'flex items-center gap-4   transition-colors ease-out -mx-4 px-4 py-2',
          {
            'hover:bg-white hover:bg-opacity-[.05]':
              !buttonHovered && props.onRoster,
            'cursor-default': !props.onRoster,
          }
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.onRoster?.();
        }}
      >
        <div
          className={classNames('flex-1 flex flex-col gap-2 sm:gap-4', {
            'md:w-7/12  md:items-center md:flex-row': !props.small,
            'md:!w-3/4':
              !props.team.stake?.breakeven_placement && !props.showScores,
            'md:!w-full md:justify-between':
              props.showScores && props.team.match_round_results,
          })}
        >
          <div
            className={classNames('flex items-center gap-3 w-full', {
              'md:w-1/2': !props.small,
            })}
          >
            <img
              src={image}
              onError={(e) => {
                // @ts-ignore
                e.target.src =
                  'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
              }}
              alt={props.team.roster.team_name}
              className="rounded-full object-cover self-start w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
            />
            <div>
              <h3 className="font-extrabold text-white leading-none  flex items-center gap-2">
                <span>{props.team.roster.team_name}</span>
              </h3>
              {props.team.roster.players.length > 1 ? (
                <p className="text-xs mt-1">
                  {props.team.roster.players
                    .map((p) => p.user_profile.username)
                    .join(' · ')}
                </p>
              ) : props.team.tag ? (
                <span
                  style={{
                    backgroundColor: props.team.tag.background_color,
                    color: props.team.tag.text_color,
                  }}
                  className="text-xs bg-grey-3 px-2 py-[3px] rounded-full mt-1 inline-flex items-center gap-1"
                >
                  {props.team.tag.image_url && (
                    <img
                      src={props.team.tag.image_url}
                      alt={props.team.tag.title}
                      className="w-[16px] object-contain"
                    />
                  )}
                  {props.team.tag.title}
                </span>
              ) : null}
            </div>
          </div>
          <div
            className={classNames('flex items-center  w-full ml-9 gap-4', {
              'md:ml-0 md:w-5/12 justify-end': !props.small,
            })}
          >
            {props.showScores && props.team.match_round_results ? (
              <div className="w-full flex items-center justify-end flex-1">
                {props.team.match_round_results.map((s, i) => {
                  const hasWon = s.won;
                  return (
                    <span
                      key={`has_won_${i}`}
                      className={classNames(
                        'font-extrabold text-grey-2 text-xs sm:text-sm w-[16px] text-center inline-block whitespace-nowrap',
                        { '!text-gold': hasWon }
                      )}
                    >
                      {s.score_display
                        ? s.score_display
                        : typeof hasWon === 'boolean'
                        ? hasWon
                          ? 'W'
                          : 'L'
                        : '-'}
                    </span>
                  );
                })}
              </div>
            ) : (
              <>
                <div className={classNames({ 'w-1/2': !props.small })}>
                  <p className="text-xs uppercase">Last 5</p>
                  <div className="flex flex-nowrap">
                    {[null, null, null, null, null].map((_, i) => {
                      const hasWon =
                        typeof props.team.stat.last_five_won[i] === 'boolean'
                          ? props.team.stat.last_five_won[i]
                          : null;
                      return (
                        <span
                          key={`has_won_${i}`}
                          className={classNames(
                            'font-extrabold text-grey-2 text-xs sm:text-sm w-[16px] text-center inline-block whitespace-nowrap',
                            { '!text-gold': hasWon }
                          )}
                        >
                          {typeof hasWon === 'boolean'
                            ? hasWon
                              ? 'W'
                              : 'L'
                            : '-'}
                        </span>
                      );
                    })}
                  </div>
                </div>

                <div className="w-1/2">
                  <p className="text-xs uppercase">Win %</p>
                  {props.team.stat.completed > 0 ? (
                    <p className="text-white">
                      {Math.round(
                        (props.team.stat.wins / props.team.stat.completed) * 100
                      )}
                      %
                    </p>
                  ) : (
                    <p className="text-grey-2">--</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {!props.showScores ||
          (props.showScores && !props.team.match_round_results && (
            <div className={classNames('w-1/3 md:!w-1/3 self-stretch', {})}>
              {!props.noStake && (
                <button
                  type="button"
                  onMouseEnter={() => setButtonHovered(true)}
                  onMouseLeave={() => setButtonHovered(false)}
                  disabled={isDisabled}
                  onClick={(e) => {
                    if (isDisabled) {
                      return;
                    }
                    if (props.onSelect) {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onSelect();
                    }
                  }}
                  className={classNames(
                    'rounded-lg w-full sm:p-4 font-bold uppercase text-xs h-full !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-gold',
                    {
                      'text-success !bg-grey-4 border !border-success pointer-events-none':
                        buttonVariant === 'green-disabled',
                      'text-gold !bg-grey-4 border !border-gold':
                        buttonVariant === 'gold-disabled',

                      'text-black !bg-success':
                        buttonVariant === 'green-filled',
                      'text-black bg-gold': buttonVariant === 'gold-filled',

                      'text-success bg-grey-3 border !border-grey-4':
                        buttonVariant === 'green-border',
                      'text-gold bg-grey-3 border !border-gold':
                        buttonVariant === 'gold-border' ||
                        buttonVariant === 'gold-pulse',
                      'promo-pulse': buttonVariant === 'gold-pulse',

                      '!bg-grey-4 text-grey-2 border-2 border-grey-2 pointer-events-none opacity-75':
                        buttonVariant === 'grey-border',

                      'md:min-h-[84px] transition-all ease-in-out hover:opacity-75 hover:shadow-xl':
                        !props.small && props.onSelect,
                      'pointer-events-none': props.small || !props.onSelect,
                    }
                  )}
                >
                  {!props.team.stake ? (
                    'Loading...'
                  ) : (
                    <>
                      <span className="sm:hidden whitespace-nowrap block">
                        Stake{' '}
                        {convertToDollarAmount(
                          props.team.stake?.purchase_amount
                        )}
                      </span>
                      <span
                        className={classNames(
                          'hidden sm:block whitespace-nowrap text-xs text-white',
                          {
                            '!text-grey-3':
                              buttonVariant === 'gold-filled' ||
                              buttonVariant === 'green-filled',
                          }
                        )}
                      >
                        {props.team.roster.team_name}
                      </span>
                      <span className="block whitespace-nowrap font-bold sm:text-base">
                        Win{' '}
                        {convertToDollarAmount(
                          props.team.stake?.prizes?.find(
                            (p) => p.placement === 1
                          )?.payout || 0
                        )}
                      </span>
                      {!props.small &&
                        !props.cantStake &&
                        props.team.stake?.sold_out && (
                          <p className="uppercase text-warning font-semibold text-[10px] md:text-[8px] 3xl:text-[10px] whitespace-nowrap pt-1 text-center hidden xl:block -mx-4">
                            Sold Out Until Other Teams Get Staked
                          </p>
                        )}
                    </>
                  )}
                </button>
              )}
            </div>
          ))}
      </div>
      {!props.cantStake && !props.small && (
        <div className="pt-4 xl:hidden pl-16">
          {props.team.stake?.sold_out ? (
            <p className="uppercase text-warning font-semibold text-[10px] text-center sm:-mt-6 rounded-lg border-2 border-grey-4 py-2 px-8">
              Sold Out Until Other Teams Get Staked
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Team2;
