import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/client';

const auditsContext = createContext({
  wallet: [],
  loading: false,
});

const useAudit = () => {
  const [audits, setAudits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isMore, setIsMore] = useState(true);

  const getAudits = async (userId) => {
    setLoading(true);

    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/audit_log?page=${page}&page_size=20`
    );

    setAudits(data);
    setLoading(false);
  };

  const paginateAudits = async (userId) => {
    if (isMore) {
      setLoading(true);
      const { data } = await client.get(
        `${
          process.env.REACT_APP_API_URL
        }/admin/v1/users/${userId}/audit_log?page=${page + 1}&page_size=20`
      );

      if (data.length === 0 || data.length < 7) {
        setIsMore(false);
      }

      setPage(page + 1);
      setAudits([...audits, ...data]);
    }
    setLoading(false);
  };

  return {
    loading,
    audits,
    getAudits,
    paginateAudits,
  };
};

export function AuditsProvider({ children }) {
  const audits = useAudit();

  return (
    <auditsContext.Provider value={audits}>{children}</auditsContext.Provider>
  );
}

export default function AuditConsumer() {
  return React.useContext(auditsContext);
}

AuditsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
