/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
import * as React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const ChatBubble = ({
  message,
  prevMessage,
  nextMessage,
  handleOpenUserModal,
}) => {
  const renderProfileImage = () => {
    if (
      prevMessage !== undefined &&
      prevMessage.username === message.username &&
      nextMessage === undefined
    ) {
      return (
        <img
          src={message.user_profile_image}
          alt="chat"
          className="w-6 rounded-full"
        />
      );
    }
    if (prevMessage === undefined && nextMessage === undefined) {
      return (
        <img
          src={message.user_profile_image}
          alt="chat"
          className="w-6 rounded-full"
        />
      );
    }
    return <div className="w-6" />;
  };

  return (
    <div
      className="mt-1 flex items-end"
      key={message.id}
      onClick={() =>
        handleOpenUserModal({
          userId: message.id,
          user: message.username,
          message: message.message,
          messageId: message.messageId,
        })
      }
      role="button"
    >
      {renderProfileImage()}

      <div className="ml-1 flex items-center">
        <p className="text-white text-sm rounded-tl-md rounded-tr-xl rounded-br-xl bg-grey-4 w-full py-2 px-4 inline text-base break-all">
          {message.message.split(' ').map((text) =>
            text.includes('@') ? (
              <span key={uuidv4()} className="font-bold mr-1">
                {text}
              </span>
            ) : (
              <span key={uuidv4()} className="mr-1">
                {text}
              </span>
            )
          )}
        </p>
      </div>
    </div>
  );
};

ChatBubble.propTypes = {
  message: PropTypes.shape({
    message: PropTypes.string,
    username: PropTypes.string,
    user_profile_image: PropTypes.string,
    id: PropTypes.number,
    messageId: PropTypes.number,
  }),
  prevMessage: PropTypes.shape({
    username: PropTypes.string,
  }),
  nextMessage: PropTypes.shape({
    username: PropTypes.string,
  }),
  handleOpenUserModal: PropTypes.func,
};

export default ChatBubble;
