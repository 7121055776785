/* eslint-disable no-console */
import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { FileUploader } from 'react-drag-drop-files';
import classNames from 'classnames';
import ImageIcon from '../../assets/Icons/image-large.svg';
import CopyIcon from '../../assets/Icons/copy.svg';

import useAuth from '../../contexts/AuthContext/AuthContext';

import Layout from '../../components/Layout/Layout';

import Button from '../../components/Buttons/Button/Button';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';

const fileTypes = ['JPG', 'PNG', 'GIF'];

const ImageUploader = () => {
  const { god } = useAuth();
  const [error, setError] = useState(false);
  const [uploaded, setUploaded] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (files: FileList) => {
    try {
      setIsLoading(true);

      const { data: urls } = await client.get<string[]>(
        `${process.env.REACT_APP_API_URL}/admin/v1/media/upload_batch?quantity=${files.length}`
      );
      const mappedUrls = await Promise.all(
        urls.map(async (url, i) => {
          await axios.put(url, files[i], {
            headers: { 'Content-Type': files[i].type },
          });
          const [, path] = url.split('/uploads/');
          const [fileName] = path.split('?');
          return `https://mobile-assets.1v1me.com/uploads/${fileName}`;
        })
      );

      setUploaded(mappedUrls);
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (Object.keys(god).length === 0) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <Layout className="" showHeaderElements userId={god.id}>
      <div className="mx-auto max-w-[100rem] px-4 overflow-hidden">
        <h2 className="text-white font-bold text-2xl mb-3">Image Uploader</h2>
        {uploaded.length ? (
          <div className="max-w-4xl mx-auto">
            {uploaded.map((url) => (
              <ImageCopy key={url} url={url} />
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center py-24">
            {isLoading ? (
              <Loader />
            ) : (
              <FileUploader
                handleChange={handleChange}
                name="file"
                multiple
                types={fileTypes}
              >
                <div className="flex flex-col items-center justify-center">
                  <img
                    src={ImageIcon}
                    alt="upload"
                    className="max-w-[75%] w-[350px] mb-12"
                  />
                  <p className="text-grey-2 uppercase mb-12">
                    Drag & Drop Image
                  </p>
                  <Button
                    text="Browse"
                    variant="primary"
                    rounded
                    large
                    className="!w-full !max-w-xs"
                  />
                </div>
              </FileUploader>
            )}
          </div>
        )}
        {error && (
          <p className="text-center text-red-600">
            There was an error uploading your images. See the log for details
          </p>
        )}
      </div>
    </Layout>
  );
};

const ImageCopy: FC<{ url }> = ({ url }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  return (
    <div className="mb-16 pb-16 border-b border-grey-4">
      <div className="items-center justify-start gap-4 sm:flex">
        <img
          alt="Uploaded"
          className="mx-auto w-full sm:w-1/3 shrink-0 mb-4  sm:mb-0"
          src={url}
        />
        <div className="w-[3/4] sm:w-2/3">
          <div className="flex items-stretch">
            <div className="py-3 flex-1 p-4 bg-grey-4 text-grey-2 rounded-l-lg whitespace-nowrap overflow-auto">
              {url}
            </div>
            <button
              className="bg-grey-3 disabled:opacity-50 hover:opacity-75 transition-opacity text-white font-bold text-sm rounded-r-lg w-28 overflow-hidden inline-flex items-center justify-center"
              type="button"
              disabled={copied}
              onClick={handleCopy}
            >
              COPY
            </button>
          </div>
          <p
            className={classNames(
              'text-success transition-opacity text-xs pt-1',
              {
                'pointer-events-none opacity-0': !copied,
              }
            )}
          >
            Link Copied!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
