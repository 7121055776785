import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch';
import classNames from 'classnames';
import SearchIcon from '../../assets/Icons/search-icon.svg';
import client from '../../services/client';
import useDebounce from '../../utils/useDebounce';
import Button from '../../components/Buttons/Button/Button';

const Search = ({ onClick, filteredIds, selectedId }) => {
  const [algoliaId, setAlgoliaId] = useState(null);
  const [algoliaKey, setAlgoliaKey] = useState(null);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState('');
  const debouncedVal = useDebounce(value);

  useEffect(() => {
    if (algoliaId && algoliaKey) {
      const algolia = algoliasearch(algoliaId, algoliaKey);
      const index = algolia.initIndex('User');
      index
        .search(debouncedVal, {
          length: 24,
          offset: 0,
        })
        .then(({ hits }) => {
          setResults(hits);
        })
        .catch(() => setResults([]));
    }
  }, [algoliaId, algoliaKey, debouncedVal]);

  useEffect(() => {
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/config`)
      .then(({ data }) => {
        setAlgoliaId(data.algolia_app_id);
        setAlgoliaKey(data.algolia_search_key);
      });
  }, []);

  return (
    <div>
      <label className="block relative pb-8" htmlFor="#search">
        <div className="absolute top-0 left-0 ml-8 pt-3">
          <img
            src={SearchIcon}
            height={24}
            width={24}
            className="object-contain"
            alt="search"
          />
        </div>
        <input
          className="flex-1 py-3 pr-8 pl-20 bg-grey-4 rounded-full w-full block !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue text-white mt-1"
          placeholder="Search for players"
          id="search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </label>
      <div className="max-h-[200px] overflow-auto">
        {results
          .filter((r) => !filteredIds.includes(r.id))
          .map((r) => (
            <div
              key={`user_${r.id}`}
              className={classNames(
                'flex items-center justify-between mb-4 px-4 py-2 rounded-lg',
                {
                  'bg-grey-4': selectedId === r.id,
                }
              )}
            >
              <div className={classNames('flex items-center gap-4', {})}>
                <img
                  src={r.profile_image_url}
                  alt={r.username}
                  height={48}
                  width={48}
                  className="object-cover rounded-full"
                  onError={(e) => {
                    e.target.src =
                      'https://mobile-assets.1v1me.com/users/v1/default_profile.png';
                  }}
                />
                <p className="text-white font-extrabold">{r.username}</p>
              </div>
              <Button
                text={selectedId === r.id ? 'Selected' : 'Select'}
                variant="primary"
                rounded
                disabled={selectedId === r.id}
                onClick={() => {
                  onClick(r.id);
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Search;
