/* eslint-disable */

import { Match } from '../types';

export const anonymizeMatch = (match: Match, userId: number): Match => {
  const userParticipant = match.participants.find((p) => p.user_id === userId);
  const shouldHide = match.state === 'open' || match.state === 'locked';

  return {
    ...match,
    participants: match.participants.map((p) =>
      !shouldHide || p.team_uuid === userParticipant?.team_uuid
        ? p
        : {
            ...p,
            username: '--',
            profile_image_url:
              'https://mobile-assets.1v1me.com/users/v1/anonymous_user_icon.png',
            gamer_id: '--',
            user_profile: {
              ...p.user_profile,
              username: '--',
              profile_image_url:
                'https://mobile-assets.1v1me.com/users/v1/anonymous_user_icon.png',
            },
          }
    ),
  };
};
