/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import {
  nextFriday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
} from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../components/Buttons/Button/Button';
import Loader from '../../components/Loader/Loader';
import useAuth from '../../contexts/AuthContext/AuthContext';
import client from '../../services/client';
import ScheduledEvent from '../Tournaments/Steps/ScheduledEvent';
import GlobalSchedule from './GlobalSchedule';
import { getDates } from './util';
import BaseModal from '../../components/BaseModal/BaseModal';

const ReplaceEvents = () => {
  const { god } = useAuth();

  const dates = useMemo(getDates, []);
  const [selectedDate, setSelectedDate] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [dateOverride, setDateOverride] = useState('');
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [matchesError, setMatchesError] = useState(false);
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matches, setMatches] = useState([]);
  const [matchesPage, setMatchesPage] = useState(1);
  const [doneFetching, setDoneFetching] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const openModal = (draft) => {
    const startDateTime = new Date(draft.event.start_date);
    const startHour = startDateTime.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if needed
    const startMinute = startDateTime.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if needed

    setSelectedDraft(draft);
    setDateOverride(`${startHour}:${startMinute}`); // Set the draft object in the state before opening the modal
    setModalOpen(true);
  };

  const daysOfWeek = useMemo(() => {
    const monday = new Date(dates[selectedDate].split(' - ')[0]);
    const tuesday = nextTuesday(monday);
    const wednesday = nextWednesday(tuesday);
    const thursday = nextThursday(wednesday);
    const friday = nextFriday(thursday);
    const saturday = nextSaturday(friday);
    const sunday = nextSunday(saturday);

    return {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    };
  }, [dates, selectedDate]);

  const fetchSlots = useCallback(
    (disableLoad) => {
      setError(false);
      setMatchesPage(1);
      if (!disableLoad) {
        setLoading(true);
      }

      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/events/drafts/schedule?period_offset=${selectedDate}`
        )
        .then(({ data }) => setSlots(data))
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoading(false));
    },
    [selectedDate]
  );

  const fetchSlotMatches = useCallback(() => {
    setMatchesError(false);

    if (!selectedSlot) {
      return;
    }

    if (matchesPage === 1) {
      setDoneFetching(false);
    }
    setLoadingMatches(true);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/events/drafts/available_matches?period_offset=${selectedDate}&timeslot_dow_id=${selectedSlot}&page_size=10&page=${matchesPage}`
      )
      .then(({ data }) => {
        if (matchesPage === 1) {
          setMatches(data);
        } else {
          setMatches((m) => [...m, ...data]);
        }

        if (data.length < 10) {
          setDoneFetching(true);
        }
      })
      .catch(() => {
        setMatchesError(true);
      })
      .finally(() => setLoadingMatches(false));
  }, [selectedSlot, matchesPage, selectedDate]);

  useEffect(() => {
    fetchSlots();
  }, [fetchSlots]);

  useEffect(() => {
    fetchSlotMatches();
  }, [fetchSlotMatches]);

  const handleApprove = async (time_override) => {
    const start_date = new Date(selectedDraft.event.start_date);
    const [overrideHour, overrideMinute] = time_override.split(':').map(Number);

    start_date.setHours(overrideHour);
    start_date.setMinutes(overrideMinute);
    try {
      const res = window.confirm('Are you sure?');
      if (res) {
        setIsLoadingSave(true);
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/events/drafts/approve_draft`,
          {
            draft_id: selectedDraft.id,
            start_date: start_date.toISOString(),
          }
        );

        fetchSlots(true);
        fetchSlotMatches();
        setModalOpen(false);
      }
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoadingSave(false);
    }
  };

  if (Object.keys(god).length === 0) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }
  return (
    <div className="flex gap-8 border-t border-grey-4  h-[calc(100vh-80px)] overflow-y-auto w-full overflow-hidden">
      <div className="p-4 py-8 max-w-7xl mx-auto w-full text-grey-2">
        <div className="flex items-center gap-4 justify-between mb-8">
          <h3 className="text-3xl text-white font-extrabold">Replace Events</h3>
          <select
            aria-label="Date"
            className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 min-w-[300px] rounded-full"
            value={selectedDate}
            onChange={(e) => setSelectedDate(Number(e.target.value))}
          >
            <option key={`date_${0}`} value={0}>
              {dates[0]}
            </option>
            <option key={`date_${1}`} value={1}>
              {dates[1]}
            </option>
          </select>
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <p className="text-center py-12 px-4 text-warning">
            Something went wrong
          </p>
        ) : (
          <div className="overflow-auto">
            <GlobalSchedule
              slots={slots}
              dates={daysOfWeek}
              selectedSlot={selectedSlot}
              onSelectSlot={setSelectedSlot}
            />
          </div>
        )}
        {loadingMatches ? (
          <Loader />
        ) : matchesError ? (
          <p className="text-center text-warning">Error loading matches</p>
        ) : (
          <div className="pb-12">
            {matches.map((draft) => (
              <div className="pb-12">
                <BaseModal
                  modalIsOpen={modalOpen}
                  large
                  header="Select Time"
                  height="30vh"
                >
                  <div className="pt-8">
                    <p className="text-white font-bold mb-3" style={{ marginLeft: '15px' }}>
                      Would you like to override the start time?
                    </p>
                    <input
                      type="time"
                      className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
                      placeholder="Click to enter the start date"
                      name="start_date"
                      onChange={(e) => setDateOverride(e.target.value)}
                      value={dateOverride}
                    />
                    <div className="flex items-center justify-center pt-4 space-x-4">
                      <Button
                        rounded
                        large
                        variant="tertiary"
                        text="Cancel"
                        onClick={() => setModalOpen(false)}
                      />
                      <Button
                        rounded
                        large
                        variant="primary"
                        text="Approve"
                        onClick={() => handleApprove(dateOverride)}
                      />
                    </div>
                  </div>
                </BaseModal>
                <ScheduledEvent
                  key={`draft_${draft.id}`}
                  game={draft.event.play_info.game_name}
                  title={draft.event.play_info.game_mode_title}
                  color={draft.event.play_info.game_mode_color}
                  icon={draft.event.play_info.game_icon}
                  subtitle={draft.event.play_info.subtitle}
                  startDate={draft.event.start_date}
                  rollover_available={draft.rollover_available}
                  teams={draft.event.teams}
                  delta={draft.score}
                  onApprove={() => openModal(draft)}
                  isLoading={isLoadingSave}
                />
              </div>
            ))}
            {selectedSlot && doneFetching && matches.length === 0 && (
              <p className="text-center">
                Nothing to display for this timeslot
              </p>
            )}
            {!doneFetching && matches.length > 0 && (
              <div className="flex items-center justify-center pt-4">
                <Button
                  variant="tertiary"
                  text="Load More"
                  onClick={() => setMatchesPage(matchesPage + 1)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReplaceEvents;
