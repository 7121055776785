const SELLING_OUT = {
  counters: {
    u_column: 105,
    u_row: 48,
    u_content_image: 63,
    u_content_text: 36,
    u_content_heading: 34,
    u_content_button: 15,
    u_content_menu: 1,
    u_content_html: 1,
    u_content_divider: 5,
  },
  body: {
    id: '34nYduowRQ',
    rows: [
      {
        id: 'IWAqxLIsrY',
        cells: [1],
        columns: [
          {
            id: 'ifAWbXVslT',
            contents: [
              {
                id: 'eZcFMSuCDb',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/da53a24d-6a71-49a8-85c0-4cf85f2f38b1',
                    width: 2398,
                    height: 332,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '#000000',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'shvpketRLu',
        cells: [1],
        columns: [
          {
            id: '5S7ezG6Gyr',
            contents: [
              {
                id: '9juzZiurrQ',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/31767128-b86b-4dcf-86fa-c4453536334c',
                    width: 499,
                    height: 366,
                    autoWidth: false,
                    maxWidth: '38%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_58',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: 'byW8_HjwHC',
                type: 'heading',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '24px',
                  textAlign: 'center',
                  lineHeight: '130%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_12',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'Selling out fast',
                },
              },
              {
                id: 'HtAVCJRa6U',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '14px',
                  color: '#ffffff',
                  textAlign: 'center',
                  lineHeight: '170%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_8',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 170%;">Lock in your stake on {{teamname}} before the sell out</p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px 24px 12px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_37', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_18', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'wKQLYBJhVW',
        cells: [16.4, 83.6],
        columns: [
          {
            id: 'Bb46_1S_fD',
            contents: [
              {
                id: 'O1cT1u8MaT',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/games/v1/nba2k23/logo.png',
                    width: 256,
                    height: 256,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_55',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '12px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#4a19a0',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_81', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'XWLQEZZasn',
            contents: [
              {
                id: 'y3VEwPv5of',
                type: 'text',
                values: {
                  containerPadding: '0px 0px 0px 4px',
                  anchor: '',
                  fontSize: '12px',
                  color: '#8d8d8d',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_25',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">NBA2K23</p>',
                },
              },
              {
                id: 'REYKkpQfmN',
                type: 'heading',
                values: {
                  containerPadding: '4px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'left',
                  lineHeight: '100%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_28',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '2v2 MyPlayer',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '16px 0px 0px 4px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_82', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px 10px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_35', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'dCVcF0mN07',
        cells: [1, 1],
        columns: [
          {
            id: 'G67s9Ixis3',
            contents: [
              {
                id: 'MIRLVYfQRD',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '10px',
                  color: '#8d8d8d',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_33',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">8:00PM · April 12th </p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_84', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'JMRX0E8f78',
            contents: [
              {
                id: 'Vo1KDiTULK',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '10px',
                  color: '#8d8d8d',
                  textAlign: 'right',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_26',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Stake $100</p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_99', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_36', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'fW-7vPR9OA',
        cells: [13.74, 55.33, 30.93],
        columns: [
          {
            id: 'wQGxTdDIi0',
            contents: [
              {
                id: '3eGIqfeF5w',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://1v1me-profile-pictures.s3.amazonaws.com/226695-1674153678-ee0a8e7d-4a08-47a0-a0fa-66c38ab6a1cb',
                    width: 640,
                    height: 640,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_56',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_85', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'ZD0OKTpFmQ',
            contents: [
              {
                id: 'utildE1YGU',
                type: 'heading',
                values: {
                  containerPadding: '0px 8px 0px 4px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '120%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_29',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'Team iqcontained',
                },
              },
              {
                id: 'sX5AWWs-5P',
                type: 'text',
                values: {
                  containerPadding: '0px 4px',
                  anchor: '',
                  fontSize: '10px',
                  color: '#8d8d8d',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_27',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">iqcontained · Streakyx</p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '16px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_86', htmlClassNames: 'u_column' },
              _override: { mobile: { padding: '12px 0px 0px' } },
            },
          },
          {
            id: '-FB1tD5qqd',
            contents: [
              {
                id: 'ni2q7g_eKL',
                type: 'button',
                values: {
                  containerPadding: '12px 10px 12px 0px',
                  anchor: '',
                  href: { name: 'web', values: { href: '', target: '_blank' } },
                  buttonColors: {
                    color: '#00e024',
                    backgroundColor: '#333333',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: { autoWidth: true, width: '100%' },
                  fontSize: '12px',
                  textAlign: 'right',
                  lineHeight: '120%',
                  padding: '10px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_14',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'WIN $123*',
                  calculatedWidth: 98,
                  calculatedHeight: 34,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_100', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_37', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'Fq1VHTGyGW',
        cells: [1],
        columns: [
          {
            id: 'xSIi4c_UCc',
            contents: [
              {
                id: 'sAoRSqjQD_',
                type: 'divider',
                values: {
                  width: '0%',
                  border: {
                    borderTopWidth: '1px',
                    borderTopStyle: 'solid',
                    borderTopColor: '#696969',
                  },
                  textAlign: 'center',
                  containerPadding: '10px',
                  anchor: '',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_divider_5',
                    htmlClassNames: 'u_content_divider',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_98', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_46', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'GjrqPjMPFx',
        cells: [1],
        columns: [
          {
            id: 'j6pwUUqBvM',
            contents: [
              {
                id: 'jpmB7F7Tp4',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1a36f25d-9ba0-4e22-815f-50e76f93dd14',
                    width: 1013,
                    height: 192,
                    autoWidth: false,
                    maxWidth: '51%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_59',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '12px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_91', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_41', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'Qatqk-r7PG',
        cells: [1],
        columns: [
          {
            id: 'D4hv7WLdCS',
            contents: [
              {
                id: 'tNAboqosHT',
                type: 'heading',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '24px',
                  textAlign: 'center',
                  lineHeight: '130%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_31',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'Upcoming matches',
                },
              },
              {
                id: 'uZrBqHl7SU',
                type: 'text',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  fontSize: '14px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '170%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_29',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 170%;">Checkout other matchups happening today</p>',
                },
              },
            ],
            values: {
              backgroundColor: '#0d0d0d',
              padding: '24px 24px 12px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_92', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_42', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'FFedBSHy45',
        cells: [16.4, 83.6],
        columns: [
          {
            id: 'BCZhQaZt61',
            contents: [
              {
                id: 'WUKDuimvTd',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/games/v1/nba2k23/logo.png',
                    width: 256,
                    height: 256,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_61',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '12px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#4a19a0',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_93', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '0h_EN9l4l6',
            contents: [
              {
                id: 'do5wVpecqW',
                type: 'text',
                values: {
                  containerPadding: '0px 0px 0px 4px',
                  anchor: '',
                  fontSize: '12px',
                  color: '#8d8d8d',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_30',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">NBA2K23</p>',
                },
              },
              {
                id: 'Ai1eP5BL3s',
                type: 'heading',
                values: {
                  containerPadding: '4px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'left',
                  lineHeight: '100%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_32',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '2v2 MyPlayer',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '16px 0px 0px 4px',
              border: {
                borderTopWidth: '0px',
                borderTopStyle: 'solid',
                borderTopColor: '#CCC',
                borderLeftWidth: '0px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#CCC',
                borderRightWidth: '0px',
                borderRightStyle: 'solid',
                borderRightColor: '#CCC',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#333333',
              },
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_94', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px 10px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_43', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: '6wvFhhViua',
        cells: [1, 1],
        columns: [
          {
            id: '7C-ZDlvPOU',
            contents: [
              {
                id: 'MuX3She3UZ',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '10px',
                  color: '#8d8d8d',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_35',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">8:00PM · April 12th </p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_104', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'f9zOUqHe9z',
            contents: [
              {
                id: '1XhgxBmo23',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '10px',
                  color: '#8d8d8d',
                  textAlign: 'right',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_36',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Stake $100</p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_105', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_48', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'KYVfOxLDUG',
        cells: [13.74, 55.33, 30.93],
        columns: [
          {
            id: 'uObfYyaVis',
            contents: [
              {
                id: 'NsE3Y0jeNq',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://1v1me-profile-pictures.s3.amazonaws.com/226695-1674153678-ee0a8e7d-4a08-47a0-a0fa-66c38ab6a1cb',
                    width: 640,
                    height: 640,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_63',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_101', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '9TNUVFLzLS',
            contents: [
              {
                id: 'JIYJaG4F-a',
                type: 'heading',
                values: {
                  containerPadding: '0px 8px 0px 4px',
                  anchor: '',
                  headingType: 'h1',
                  fontWeight: 700,
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '120%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_34',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'Team iqcontained',
                },
              },
              {
                id: 'ueD5qkA63Q',
                type: 'text',
                values: {
                  containerPadding: '0px 4px',
                  anchor: '',
                  fontSize: '10px',
                  color: '#8d8d8d',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_34',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">iqcontained · Streakyx</p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '16px 0px 0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_102', htmlClassNames: 'u_column' },
              _override: { mobile: { padding: '12px 0px 0px' } },
            },
          },
          {
            id: 'JWE9UpDNkD',
            contents: [
              {
                id: 'wOAYb7TGxe',
                type: 'button',
                values: {
                  containerPadding: '12px 10px 12px 0px',
                  anchor: '',
                  href: { name: 'web', values: { href: '', target: '_blank' } },
                  buttonColors: {
                    color: '#00e024',
                    backgroundColor: '#333333',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: { autoWidth: true, width: '100%' },
                  fontSize: '12px',
                  textAlign: 'right',
                  lineHeight: '120%',
                  padding: '10px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_15',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'WIN $123*',
                  calculatedWidth: 98,
                  calculatedHeight: 34,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_103', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_47', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { mobile: { noStackMobile: true } },
        },
      },
      {
        id: 'CT4idjgPBd',
        cells: [1],
        columns: [
          {
            id: '3UX__uuocO',
            contents: [
              {
                id: 'xi26-1J-pK',
                type: 'divider',
                values: {
                  width: '0%',
                  border: {
                    borderTopWidth: '1px',
                    borderTopStyle: 'solid',
                    borderTopColor: '#696969',
                  },
                  textAlign: 'center',
                  containerPadding: '10px',
                  anchor: '',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_divider_4',
                    htmlClassNames: 'u_content_divider',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_90', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '#191919',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_40', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'HqzczuFTx2',
        cells: [1],
        columns: [
          {
            id: '-VVXnuSF-Y',
            contents: [
              {
                id: 'btjx9RkM9P',
                type: 'divider',
                values: {
                  width: '100%',
                  border: {
                    borderTopWidth: '1px',
                    borderTopStyle: 'solid',
                    borderTopColor: '#0d0d0d',
                  },
                  textAlign: 'center',
                  containerPadding: '10px',
                  anchor: '',
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_divider_3',
                    htmlClassNames: 'u_content_divider',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_89', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#0d0d0d',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_39', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'I-wX3TNkhp',
        cells: [1, 1],
        columns: [
          {
            id: 'IMpFDkPZS1',
            contents: [
              {
                id: 'SqSfH0my7v',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/dcc6bb49-3751-4612-b3d3-0320d3f5c96c',
                    width: 709,
                    height: 384,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '', target: '_blank' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_10',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '24px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_8', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'AYi8vDVDXY',
            contents: [
              {
                id: 'Ftk3G-q6ub',
                type: 'heading',
                values: {
                  containerPadding: '48px 0px 12px',
                  anchor: '',
                  headingType: 'h3',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_7',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'DOWNLOAD THE APP',
                },
              },
              {
                id: 'zu-GlKqmLL',
                type: 'image',
                values: {
                  containerPadding: '0px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6f2a8e3f-9d12-43e2-a64d-46118a7d5f0e',
                    width: 400,
                    height: 131,
                    autoWidth: false,
                    maxWidth: '48%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/AxN12BiEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_11',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_8', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'z7NauV6I9m',
        cells: [1],
        columns: [
          {
            id: 'FbDEsm_j83',
            contents: [
              {
                id: 'BJbBsIqn1-',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/6b939366-6cd7-4c64-b091-fadc021680b8',
                    width: 400,
                    height: 124,
                    autoWidth: false,
                    maxWidth: '19%',
                  },
                  textAlign: 'center',
                  altText: '1v1Me',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/kI2naigEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_23',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: { mobile: { hideMobile: false } },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_35', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_17', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: { desktop: { hideDesktop: false } },
        },
      },
      {
        id: 'oudVuuSmXB',
        cells: [25.76, 10, 10, 10, 10, 10, 24.24],
        columns: [
          {
            id: 'OhUm-JNmeN',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_29', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'QeAT1lN5yn',
            contents: [
              {
                id: 'MsGoiy4bew',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/d2c56c65-0fbf-475f-9572-90134f7bc268',
                    width: 96,
                    height: 96,
                    autoWidth: true,
                    maxWidth: '100%',
                  },
                  textAlign: 'center',
                  altText: 'tiktok',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/93FpFYkEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_24',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_30', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'lBsm5bHyM-',
            contents: [
              {
                id: 'ViNkcSi7sv',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/1681b2d9-916f-4e3a-be71-2ced09bf968a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitter',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/V1PW9RqEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_25',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_31', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '8TzlLLrBwQ',
            contents: [
              {
                id: 'YQ0_d2ulfA',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/856f006d-6370-4054-9a55-e15192f4da7c',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Facebook',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/st8hFXsEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_26',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_32', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'E7-VwyL17N',
            contents: [
              {
                id: 'sOIA_u7IBD',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/33ae9ddb-f9c7-446b-b11b-7297a7738c48',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'instagram',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/BJ4BBSoEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_27',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_33', htmlClassNames: 'u_column' },
            },
          },
          {
            id: 'BKudhtcc4r',
            contents: [
              {
                id: 'nCNxFmMcqj',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://mobile-assets.1v1me.com/uploads/f862219c-bbf7-47ab-853f-a0e78e6bd24a',
                    width: 97,
                    height: 97,
                  },
                  textAlign: 'center',
                  altText: 'Twitch',
                  action: {
                    name: 'web',
                    values: {
                      href: 'https://1v1.me/wsKKtQmEMyb',
                      target: '_blank',
                    },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_28',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_34', htmlClassNames: 'u_column' },
            },
          },
          {
            id: '4hD1dm-2wc',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_36', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_16', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          _override: {
            mobile: {
              noStackMobile: true,
              backgroundColor: '#000000',
              hideMobile: false,
            },
            desktop: { hideDesktop: false },
          },
        },
      },
      {
        id: 'tYRgQpRhOY',
        cells: [1],
        columns: [
          {
            id: 'yPGkfQS7Pu',
            contents: [
              {
                id: 'HxwSHKZ1Nh',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  color: '#979797',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_text_7',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Don’t want these emails? <a rel="noopener" href="<%= unsubscribe %>" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6IjwlPSB1bnN1YnNjcmliZSAlPiIsInRhcmdldCI6Il9ibGFuayJ9fQ=="><span style="line-height: 19.6px;">Unsubscribe</span></a></p>',
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_20', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '#000000',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_11', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: 'center',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      textColor: '#ffffff',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: '#000000',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      preheaderText: 'STAKE & WIN BIG',
      linkStyle: {
        body: true,
        linkColor: '#ffc20e',
        linkHoverColor: '#0000ee',
        linkUnderline: false,
        linkHoverUnderline: true,
        inherit: false,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 14,
};

export default SELLING_OUT;
