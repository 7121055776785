const convertToDollarAmount = (amount) => {
  const dollars = amount / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

const formattedDate = (date) => {
  const event = new Date(date);
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const dateEvent = event.toLocaleDateString('en-US', options);

  const time = event.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return `${dateEvent} - ${time}`;
};

const formatTime = (time) => {
  const event = new Date(time);

  const formattedTime = event.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return formattedTime;
};

const formateAudit = (userId, description, title) => {
  const unassigned = description?.includes('(Unassigned)');
  const cheating = description?.includes('Cheating Ban until');
  const financial = description?.includes('Financial Ban until');
  const community = description?.includes('Community Ban until');
  const dispute = description?.includes('Dispute Ban until');
  const device = description?.includes('Device Ban until');
  const deleted = description?.includes('Deleted Ban until');

  const dateOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  if (unassigned) {
    return `User${userId} ${description.replace('(Unassigned)', '')}`;
  }

  if (cheating) {
    const grabDate = description.replace('Cheating Ban until', '');
    const newDate = new Date(grabDate);

    return `Cheating ban until ${newDate.toLocaleDateString(
      'en-US',
      dateOptions
    )}`;
  }

  if (financial) {
    const grabDate = description.replace('Cheating Ban until', '');
    const newDate = new Date(grabDate);

    return `Financial ban until ${newDate.toLocaleDateString(
      'en-US',
      dateOptions
    )}`;
  }

  if (community) {
    const grabDate = description.replace('Cheating Ban until', '');
    const newDate = new Date(grabDate);

    return `Community ban until ${newDate.toLocaleDateString(
      'en-US',
      dateOptions
    )}`;
  }
  if (dispute) {
    const grabDate = description.replace('Dispute Ban until', '');
    const newDate = new Date(grabDate);

    return `Dispute ban until ${newDate.toLocaleDateString(
      'en-US',
      dateOptions
    )}`;
  }

  if (device) {
    const grabDate = description.replace('Device Ban until', '');
    const newDate = new Date(grabDate);

    return `Device ban until ${newDate.toLocaleDateString(
      'en-US',
      dateOptions
    )}`;
  }
  if (deleted) {
    const grabDate = description.replace('Deleted Ban until', '');
    const newDate = new Date(grabDate);

    return `Deleted ban until ${newDate.toLocaleDateString(
      'en-US',
      dateOptions
    )}`;
  }

  if (title === 'USER ENABLED') {
    return 'User Unbanned';
  }

  return description;
};

export { convertToDollarAmount, formattedDate, formateAudit, formatTime };
