import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import EmptyState from '../../components/EmptyState';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';

const ReferralsModal = ({ isOpen, onClose, userId }) => {
  const [referrals, setReferrals] = useState([]);
  const [page, setPage] = useState(1);
  const [doneFetching, setDoneFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (page === 1) {
      setLoading(true);
    }
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/referrals/referred?user_id=${userId}&page=${page}&page_size=20`
      )
      .then(({ data }) => {
        if (data.length < 20) {
          setDoneFetching(true);
        }
        if (page === 1) {
          setLoading(false);
          setReferrals(data);
        } else {
          setReferrals((r) => [...r, ...data]);
        }
      })
      .catch(() => {
        // noop
      })
      .finally(() => setLoading(false));
  }, [page, userId]);
  return (
    <BaseModal modalIsOpen={isOpen} closeModal={onClose} header="Referrals">
      {loading && <Loader />}
      {!loading && referrals.length === 0 && (
        <EmptyState text="This user has no referrals" />
      )}
      {referrals.length > 0 &&
        referrals.map((referral) => (
          <Link
            onClick={() => onClose()}
            key={referral.user_profile.username}
            to={`/godmode/users/${referral.user_profile.id}`}
            className="flex gap-4 py-2 text-grey-4 items-center transition-colors hover:bg-white/10"
          >
            <div>
              <img
                src={referral.user_profile.profile_image_url}
                alt={referral.user_profile.username}
                className="rounded-full object-cover h-[32px] w-[32px]"
              />
            </div>

            <div className="flex-1">
              <h3 className="text-white font-bold leading-none mb-1">
                {referral.user_profile.username}
              </h3>
            </div>
            <div className="self-center">
              {referral.redeemed ? (
                <p className="text-gold font-semibold  py-2 rounded-full">
                  Redeemed
                </p>
              ) : (
                <p className="text-grey-2 font-semibold  py-2   rounded-full">
                  Pending
                </p>
              )}
            </div>
          </Link>
        ))}
      {!loading && referrals.length > 0 && !doneFetching && (
        <div className="flex  pt-8">
          <Button
            text="Load More"
            variant="primary"
            onClick={() => setPage((p) => p + 1)}
          />
        </div>
      )}
    </BaseModal>
  );
};

export default ReferralsModal;
