/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import Button from '../Buttons/Button/Button';

const DisputeCard = ({
  disputeId,
  participants,
  players,
  matchName,
  submitMatchPlacement,
  onReplay,
}) => {
  const disputeTeam = groupBy(participants, 'team_uuid');
  const sortedTeams = Object.keys(disputeTeam).map((key) => disputeTeam[key]);
  const teams = players.map((t) => {
    const id = sortedTeams.find((s) =>
      s.some((u) => u.username === t.players[0].username)
    )?.[0].team_uuid;

    return {
      ...t,
      teamId: id,
    };
  });

  return (
    <div className="bg-grey-4 px-3 py-4 rounded-xl mt-3">
      <div>
        <h4 className="text-white text-xl font-bold">{matchName}</h4>
        <div className="mt-3 flex justify-between gap-4 mb-4">
          {submitMatchPlacement &&
            teams.map((player, i) => (
              <div key={uuidv4()} className="">
                <p className="text-white text-base font-bold">
                  Team {player.name}
                </p>
                {player.players.map((team) =>
                  team.link.length ? (
                    <a
                      target="_blank"
                      href={team.link}
                      key={uuidv4()}
                      className="text-secondary font-bold text-sm block mb-6 cursor-pointer hover:underline"
                      rel="noreferrer"
                    >
                      {team.username} VOD
                    </a>
                  ) : (
                    <div key={uuidv4()} className=" mb-4" />
                  )
                )}
                <Button
                  rounded
                  onClick={() =>
                    submitMatchPlacement(
                      disputeId,
                      player.teamId,
                      players[i === 1 ? 0 : 1].teamId
                    )
                  }
                  variant="primary"
                  text={`Team ${player.players[0].username} Won`}
                />
              </div>
            ))}
        </div>
        <Button
          text="Force Replay Match"
          variant="tertiary"
          className="w-full !text-warning !bg-grey-3"
          onClick={onReplay}
        />
      </div>
    </div>
  );
};

DisputeCard.propTypes = {
  disputeId: PropTypes.number,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      username: PropTypes.string,
    })
  ),
  matchName: PropTypes.string,
  submitMatchPlacement: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  participants: PropTypes.array,
};

export default DisputeCard;
