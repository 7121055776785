/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SelectGame from './SelectGame';
import ModeSelect from './ModeSelect.tsx';
import useTournament from '../../../contexts/TournamentsContext/TournamentsContext';
import Button from '../../../components/Buttons/Button/Button';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Notes from '../../../features/Notes';
import client from '../../../services/client';

const Recruitment = () => {
  const { games, getGames } = useTournament();
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [players, setPlayers] = useState([]);
  const [page, setPage] = useState(1);
  const [doneFetching, setDoneFetching] = useState(false);

  useEffect(() => {
    if (!selectedMode) {
      return;
    }
    setDoneFetching(false);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/recruitment?game_mode_id=${selectedMode.id}&page_size=20&page=${page}`
      )
      .then(({ data }) => {
        if (data.length < 20) {
          setDoneFetching(true);
        }
        if (!page) {
          setPlayers(data);
        } else {
          setPlayers((e) => [...e, ...data]);
        }
      })
      .catch((e) => {
        alert(e.response?.data?.message || 'Something went wrong');
      });
  }, [page, selectedMode]);

  useEffect(() => {
    getGames();
  }, []);

  const handleBack = () => {
    if (selectedMode) {
      setPlayers([]);
      setDoneFetching(false);
      setPage(1);
      return setSelectedMode(null);
    }
    return setSelectedGame(null);
  };

  return (
    <div className="flex gap-8 border-t border-grey-4  h-[calc(100vh-80px)] overflow-y-auto">
      <div className="p-4 max-w-7xl mx-auto w-full text-grey-2">
        {selectedGame && (
          <div className="mb-4">
            <Button text="Back" variant="grey" onClick={handleBack} />
          </div>
        )}
        {!selectedGame && (
          <SelectGame games={games} setGameSelected={setSelectedGame} />
        )}
        {selectedGame && !selectedMode && (
          <ModeSelect
            gameId={selectedGame?.id}
            selectedMode={selectedMode}
            onSelect={(id, _b, _c, name) => setSelectedMode({ id, name })}
          />
        )}
        {selectedGame && selectedMode && (
          <div>
            <h3 className="text-3xl font-bold text-white mb-4">
              {selectedGame.name}: {selectedMode.name}
            </h3>
            <div>
              <div className="rounded-t-2xl bg-grey-4 p-4 grid grid-cols-12 text-white items-center gap-4 mb-1">
                <p className="col-span-3 text-xs">Username</p>
                <p className="text-xs col-span-2">Total Matches</p>
                <p className="text-xs col-span-2">Dispute Loss %</p>
                <p className="text-xs col-span-2">RP</p>
              </div>
              {players.map((player) => (
                <Row
                  key={player.user_profile.id}
                  username={player.user_profile.username}
                  profileImage={player.user_profile.profile_image_url}
                  id={player.user_profile.id}
                  totalMatches={player.num_of_matches}
                  disputeLossPercent={player.dispute_lost_percentage}
                  rp={player.rp}
                />
              ))}
            </div>
            {!doneFetching && players.length > 0 && (
              <div className="flex items-center justify-center pb-8 mt-8">
                <Button
                  variant="secondary"
                  text="Load More"
                  onClick={() => setPage(page + 1)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Row = ({
  username,
  profileImage,
  id,
  totalMatches,
  disputeLossPercent,
  rp,
}) => {
  const [messaging, setMessaging] = useState(false);
  const [notes, setNotes] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMessageInput('');
  }, [notes, messaging]);

  const handleSend = async () => {
    setLoading(true);
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/recruitment_message`,
        {
          user_id: id,
          message: messageInput,
        }
      );
      alert('Message Sent');
      setMessageInput('');
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="rounded-lg hover:bg-grey-4/50 px-4 py-3 grid grid-cols-12 text-white items-center gap-4">
      <Link
        to={`/godmode/users/${id}`}
        target="_blank"
        className="col-span-3 text-sm grid grid-cols-12 gap-2 hover:underline"
      >
        <img
          src={profileImage}
          className="w-[24px] h-[24px] object-cover rounded-full shrink-0 col-span-2"
          alt={username}
        />
        <span className="col-span-10 truncate text-white font-bold">
          {username}
        </span>
      </Link>
      <p className="text-sm col-span-2 text-grey-2">{totalMatches}</p>
      <p className="text-sm text-grey-2 col-span-2">{disputeLossPercent}%</p>
      <p className="text-sm text-white font-bold col-span-2">
        {rp.toLocaleString()} RP
      </p>
      <p className="text-sm col-span-3 grid grid-cols-2 gap-2">
        <Button
          text="Notes"
          rounded
          variant="grey"
          className="!w-full"
          large
          onClick={() => setNotes(true)}
        />
        <Button
          text="Message"
          rounded
          variant="primary"
          large
          className="!w-full"
          onClick={() => setMessaging(true)}
        />
      </p>
      <BaseModal
        modalIsOpen={messaging}
        closeModal={() => setMessaging(false)}
        header={`Message ${username}`}
        height="200px"
      >
        <textarea
          className="py-3 text-sm text-white bg-grey-3 focus:outline-none px-4 w-full rounded-lg mb-4"
          rows={5}
          onChange={(e) => setMessageInput(e.target.value)}
          value={messageInput}
          placeholder="Write a message..."
        />
        <Button
          text="Send"
          variant="primary"
          rounded
          large
          className="!w-full"
          onClick={handleSend}
          isLoading={loading}
          disabled={loading || !messageInput.trim().length}
        />
      </BaseModal>

      <BaseModal
        large
        modalIsOpen={!!notes}
        className="!pb-0"
        height="600px"
        closeModal={() => setNotes(false)}
      >
        <div className="flex flex-col h-full">
          {notes && <Notes userIdOverride={id} />}
        </div>
      </BaseModal>
    </div>
  );
};

export default Recruitment;
