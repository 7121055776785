/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { isAfter } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import React, { useEffect, useState } from 'react';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import client from '../../services/client';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const timeLookup = {
  '1S': SECOND,
  '1H': HOUR,
  '24H': DAY,
  '48H': DAY * 2,
  '72H': DAY * 3,
  '1W': DAY * 7,
  '1M': DAY * 31,
  Forever: DAY * 365 * 2000,
};

const renderTime = (d) => {
  if (typeof d !== 'string') {
    return '';
  }
  const date = new Date(d);
  if (date.getFullYear() > 3000) return 'Forever';
  const today = isToday(date);
  const tomorrow = isTomorrow(date);
  const thisWeek = differenceInDays(date, new Date()) < 7;
  const time = format(date, 'h:mmaa');

  if (today) return `Until ${time}`;
  if (tomorrow) return `Until Tomorrow at ${time}`;
  if (thisWeek) return `Until ${format(date, 'EEEE')} at ${time}`;
  return `Until ${format(date, 'MMMM dd')} at ${time}`;
};

const keyLookup = {
  Messaging: 'messaging_disabled_until',
  Wagering: 'wagering_disabled_until',
  Staking: 'staking_disabled_until',
  Referral: 'referral_code_disabled',
};

const FeatureModal = ({ isOpen, onClose, onSave, user }) => {
  const [toDisable, setToDisable] = useState('');
  const [time, setTime] = useState('');
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toDisable) {
      setTime('');
      setReason('');
    }
  }, [toDisable]);

  const handleDisable = (val) => {
    setToDisable(val);
  };

  const handleSubmit = async (override, noExpiry) => {
    try {
      const ok = confirm('Are You Sure?');
      if (!ok) {
        return;
      }
      const payload = {
        reason:
          override.reason === null
            ? prompt('Reason?')
            : override.reason || reason,
        feature: override.feature || toDisable.toLowerCase(),
      };
      if (!noExpiry) {
        const expTime = new Date(new Date().getTime() + timeLookup[time]);
        payload.expires_at =
          override.expires_at === null
            ? null
            : override.expires_at || expTime.toISOString();
      }
      if (typeof override.active === 'boolean') {
        payload.active = override.active;
      }
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${user.user.id}/feature_disablement`,
        payload
      );
      onSave();
      setToDisable('');
    } catch (e) {
      console.error(e);
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header="Disabled Features"
    >
      <div>
        {['Messaging', 'Wagering', 'Staking', 'Referral'].map((r) => {
          const date = user[keyLookup[r]];
          const isDisabled =
            date === true || (!!date && isAfter(new Date(date), new Date()));
          return (
            <div
              key={r}
              className="flex items-center gap-4 justify-between py-3 hover:bg-grey-3/5"
            >
              <h3 className=" font-extrabold text-white text-lg">{r}</h3>
              {isDisabled ? (
                <Button
                  text={`Disabled ${renderTime(date)}`}
                  rounded
                  className="!text-warning !bg-grey-3"
                  variant="grey"
                  onClick={() =>
                    r === 'Referral'
                      ? handleSubmit(
                          {
                            active: true,
                            reason: 'Re-Enable',
                            feature: r.toLowerCase(),
                          },
                          true
                        )
                      : handleSubmit({
                          expires_at: null,
                          reason: 'Re-Enable',
                          feature: r.toLowerCase(),
                        })
                  }
                />
              ) : (
                <Button
                  text="Disable"
                  rounded
                  variant="warning"
                  onClick={() => handleDisable(r)}
                />
              )}
            </div>
          );
        })}
      </div>
      <BaseModal
        modalIsOpen={!!toDisable}
        closeModal={() => setToDisable('')}
        header={`Disable ${toDisable}`}
        height="auto"
      >
        {toDisable !== 'Referral' && (
          <div className="my-6 border border-zinc-700 flex justify-between w-full rounded-full overflow-hidden">
            {['1H', '24H', '48H', '72H', '1W', '1M', 'Forever'].map((t) => (
              <button
                key={t}
                type="button"
                className={classNames(
                  'w-32 text-zinc-500 py-2 px-3 transition-colors',
                  {
                    '!bg-white !text-black': time === t,
                  }
                )}
                onClick={() => setTime(t)}
              >
                {t}
              </button>
            ))}
          </div>
        )}
        <textarea
          className="bg-grey-3 text-grey-2 rounded-2xl h-44 w-full p-4 resize-none"
          placeholder="Reason (required)"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <Button
          className="w-full mt-12 mb-4"
          rounded
          variant="primary"
          text="Disable"
          isLoading={loading}
          disabled={!reason || (toDisable !== 'Referral' && !time) || loading}
          onClick={() =>
            toDisable !== 'Referral'
              ? handleSubmit({})
              : handleSubmit(
                  {
                    active: false,
                    reason,
                    feature: 'referral',
                  },
                  true
                )
          }
        />
      </BaseModal>
    </BaseModal>
  );
};

export default FeatureModal;
