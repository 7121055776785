/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';

const Carousel: FC<{
  items: ReactNode[];
  currentPage: number;
  unknownHeight?: boolean;
}> = ({ items, currentPage, unknownHeight }) => (
  <div
    className={classNames(
      'transition-all duration-300 ease-in-out flex flex-nowrap items-stretch ',
      { 'h-full': !unknownHeight }
    )}
    style={{
      transform: `translateX(calc(100% * ${-currentPage}))`,
    }}
  >
    {items.slice(0, currentPage + 1).map((item, i) => (
      <CarouselItem
        key={`carousel_${i}`}
        isActive={i === currentPage}
        unknownHeight={unknownHeight}
      >
        {item}
      </CarouselItem>
    ))}
  </div>
);

const CarouselItem: FC<
  PropsWithChildren<{ isActive: boolean; unknownHeight?: boolean }>
> = ({ isActive, unknownHeight, children }) => {
  const [isVisible, setIsVisible] = useState(isActive);
  useEffect(() => {
    if (isActive) {
      setIsVisible(true);
    } else {
      setTimeout(() => {
        setIsVisible(false);
      }, 300);
    }
  }, [isActive]);

  if (!isVisible) {
    return (
      <div
        className={classNames('w-full shrink-0 overflow-auto', {
          'h-screen': !unknownHeight,
        })}
      />
    );
  }

  return (
    <div
      className={classNames(
        'w-full shrink-0 overflow-y-auto relative flex flex-col overflow-x-hidden',
        { 'h-screen': !unknownHeight }
      )}
    >
      {children}
    </div>
  );
};

export default Carousel;
