/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { dif, format, intervalToDuration } from 'date-fns';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import Pill from '../Pill/Pill';

import convertToDollarAmount from '../../utils/convertToDollar';

import Button from '../Buttons/Button/Button';
import RulesImage from '../../assets/Icons/rules.svg';

export const useTimer = () => {
  const tick = useRef();
  const [count, setCount] = useState(0);

  useEffect(() => {
    tick.current = setInterval(() => {
      setCount((t) => t + 1);
    }, 1000);

    return () => clearInterval(tick.current); // <-- clear on unmount!
  }, []);

  return count;
};

const Accordion = ({
  matchId,
  participants = [],
  amount,
  game_name,
  game_mode_title,
  console_name,
  elementSetId,
  isOpen,
  gameModeId,
  handleGetGameMode,
  handleSetDispute,
  setSelectedDispute,
  closeVideoPlayer,
  categories,
  disputedAt,
  region,
}) => {
  const [isActive, setIsActive] = useState(isOpen);
  const timer = useTimer(new Date(disputedAt).getTime());
  const [formattedTime, setFormattedTime] = useState('');

  useEffect(() => {
    try {
      const duration = intervalToDuration({
        start: new Date(disputedAt).getTime(),
        end: new Date().getTime(),
      });
      const zeroPad = (num) => String(num).padStart(2, '0');

      setFormattedTime(
        [duration.days, duration.hours, duration.minutes, duration.seconds]
          .filter((t, i) => (i === 2 || i === 3 ? true : Boolean(t)))
          .map(zeroPad)
          .join(':')
      );
    } catch (e) {
      setFormattedTime(format(new Date(disputedAt), 'yyyy-MM-dd HH:mm'));
    }
  }, [timer]);

  const openAccordion = () => {
    if (isActive) {
      setSelectedDispute({});
      setIsActive(false);
      closeVideoPlayer();
    } else {
      handleSetDispute(matchId);
      setIsActive(true);
    }
  };

  const team1 = participants[0].filter(
    (participant) => participant.type === 'host'
  );

  const team2 = participants[1].filter(
    (participant) => participant.type === 'teamleader'
  );

  const getCategoryColor = (categrory) => {
    if (categrory === 'No Show') {
      return '#00AD1C';
    }
    if (categrory === 'Broken Rule') {
      return '#DE12FF';
    }
    if (categrory === 'Disconnect') {
      return '#FFD912';
    }
    if (categrory === 'Cheating') {
      return '#FF124F';
    }
    if (categrory === 'Mutual Cancel') {
      return '#1263FF';
    }
    return '#FFFFFF';
  };

  const over15 =
    new Date().getTime() - new Date(disputedAt).getTime() >= 1000 * 60 * 15;

  return (
    <div className={cn('px-4 py-4 hover:bg-grey-4', { 'bg-grey-4': isActive })}>
      <div onClick={(e) => openAccordion()} role="button">
        <div className="flex items-start justify-between">
          <p className="text-grey-2 uppercase text-xs">
            Match {matchId}
            <small className="block">Region: {region}</small>
          </p>
          <p
            className={cn('text-grey-2 font-semibold text-sm', {
              '!text-warning': over15,
            })}
          >
            {formattedTime}
          </p>
        </div>
        <div className="flex justify-between mt-2 mb-6">
          <div>
            {participants[0].length > 1 && participants[1].length > 1 ? (
              <>
                <h3 className="text-white font-bold">
                  Team {team1[0].username}
                </h3>
                <h3 className="text-white font-bold">
                  <span className="mr-1 text-grey-2">VS</span>
                  Team {team2[0].username}
                </h3>
              </>
            ) : (
              <>
                <h3 className="text-white font-bold">{team1[0].username}</h3>
                <h3 className="text-white font-bold">
                  <span className="mr-1 text-grey-2">VS</span>
                  {team2[0].username}
                </h3>
              </>
            )}
          </div>
          <div>
            <p className="uppercase font-bold text-2xl text-white">
              {convertToDollarAmount(amount)}
            </p>
            <p className="uppercase text-warning font-bold text-xs text-right">
              pot
            </p>
          </div>
        </div>
        <ScrollContainer className="flex items-center w-full">
          {categories?.length > 0 &&
            categories.map((category) => (
              <Pill
                key={category}
                className="flex items-center mr-3 border border-grey-3 bg-grey-4 flex-shrink-0"
              >
                <p
                  className="text-xs font-bold"
                  style={{ color: getCategoryColor(category || 'Other') }}
                >
                  {category || 'Other'}
                </p>
              </Pill>
            ))}

          <Pill className="flex items-center mr-3 border border-grey-3 bg-grey-4 flex-shrink-0">
            <p className="text-xs font-bold text-grey-2">{game_name}</p>
          </Pill>
          <Pill className="flex items-center mr-3 border border-grey-3 bg-grey-4 flex-shrink-0">
            <p className="text-xs font-bold text-grey-2">{game_mode_title}</p>
          </Pill>
          <Pill className="flex items-center mr-3 border border-grey-3 bg-grey-4 flex-shrink-0">
            <p className="text-xs font-bold text-grey-2">{console_name}</p>
          </Pill>
        </ScrollContainer>
      </div>
      {isActive && (
        <Button
          className="w-full my-3 h-14 bg-grey-3"
          text="Rules"
          variant="tertiary"
          image={RulesImage}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleGetGameMode(gameModeId, elementSetId, matchId);
          }}
        />
      )}
    </div>
  );
};

Accordion.propTypes = {
  matchId: PropTypes.number,
  participants: PropTypes.array,
  amount: PropTypes.number,
  game_mode_title: PropTypes.string,
  game_name: PropTypes.string,
  console_name: PropTypes.string,
  elementSetId: PropTypes.number,
  gameModeId: PropTypes.number,
  handleGetGameMode: PropTypes.func,
  handleSetDispute: PropTypes.func,
  setSelectedDispute: PropTypes.func,
  closeVideoPlayer: PropTypes.func,
  isOpen: PropTypes.bool,
  categories: PropTypes.array,
  disputedAt: PropTypes.string,
};

export default Accordion;
