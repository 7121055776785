import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/client';

const recentMatchesContext = createContext({
  wallet: [],
});

const useRecentMatches = () => {
  const [matches, setMatches] = useState([]);

  const getMatches = async (userId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/api/v1/matches?page=1&participant_user_id=${userId}&state=closed`
    );

    setMatches(data);
  };

  return {
    matches,
    getMatches,
  };
};

export function RecentMatchesProvider({ children }) {
  const matches = useRecentMatches();

  return (
    <recentMatchesContext.Provider value={matches}>
      {children}
    </recentMatchesContext.Provider>
  );
}

export default function RecentMatchesConsumer() {
  return React.useContext(recentMatchesContext);
}

RecentMatchesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
