import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import GoogleIcon from '../../../assets/Icons/google-light.svg';

const GoogleButton = ({ onClick, className }) => (
  <button className={cn('flex bg-white items-center rounded-full px-6', className)} onClick={onClick} type="button">
    <img src={GoogleIcon} alt="google icon" />
    <span className="font-bold pr-3"> Continue with Google</span>
  </button>
);

GoogleButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default GoogleButton;
