/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import ChatBubble from '../ChatBubble/ChatBubble';

export default class BubbleGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  renderGroup(messages) {
    const { senderName, handleOpenUserModal } = this.props;
    const sampleMessage = messages[0];

    return (
      <div className="mt-3">
        {(senderName || sampleMessage.senderName) !== '' &&
          sampleMessage.id !== 0 && (
            <p className="ml-8 text-grey-2">
              {senderName || sampleMessage.senderName}
            </p>
            // eslint-disable-next-line indent
          )}
        {messages.map((message, index) => {
          const prevMessage = messages[index - 1];
          const nextMessage = messages[index + 1];
          return (
            <ChatBubble
              key={index}
              message={message}
              prevMessage={prevMessage}
              nextMessage={nextMessage}
              handleOpenUserModal={handleOpenUserModal}
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { messages, id } = this.props;
    return this.renderGroup(messages, id);
  }
}
