import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/client';

const pendingDisputesContext = createContext({
  wallet: [],
});

const usePendingDisputes = () => {
  const [disputes, setDisputes] = useState([]);

  const getDisputes = async (userId) => {
    const { data } = await client.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/users/${userId}/disputes`
    );

    setDisputes(data);
  };

  return {
    disputes,
    getDisputes,
  };
};

export function SettledDisputesProvider({ children }) {
  const disputes = usePendingDisputes();

  return (
    <pendingDisputesContext.Provider value={disputes}>
      {children}
    </pendingDisputesContext.Provider>
  );
}

export default function AuditConsumer() {
  return React.useContext(pendingDisputesContext);
}

SettledDisputesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
