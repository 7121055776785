/* eslint-disable import/prefer-default-export */
import {
  addDays,
  differenceInWeeks,
  format,
  nextMonday,
  nextSunday,
} from 'date-fns';

const beginingOfYear = () => {
  const year = new Date().getFullYear();
  return new Date(`Jan 01 ${year} 00:00:00 GMT-0500 (Eastern Standard Time)`);
};

export const getDates = () => {
  const today = new Date();
  const monday = nextMonday(today);
  const sunday = nextSunday(monday);
  const f = 'MMMM dd, yyyy';
  const list = [];

  const start = beginingOfYear();

  for (let i = differenceInWeeks(start, monday); i <= 2; i += 1) {
    list.push(i);
  }
  return list.reduce(
    (prev, curr) => ({
      ...prev,
      [curr + 1]: `${format(addDays(monday, curr * 7), f)} - ${format(
        addDays(sunday, curr * 7),
        f
      )}`,
    }),
    {}
  );
};
