import React, { FC, useEffect, useState } from 'react';
import BaseModal from '../../components/BaseModal/BaseModal';
import Button from '../../components/Buttons/Button/Button';
import CurrencyInput from '../../components/CurrencyInput';
import HoldButton from '../../components/HoldButton.tsx';
import Loader from '../../components/Loader/Loader';
import client from '../../services/client';
import convertToDollarAmount from '../../utils/convertToDollar';

const MAX_AMOUNT = 2500000;

const WithdrawIncreaseModal: FC<{
  userId: number;
  isOpen: boolean;
  onClose: () => void;
  currAmount: number;
  onSuccess: () => void;
}> = ({ userId, isOpen, onClose, currAmount, onSuccess }) => {
  const [amount, setAmount] = useState(currAmount);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleWithdrawIncrease = async () => {
    try {
      setIsLoading(true);
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/wallets/withdrawal_limit`,
        {
          user_id: userId,
          amount,
        }
      );
      onSuccess();
      onClose();
      setAmount(0);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setAmount(currAmount);
      setIsLoading(false);
      setIsError(false);
    }
  }, [isOpen]);

  return (
    <BaseModal
      fullWidth={false}
      modalIsOpen={isOpen}
      large
      closeModal={onClose}
      header="Increase Withdraw Amount"
    >
      {isLoading && (
        <div className="h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col justify-between h-full px-4 sm:px-8">
          <div className="">
            <CurrencyInput
              name={amount}
              onValueChange={setAmount}
              value={amount}
            />
            {amount !== currAmount && (
              <div className="flex items-center justify-center mb-4">
                <p className="text-grey-2">
                  Current Limit is {convertToDollarAmount(currAmount)}
                </p>
              </div>
            )}
            {isError && (
              <p className="text-warning text-center pb-4">
                There was an issue updating the withdraw limit
              </p>
            )}
            {amount > MAX_AMOUNT && (
              <p className="text-warning text-center pb-4">
                The most you can set the limit to is{' '}
                {convertToDollarAmount(MAX_AMOUNT)}
              </p>
            )}
          </div>

          <div className="">
            <HoldButton
              variant="primary"
              text="Hold To Confirm Limit Change"
              timeout={250}
              onConfirm={handleWithdrawIncrease}
              disabled={amount === 0 || amount > MAX_AMOUNT}
            />
          </div>
        </div>
      )}
    </BaseModal>
  );
};

export default WithdrawIncreaseModal;
