import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Card = ({ children, bordered }) => (
  <div
    className={cn('p-6 rounded-2xl border border-grey-3 mt-3', {
      'border-dashed border-2': bordered,
    })}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  bordered: PropTypes.bool,
};

export default Card;
