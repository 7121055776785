import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Loader from '../../../components/Loader/Loader';
import TabGroup from '../../../components/TabGroup.tsx';
import client from '../../../services/client';
import { GameMode } from '../../../types';
import Selected from '../../../assets/Icons/selected.svg';

const ModeSelect: FC<{
  onSelect: (
    id: number,
    teamSize: number,
    payoutId: number,
    modeName: string
  ) => void;
  gameId: number;
  selectedMode?: number;
}> = ({ onSelect, gameId, selectedMode }) => {
  const [gameModes, setGameModes] = useState<GameMode[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('');

  const enabledModes = useMemo(() => {
    if (!gameModes.length) return [];
    return gameModes.filter((m) => m.game_id === gameId);
  }, [gameModes, gameId]);

  const categories = useMemo(() => {
    if (!enabledModes.length) return [];
    return enabledModes
      .reduce(
        (prev: string[], curr: GameMode) =>
          prev.includes(curr.label) || !curr.is_active
            ? prev
            : [...prev, curr.label],
        []
      )
      .sort();
  }, [enabledModes]);

  useEffect(() => {
    const getModes = async () => {
      try {
        setLoading(true);
        const { data } = await client.get(
          `${process.env.REACT_APP_API_URL}/api/v1/game_modes`
        );
        setGameModes(data);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getModes();
  }, []);

  useEffect(() => {
    setCategory(categories?.[0] || '');
  }, [categories]);

  const filteredModes = useMemo(
    () => enabledModes.filter((m) => m.label === category && m.is_active),
    [category, enabledModes]
  );

  return (
    <div className="overflow-y-auto no-scrollbar transition-all duration-300 ease-in-out translate-x-0 fade-in-fast">
      <h3 className="text-3xl font-bold text-white mb-4">Select Game Mode</h3>
      {error && (
        <p className="text-warning text-center">
          There was an issue fecthing the list of game modes
        </p>
      )}
      {loading && (
        <div className="py-12">
          <Loader />
        </div>
      )}
      <div className="mb-8 w-full overflow-auto">
        <TabGroup
          tabs={categories}
          onChange={setCategory}
          activeTab={category}
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-8 sm:mb-24">
        {filteredModes.map((mode) => (
          <button
            key={`console_${mode.id}`}
            type="button"
            onClick={() =>
              onSelect(
                mode.id,
                mode.num_players_per_team,
                mode.match_payouts[0].id,
                mode.title
              )
            }
            className={classNames(
              'relative rounded-2xl !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue transition-opacity ease-in-out hover:opacity-75 text-left h-full flex flex-col',
              { 'opacity-50': selectedMode && selectedMode !== mode.id }
            )}
          >
            {selectedMode === mode.id && (
              <img
                src={Selected}
                alt="selected"
                className="absolute top-2 right-2 h-12 w-12"
              />
            )}
            <img
              src={mode.hero_url}
              alt={mode.name}
              className="rounded-2xl mb-2 h-40 w-full object-cover object-center"
            />
            <div className="px-3">
              <p className="font-semibold text-white text-base mb-2">
                {mode.title}
              </p>
              <p className="text-xs leading-none mb-2 flex gap-2 text-grey-2">
                {mode.label}
              </p>
              {mode.game_maps.length > 0 && (
                <p className="text-xs leading-none flex gap-2 items-start text-grey-2">
                  {mode.game_maps.length > 6
                    ? `${mode.game_maps
                        .slice(0, 6)
                        .map((m) => m.name)
                        .join(', ')}, +${mode.game_maps.length - 3} more`
                    : mode.game_maps.map((m) => m.name).join(', ')}
                </p>
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ModeSelect;
