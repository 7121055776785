/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable indent */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router';
import Button from '../../components/Buttons/Button/Button';
import Modal from '../../components/BaseModal/BaseModal';

import useUsers from '../../contexts/UserContext/UserContext';
import client from '../../services/client';
import FeatureModal from './FeatureModal';
import ReferralsModal from './ReferralsModal';

const ONE_MINUTE = 60 * 1000;
const TEN_MINUTES = ONE_MINUTE * 10;
const THIRTY_MINUTES = ONE_MINUTE * 30;
const ONE_HOUR = ONE_MINUTE * 60;
const TWELVE_HOURS = ONE_HOUR * 12;
const ONE_DAY = TWELVE_HOURS * 2;
const FOREVER = 0;

const UserAccounts = ({ user }) => {
  const { banUser, unBanUser, freezeUser, unFreezeUser, getSingleUser } =
    useUsers();
  const [features, setFeatures] = useState(false);
  const [referrals, setReferrals] = useState(false);
  const [banModalIsOpen, setBanModalIsOpen] = useState(false);
  const [referModalIsOpen, setReferModalIsOpen] = useState(false);
  const [freezeModalIsOpen, setFreezeModalIsOpen] = useState(false);
  const [purgeModalIsOpen, setPurgeModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const [freezeReason, setFreezeReason] = useState('');
  const [banType, setBanType] = useState('Community');
  const [banReason, setBanReason] = useState('');
  const [purgeLength, setPurgeLength] = useState(TEN_MINUTES);
  const [purgeReason, setPurgeReason] = useState('');
  const [loadingPurge, setLoadingPurge] = useState(false);
  const [buttonFocus, setButtonFocus] = useState({ day1: true });
  const [referral, setReferral] = useState(null);
  const [code, setCode] = useState('');
  const [referralLoading, setReferralLoading] = useState(false);

  const getTommorrow = () => {
    const today = new Date();

    today.setDate(today.getDate() + 1);

    return today.toISOString();
  };

  const [expiration, setExpirattion] = useState(getTommorrow());

  useEffect(() => {
    if (banType === 'Idrequired' || banType === 'financial') {
      setButtonFocus({ forever: true });
      setExpirattion(null);
    }
  }, [banType]);
  const openBanModal = () => {
    setBanModalIsOpen(true);
  };
  const openReferModal = () => {
    setReferModalIsOpen(true);
  };

  const closeBanModal = () => {
    setBanModalIsOpen(false);
  };

  const closeReferModal = () => {
    setReferModalIsOpen(false);
  };

  const openFreezeModal = () => {
    setFreezeModalIsOpen(true);
  };

  const closeFreezeModal = () => {
    setFreezeModalIsOpen(false);
  };

  const closePurgeModal = () => {
    setPurgeModalIsOpen(false);
    setPurgeReason('');
    setPurgeLength(TEN_MINUTES);
  };

  const openPurgeModal = () => {
    setPurgeModalIsOpen(true);
  };

  const getReferral = (id) => {
    setReferralLoading(true);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/referrals/attached?user_id=${id}`
      )
      .then(({ data }) => setReferral(data))
      .catch(() => {})
      .finally(() => setReferralLoading(false));
  };

  useEffect(() => {
    if (user?.user?.id) {
      getReferral(user?.user?.id);
    }
  }, [user?.user?.id]);

  const handlePurge = async () => {
    try {
      const res = window.confirm('Are you sure!?');
      if (res) {
        setLoadingPurge(true);
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/messages/purge`,
          {
            user_id: user.user.id,
            minute_offset: purgeLength / 1000 / 60,
            reason: purgeReason,
          }
        );
        await client.post(`${process.env.REACT_APP_API_URL}/admin/v1/notes`, {
          user_id: user.user.id,
          note: `Messages Purged: ${purgeReason}`,
        });
        closePurgeModal();
      }
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoadingPurge(false);
    }
  };

  const handleExpirations = (date, button) => {
    if (button === 'forever') {
      setButtonFocus({ [button]: true });
      setExpirattion(null);
    } else {
      const today = new Date();
      const tomorrow = new Date(today);
      if (date === '1h') {
        tomorrow.setHours(tomorrow.getHours() + 1);
      } else {
        tomorrow.setDate(tomorrow.getDate() + date);
      }
      setButtonFocus({ [button]: true });
      setExpirattion(tomorrow.toISOString());
    }
  };

  const handleBanUser = () => {
    banUser(user.user.id, expiration, banType, banReason);
    setBanModalIsOpen(false);
  };

  const handleReferUser = () => {
    client
      .post(`${process.env.REACT_APP_API_URL}//admin/v1/referrals/add`, {
        user_id: user.user.id,
        code,
      })
      .then(() => getReferral(user.user.id))
      .catch(() => {});

    setReferModalIsOpen(false);
  };

  const handleFreezeUser = () => {
    freezeUser(user.user.id, freezeReason);
    setFreezeModalIsOpen(false);
  };

  return (
    <div className="mt-6">
      <div className="grid grid-cols-2 mt-3 lg:mt-0 gap-4">
        <Button
          variant="grey"
          className="!text-gold w-full"
          text="View All Referrals"
          onClick={() => setReferrals(true)}
        />
        {referralLoading ? null : !referral ? (
          <Button
            variant="primary"
            className="!bg-gold w-full"
            text="Add Referral"
            onClick={openReferModal}
          />
        ) : (
          <Button
            variant="grey"
            className="!text-gold w-full"
            text={`Referred By ${referral.user_profile.username}`}
            onClick={() => {
              window.location.href = `/godmode/users/${referral.user_profile.id}`;
            }}
          />
        )}
        {user.user.status === 'enabled' || user.user.status === 'freezed' ? (
          <>
            <Button
              variant="tertiary"
              text="Purge Messages"
              className="w-full"
              onClick={openPurgeModal}
            />
            <Button
              variant="primary"
              text="Disable Features"
              className="w-full"
              onClick={() => setFeatures(true)}
            />
            <Button
              variant="info"
              text={user.user.status === 'freezed' ? 'Defrost' : 'Freeze'}
              className="w-full"
              onClick={
                user.user.status === 'freezed'
                  ? () => unFreezeUser(user.user.id)
                  : openFreezeModal
              }
            />
            <Button
              variant="warning"
              text="Ban"
              className="w-full"
              onClick={openBanModal}
            />
          </>
        ) : (
          <Button
            variant="tertiary-danger"
            text="Unban"
            className="w-full"
            onClick={() => unBanUser(user.user.id)}
          />
        )}
      </div>

      <FeatureModal
        isOpen={features}
        onClose={() => setFeatures(false)}
        user={user}
        onSave={() => getSingleUser(user.user.id)}
      />

      {/* FREEZE MODAL */}
      <Modal
        modalIsOpen={freezeModalIsOpen}
        closeModal={closeFreezeModal}
        header={`Freeze ${user.user.username}`}
      >
        <div className="relative mx-3">
          <p className="mt-3 text-grey-2">
            Confirm that you’d like to freeze this user. Freezing will stop a
            user from: withdrawing, depositing, playing in buy-in tournaments &
            playing in wagers
          </p>
          <textarea
            className="bg-grey-3 text-grey-2 rounded-md h-44 w-full pl-3 pt-3 mt-6 resize-none"
            placeholder="Reason (required)"
            onChange={(e) => setFreezeReason(e.target.value)}
          />

          <div className="absolute modal-button w-full">
            <Button
              className={cn('w-full mt-16', {
                'opacity-50 hover:cursor-default': freezeReason.length === 0,
              })}
              variant="warning"
              text={`Freeze ${user.user.username}`}
              onClick={handleFreezeUser}
              disabled={freezeReason.length === 0}
            />
          </div>
        </div>
      </Modal>

      {/* BAN MODAL */}
      <Modal
        modalIsOpen={banModalIsOpen}
        closeModal={closeBanModal}
        header={`Ban ${user.user.username}`}
      >
        <div className="relative">
          <div className="my-6 border border-zinc-700 flex justify-between w-full rounded-full overflow-hidden">
            <button
              disabled={banType === 'Idrequired' || banType === 'financial'}
              onClick={() => handleExpirations(1, 'day1')}
              types="button"
              className={cn(
                'w-32 text-grey-2 bg-grey-3 disabled:opacity-50 py-2 px-3'
              )}
              style={
                buttonFocus.day1
                  ? {
                      backgroundColor: 'white',

                      color: 'black',
                    }
                  : {}
              }
            >
              24H
            </button>
            <button
              disabled={banType === 'Idrequired' || banType === 'financial'}
              onClick={() => handleExpirations(2, 'day2')}
              types="button"
              className={cn(
                'w-32 text-grey-2 bg-grey-3 disabled:opacity-50 py-2 px-3'
              )}
              style={
                buttonFocus.day2
                  ? {
                      backgroundColor: 'white',

                      color: 'black',
                    }
                  : {}
              }
            >
              48H
            </button>
            <button
              disabled={banType === 'Idrequired' || banType === 'financial'}
              onClick={() => handleExpirations(3, 'day3')}
              types="button"
              className={cn(
                'w-32 text-grey-2 bg-grey-3 disabled:opacity-50 py-2 px-3'
              )}
              style={
                buttonFocus.day3
                  ? {
                      backgroundColor: 'white',

                      color: 'black',
                    }
                  : {}
              }
            >
              72H
            </button>
            <button
              disabled={banType === 'Idrequired' || banType === 'financial'}
              onClick={() => handleExpirations(7, 'week')}
              types="button"
              className={cn(
                'w-32 text-grey-2 bg-grey-3 disabled:opacity-50 py-2 px-3'
              )}
              style={
                buttonFocus.week
                  ? {
                      backgroundColor: 'white',

                      color: 'black',
                    }
                  : {}
              }
            >
              1W
            </button>
            <button
              disabled={banType === 'Idrequired' || banType === 'financial'}
              onClick={() => handleExpirations(30, 'month')}
              types="button"
              className={cn(
                'w-32 text-grey-2 bg-grey-3 disabled:opacity-50 py-2 px-3'
              )}
              style={
                buttonFocus.month
                  ? {
                      backgroundColor: 'white',

                      color: 'black',
                    }
                  : {}
              }
            >
              1M
            </button>
            <button
              onClick={() => handleExpirations(10000, 'forever')}
              types="button"
              className={cn(
                'w-32 text-grey-2 bg-grey-3 disabled:opacity-50 py-2 px-3'
              )}
              style={
                buttonFocus.forever
                  ? {
                      backgroundColor: 'white',

                      color: 'black',
                    }
                  : {}
              }
            >
              Forever
            </button>
          </div>
          <select
            className="w-full rounded-full bg-grey-3 text-white py-2 px-3 mb-4"
            onChange={(e) => setBanType(e.target.value)}
          >
            <option value="community">Community</option>
            <option value="dispute">Dispute</option>
            <option value="cheating">Cheating</option>
            <option value="duplicate">Duplicate</option>
            <option value="deleted">Deleted</option>
            <option value="financial">Financial</option>
            <option value="Idrequired">ID Required</option>
          </select>
          <textarea
            className="bg-grey-3 text-grey-2 rounded-xl h-44 w-full pl-3 pt-3 mb-4 resize-none"
            placeholder="Reason (required)"
            value={banReason}
            onChange={(e) => setBanReason(e.target.value)}
          />
          <div className="w-full absolute modal-button">
            <Button
              className="w-full mt-12"
              disabled={!banReason.trim().length}
              variant="warning"
              text={`Ban ${user.user.username}`}
              onClick={handleBanUser}
            />
          </div>
        </div>
      </Modal>

      <Modal
        modalIsOpen={referModalIsOpen}
        closeModal={closeReferModal}
        height={160}
        header={`Add Referral To ${user.user.username}`}
      >
        <div className="relative pt-4">
          <input
            type="text"
            className="py-3 mb-8 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
            name="code"
            onChange={(e) => setCode(e.target.value)}
            value={code}
            placeholder="Referral Code"
          />
          <Button
            className="w-full"
            disabled={!code.trim().length}
            variant="primary"
            text="Add Referral"
            large
            rounded
            onClick={handleReferUser}
          />
        </div>
      </Modal>

      {user?.user?.id && (
        <ReferralsModal
          isOpen={referrals}
          onClose={() => setReferrals(false)}
          userId={user.user.id}
        />
      )}

      {/* Purge MODAL */}
      <Modal
        modalIsOpen={purgeModalIsOpen}
        closeModal={closePurgeModal}
        large
        header={`Purge ${user.user.username}'s Public Messages`}
      >
        <div className="relative sm:px-8 px-4 pt-4">
          <div className="flex mb-4 items-center rounded-full overflow-hidden border border-grey-4">
            {[
              {
                label: '10M',
                length: TEN_MINUTES,
              },
              {
                label: '30M',
                length: THIRTY_MINUTES,
              },
              {
                label: '1H',
                length: ONE_HOUR,
              },
              {
                label: '12H',
                length: TWELVE_HOURS,
              },
              {
                label: '24H',
                length: ONE_DAY,
              },
            ].map((l) => (
              <button
                key={`purge_${l.label}`}
                onClick={() => setPurgeLength(l.length)}
                className={cn(
                  'py-2 flex-1 rounded-full text-grey-2 transition-colors',
                  {
                    '!text-black !bg-white': purgeLength === l.length,
                  }
                )}
              >
                {l.label}
              </button>
            ))}
          </div>
          <textarea
            className="bg-grey-3 text-grey-2 rounded-xl h-44 w-full pl-3 pt-3 mb-4 resize-none"
            placeholder="Reason (required)"
            value={purgeReason}
            onChange={(e) => setPurgeReason(e.target.value)}
          />
          <Button
            text={`Purge ${user.user.username}'s Messages`}
            variant="primary"
            rounded
            large
            className="!w-full"
            onClick={handlePurge}
            isLoading={loadingPurge}
            disabled={loadingPurge || !purgeReason.trim().length}
          />
        </div>
      </Modal>
    </div>
  );
};

UserAccounts.propTypes = {
  user: PropTypes.shape({
    is_silenced: PropTypes.bool,
    user: PropTypes.shape({
      gamer_ids: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
        })
      ),
      full_name: PropTypes.string,
      username: PropTypes.string,
      twitch_username: PropTypes.string,
      status: PropTypes.string,
      id: PropTypes.number,
    }),
    wallet: PropTypes.shape({
      can_withdraw: PropTypes.bool,
      payment_sources: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            email: PropTypes.string,
          }),
        })
      ),
    }),
    identity: PropTypes.shape({
      providers: PropTypes.arrayOf(PropTypes.string),
      full_name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
};

export default UserAccounts;
