/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import axios from 'axios';
import useAuth from '../../contexts/AuthContext/AuthContext';

import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Buttons/Button/Button';
import { HEADER, WELCOME } from './templates';
import client from '../../services/client';
import SOCIAL_PROOF from './social-proof';
import BOOST from './boost';
import SCHEDULE from './schedule';
import SELLING_OUT from './sellingout';

const EmailBuilder = () => {
  const { god } = useAuth();
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { html } = data;
      console.log({ html });
      const url = window.URL.createObjectURL(new Blob([html]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.html');

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };
  const exportJson = () => {
    emailEditorRef.current.editor.saveDesign((data) => {
      console.log(JSON.stringify(data));
    });
  };

  const loadTemplate = (template) => {
    emailEditorRef.current.editor.loadDesign(template);
  };

  const onReady = async () => {
    emailEditorRef.current.editor.registerCallback('image', (file, done) => {
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/media/upload_batch?quantity=1`
        )
        .then(({ data }) => {
          axios
            .put(data[0], file.attachments[0], {
              headers: { 'Content-Type': file.attachments[0].type },
            })
            .then(() => {
              const [, path] = data[0].split('/uploads/');
              const [fileName] = path.split('?');

              done({
                progress: 100,
                url: `https://mobile-assets.1v1me.com/uploads/${fileName}`,
              });
            });
        });
    });
  };

  if (Object.keys(god).length === 0) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <Layout className="" showHeaderElements userId={god.id}>
      <div className="mx-auto max-w-[100rem] px-4 overflow-hidden">
        <h2 className="text-white font-bold text-2xl mb-3">Email Builder</h2>
        <div className="flex items-center gap-4 mb-8">
          <Button variant="primary" text="Export HTML" onClick={exportHtml} />
          <Button variant="primary" text="Export JSON" onClick={exportJson} />
          <Button
            variant="grey"
            text="Template: Frame"
            onClick={() => loadTemplate(HEADER)}
          />
          <Button
            variant="grey"
            text="Template: Welcome"
            onClick={() => loadTemplate(WELCOME)}
          />
          <Button
            variant="grey"
            text="Template: Proof"
            onClick={() => loadTemplate(SOCIAL_PROOF)}
          />
          <Button
            variant="grey"
            text="Template: BOOST"
            onClick={() => loadTemplate(BOOST)}
          />
          <Button
            variant="grey"
            text="Template: Schedule"
            onClick={() => loadTemplate(SCHEDULE)}
          />
          <Button
            variant="grey"
            text="Template: Schedule"
            onClick={() => loadTemplate(SELLING_OUT)}
          />
        </div>
        <div className="min-h-[80vh]">
          <EmailEditor
            minHeight="80vh"
            ref={emailEditorRef}
            appearance={{ theme: 'dark' }}
            onReady={onReady}
          />
        </div>
      </div>
    </Layout>
  );
};

export default EmailBuilder;
