import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../components/Buttons/Button/Button';
import Jet from '../../assets/Images/jet.svg';
import BaseModal from '../../components/BaseModal/BaseModal';
import Notes from '../Notes';

const AssignDispute = ({
  disputedSortedGroups,
  selectedDispute,
  handleMatchDispute,
  cancelMatch,
  replayMatch,
}) => {
  const [notes, setNotes] = useState(null);
  return (
    Object.keys(disputedSortedGroups).length > 0 && (
      <>
        <div className="">
          <div>
            <img
              src={disputedSortedGroups[0][0].profile_image_url}
              alt={selectedDispute.id}
              className="w-12 rounded-full m-auto"
            />
            <p className="text-white font-bold text-center text-lg mt-3">
              Team {disputedSortedGroups[0][0].username}
            </p>
          </div>
          <div className="pt-3">
            {disputedSortedGroups[0].map(
              ({ id, profile_image_url, username, user_id }) => (
                <div
                  className="flex  justify-start items-center gap-4"
                  key={id}
                >
                  <Link
                    to={`/godmode/users/${user_id}`}
                    className="flex items-center flex-1 overflow-hidden"
                    target="_blank"
                  >
                    <img
                      src={profile_image_url}
                      alt={selectedDispute.id}
                      className="w-10 rounded-full"
                    />
                    <p className="text-white ml-8 font-bold text-md truncate">
                      {username}
                    </p>
                  </Link>
                  <Button
                    variant="grey"
                    onClick={() => setNotes(user_id)}
                    className=" whitespace-nowrap"
                    type="button"
                    text="Notes"
                  />
                </div>
              )
            )}
          </div>
          <Button
            className="w-full mt-6"
            variant="primary"
            text={
              disputedSortedGroups[0].length === 1
                ? `Assign ${disputedSortedGroups[0][0].username} Win`
                : `Assign Team ${disputedSortedGroups[0][0].username} Win`
            }
            onClick={() =>
              handleMatchDispute(
                selectedDispute.id,
                disputedSortedGroups[0][0].team_uuid,
                disputedSortedGroups[1][0].team_uuid
              )
            }
          />
        </div>
        <div className="mt-6">
          <div>
            <img
              src={disputedSortedGroups[1][0].profile_image_url}
              alt={selectedDispute.id}
              className="w-12 rounded-full m-auto"
            />
            <p className="text-white font-bold text-center text-lg mt-3">
              Team {disputedSortedGroups[1][0].username}
            </p>
          </div>
          <div className="">
            {disputedSortedGroups[1].map(
              ({ id, profile_image_url, username, user_id }) => (
                <div
                  className="flex  justify-start items-center gap-4"
                  key={id}
                >
                  <Link
                    to={`/godmode/users/${user_id}`}
                    className="flex items-center flex-1 overflow-hidden"
                    target="_blank"
                  >
                    <img
                      src={profile_image_url}
                      alt={selectedDispute.id}
                      className="w-10 rounded-full"
                    />
                    <p className="text-white ml-8 font-bold text-md truncate">
                      {username}
                    </p>
                  </Link>
                  <Button
                    variant="grey"
                    onClick={() => setNotes(user_id)}
                    className=" whitespace-nowrap"
                    type="button"
                    text="Notes"
                  />
                </div>
              )
            )}
          </div>

          <Button
            className="w-full mt-6"
            variant="primary"
            text={
              disputedSortedGroups[1].length === 1
                ? `Assign ${disputedSortedGroups[1][0].username} Win`
                : `Assign Team ${disputedSortedGroups[1][0].username} Win`
            }
            onClick={() =>
              handleMatchDispute(
                selectedDispute.id,
                disputedSortedGroups[1][0].team_uuid,
                disputedSortedGroups[0][0].team_uuid
              )
            }
          />
        </div>
        <div className="mt-6">
          <div className="flex items-center">
            <h3 className="text-white font-bold text-center text-lg mt-3">
              Danger Zone
            </h3>
            <img src={Jet} alt="danger-zone" className="w-10 mt-3 ml-3" />
          </div>
          <Button
            className="mt-6 w-full !text-warning"
            variant="tertiary"
            text="Force Replay Match"
            onClick={() => replayMatch(selectedDispute.id)}
          />
          <Button
            className="w-full mt-4"
            variant="warning"
            text="Cancel Match"
            onClick={() => cancelMatch(selectedDispute.id)}
          />
        </div>
        <BaseModal
          large
          modalIsOpen={!!notes}
          className="!pb-0"
          closeModal={() => setNotes(false)}
        >
          <div className="flex flex-col h-full">
            {notes && <Notes userIdOverride={notes} />}
          </div>
        </BaseModal>
      </>
    )
  );
};

AssignDispute.propTypes = {
  disputedSortedGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        profile_image_url: PropTypes.string,
        team_uuid: PropTypes.string,
        username: PropTypes.string,
      })
    )
  ),
  selectedDispute: PropTypes.shape({
    id: PropTypes.number,
  }),
  handleMatchDispute: PropTypes.func,
  cancelMatch: PropTypes.func,
};

export default AssignDispute;
