/* eslint-disable indent */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import AppleIcon from '../../assets/Icons/Apple-Icon.svg';
import GoogleIcon from '../../assets/Icons/google.svg';
import TwitchIcon from '../../assets/Icons/Twitch-Icon.svg';
import ConnectIcon from '../../assets/Icons/connect.svg';
import ActivionIcon from '../../assets/Icons/Activision-Icon.svg';
import EAIcon from '../../assets/Icons/EA-Icon.svg';
import PaypalIcon from '../../assets/Icons/Paypal-Icon.svg';
import ShareIcon from '../../assets/Icons/share-icon.svg';
import VenmoIcon from '../../assets/Icons/venmo-icon-black.svg';
import Unlink from '../../assets/Icons/unlink-icon.svg';
import ConnectedPSN from '../../assets/Icons/connected-playstation-icon.svg';
import ConnectedXBOXLive from '../../assets/Icons/connected-xbox-icon.svg';

import Card from '../../components/Card/Card';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Buttons/Button/Button';

import useUsers from '../../contexts/UserContext/UserContext';
import CreditCard from './CreditCard';

const UserAccounts = ({ user, onTwitch, onGamerId }) => {
  const {
    disconnectUserAccount,
    disconnectUserTwitch,
    disconnectUserPayPal,
    disconnectUserVenmo,
    disconnectUserCredit,
  } = useUsers();

  const [openDisconnectModal, setDisconnectModal] = useState(false);
  const [disconnectType, setDisconnectType] = useState('');
  const [disconnectId, setDisconnectId] = useState(null);
  const [disconnectReason, setDisconnectReason] = useState('');

  const handleOpenDisconnectModal = (type) => {
    setDisconnectType(type);
    setDisconnectModal(true);
  };

  const getAccountType = (idSystem) =>
    user.user.gamer_ids.filter((id) => id.id_system_type === idSystem);

  const handleDisconnectAccount = () => {
    if (
      disconnectType === 'Activision' ||
      disconnectType === 'EA' ||
      disconnectType === 'PSN' ||
      disconnectType === 'EA' ||
      disconnectType === 'Fighter' ||
      disconnectType === 'TheShow' ||
      disconnectType === 'Epic' ||
      disconnectType === 'TwoK' ||
      disconnectType === 'XBOX Live'
    ) {
      const idSystem = getAccountType(
        `IdSystem::${disconnectType === 'XBOX Live' ? 'XBL' : disconnectType}`
      );

      disconnectUserAccount(
        user.user.id,
        idSystem[0].id_system_id,
        disconnectReason
      );
    } else if (disconnectType === 'Twitch') {
      disconnectUserTwitch(user.user.id, disconnectReason);
    } else if (disconnectType === 'PayPal') {
      disconnectUserPayPal(user.user.id, disconnectReason);
    } else if (disconnectType === 'Venmo') {
      disconnectUserVenmo(user.user.id, disconnectReason);
    } else if (disconnectType === 'Card') {
      disconnectUserCredit(user.user.id, disconnectId, disconnectReason);
    }
    setDisconnectId(null);
    setDisconnectModal(false);
  };

  const getPayPal = user.wallet.payment_sources.find(
    (s) => s.type === 'PaymentSource::PayPal'
  );
  const venmo = user.wallet.payment_sources.find(
    (s) => s.type === 'PaymentSource::Venmo'
  );
  const cards = user.wallet.payment_sources.filter(
    (s) =>
      s.type === 'PaymentSource::DotsCreditCard' ||
      s.type === 'PaymentSource::PayPalCreditCard'
  );

  return (
    <div className="mt-6">
      {user.identity.providers && (
        <Card>
          <div className="flex justify-between">
            <div className="flex flex-wrap lg:flex-nowrap items-center">
              <img
                src={
                  user.identity.providers[0] === 'google'
                    ? GoogleIcon
                    : AppleIcon
                }
                alt="profile"
                className={cn({
                  'bg-white rounded-full w-12 p-3':
                    user.identity.providers[0] === 'google',
                })}
              />
              <div className="ml-3">
                {user.identity.full_name ? (
                  <h2 className="text-white  text-zinc-500 uppercase">
                    {user.identity.full_name}
                  </h2>
                ) : (
                  <h2 className=" italic text-zinc-500">No Name Available</h2>
                )}

                <h2 className="text-white  lowercase">{user.identity.email}</h2>
              </div>
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::Activision').length > 0 ? (
        <Card>
          <div className="flex justify-between">
            <div className="flex">
              <img
                src={ActivionIcon}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">
                  Activision ID
                </h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::Activision')[0]?.name}
                </h2>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div
                onClick={() => handleOpenDisconnectModal('Activision')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="mr-6 hover:cursor-pointer"
                />
              </div>
              <a
                target="_blank"
                className="text-white"
                href={`https://cod.tracker.gg/warzone/profile/atvi/${encodeURIComponent(
                  getAccountType('IdSystem::Activision')[0]?.name
                )}/overview`}
                rel="noreferrer"
              >
                <img src={ShareIcon} alt="share" />
              </a>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex justify-between">
              <div className="flex">
                <img
                  src={ActivionIcon}
                  alt="profile"
                  className="rounded-full w-12"
                />
                <div className="ml-3">
                  <h2 className="text-white text-zinc-500 uppercase">
                    Activision ID
                  </h2>
                  <h2 className="text-white">Not Connected</h2>
                </div>
              </div>
            </div>
            <div
              onClick={() => onGamerId('IdSystem::Activision')}
              role="button"
            >
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {user.user.twitch_username ? (
        <Card>
          <div className="flex justify-between">
            <div className="flex">
              <img
                src={TwitchIcon}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white text-zinc-500 uppercase">
                  Twitch ({user.user.twitch_email})
                </h2>
                <h2 className="text-white">{user.user.twitch_username}</h2>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div
                onClick={() => handleOpenDisconnectModal('Twitch')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="mr-6 hover:cursor-pointer"
                />
              </div>
              <a
                target="_blank"
                href={`https://www.twitch.tv/${user.user.twitch_username}`}
                rel="noreferrer"
              >
                <img src={ShareIcon} alt="share" />
              </a>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src={TwitchIcon}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">Twitch</h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>
            <div onClick={onTwitch} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::EA').length > 0 ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img src={EAIcon} alt="profile" className="rounded-full w-12" />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">EA ID</h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::EA')[0]?.name}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('EA')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex">
              <img src={EAIcon} alt="profile" className="rounded-full w-12" />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">EA ID</h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>
            <div onClick={() => onGamerId('IdSystem::EA')} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {cards.length > 0 && (
        <>
          {cards.map((c) => (
            <div key={`card_${c.id}`} className="mt-4">
              <CreditCard
                type={c.type}
                last4={c.data.last_four}
                expMonth={c.data.exp_month}
                expYear={c.data.exp_year}
                cardName={c.data.brand}
                isSelected
                image={c.data.brand_image_url}
                holder={c.data.holder_name}
                disabled
                onUnlink={() => {
                  handleOpenDisconnectModal('Card');
                  setDisconnectId(c.id);
                }}
              />
            </div>
          ))}
        </>
      )}
      {getPayPal ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src={PaypalIcon}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white text-zinc-500 uppercase">
                  PayPal{' '}
                  {getPayPal.data.verified ? '(VERIFIED)' : '(UNVERIFIED)'}{' '}
                </h2>
                <h2 className="text-white lowercase">{getPayPal.data.email}</h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('PayPal')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex">
            <img src={PaypalIcon} alt="profile" className="rounded-full w-12" />
            <div className="ml-3">
              <h2 className="text-white text-zinc-500 uppercase">PayPal </h2>
              <h2 className="text-white ">Not Connected</h2>
            </div>
          </div>
        </Card>
      )}
      {venmo ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src={VenmoIcon}
                alt="profile"
                className="rounded-full w-12 bg-white"
              />
              <div className="ml-3">
                <h2 className="text-white text-zinc-500 uppercase">Venmo</h2>
                <h2 className="text-white lowercase">
                  {venmo.data.phone_number || venmo.data.email}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('Venmo')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex">
            <img src={VenmoIcon} alt="profile" className="rounded-full w-12" />
            <div className="ml-3">
              <h2 className="text-white text-zinc-500 uppercase">Venmo</h2>
              <h2 className="text-white ">Not Connected</h2>
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::PSN').length > 0 ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src={ConnectedPSN}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">PSN</h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::PSN')[0]?.name}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('PSN')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex">
              <img
                src={ConnectedPSN}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">PSN</h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>{' '}
            <div onClick={() => onGamerId('IdSystem::PSN')} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::XBL').length > 0 ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src={ConnectedXBOXLive}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">
                  XBOX Live
                </h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::XBL')[0]?.name}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('XBOX Live')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex">
              <img
                src={ConnectedXBOXLive}
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">
                  XBOX Live
                </h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>{' '}
            <div onClick={() => onGamerId('IdSystem::XBL')} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::TheShow').length > 0 ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/the_show_id/icon.png"
                alt="profile"
                className="rounded-full w-12 invert bg-black object-contain"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">MLB ID</h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::TheShow')[0]?.name}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('TheShow')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/the_show_id/icon.png"
                alt="profile"
                className="rounded-full w-12 invert bg-black object-contain"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">MLB ID</h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>{' '}
            <div onClick={() => onGamerId('IdSystem::TheShow')} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::Fighter').length > 0 ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/fighter_id/icon.png"
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">
                  Fighter ID
                </h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::Fighter')[0]?.name}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('Fighter')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/fighter_id/icon.png"
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">
                  Fighter ID
                </h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>{' '}
            <div onClick={() => onGamerId('IdSystem::Fighter')} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::Epic').length > 0 ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/epic_id/icon.png"
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">Epic ID</h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::Epic')[0]?.name}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('Epic')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/epic_id/icon.png"
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">Epic ID</h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>{' '}
            <div onClick={() => onGamerId('IdSystem::Epic')} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}
      {getAccountType('IdSystem::TwoK').length > 0 ? (
        <Card>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/2k_id/icon.png"
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">2K ID</h2>
                <h2 className="text-white">
                  {getAccountType('IdSystem::TwoK')[0]?.name}
                </h2>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleOpenDisconnectModal('TwoK')}
                role="button"
              >
                <img
                  src={Unlink}
                  alt="unlink"
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <div className="flex">
              <img
                src="https://mobile-assets.1v1me.com/id_systems/v1/2k_id/icon.png"
                alt="profile"
                className="rounded-full w-12"
              />
              <div className="ml-3">
                <h2 className="text-white  text-zinc-500 uppercase">2K ID</h2>
                <h2 className="text-white">Not Connected</h2>
              </div>
            </div>{' '}
            <div onClick={() => onGamerId('IdSystem::TwoK')} role="button">
              <img
                src={ConnectIcon}
                alt="link"
                className="w-[24px] hover:cursor-pointer"
              />
            </div>
          </div>
        </Card>
      )}

      <Modal
        height="250px"
        modalIsOpen={openDisconnectModal}
        closeModal={() => setDisconnectModal(false)}
        header={`Disconnect ${disconnectType}`}
      >
        <div className="mx-3">
          <textarea
            className="bg-grey-3 text-grey-2 rounded-md h-44 w-full pl-3 pt-3 resize-none mb-4"
            placeholder="Reason (required)"
            onChange={(e) => setDisconnectReason(e.target.value)}
          />
          <div className="w-full">
            <Button
              className="w-full"
              variant="primary"
              text={`Disconnect ${disconnectType}`}
              onClick={handleDisconnectAccount}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

UserAccounts.propTypes = {
  onGamerId: PropTypes.func,
  onTwitch: PropTypes.func,
  user: PropTypes.shape({
    user: PropTypes.shape({
      gamer_ids: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
        })
      ),
      full_name: PropTypes.string,
      username: PropTypes.string,
      twitch_username: PropTypes.string,
      twitch_email: PropTypes.string,
      status: PropTypes.string,
      id: PropTypes.number,
      is_verified: PropTypes.bool,
    }),
    wallet: PropTypes.shape({
      can_withdraw: PropTypes.bool,
      payment_sources: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            email: PropTypes.string,
          }),
        })
      ),
    }),
    identity: PropTypes.shape({
      providers: PropTypes.arrayOf(PropTypes.string),
      full_name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
};

export default UserAccounts;
