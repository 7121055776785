/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import client from '../services/client';
import BaseModal from './BaseModal/BaseModal';
import HoldButton from './HoldButton';

const SwapPlayerModal = ({
  isOpen,
  onClose,
  onSuccess,
  toPromoteId,
  bench,
  lineup,
  disablePromote,
  teamId,
}) => {
  const [toPromote, setToPromote] = useState(null);
  const [toBench, setToBench] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [dropPlayer, dropPlayerState] = useDropPartnerTeammateMutation();
  // const [pickupPlayer, pickupPlayerState] = usePickupPartnerTeammateMutation();
  // const [swapRoster, swapRosterState] = useSwapLeagueLineupMutation();
  // const [swapEvent, swapEventState] = useSwapEventLineupMutation();

  useEffect(() => {
    if (lineup.length) {
      setToBench(lineup[0].id);
    }
  }, [bench, lineup]);

  useEffect(() => {
    if (!toPromoteId) {
      setTimeout(() => {
        setToPromote(null);
        setToBench(null);
      }, 500);
    } else {
      setToPromote(toPromoteId);
    }
  }, [toPromoteId]);

  const handleConfirm = async () => {
    try {
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/event_promote`,
        {
          event_team_id: teamId,
          bench_user_id: toPromoteId,
          lineup_user_id: toBench,
        }
      );
      onSuccess?.();
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  const title = useMemo(() => 'Change Lineup', []);

  const subtext = useMemo(
    () =>
      'Confirm who you’d like to bench who you’d like to promote to your lineup for this match',
    []
  );

  return (
    <BaseModal
      closeModal={onClose}
      modalIsOpen={isOpen}
      large
      header={title}
      className="z-50"
    >
      <div className="px-4 sm:px-8 pb-8 text-grey-2">
        <p className="mb-8">{subtext}</p>
        <div className="grid sm:grid-cols-3 gap-1 sm:gap-4 items-center mb-4">
          <span className="font-bold text-white">Send To Bench</span>
          <div className="col-span-2">
            <Select
              value={toBench}
              onChange={setToBench}
              options={lineup
                .filter((l) => l.id !== toPromoteId)
                .map((l) => ({
                  value: l.id,
                  image: l.profile_image_url,
                  label: l.username,
                }))}
            />
          </div>
        </div>
        <div className="grid sm:grid-cols-3 gap-1 sm:gap-4 items-center mb-8">
          <span className="font-bold text-white">Promote To Lineup</span>
          <div className="col-span-2">
            <Select
              value={toPromote}
              onChange={setToPromote}
              options={bench.map((l) => ({
                value: l.id,
                image: l.profile_image_url,
                label: l.username,
              }))}
              disabled={disablePromote}
            />
          </div>
        </div>
        <div className="items-center justify-end grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
          <div>
            {error && (
              <p className="mb-2 sm:text-sm text-xs text-warning text-center sm:text-left">
                There was an issue promoting up this teammate
              </p>
            )}
          </div>
          <div>
            <HoldButton
              text="Click & Hold To Confirm Change"
              timeout={200}
              variant="primary"
              onConfirm={handleConfirm}
              disabled={!toBench || loading}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

const Select = ({ value, options, onChange, disabled }) => {
  const selected = useMemo(
    () => options.find((b) => b.value === value),
    [options, value]
  );

  return (
    <Listbox disabled={disabled} value={value} onChange={onChange}>
      <div className={`relative mt-1 ${disabled ? 'opacity-50' : ''}`}>
        <Listbox.Button className="relative w-full text-white font-bold cursor-default rounded-full bg-grey-4 py-3 pl-6 pr-6 text-left focus:outline-none  focus-visible:ring-2 focus-visible:ring-gold focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ">
          <span className="flex items-center gap-3">
            {selected ? (
              <>
                <div className="shrink-0">
                  <img
                    src={selected.image}
                    alt={selected.label}
                    className="w-[32px] h-[32px] rounded-full object-cover"
                  />
                </div>
                <span className="block truncate flex-1">{selected.label}</span>
                <span className="text-grey-2">▼</span>
              </>
            ) : (
              <>
                <div className="shrink-0">
                  <div className="h-[32px] rounded-full" />
                </div>
                <span className="block truncate flex-1">Select one</span>
                <span className="text-grey-2">▼</span>
              </>
            )}
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-50"
          leaveFrom="opacity-100"
          leaveTo="opacity-50"
          enter="transition ease-in duration-50"
          enterTo="opacity-100"
          enterFrom="opacity-50"
        >
          <Listbox.Options className="absolute mt-1 max-h-[150px] z-20 w-full overflow-auto rounded-2xl bg-grey-4 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, i) => (
              <Listbox.Option
                key={`option_${i}`}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-grey-3' : ''
                  }`
                }
                value={option.value}
              >
                {({ selected }) => (
                  <span
                    className={`flex items-center gap-3 text-white font-bold ${
                      selected ? 'opacity-50' : ''
                    }`}
                  >
                    <div className="shrink-0">
                      <img
                        src={option.image}
                        alt={option.label}
                        className="w-[32px] h-[32px] rounded-full object-cover"
                      />
                    </div>
                    <span className="block truncate">{option.label}</span>
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SwapPlayerModal;
