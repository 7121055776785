import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Buttons/Button/Button';

const Stakeable = ({ handleNextStep }) => (
  <div className=" transition-all duration-300 ease-in-out translate-x-0 fade-in-fast">
    <h3 className="text-white font-bold text-2xl mb-6">
      Is this Tournament Stakeable?
    </h3>
    <div className="flex gap-3 w-full lg:w-8/12 m-auto border border-grey-3 rounded-2xl p-6">
      <Button
        className="w-full"
        rounded
        text="No, It Is NOT Stakeable"
        variant="tertiary"
        onClick={() => handleNextStep(false)}
      />
      <Button
        className="w-full"
        rounded
        text="Yes, It Is Stakeable"
        variant="primary"
        onClick={() => handleNextStep(true)}
      />
    </div>
  </div>
);

Stakeable.propTypes = {
  handleNextStep: PropTypes.func,
};

export default Stakeable;
