import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Buttons/Button/Button';

const Stakeable = ({
  handleNextStep,
  handleInputChange,
  startDate,
  prevStep,
  step,
}) => {
  const [removeClass, setRemoveClass] = useState(false);
  useEffect(() => {
    setRemoveClass(true);
  }, []);

  return (
    <div
      className=" transition-all duration-300 ease-in-out translate-x-0"
      style={{
        opacity: removeClass ? 1 : 0,
        transform:
          prevStep === step
            ? `translateX(${removeClass ? 0 : -200}px)`
            : `translateX(${removeClass ? 0 : 200}px)`,
      }}
    >
      <h3 className="text-white font-bold text-2xl mb-6">Dates</h3>
      <div className="w-full lg:w-5/12 m-auto border border-grey-3 rounded-2xl p-6">
        <div className="mt-8">
          <p className="text-white font-bold mb-3">
            Start Date (When does this tournament start)
          </p>
          <input
            type="datetime-local"
            className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
            placeholder="Click to enter the start date"
            name="start_date"
            onChange={handleInputChange}
            value={startDate}
          />
        </div>

        <Button
          className="w-full mt-14"
          rounded
          text="Continue"
          variant="primary"
          onClick={() => handleNextStep()}
          disabled={!startDate}
        />
      </div>
    </div>
  );
};

Stakeable.propTypes = {
  handleNextStep: PropTypes.func,
  handleInputChange: PropTypes.func,
  startDate: PropTypes.string,
};

export default Stakeable;
