/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CloseIcon from '../../assets/Icons/close-icon.svg';

const SlidingMenu = ({
  isOpen,
  children,
  handleOpenSlider,
  className,
  setSlideOutWidth,
  setSlideOutHeight,
}) => {
  const listRef = useRef();

  const getListSize = () => {
    const newWidth = listRef.current.clientWidth;
    setSlideOutWidth(newWidth);
    const newHeight = listRef.current.clientHeight;
    setSlideOutHeight(newHeight);
  };

  useEffect(() => {
    getListSize();
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('resize', getListSize);
  }, []);

  return (
    <div
      ref={listRef}
      className={cx(
        'menu bg-black px-3 w-full lg:w-3/12 z-100 overflow-hidden',
        className,
        {
          'menu active': isOpen,
        }
      )}
    >
      <div className="flex justify-end pt-3 absolute right-0 top-14">
        <button
          onClick={handleOpenSlider}
          type="button"
          className="z-50 top-16"
        >
          <img src={CloseIcon} alt="close" />
        </button>
      </div>
      <div className="pt-14 overflow-auto">{children}</div>
    </div>
  );
};

SlidingMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleOpenSlider: PropTypes.func,
  className: PropTypes.string,
  setSlideOutWidth: PropTypes.func,
  setSlideOutHeight: PropTypes.func,
};

export default SlidingMenu;
