/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';

import useAuth from '../../contexts/AuthContext/AuthContext';
import useMarkting from '../../contexts/MarketingContext';

import Layout from '../../components/Layout/Layout';

import Input from '../../components/FormElements/Input';
import Button from '../../components/Buttons/Button/Button';
import Loader from '../../components/Loader/Loader';

const ClipDownloader = () => {
  const { god } = useAuth();
  const { getClipById, getBranch } = useMarkting();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clipUrl, setClipUrl] = useState('');
  const [linkStyle, setLinkStyle] = useState('direct');

  useEffect(() => {
    setError(false);
    setSuccess(false);
    if (clipUrl) {
      try {
        const { host } = new URL(clipUrl);
        setLinkStyle(
          host.includes('localhost') || host.includes('1v1me.com')
            ? 'direct'
            : 'branch'
        );
      } catch (e) {
        // noop
      }
    }
  }, [clipUrl]);

  const handleValueChange = (e) => {
    setClipUrl(e.target.value);
  };

  const downloadFromLink = async (targetUrl, clipId) => {
    setIsLoading(true);
    setError(false);
    setSuccess(false);
    try {
      const resp = await fetch(targetUrl);
      const blob = await resp.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `clip-${clipId}.mp4`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setSuccess(true);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadClip = async () => {
    try {
      setIsLoading(true);
      let url = clipUrl;
      if (linkStyle === 'branch') {
        const { data } = await getBranch(clipUrl);
        url = data.$fallback_url;
      }
      const id = new URL(url).pathname
        .split('/clips')[1]
        .replace(/[^0-9.]+/g, '');
      const data = await getClipById(id);
      await downloadFromLink(data.edit_file_location, data.id);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (Object.keys(god).length === 0) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <Layout Layout showHeaderElements userId={god.id}>
      <div className="m-auto w-8/12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h2 className="text-white font-bold text-2xl mb-3">Clip Downloader</h2>
        <div className="flex mb-1">
          <Input
            placeholder="Paste Clip URL"
            onChange={handleValueChange}
            name="clipURL"
          />
          <Button
            variant="primary"
            text={isLoading ? 'Downloading...' : 'Download Clip'}
            className="rounded-full w-48 ml-3"
            onClick={downloadClip}
            isLoading={isLoading}
            disabled={isLoading || !clipUrl}
          />
        </div>
        <p className="text-xs capitalize text-grey-2 pl-6 mb-3">
          {linkStyle} Link
        </p>
        {success && (
          <p className="text-center text-success">
            Clip successfully downloaded
          </p>
        )}
        {error && (
          <p className="text-center text-red-600">
            There was an error fetching your clip. See the log for details
          </p>
        )}
      </div>
    </Layout>
  );
};

export default ClipDownloader;
