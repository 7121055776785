/* eslint-disable */
import React, { FC } from 'react';
import { Tag } from '../../types';
import convertToDollarAmount from '../../utils/convertToDollar';

const WatchCard: FC<{
  topTeam: string;
  bottomTeam?: string;
  tags: Tag[];
  label: string;
  amount: number;
  amountLabel?: string;
  link?: string;
  image: string;
}> = (props) => {
  return (
    <div className="rounded-lg  py-3 px-4 overflow-hidden bg-black border border-grey-3  w-[400px]">
      <div className="h-40 relative -mx-4 -mt-3 mb-4">
        <img
          src={props.image}
          className="object-cover w-full h-full absolute top-0 left-0"
          alt={'bg'}
        />
        <span className="absolute top-0 left-0 bg-black rounded-lg uppercase text-sm py-1 px-2 m-2 z-20">
          {props.label}
        </span>
      </div>
      <div className="flex gap-4 justify-between mb-4 items-start">
        <p className="font-bold text-white text-lg leading-tight leading">
          {props.topTeam}
          {props.bottomTeam && (
            <span className="block">
              <span className="text-grey-2">vs</span> {props.bottomTeam}
            </span>
          )}
        </p>
        <p className="text-2xl font-bold leading-none">
          {convertToDollarAmount(props.amount)}
          <span className="block text-warning font-normal uppercase text-sm text-right">
            {props.amountLabel || 'Pot'}
          </span>
        </p>
      </div>
      <div className="whitespace-nowrap overflow-x-auto tags flex items-center">
        {props.tags.map((tag, i) => (
          <div
            key={`tag_${i}_${tag.title}`}
            className="bg-grey-4 rounded-full py-1 px-4 text-xs md:text-sm mr-2 inline-flex items-center justify-center gap-2 text-grey-2"
          >
            {tag.image_url && (
              <div
                style={{ width: 18, height: 18 }}
                className="flex items-center"
              >
                <img
                  height={18}
                  width={18}
                  src={tag.image_url}
                  className="object-contain"
                  alt="tag image"
                />
              </div>
            )}
            {tag.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WatchCard;
