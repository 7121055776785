/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import classNames from 'classnames';
import { format, isAfter } from 'date-fns';
import convertToDollarAmount from '../../../utils/convertToDollar';

const StakeSlip = (props) => (
  <>
    {props.stakes.teams.map((t) => (
      <Slip
        key={`stake_${t.name}_${props.event.id}`}
        team={t}
        showHeader
        event={props.event}
      />
    ))}
  </>
);
const Slip = (props) => (
  <div className="bg-grey-4 rounded-2xl overflow-hidden mb-8 text-grey-2">
    {props.showHeader && (
      <div className=" bg-grey-4 transition-opacity ease-in-out !ring-0 !outline-none focus:!outline-2 focus:!outline-gold group">
        <div
          style={{ borderColor: props.event.play_info.game_mode_color }}
          className={classNames('border-l-[16px] ')}
        >
          <div className="flex flex-1 shrink-0 gap-2 sm:gap-4 items-start p-4 pr-0 border-b border-grey-3">
            <div className="flex-1 flex items-center">
              <div className="flex-1">
                <p className="uppercase text-grey-2 text-xs flex items-end gap-1 sm:mb-0">
                  <img
                    src={props.event.play_info.game_icon}
                    alt="player"
                    className="w-[16px] h-[16px] object-cover rounded-full mt-1"
                  />
                  {props.event.play_info.game_name}
                </p>
                <h3 className="text-white font-extrabold uppercase  sm:text-lg flex items-center justify-between gap-4 w-full ">
                  <span className="flex-1">
                    {props.event.play_info.game_mode_title}
                  </span>
                </h3>
              </div>

              <div className="flex items-center gap-2 mr-4">
                {!props.event.completed ? (
                  <span className="text-xs text-white sm:text-sm bg-warning px-1 sm:px-2 rounded font-extrabold uppercase">
                    Live
                  </span>
                ) : (
                  <span className="text-xs sm:text-sm bg-grey-3 px-1 sm:px-2 rounded font-extrabold uppercase">
                    Final
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    <div className="px-4 py-4">
      <h3 className="text-white font-extrabold mb-1">{props.team.name}</h3>
      <p className="text-xs sm:text-sm mb-4">
        Stake: {convertToDollarAmount(props.team.staked, true)}{' '}
        {props.team.promoAmount > 0 && (
          <span className="font-bold text-gold">
            + {convertToDollarAmount(props.team.promoAmount, true)}
          </span>
        )}{' '}
        →{' '}
        {typeof props.team.returned === 'number' ? (
          <>
            You Won:{' '}
            <span
              className={classNames('font-bold text-white', {
                'text-success':
                  props.team.returned >=
                  props.team.staked + props.team.promoAmount,
                'text-warning':
                  props.team.returned <
                  props.team.staked + props.team.promoAmount,
              })}
            >
              {convertToDollarAmount(props.team.returned, true)}
            </span>
          </>
        ) : (
          <>
            To Win:{' '}
            <span className={classNames('font-bold text-white')}>
              {convertToDollarAmount(props.team.toWin, true)}
              {isAfter(new Date(props.event.start_date), new Date()) && '*'}
            </span>
          </>
        )}
      </p>
      <div className="bg-grey-3/50 p-3 sm:px-4 rounded-lg flex items-end border border-grey-3 mb-6">
        <div className="flex-1">
          {props.event.teams.map((t2, i, arr) => (
            <div
              key={`${props.team.name}_${t2.roster.team_name}_${props.event.id}`}
              className={classNames(
                'flex items-center gap-2  font-bold text-white uppercase text-xs sm:text-sm ',
                { 'mb-2 sm:mb-3': i !== arr.length - 1 }
              )}
            >
              <img
                src={
                  t2.roster.players.find((p) => p.type === 'leader')
                    ?.user_profile.profile_image_url
                }
                alt={t2.roster.team_name}
                className="rounded-full object-cover w-[16px] h-[16px] sm:w-[20px] sm:h-[20px]"
              />{' '}
              <p className="flex-1">{t2.roster.team_name}</p>
              <p className="text-[10px] sm:text-xs font-normal text-grey-2">
                {i === arr.length - 1 &&
                  format(
                    props.event.completed && props.event.end_date
                      ? new Date(props.event.end_date)
                      : new Date(props.event.stakes_close_at),
                    'hh:mm aa · MMMM dd'
                  )}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-end py-2">
        <img
          src="https://1v1me.com/1v1me.svg"
          className="w-[69px] sm:w-[80px] saturate-0"
          alt="1v1Me logo"
        />
      </div>
    </div>
  </div>
);

export default StakeSlip;
