/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import classNames from 'classnames';
import React from 'react';
import { Bracket, Seed, SeedItem, SingleLineSeed } from 'react-brackets';

// @ts-ignore
export const formatTeams = (t) =>
  t[0]?.roster
    ? t.map((team) => ({
        id: team.roster.id,
        name: team.roster.team_name,
        display_team_name: team.roster.team_name,
        tournament_players: team.roster.players,
        active_television_channel_id: team.television_channel_id,
        stake: team.stake,
      }))
    : t;

const formatBrackets = (brackets, teams) =>
  brackets.rounds.map((round) => ({
    title: round.name,
    seeds: round.matches.map((match) => {
      const team1 = teams.find((t) => t.id === match.tournament_team_1_id);
      const team2 = teams.find((t) => t.id === match.tournament_team_2_id);

      return {
        id: match.id,
        ...match,
        teams: [team1, team2],
      };
    }),
  }));

const Team = (props) => {
  const getTeamStatus = (team) => {
    if (!team) {
      return '--';
    }
    if (team?.id === props.winnerId && props.bye) {
      return 'Bye';
    }
    if (team?.id === props.winnerId && !props.bye) {
      return 'Won';
    }
    if (props.winnerId && team?.id !== props.winnerId && !props.bye) {
      return 'Lost';
    }
    return '--';
  };
  const teamStatus = getTeamStatus(props.team);
  return (
    <div
      className={classNames('flex justify-between gap-4 text-sm text-grey-2', {
        '!text-white font-bold': props.winnerId === props.team?.id,
      })}
    >
      <span className="flex-1 relative flex items-center gap-1.5">
        <span className=" whitespace-nowrap text-sm">
          {props.team?.name || (!props.bye ? props.label : '--')}
        </span>
      </span>
      {!props.isLive && (
        <span className="text-sm whitespace-nowrap inline-block ml-4">
          {teamStatus}
        </span>
      )}
    </div>
  );
};

const RenderSeed = ({ breakpoint, seed, isSingle }) => {
  const team1 = seed.teams[0];
  const team2 = seed.teams[1];

  const Wrapper = isSingle ? SingleLineSeed : Seed;

  return (
    <Wrapper
      style={{ background: 'transparent', border: 0, boxShadow: 'none' }}
      mobileBreakpoint={breakpoint}
    >
      <SeedItem
        style={{
          background: 'transparent',
          width: '100%',
          boxShadow: 'none',
          border: 0,
        }}
      >
        <p className="text-[10px] mb-1 text-left text-grey-2 pl-[3px]">
          {seed.television_channel_id && (
            <span className="bg-warning text-white rounded inline-block mr-2 font-bold px-2">
              LIVE
            </span>
          )}
          {seed.cell_label}
        </p>
        <div className="relative text-left text-grey-2 ml-[3px]">
          <div className="bg-black border border-grey-4  py-2 px-4 flex gap-4">
            <div className="flex-1 flex flex-col gap-2">
              <Team
                team={team1}
                label={seed.top_label}
                bye={seed.bye}
                isLive={!!seed.television_channel_id}
                winnerId={seed.round_winner_id}
              />
              <Team
                team={team2}
                label={seed.bottom_label}
                bye={seed.bye}
                isLive={!!seed.television_channel_id}
                winnerId={seed.round_winner_id}
              />
            </div>
          </div>
        </div>
      </SeedItem>
    </Wrapper>
  );
};

const BracketV2 = (props) => (
  <div className="overflow-auto no-scrollbar">
    {props.brackets.map((b) => {
      const rounds = formatBrackets(b, formatTeams(props.teams));
      return (
        <div key={`bracket_${b.id}`} className="mb-6">
          {props.brackets.length !== 1 && (
            <p className="font-bold text-2xl sm:px-4 mb-8 text-white sm:text-left text-center">
              {b.name}
            </p>
          )}
          <div className="">
            <Bracket
              rounds={rounds}
              swipeableProps={{
                enableMouseEvents: true,
                animateHeight: true,
              }}
              // @ts-ignore
              renderSeedComponent={(p) =>
                RenderSeed({
                  ...p,
                  isSingle:
                    rounds[p.roundIndex].seeds.length === 1 &&
                    rounds[p.roundIndex + 1]?.seeds.length > 0,
                })
              }
              // eslint-disable-next-line react/no-unstable-nested-components
              roundTitleComponent={(title) => (
                <p className="font-bold text-white text-center sticky">
                  {title}
                </p>
              )}
            />
          </div>
        </div>
      );
    })}
  </div>
);

export default BracketV2;
