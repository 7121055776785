import React, { FC } from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import { Link, useNavigate } from 'react-router-dom';
import Trophy from '../../../assets/Images/trophy.svg';
import convertToDollarAmount from '../../../utils/convertToDollar';
import Button from '../../../components/Buttons/Button/Button';

const PlayEventCard = ({ event, hasButtons }) => {
  const navigate = useNavigate();
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    if (isToday(date)) {
      return `Today ${format(date, '@ h:mmaa')}`;
    }
    if (isTomorrow(date)) {
      return `Tomorrow ${format(date, '@ h:mmaa')}`;
    }
    return format(date, 'MMMM do @ h:mmaa');
  };
  //   to={`/godmode/tournament-management/${event.id}`}
  return (
    <div className="bg-grey-4 block rounded-3xl shadow-black/50 mb-4 ">
      <div
        className={classNames(
          'rounded-t-3xl overflow-hidden !ring-0 !outline-none   border-grey-3 block group'
        )}
      >
        <div
          className={classNames('border-l-[16px] flex gap-4')}
          style={{ borderColor: event.play_info.game_mode_color }}
        >
          <div className="flex flex-1 shrink-0 gap-2 sm:gap-4 items-start p-4 pr-0 ">
            <div className="w-full">
              <p className="uppercase text-grey-2 text-xs font-bold flex items-center gap-1 ">
                <div className=" mt-1">
                  <img
                    src={event.play_info.game_icon}
                    alt="player"
                    height={16}
                    width={16}
                    objectFit="cover"
                    className="rounded-full"
                  />
                </div>
                <p className="truncate">{event.play_info.game_name}</p>
                <div
                  className="ml-2 rounded-full  h-[18px] w-[18px] flex items-center justify-center"
                  style={{
                    backgroundColor:
                      event.play_info.console_color || 'rgb(51, 51, 51)',
                  }}
                >
                  <img
                    src={event.play_info.console_icon_url}
                    alt="player"
                    height={14}
                    width={14}
                    objectFit="cover"
                  />
                </div>
                {event.play_info.console_abbreviation}
              </p>
              <h3 className="text-white font-extrabold uppercase   flex flex-1 items-center justify-between gap-4">
                <span className="flex-1">
                  {event.title || event.play_info.game_mode_title}
                </span>
              </h3>
              <p className="uppercase text-grey-2 font-bold text-[10px]">
                {event.play_info.subtitle}
              </p>
            </div>
            <div className="self-center pr-[16px]">
              <img
                src={Trophy}
                alt="trophy"
                objectFit="contain"
                objectPosition="bottom"
                width={72}
                height={72}
              />
            </div>
          </div>
        </div>
        <div className="pb-4 px-4 pt-4 border-t border-grey-3 flex items-end gap-4">
          <div className="flex-1 flex flex-col">
            {event.team_info?.is_registered && event.state !== 'completed' && (
              <p className="mb-2 uppercase font-bold text-xs">Registered ✓</p>
            )}
            <p className="uppercase text-xs text-grey-2 mb-1">
              {event.state !== 'completed' && 'Starts'}{' '}
              {formatDate(event.start_date)}
            </p>
            <p className="uppercase text-xs text-grey-2">
              {event.entry_info.num_of_teams}/
              {event.entry_info.max_num_of_teams} Teams
            </p>
          </div>
          <div className="text-right">
            <p className="text-grey-2 text-xs">
              {convertToDollarAmount(event.entry_info.entry_fee)} BUY-IN
            </p>
            <p className="text-3xl font-extrabold text-white">
              {convertToDollarAmount(event.prize_info.max_prize_pool)}
              {event.prize_info.current_prize_pool !==
              event.prize_info.max_prize_pool ? (
                <small className="text-xs align-super">*</small>
              ) : (
                ''
              )}
              <span className="uppercase text-xs text-grey-2 font-normal block">
                Total Prizes
              </span>
            </p>
          </div>
        </div>
      </div>
      {hasButtons && (
        <div className="flex items-center justify-end gap-4 pb-4 pr-4">
          <Button
            text="Edit"
            rounded
            className="!bg-grey-3 text-white"
            onClick={() =>
              window.open(`/godmode/tournament-management/${event.id}/manage`)
            }
          />
          <Button
            text="Overview"
            variant="primary"
            rounded
            onClick={() =>
              window.open(`/godmode/tournament-management/${event.id}`)
            }
          />
        </div>
      )}
    </div>
  );
};

export default PlayEventCard;
