import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const SelectGame = ({
  games,
  handleNextStep,
  setGameSelected,
  prevStep,
  step,
}) => {
  const [removeClass, setRemoveClass] = useState(false);
  const handleSetGame = (id, name) => {
    handleNextStep?.();
    setGameSelected({ id, name });
  };

  useEffect(() => {
    setRemoveClass(true);
  }, []);

  return (
    <div
      className="overflow-y-auto no-scrollbar transition-all duration-300 ease-in-out translate-x-0 fade-in-fast"
      style={{
        opacity: removeClass ? 1 : 0,
        transform:
          prevStep === step
            ? `translateX(${removeClass ? 0 : -200}px)`
            : `translateX(${removeClass ? 0 : 200}px)`,
      }}
    >
      <h3 className="text-3xl font-bold text-white mb-4">Select a Game</h3>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3 w-full">
        {games.map(({ name, hero_image_url, id }) => (
          <div
            role="button"
            key={id}
            className=""
            onClick={() => handleSetGame(id, name)}
          >
            <LazyLoadImage alt={id} src={hero_image_url} effect="opacity" />
          </div>
        ))}
      </div>
    </div>
  );
};

SelectGame.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      hero_image_url: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  handleNextStep: PropTypes.func,
  setGameSelected: PropTypes.func,
};

export default SelectGame;
