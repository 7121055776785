import classNames from 'classnames';
import React, { FC, KeyboardEvent, useCallback } from 'react';
import convertToDollarAmount from '../../utils/convertToDollar';

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput: FC<{
  onValueChange: (value: number) => void;
  value: number;
  simple?: boolean;
}> = ({ onValueChange, value, simple }) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  const [dollars, cents] = convertToDollarAmount(value, true).split('.');
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    throw new Error('invalid value property');
  }
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>): void => {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue: number;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString: string =
          value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
      }
      onValueChange(nextValue);
    },
    [onValueChange, value]
  );
  const handleChange = useCallback(() => {}, []);
  const valueDisplay = (value / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div
      className={classNames('w-full relative', {
        'rounded-full px-4 bg-grey-4': !!simple,
      })}
    >
      <h1
        className={classNames(
          'font-black text-white mb-2 sm:mb-6 bg-transparent text-center border-b border-grey-3 py-3',
          {
            'text-7xl sm:text-8xl': dollars.length <= 6,
            'text-6xl sm:text-7xl': dollars.length === 7,
            'text-5xl sm:text-6xl': dollars.length === 8,
            'text-4xl sm:text-5xl': dollars.length === 9,
            'text-3xl sm:text-4xl': dollars.length >= 10,
            '!text-base !text-left !border-0 !font-normal': !!simple,
          }
        )}
      >
        {dollars}
        <span
          className={classNames('text-lg sm:text-2xl text-grey-2 font-normal', {
            '!text-base': !!simple,
          })}
        >
          .{cents}
        </span>
      </h1>
      <input
        className="text-transparent bg-transparent absolute top-0 left-0 w-full h-full !ring-0 !outline-none"
        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={valueDisplay}
      />
    </div>
  );
};

export default CurrencyInput;
