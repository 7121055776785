/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React, { FC, useEffect, useState } from 'react';
import Button from '../../../components/Buttons/Button/Button';
import Loader from '../../../components/Loader/Loader';
import TabGroup from '../../../components/TabGroup.tsx';
import client from '../../../services/client';
import { GameMode } from '../../../types';

const Meta: FC<{
  type: string;
  onTypeChange: (value: string) => void;
  title: string;
  onTitleChange: (value: string) => void;
  squareImage: string;
  onSquareImageChange: (value: string) => void;
  landscapeImage: string;
  onLandscapeImageChange: (value: string) => void;
  cmsId?: string;
  onCmsIdChange?: (value: string) => void;
  bracketType?: string;
  onBracketTypeChange?: (value: string) => void;
  bracketSize?: number;
  onBracketSizeChange?: (value: number) => void;
  payoutId?: number;
  onPayoutIdChange?: (value: number) => void;
  onConfirm: () => void;
  editing?: boolean;
  shoutcast: string;
  onShoutcastChange: (value: string) => void;
}> = ({
  type,
  onTypeChange,
  title,
  onTitleChange,
  squareImage,
  onSquareImageChange,
  landscapeImage,
  onLandscapeImageChange,
  cmsId,
  onCmsIdChange,
  bracketSize,
  bracketType,
  onBracketSizeChange,
  onBracketTypeChange,
  onConfirm,
  payoutId,
  onPayoutIdChange,
  editing,
  shoutcast,
  onShoutcastChange,
}) => {
  const [square, setSquare] = useState('');
  const [landscape, setLandscape] = useState('');
  const [payouts, setPayouts] = useState<GameMode['match_payouts']>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPayouts = async () => {
      try {
        setLoading(true);
        const { data } = await client.get<GameMode['match_payouts']>(
          `${process.env.REACT_APP_API_URL}/api/v1/match_payouts`
        );
        setPayouts(data);
        onPayoutIdChange?.(data[0].id);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    if (!editing) {
      getPayouts();
    } else {
      setSquare(squareImage);
      setLandscape(landscapeImage);
    }
  }, []);
  return (
    <div className="overflow-y-auto no-scrollbar transition-all duration-300 ease-in-out translate-x-0 mb-12 fade-in-fast">
      <h3 className="text-white font-bold text-2xl mb-6">Event Info</h3>
      <div className="w-full lg:w-5/12 m-auto border border-grey-3 rounded-2xl p-6">
        {error && (
          <p className="text-warning text-center">
            There was an issue fecthing the list of payouts
          </p>
        )}
        {loading && (
          <div className="py-12">
            <Loader />
          </div>
        )}
        <div className="mt-8 relative">
          <p className="text-white font-bold mb-3">Title</p>
          <input
            type="text"
            className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
            name="stakers_percentage"
            onChange={(e) => onTitleChange(e.target.value)}
            value={title}
          />
        </div>

        <div className="mt-8 relative">
          <p className="text-white font-bold mb-3">Image (URL)</p>
          <input
            type="text"
            onBlur={() => setSquare(squareImage)}
            className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
            onChange={(e) => onSquareImageChange(e.target.value)}
            value={squareImage}
          />
          {square && (
            <img
              src={square}
              className="h-36 w-36 object-contain mt-3"
              alt="Square"
            />
          )}
        </div>
        <div className="mt-8 relative">
          <p className="text-white font-bold mb-3">Wide Image (URL)</p>
          <input
            type="text"
            onBlur={() => setLandscape(landscapeImage)}
            className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
            onChange={(e) => onLandscapeImageChange(e.target.value)}
            value={landscapeImage}
          />
          {landscape && (
            <img
              src={landscape}
              className="h-36 w-64 object-contain mt-3"
              alt="landscape"
            />
          )}
        </div>
        <div className="mt-8 relative">
          <p className="text-white font-bold mb-3">Shoutcast (Channel Name)</p>
          <input
            type="text"
            className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
            name="stakers_percentage"
            onChange={(e) => onShoutcastChange(e.target.value)}
            value={shoutcast}
          />
        </div>
        {!editing && (
          <>
            <div className="mt-8 relative">
              <p className="text-white font-bold mb-3">CMS FAQ ID</p>
              <input
                type="text"
                className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
                onChange={(e) => onCmsIdChange?.(e.target.value)}
                value={cmsId}
              />
            </div>
            <div className="mt-8 relative">
              <p className="text-white font-bold mb-3">Event Type</p>
              <select
                value={type}
                onChange={(e) => onTypeChange(e.target.value)}
                className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
              >
                <option value="Tournament::MatchEvent">Match</option>
                <option value="Tournament::Bracket">Bracket</option>
              </select>
            </div>
            {type === 'Tournament::Bracket' && (
              <>
                <div className="mt-8 relative">
                  <p className="text-white font-bold mb-3">Payout Type</p>
                  <select
                    value={payoutId}
                    onChange={(e) => onPayoutIdChange?.(Number(e.target.value))}
                    className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
                  >
                    {payouts.map((p) => (
                      <option key={`payout_${p.id}`} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-8 relative">
                  <p className="text-white font-bold mb-3">Bracket Type</p>
                  <select
                    value={bracketType}
                    onChange={(e) => onBracketTypeChange?.(e.target.value)}
                    className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-full"
                  >
                    <option value="single">Single Elimination</option>
                    <option value="double">Double Elimination</option>
                  </select>
                </div>
                <div className="mt-8 relative">
                  <p className="text-white font-bold mb-3">Bracket Size</p>
                  <TabGroup
                    tabs={['4', '8', '16', '32', '64']}
                    activeTab={String(bracketSize)}
                    onChange={(val) => onBracketSizeChange?.(Number(val))}
                  />
                </div>
              </>
            )}
          </>
        )}

        <Button
          className="w-full mt-14"
          rounded
          text="Continue"
          variant="primary"
          onClick={() => onConfirm()}
          disabled={
            !title ||
            (landscapeImage ? !squareImage : false) ||
            (squareImage ? !landscapeImage : false)
          }
        />
      </div>
    </div>
  );
};

export default Meta;
