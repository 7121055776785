/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import { useDebouncedCallback } from 'use-debounce';
import convertToDollarAmount from '../../utils/convertToDollar';

import useWallet from '../../contexts/WalletContext/WalletContext';
import useAuth from '../../contexts/AuthContext/AuthContext';
import useUsers from '../../contexts/UserContext/UserContext';

import Layout from '../../components/Layout/Layout';
import TransactionsList from '../../features/Wallet/TransactionsList';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/BaseModal/BaseModal';
import Pill from '../../components/Pill/Pill';
import Button from '../../components/Buttons/Button/Button';
import RefundTransactionModal from '../../features/Wallet/RefundTransaction';
import WithdrawModal from './WithdrawModal.tsx';
import DonateModal from './DonateModal.tsx';
import WithdrawIncreaseModal from './WithdrawIncreaseModal.tsx';
import client from '../../services/client';

const Wallet = () => {
  const { userId } = useParams();
  const { user, getSingleUser } = useUsers();
  const {
    transactions,
    wallet,
    PayPalLink,
    getTransactions,
    getWallet,
    paginateTransactions,
    refundStake,
    setPage,
    getPaypalURL,
    loading,
    setTransactions,
  } = useWallet();

  const [values, setValues] = useState({ min: 0, max: 20000 });
  const [filtersType, setFiltersType] = useState([]);
  const [appliedFilters, setIsAppliedFilters] = useState(false);
  const [preExpanded, setPreExpanded] = useState([1, 2]);
  const [transactionToRefund, setTransactionToRefund] = useState(null);

  const [filterTransactionsModal, setFilterTransactionModal] = useState(false);
  const [isPayPalModalOpen, setIsPayPalModalOpen] = useState(false);
  const [isRefundTransactionModalOpen, setRefundTransactionModalOpen] =
    useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [donateOpen, setDonateOpen] = useState(false);
  const [withdrawIncreaseOpen, setWithdrawIncreaseOpen] = useState(false);
  const [stats, setStats] = useState({
    total_deposit_amount: 0,
    num_of_deposits: 0,
    total_withdrawal_amount: 0,
    num_of_withdrawals: 0,
    force_withdrawal_amount: 0,
  });

  const { god } = useAuth();

  const scrollRef = useRef();

  useEffect(() => {
    if (userId) {
      getSingleUser(userId);
      getTransactions(userId);
      getWallet(userId);
      client
        .get(
          `${process.env.REACT_APP_API_URL}/admin/v1/wallets/stats?user_id=${userId}`
        )
        .then(({ data }) => setStats(data))
        .catch(() => {});
    }
  }, [userId]);

  useEffect(() => {
    if (PayPalLink && isPayPalModalOpen) {
      setIsPayPalModalOpen(false);
      window.open(PayPalLink.url);
    }
  }, [PayPalLink]);

  const debouncedPaginatedTransactionSearch = useDebouncedCallback(
    () => {
      let transactionsToFilter = filtersType
        .map((key) => `types[]=${key}`)
        .join('&');
      if (values.min !== 0) {
        transactionsToFilter = `${transactionsToFilter}&min_amount=${values.min}&`;
      }

      if (values.max !== 20000) {
        transactionsToFilter = `${transactionsToFilter}&max_amount=${values.max}`;
      }
      paginateTransactions(userId, transactionsToFilter);
    },
    // delay in ms
    300
  );

  const handleGetPayPalURL = (transactionId) => {
    getPaypalURL(transactionId);
    setIsPayPalModalOpen(true);
  };

  const handleTransactionsPriceFilter = (value) => {
    setValues(value);

    setIsAppliedFilters(true);
  };

  const handleFilterTransaction = (type) => {
    if (type === 'deposit' && !filtersType.includes('deposit')) {
      setFiltersType([...filtersType, 'deposit']);
      setIsAppliedFilters(true);
    } else if (type === 'withdrawal' && !filtersType.includes('withdrawal')) {
      setFiltersType([...filtersType, 'withdrawal']);

      setIsAppliedFilters(true);
    } else if (type === 'stake' && !filtersType.includes('stake')) {
      setFiltersType([...filtersType, 'stake']);

      setIsAppliedFilters(true);
    } else if (type === 'wager' && !filtersType.includes('wager')) {
      setFiltersType([...filtersType, 'wager']);

      setIsAppliedFilters(true);
    } else if (type === 'tournament' && !filtersType.includes('tournament')) {
      setFiltersType([...filtersType, 'tournament']);
      setIsAppliedFilters(true);
    } else {
      const removedFilter = filtersType.filter((e) => e !== type); // will return ['A', 'C']

      setPage(1);
      if (removedFilter.length === 0) {
        setIsAppliedFilters(false);
      }
      setFiltersType([...removedFilter]);
    }
  };

  const handleRemoveFilter = (type) => {
    const removedFilter = filtersType.filter((e) => e !== type);
    let transactionsToFilter = removedFilter
      .map((key) => `types[]=${key}`)
      .join('&');

    if (values.min !== 0) {
      transactionsToFilter = `${transactionsToFilter}&min_amount=${values.min}`;
    }

    if (values.max !== 20000) {
      transactionsToFilter = `${transactionsToFilter}&max_amount=${values.max}`;
    }

    getTransactions(userId, transactionsToFilter, 1);
    setPage(1);
    setFiltersType([...removedFilter]);

    if (removedFilter.length === 0) {
      setIsAppliedFilters(false);
    }
  };

  const handleRemoveMinandMaxFilter = () => {
    const transactionsToFilter = filtersType
      .map((key) => `types[]=${key}`)
      .join('&');

    setValues({ min: 0, max: 20000 });
    getTransactions(userId, transactionsToFilter, 1);
    setPage(1);
    if (filtersType.length === 0) {
      setIsAppliedFilters(false);
    }
  };

  const handleApplyFilter = () => {
    // Builds string from filters types[]=deposit&types[]=withdrawl&types[]=stake&types[]=wager
    let transactionsToFilter = filtersType
      .map((key) => `types[]=${key}`)
      .join('&');

    if (values.min !== 0) {
      transactionsToFilter = `${transactionsToFilter}&min_amount=${values.min}`;
    }

    if (values.max !== 20000) {
      transactionsToFilter = `${transactionsToFilter}&max_amount=${values.max}`;
    }

    getTransactions(userId, transactionsToFilter, 1);
    setFilterTransactionModal(false);
    if (filtersType.length > 0 && values.min === 0 && values.max === 20000) {
      setIsAppliedFilters(true);
    } else if (
      filtersType.length > 0 &&
      (values.min !== 0 || values.max !== 20000)
    ) {
      setPreExpanded([1, 2]);
      setIsAppliedFilters(true);
    } else if (
      filtersType.length === 0 &&
      (values.min !== 0 || values.max !== 20000)
    ) {
      setIsAppliedFilters(true);
    }
  };

  const handleClearFilters = () => {
    getTransactions(userId, '', 1);
    setFiltersType([]);
    setValues({ min: 0, max: 20000 });
    setIsAppliedFilters(false);
    setPage(1);
  };

  const handleRefund = (id) => {
    setTransactions((tr) => tr.filter((t) => t.id !== id));
    getWallet(userId);
  };

  const onScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      debouncedPaginatedTransactionSearch();
    }
  };

  const globalUser = Object.keys(user).length ? user : god;

  if (Object.keys(globalUser).length === 0 || wallet === undefined) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }
  const [dollars, cents] = convertToDollarAmount(wallet.balance, true).split(
    '.'
  );
  const promo = convertToDollarAmount(wallet.promotion_balance, true);

  return (
    <Layout showHeaderElements userId={globalUser?.user?.id}>
      <main className="max-w-7xl mx-auto px-4 lg:pt-24 sm:pt-8 pt-12">
        <div className="lg:flex gap-24 items-start  sm:px-24 lg:px-0">
          <div className="text-center lg:w-1/3 max-w-md lg:sticky lg:top-[100px] mx-auto mb-8 sm:mb-16 lg:mb-0">
            <h3 className="sm:text-2xl uppercase mb-2 sm:mb-6 text-grey-2">
              Total Balance
            </h3>
            <h1
              className={classNames(
                'font-black text-white mb-2 sm:mb-6 truncate',
                {
                  'text-7xl sm:text-8xl': dollars.length <= 6,
                  'text-6xl sm:text-7xl': dollars.length === 7,
                  'text-5xl sm:text-6xl': dollars.length === 8,
                  'text-4xl sm:text-5xl': dollars.length === 9,
                  'text-3xl sm:text-4xl': dollars.length >= 10,
                }
              )}
            >
              {dollars}
              <span className="text-lg sm:text-2xl text-grey-2 font-normal">
                .{cents}
              </span>
            </h1>
            {wallet.promotion_balance > 0 && (
              <p className="sm:text-sm text-grey-2 mb-2">{`+${promo} Promo`}</p>
            )}
            {wallet.deposit_pending_amount > 0 && (
              <p className="sm:text-sm text-grey-2 mb-8">
                {`+${convertToDollarAmount(
                  wallet.deposit_pending_amount,
                  true
                )} Pending`}
              </p>
            )}
            <Button
              variant="info"
              text="Donate"
              className="!w-full mb-4"
              rounded
              onClick={() => setDonateOpen(true)}
            />
            <Button
              variant="tertiary"
              text={`Edit ${convertToDollarAmount(
                wallet?.max_weekly_withdrawal_amount || 0
              )} Withdrawal Limit`}
              className="!w-full mb-4 !text-success"
              rounded
              onClick={() => setWithdrawIncreaseOpen(true)}
            />
            <Button
              variant="tertiary"
              text="Force Withdraw"
              className="!w-full mb-12 !text-warning"
              rounded
              onClick={() => setWithdrawOpen(true)}
            />
            <div className="bg-grey-4 rounded-lg p-4 text-left text-grey-2">
              <h3 className="font-extrabold text-white text-2xl mb-8">Stats</h3>
              <p>
                <b className="text-white font-bold">Total Deposits: </b>
                {convertToDollarAmount(stats.total_deposit_amount)}
              </p>
              <p>
                <b className="text-white font-bold">Instant Deposit Limit: </b>
                {convertToDollarAmount(wallet?.instant_deposit_limit)}
              </p>
              <p>
                <b className="text-white font-bold">
                  Instant Deposit Remaining:{' '}
                </b>
                {convertToDollarAmount(wallet?.instant_deposit_remaining)}
              </p>
              <p>
                <b className="text-white font-bold">Max Deposit Amount: </b>
                {convertToDollarAmount(wallet?.max_deposit_amount)}
              </p>
              <p className="mb-8">
                <b className="text-white font-bold">Number of Deposits: </b>
                {stats.num_of_deposits}
              </p>
              <p>
                <b className="text-white font-bold">Total Withdrawals: </b>
                {convertToDollarAmount(stats.total_withdrawal_amount)}
              </p>
              <p>
                <b className="text-white font-bold">Number of Withdrawals: </b>
                {stats.num_of_withdrawals}
              </p>
              <p className="mb-8">
                <b className="text-white font-bold">
                  Remaining Weekly Amount:{' '}
                </b>
                {convertToDollarAmount(wallet?.withdrawable_balance)}
              </p>
              <p>
                <b className="text-white font-bold">Max Force Withdrawal: </b>
                {convertToDollarAmount(stats.force_withdrawal_amount)}
              </p>
            </div>
          </div>
          <div
            className="flex-1 no-scrollbar pb-12"
            onScroll={onScroll}
            ref={scrollRef}
            style={{ height: 'calc(100vh - 185px)', overflowY: 'scroll' }}
          >
            <div className="mb-4 sm:mb-12">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-bold text-white text-2xl sm:text-3xl">
                  {globalUser?.user?.username}&apos;s Recent Transactions
                </h3>
                <div
                  role="button"
                  onClick={() => setFilterTransactionModal(true)}
                  className="text-secondary cursor-pointer text-base"
                >
                  Filter
                </div>
              </div>
              <div className="flex items-center w-full gap-3">
                {!appliedFilters && filtersType.length === 0 ? (
                  <Pill className="flex items-center bg-grey-4 flex-shrink-0">
                    <p className="text-base text-grey-2 px-1 py-1">
                      All Transaction Types
                    </p>
                  </Pill>
                ) : (
                  appliedFilters &&
                  filtersType.map((type) => (
                    <Pill
                      onClick={() => handleRemoveFilter(type)}
                      className="flex items-center bg-white flex-shrink-0"
                      key={type}
                    >
                      <p className="text-base text-black font-bold px-1 py-1 capitalize">
                        {type}
                      </p>
                    </Pill>
                  ))
                )}
                {appliedFilters &&
                  (values.min !== 0 || values.max !== 20000) && (
                    <Pill
                      onClick={handleRemoveMinandMaxFilter}
                      className="flex items-center bg-white flex-shrink-0"
                    >
                      <p className="text-base text-black font-bold px-1 py-1">
                        {convertToDollarAmount(values.min)}{' '}
                        {`- ${convertToDollarAmount(values.max)}`}
                      </p>
                    </Pill>
                  )}
                {values.min === 0 && values.max === 20000 && (
                  <Pill className="flex items-center bg-grey-4 flex-shrink-0">
                    <p className="text-base text-grey-2 px-1 py-1">
                      All Amounts
                    </p>
                  </Pill>
                )}
              </div>
            </div>
            <TransactionsList
              transactions={transactions}
              setTransactionToRefund={setTransactionToRefund}
              handleGetPayPalURL={handleGetPayPalURL}
              setRefundTransactionModalOpen={setRefundTransactionModalOpen}
              onRefund={handleRefund}
            />
            <Button
              text="Load More"
              onClick={debouncedPaginatedTransactionSearch}
              variant="grey"
              rounded
              disabled={loading}
              isLoading={loading}
            />
          </div>
        </div>
      </main>
      <Modal
        modalIsOpen={filterTransactionsModal}
        closeModal={() => {
          setFilterTransactionModal(false);
          handleApplyFilter();
        }}
        header="Filter"
        backgroundColor="bg-black"
        height="375px"
        className="relative"
      >
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={preExpanded}
        >
          <AccordionItem uuid={1} className="py-4">
            <AccordionItemHeading>
              <AccordionItemButton className="text-white font-bold relative">
                <h2 className="text-white text-2xl font-bold">
                  Transaction Type
                </h2>
                <div className="accordion__arrow" role="presentation" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="mt-2">
              <div className="flex items-center w-full">
                <Pill
                  onClick={() => handleFilterTransaction('deposit')}
                  className={classNames(
                    'flex items-center mr-2 flex-shrink-0',
                    {
                      'bg-white text-black font-bold':
                        filtersType.includes('deposit'),
                      'bg-grey-4 text-grey-2': !filtersType.includes('deposit'),
                    }
                  )}
                >
                  <p className="text-base px-1 py-1">Deposit</p>
                </Pill>
                <Pill
                  onClick={() => handleFilterTransaction('withdrawal')}
                  className={classNames(
                    'flex items-center mr-2 flex-shrink-0',
                    {
                      'bg-white text-black font-bold':
                        filtersType.includes('withdrawal'),
                      'bg-grey-4 text-grey-2':
                        !filtersType.includes('withdrawal'),
                    }
                  )}
                >
                  <p className="text-base px-1 py-1">Withdrawal</p>
                </Pill>
                <Pill
                  onClick={() => handleFilterTransaction('stake')}
                  className={classNames(
                    'flex items-center mr-2 flex-shrink-0',
                    {
                      'bg-white text-black font-bold':
                        filtersType.includes('stake'),
                      'bg-grey-4 text-grey-2': !filtersType.includes('stake'),
                    }
                  )}
                >
                  <p className="text-base px-1 py-1">Stake</p>
                </Pill>
                <Pill
                  onClick={() => handleFilterTransaction('wager')}
                  className={classNames(
                    'flex items-center mr-2 flex-shrink-0',
                    {
                      'bg-white text-black font-bold':
                        filtersType.includes('wager'),
                      'bg-grey-4 text-grey-2': !filtersType.includes('wager'),
                    }
                  )}
                >
                  <p className="text-base px-1 py-1">Wager</p>
                </Pill>
              </div>
              <Pill
                onClick={() => handleFilterTransaction('tournament')}
                className={classNames('flex items-center mr-2 w-28 mt-3', {
                  'bg-white text-black font-bold':
                    filtersType.includes('tournament'),
                  'bg-grey-4 text-grey-2': !filtersType.includes('tournament'),
                })}
              >
                <p className="text-base py-1">Tournament</p>
              </Pill>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid={2} className="py-4">
            <AccordionItemHeading>
              <AccordionItemButton className="text-white font-bold relative">
                <h2 className="text-white text-2xl font-bold">Amount</h2>
                <div className="accordion__arrow" role="presentation" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="mt-4 relative">
              <div className="flex justify-between w-full">
                <p className="text-base text-grey-2 py-1 text-center">
                  {convertToDollarAmount(values.min)}
                </p>
                <p className="text-base text-grey-2 py-1">
                  {convertToDollarAmount(values.max)}+
                </p>
              </div>
              <div className="mt-6 pl-3 pr-4">
                <InputRange
                  maxValue={20000}
                  minValue={0}
                  value={values}
                  onChange={(val) => handleTransactionsPriceFilter(val)}
                  step={500}
                />
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <div className="flex justify-between gap-3 mt-6 absolute bottom-3 w-full">
          <Button
            variant="tertiary"
            text="Clear Filter"
            className="w-full"
            rounded
            onClick={handleClearFilters}
          />
          <Button
            variant="primary"
            text="Apply Filter"
            className="w-full"
            rounded
            onClick={handleApplyFilter}
          />
        </div>
      </Modal>
      <Modal
        modalIsOpen={isPayPalModalOpen}
        backgroundColor="bg-black"
        className="relative"
        closeModal={() => setIsPayPalModalOpen(false)}
      >
        <div className="mt-16">
          <Loader />
          <div className="text-center mt-12">
            <h3 className="text-white text-3xl font-bold">Getting Link</h3>
            <p className="text-grey-2 mt-3">
              This transaction will open in a new tab shortly
            </p>
          </div>
        </div>
      </Modal>
      <RefundTransactionModal
        isRefundTransactionModalOpen={isRefundTransactionModalOpen}
        setRefundTransactionModalOpen={setRefundTransactionModalOpen}
        transactionToRefund={transactionToRefund}
        refundStake={refundStake}
      />
      {userId && (
        <WithdrawModal
          userId={Number(userId)}
          isOpen={withdrawOpen}
          onClose={() => setWithdrawOpen(false)}
          maxAmount={wallet?.balance || 0}
          sources={wallet?.payment_sources || []}
          onSuccess={() => {
            getWallet(userId);
            getTransactions(userId);
          }}
        />
      )}
      {userId && (
        <WithdrawIncreaseModal
          userId={Number(userId)}
          isOpen={withdrawIncreaseOpen}
          onClose={() => setWithdrawIncreaseOpen(false)}
          currAmount={wallet?.max_weekly_withdrawal_amount || 0}
          onSuccess={() => {
            getWallet(userId);
          }}
        />
      )}
      {userId && (
        <DonateModal
          userId={Number(userId)}
          isOpen={donateOpen}
          onClose={() => setDonateOpen(false)}
          onSuccess={() => {
            getWallet(userId);
            getTransactions(userId);
          }}
        />
      )}
    </Layout>
  );
};

export default Wallet;
