import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

const HoldButton: FC<{
  text: string;
  disabled?: boolean;
  variant: 'primary' | 'warning' | 'success' | 'grey';
  onConfirm: () => void;
  square?: boolean;
  timeout: number;
}> = ({ onConfirm, disabled, text, variant, timeout, square }) => {
  const [counter, setCounter] = useState(0);
  const [complete, setComplete] = useState(false);
  const intervalRef = React.useRef<any>(null);
  const stopCounter = () => {
    if (intervalRef.current) {
      setCounter(0);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if ((counter / (timeout + 10)) * 100 === 100) {
      setComplete(true);
      onConfirm();
      stopCounter();
    }
  }, [counter, onConfirm, timeout]);

  const startCounter = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 10);
  };

  return (
    <div
      className={classNames(
        'relative  bg-opacity-25 w-full rounded-full overflow-hidden border transition-opacity ease-out',
        {
          'bg-success border-success': variant === 'success',
          'bg-warning border-warning': variant === 'warning',
          'bg-primary border-primary': variant === 'primary',
          'bg-grey-3 border-grey-3': variant === 'grey',
          'opacity-75': complete,
          '!rounded-2xl': square,
        }
      )}
    >
      <div
        className={classNames('absolute h-full z-0 ease-in-out', {
          'transition-all': counter === 0,
          'bg-success': variant === 'success',
          'bg-warning': variant === 'warning',
          'bg-primary': variant === 'primary',
          'bg-grey-3': variant === 'grey',
        })}
        style={{
          width: `${complete ? 100 : Math.round((counter / timeout) * 100)}%`,
        }}
      />
      <button
        type="button"
        className={classNames(
          'w-full py-3 z-10 font-semibold relative disabled:opacity-75 select-none !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-blue',
          {
            'text-white': variant !== 'primary',
            'text-black': variant === 'primary',
            '!rounded-2xl': square,
          }
        )}
        onMouseDown={startCounter}
        onMouseUp={stopCounter}
        onTouchStart={startCounter}
        onTouchEnd={stopCounter}
        onMouseLeave={stopCounter}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
};

export default HoldButton;
