const convertToDollarAmount = (amount, includeCents) => {
  const dollars = amount / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: includeCents ? 2 : 0,
    minimumFractionDigits: includeCents ? 2 : 0,
  });
};

export default convertToDollarAmount;
