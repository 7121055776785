import React, { FC, ReactNode } from 'react';

const EmptyState = ({ icon, header, text }) => (
  <div className="flex flex-col items-center justify-center border-2 border-dashed border-grey-3 rounded-lg py-8 sm:py-14 px-4 text-grey-2">
    {icon && <div className="relative sm:block hidden mb-8">{icon}</div>}
    {header && (
      <h3 className="relative sm:block hidden mb-2 font-bold text-white text-3xl text-center">
        {header}
      </h3>
    )}
    <p className="text-base sm:text-xl text-center">{text}</p>
  </div>
);

export default EmptyState;
