/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import Loader from '../Loader/Loader';

import CloseIcon from '../../assets/Icons/close.svg';

const BaseModal = ({
  header,
  children,
  modalIsOpen,
  closeModal,
  paginateData,
  loading,
  height = '400px',
  customComponent,
  scrollRef,
  fullPage,
  large,
  fullWidth,
  backgroundColor = 'bg-neutral-900',
  className,
  xl,
}) => {
  const debouncedUserSearch = useDebouncedCallback(
    () => {
      paginateData();
    },
    // delay in ms
    500
  );

  const onScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      debouncedUserSearch();
    }
  };

  return (
    <Transition appear show={modalIsOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 ${className}`}
        onClose={closeModal || (() => {})}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames('fixed inset-0 ', {
              'bg-black bg-opacity-75': backgroundColor !== 'bg-black',
              'bg-grey-4 bg-opacity-50': backgroundColor === 'bg-black',
            })}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={classNames(
              'flex min-h-full items-center justify-center text-center',
              { 'p-4': !fullPage }
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'w-full transform overflow-hidden text-left align-middle shadow-xl transition-all relative flex flex-col bg-black border border-grey-4',
                  backgroundColor,
                  {
                    'max-w-lg pt-8 pb-3 rounded-2xl': fullWidth,
                    'max-w-lg pt-8 px-8 pb-3 rounded-2xl':
                      !fullPage && !fullWidth,
                    'max-w-2xl !p-0 rounded-2xl': large,
                    'max-w-7xl !p-0 rounded-2xl': xl,
                    'h-screen overflow-auto': !!fullPage,
                  }
                )}
              >
                <div className={classNames({ 'px-8': fullWidth })}>
                  {closeModal && (
                    <button
                      type="button"
                      className={classNames('absolute top-0 right-0 z-20', {
                        'p-8': !fullPage,
                        'p-4': !!fullPage,
                      })}
                      onClick={closeModal}
                    >
                      <img src={CloseIcon} alt="close" />
                    </button>
                  )}
                  {header && (
                    <Dialog.Title
                      as="h3"
                      className={classNames(
                        'text-3xl font-bold text-white mb-2',
                        {
                          'px-4 py-2': fullPage,
                          'pt-6 pl-8': large,
                        }
                      )}
                    >
                      {header}
                    </Dialog.Title>
                  )}
                  <div className="">{customComponent}</div>
                </div>
                <div className="flex-1">
                  <div
                    className="modal-content relative"
                    onScroll={onScroll}
                    style={{ height, overflowY: 'scroll' }}
                    ref={scrollRef}
                  >
                    {children}
                    {loading && <Loader />}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

BaseModal.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  customComponent: PropTypes.node,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  paginateData: PropTypes.func,
  loading: PropTypes.bool,
  height: PropTypes.string,
  scrollRef: PropTypes.object,
  fullPage: PropTypes.bool,
  large: PropTypes.bool,
  xl: PropTypes.bool,
  className: PropTypes.string,
};

export default BaseModal;
